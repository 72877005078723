import React, { useEffect, useState } from "react";
import calendar from "../../assets/image/calendar.svg";
import SearchHistory from "../Buttons/SearchHistory";
import * as S from "./styles";
import { format } from 'date-fns';
import { useApi } from 'hooks/useApis';
import { scrollToTop } from "utils";
import { CircularProgress } from "@mui/material";


const HistoryAgentView = ({ agentId, changeVersion }) => {
  const api = useApi();
  const [history, setHistory] = useState(null);
  const [selectedVersion, setSelectedVersion] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  console.log( 'ID AGENT', agentId )

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const data = await api.fetchAgentHistory(agentId);
        setHistory(data.results);
        console.log(data, 'DATA')
        setIsLoading(false);
      } catch (error) {
        console.error('Erro ao buscar o histórico:', error);
      }
    };

    if (agentId) {
      fetchData();
    }
  }, [agentId]); // if you add api here, it will cause a loop

  const handleViewVersion = (historyVersion, selected) => {
    historyVersion.id = historyVersion.agent.id; //tem que mudar aqui, falta descobrir
    console.log("HISTORY VERSION", historyVersion )
    setSelectedVersion(selected);
    scrollToTop();
    changeVersion(historyVersion);
  }


  return (
    <S.HistoricViewContainer>
      <S.HistoricViewHeader>
        <S.HistoricViewBox>
          <img className="calendarIcon" alt="ícone do calendário" src={calendar} />
          <p className="historicText">Histórico do agente</p>
        </S.HistoricViewBox>
      </S.HistoricViewHeader>
      {isLoading ? (
        <S.LoadingContainer>
          <CircularProgress size={50} />
        </S.LoadingContainer>
      ) : (
      <S.HistoricViewTable>
        <tbody>
          <tr>
            <th></th>
            <th>Data da Mudança</th>
            <th>Agente Responsável</th>
            <th>Ação</th>
          </tr>
          {history && Array.isArray(history) && history.map((history, index) => (
            <tr key={index}>
              <td>
                <S.TdTimeline outlined={index === selectedVersion}>
                  <span className="dot"></span>
                  {index < history.length - 1 && <span className="connector"></span>}
                </S.TdTimeline>
              </td>
              <td>
                <div className="changeDateCell">
                  <span>{index === 0 ? `Última mudança:` : "Mudança:"}</span>
                  <span>
                    {format(new Date(history.date_history), 'dd/MM/yyyy HH:mm:ss')}
                  </span>
                </div>
              </td>
              <td>{history.agent_history?.name ?? 'Nome não disponível'}</td>
              <td>
                <SearchHistory onClick={() => handleViewVersion(history, index)} />
              </td>
            </tr>
          ))}
        </tbody>
      </S.HistoricViewTable>
      )}
    </S.HistoricViewContainer>
  );
};

export default HistoryAgentView;