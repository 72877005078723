import React, { useState, useEffect } from 'react';
import * as S from './styles';
import CompTextField from 'components/CompTextField';
import CompDateField from '../../CompDateField';
import { convertDateFromApi } from 'utils';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import FillErrorAlert from 'components/Alerts/FillErrorAlert';
import api from 'services/api';
import { CircularProgress } from "@mui/material";


const Forms = ({ agentData, isView, isEdit, register, unregister, watch, setValue, errors }) => {
  const [repeatPasswordError, setRepeatPasswordError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isCpfInvalid, setIsCpfInvalid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [passwordValidations, setPasswordValidations] = useState({
    minLength: false,
    hasLetters: false,
    hasNumbers: false,
    passwordsMatch: false,
  });
  const [repeatPasswordValidations, setRepeatPasswordValidations] = useState({
    minLength: false,
    hasLetters: false,
    hasNumbers: false,
    passwordsMatch: false,
  });

  const checkPassword = () => {
    const password = (watch('password') || '').trim();
    const repeatPassword = (watch('repeat_password') || '').trim();

    setPasswordValidations({
      minLength: password.length >= 9,
      hasLetters: /[a-zA-Z]/.test(password),
      hasNumbers: /[0-9]/.test(password),
      passwordsMatch: password === repeatPassword,
    });
    setRepeatPasswordValidations({
      minLength: repeatPassword.length >= 9,
      hasLetters: /[a-zA-Z]/.test(repeatPassword),
      hasNumbers: /[0-9]/.test(repeatPassword),
      passwordsMatch: password === repeatPassword,
    });
  };
  const cleanCPF = (cpf) => cpf.replace(/\D/g, '');

  const checkCpfExists = async (cpf) => {
    const cpfLimpo = cleanCPF(cpf);

    setIsLoading(true);
    try {
      const response = await api.get(`/controller/agents/?CPF=${cpfLimpo}`);
      const data = response.data;

      // Verifique o campo "count"
      if (data.count > 0) {
        setValue("CPF", "")
        setIsCpfInvalid(true);
      } else {
        setIsCpfInvalid(false); 
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.error('Erro ao verificar o CPF:', error);
    }
  };


  const cpf = watch('CPF');

  useEffect(() => {
    if (cpf?.length === 14) {
      // console.log('CPF com 11 caracteres detectado.');
      checkCpfExists(cpf);
    }
  }, [cpf]);

  useEffect(() => {
    if (isEdit && agentData?.user?.email) {
      setValue('email', agentData.user.email);
    }
  }, [isEdit, agentData, setValue]);

  useEffect(() => {
    checkPassword();
  }, [watch('password'), watch('repeat_password')]);


  return (
    <S.Form>
      <S.FormRow>
        {/* Linha 1 */}
        <CompTextField
          isView={isView}
          textLabel="NOME COMPLETO:"
          required
          name={'name'}
          register={register}
          unregister={unregister}
          style={{ flexGrow: '1' }}
          apiText={agentData?.name}
          placeholder="Digite o nome completo"
          error={errors.name}
          helperText={errors.name && errors.name.message}
        />
        <CompTextField
          textLabel="CPF:"
          isView={isView}
          required
          name={'CPF'}
          register={register}
          unregister={unregister}
          style={{ flexGrow: '1' }}
          mask="999.999.999-99"
          placeholder="xxx-xxx-xxx-xx"
          apiText={agentData?.CPF}
          error={errors.CPF}
          helperText={errors.CPF && errors.CPF.message}
          InputProps={{
            endAdornment: (
              isLoading && (
                <InputAdornment position="end">
                  <CircularProgress size={20} />
                </InputAdornment>
              )
            ),
          }}
        />
        <CompTextField
          isView={isView}
          textLabel="RG:"
          required
          type='number'
          style={{ flexGrow: '1' }}
          name={'rg'}
          register={register}
          unregister={unregister}
          apiText={agentData?.rg}
          error={errors.rg}
          helperText={errors.rg && errors.rg.message}
        />
      </S.FormRow>
      <S.FormRowWithMarginDados>
        {/* Linha 2 */}

        <CompDateField
          isView={isView}
          labelText="DATA DE NASCIMENTO:"
          apiText={agentData?.birth_day}
          required
          name="birth_day"
          register={register}
          unregister={unregister}
          error={errors.birth_day}
          helperText={errors.birth_day && errors.birth_day.message}
        />
        <CompTextField
          isView={isView}
          textLabel="TELEFONE:"
          mask="(99) 9 9999-9999"
          name={'phone_number'}
          style={{ flexGrow: '1' }}
          register={register}
          unregister={unregister}
          apiText={agentData?.phone_number}
          placeholder="(99) 9 9999-9999"
        />
        <CompTextField
          isView={isView}
          textLabel="CELULAR:"
          mask="(99) 9 9999-9999"
          required
          style={{ flexGrow: '1' }}
          name="mobile_phone_number"
          register={register}
          unregister={unregister}
          apiText={agentData?.mobile_phone_number}
          placeholder="(99) 9 9999-9999"
          error={errors.mobile_phone_number}
          helperText={errors.mobile_phone_number && errors.mobile_phone_number.message}
        />
      </S.FormRowWithMarginDados>
      <S.FormRowWithMarginDados>
        <CompTextField
          isView={isView}
          textLabel="EMAIL:"
          required
          style={{ flexGrow: '1' }}
          name="email"
          type='email'
          register={register}
          unregister={unregister}
          helperText={errors.email && errors.email.message}
          error={errors.email}
          //onBlur={validateEmail}
          apiText={agentData?.user?.email}
          placeholder="Digite o email"
        />
        {!isView && !isEdit && (
          <>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <CompTextField
                isView={isView}
                textLabel="SENHA:"
                required
                style={{ flexGrow: '1' }}
                type={showPassword ? 'text' : 'password'}
                name="password"
                register={register}
                unregister={unregister}
                onBlur={checkPassword}
                helperText={errors.password && errors.password.message}
                error={errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleTogglePasswordVisibility}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <div style={{ display: "flex", flexDirection: "column", width: "auto" }}>
                <S.CheckPassword style={{ color: passwordValidations.minLength ? 'green' : 'gray' }}><CheckCircleOutlineIcon /><span>A senha deve ter no mínimo 9 caracteres</span></S.CheckPassword>
                <S.CheckPassword style={{ color: passwordValidations.hasLetters ? 'green' : 'gray' }}><CheckCircleOutlineIcon /><span>A senha deve conter letras</span></S.CheckPassword>
                <S.CheckPassword style={{ color: passwordValidations.hasNumbers ? 'green' : 'gray' }}><CheckCircleOutlineIcon /><span>A senha deve conter números</span></S.CheckPassword>
                <S.CheckPassword style={{ color: passwordValidations.passwordsMatch ? 'green' : 'gray' }}><CheckCircleOutlineIcon /><span>As senhas devem ser iguais</span></S.CheckPassword>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <CompTextField
                isView={isView}
                textLabel="REPETIR SENHA:"
                required
                style={{ flexGrow: '1' }}
                name="repeat_password"
                type={showPassword ? 'text' : 'password'}
                register={register}
                unregister={unregister}
                onBlur={checkPassword}
                helperText={errors.repeat_password && errors.repeat_password.message}
                error={errors.repeat_password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleTogglePasswordVisibility}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <div style={{ display: "flex", flexDirection: "column", marginTop: "8px" }}>
                <S.CheckPassword style={{ color: repeatPasswordValidations.minLength ? 'green' : 'gray' }}><CheckCircleOutlineIcon /><span>A senha deve ter no mínimo 9 caracteres</span></S.CheckPassword>
                <S.CheckPassword style={{ color: repeatPasswordValidations.hasLetters ? 'green' : 'gray' }}><CheckCircleOutlineIcon /><span>A senha deve conter letras</span></S.CheckPassword>
                <S.CheckPassword style={{ color: repeatPasswordValidations.hasNumbers ? 'green' : 'gray' }}><CheckCircleOutlineIcon /><span>A senha deve conter números</span></S.CheckPassword>
                <S.CheckPassword style={{ color: repeatPasswordValidations.passwordsMatch ? 'green' : 'gray' }}><CheckCircleOutlineIcon /><span>As senhas devem ser iguais</span></S.CheckPassword>
              </div>
            </div>
          </>
        )}
      </S.FormRowWithMarginDados>
      {isCpfInvalid && (
        <FillErrorAlert
          open={isCpfInvalid}
          onClose={() => setIsCpfInvalid(false)}
          message="Este CPF já está cadastrado."
        />
      )}
    </S.Form>

  );
};

export default Forms;
