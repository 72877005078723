import * as S from './styles';


const ActiveStatus = () => {
    return (
        <S.ActiveStatus>
            Ativo
        </S.ActiveStatus>
    );
};

export default ActiveStatus;