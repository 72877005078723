import { DateRangePicker } from "@mui/x-date-pickers-pro";
import styled from "styled-components";

export const ReportsHeaderTexts = styled.div`
  position: relative;
  span {
    font-size: 12px;
    font-family: "Poppins";
    font-weight: lighter;
    color: #515151;
    position: absolute;
    top: 4px;
    right: 0;
  }

  @media (max-width: 768px) {
    span {
      position: relative;
      top: -22px;
    }
  }
`;

export const ReportsContainer = styled.div`
  min-width: 78vw;
  margin-top: -24px;
`;

export const AllDataWrapper = styled.div`
  border-radius: 8px;
  /* padding: 12px; */
  padding-top: 0px;
  max-width: 1360px;
  margin: auto;
  margin-bottom: 64px;
  .firstRow {
    margin-top: 18px;
    & .MuiFormControl-root .MuiInputBase-root {
      width: 100%;
    }

    .MuiInputBase-root {
      height: 45px;
    }
  }

  @media (max-width: 768px) {
    padding: 48px 32px;
    .firstRow,
    .flex-column-mobile {
      flex-direction: column;
    }
  }

  label {
    text-transform: uppercase;
    font-weight: bold;
    color: #383838;
    font-size: 18px;
    line-height: 24px;
  }

  fieldset {
    border-color: #4bbfef;
    border-radius: 8px;
  }

  .MuiChip-root {
    background: transparent;
    border: 1px solid #c8b4ff;
  }
`;

export const ReportContainer = styled.div`
  margin-bottom: 12px;
  .title {
    color: #101828;
    padding: 10px 0px;
    font-size: 22px;
    line-height: 28px;
    font-family: "Poppins";
    font-weight: 400;
    margin-top: 5px;
    margin-bottom: 5px;
  }
`;
