import React, { useEffect, useState } from "react";
import * as S from "./styles";
import * as util from "../../../utils/constants.js";
import * as data from "utils/formatDate";

export default function ConditionalityInfo({ condicionality }) {
  const { conditionality_type, ...values } = condicionality;
  const getConditionalityLabel = (type) => {
    const found = util.condicionalTypes.find((cond) => cond.value === type);
    return found ? found.textLabel : "Desconhecido";
  };
  const conditionalityLabel = getConditionalityLabel(
    condicionality.conditionality_type
  );

  const relevantFields = {
    1: [
      "created_date",
      "check_last",
      "check_number",
      "current_gestational_age",
    ],
    2: [
      "created_date",
      "vaccine_name",
      "vaccine_dose",
      "vaccination_date",
      "other_vaccine_name",
      "other_vaccine_dose",
    ],
    3: [
      "created_date",
      "height",
      "weight",
      "imc",
      "nutritional_graph_check",
      "mark_count",
    ],
    4: ["created_date"],
    5: ["created_date", "check_current", "showed_up", "observation"],
    6: ["created_date", "check_last", "check_number"],
    7: ["created_date", "height", "weight", "imc"],
    8: [
      "created_date",
      "type_justification",
      "justification",
      "month_justification_choice",
    ],
    9: [
      "created_date",
      "vaccine_name",
      "vaccine_dose",
      "vaccination_date",
      "other_vaccine_name",
      "other_vaccine_dose",
    ],
  };

  const formatValue = (field, value) => {
    if (value === null || value === undefined) return "--";
    if (value === true) return "Sim";
    if (value === false) return "Não";

    switch (field) {
      case "created_date":
      case "check_current":
      case "check_first":
      case "check_last":
      case "vaccination_date":
        return data.brDate(value);
      case "imc":
        return value.toFixed(2);
      case "weight":
        return value + "kg";
      case "height":
        return value + "m";
      case "vaccine_name":
        const name_vac = util.vaccines.find((vac) => value === vac.value);
        return name_vac.textLabel;
      case "vaccine_dose":
        const name_dose = util.vaccines_doses.find(
          (vac) => value === vac.value
        );
        return name_dose.textLabel;
      default:
        return value;
    }
  };

  const fieldsToRender = relevantFields[conditionality_type] || [];

  return (
    <S.TypeBox>
      <div className="green-box">
        <h6>{conditionalityLabel}</h6>
      </div>
      <div>
        {condicionality.child_name && (
          <p
            style={{
              fontSize: "14px",
              fontFamily: "Poppins",
              margin: " 1% 2% ",
            }}
          >
            <strong>{util.fieldLabelsConditionality["child_name"]}: </strong>
            {condicionality.child_name}
          </p>
        )}

        {fieldsToRender.map((field) => (
          <p
            key={field}
            style={{
              fontSize: "14px",
              fontFamily: "Poppins",
              margin: " 1% 2% ",
            }}
          >
            <strong>{util.fieldLabelsConditionality[field]}: </strong>
            {formatValue(field, values[field])}
          </p>
        ))}
      </div>
    </S.TypeBox>
  );
}
