import React from 'react';
import { useSelector } from 'react-redux';

const AgentType = () => {
  const user = useSelector((state) => state.user?.user);

  return user ? user.agent_type : '';
};

export default AgentType;
