import styled from "styled-components";
import * as palette from "styles/variables";
import Button from "@mui/material/Button";

export const Box = styled("div")`
  max-width: 130%;
  min-height: 100vh;
  /* border: 1px solid red; */
`;

export const TypeBox = styled("div")`
  width: 90%;
  .green-box {
    border-radius: 4px;
    background-color: ${palette.lightVerde};
    padding: 7px;
    h6 {
      color: #fafafa;
      font-size: 14px;
      font-family: "Poppins";
      font-weight: 500;
    }
  }
`;

export const LoadingBox = styled("div")`
  width: 130%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonFilter = styled(Button)`
  && {
    color: ${({ active }) => (active ? "white" : `${palette.darkLaranja}`)};
    background-color: ${({ active }) =>
      active ? `${palette.lightAmarelo}` : "transparent"};
    border: 1px solid
      ${({ active }) => (active ? "transparent" : `${palette.darkLaranja}`)};

    &:hover {
      border: 1px solid ${palette.lightAmarelo};
      background-color: ${({ active }) =>
        active ? `${palette.lightAmarelo}` : "rgba(255, 165, 0, 0.1)"};
    }
  }
`;
export const ButtonBack = styled(Button)({
  backgroundColor: palette.darkLaranja,
  color: "white",
  fontFamily: "Roboto",
  textTransform: "capitalize",
  fontSize: "16px",
  "&:hover": {
    backgroundColor: palette.lightAmarelo,
  },
});
