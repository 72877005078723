import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        outline: 0;
        box-sizing: border-box;
    }
    button {
        cursor: pointer;
      }

    .MuiDateRangeCalendar-root {
        & > div:first-child {
        display: none;
        }
    }
    .MuiPickersCalendarHeader-label {
        text-transform: capitalize;
    }

    .flex {
        display: flex;
    }

`;

export default GlobalStyle;
