import * as S from './styles';

const VersionStatus = ({ versionDate }) => {
    return (
        <S.VersionStatus>
           Versão {versionDate ? new Date(versionDate).toLocaleString() : "atual"}
           {/* Versão de {versionDate ? new Date(versionDate).toLocaleDateString("pt-BR") : "Versão atual"} */}
        </S.VersionStatus>
    );
};

export default VersionStatus;