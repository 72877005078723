import backLogin from "assets/image/backLogin.svg"

const useStyles = {
  container: {
    //minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundImage: `url(${backLogin})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  logo: {
    marginBottom: '8px',
    marginTop: '35px',
    width: '100px',
    height: 'auto'
  },
  formContainer: {
    width: '80%',
    maxWidth: '400px',
    padding: '20px',
    margin: '20px auto',
    backgroundColor: '#ffffff',
    //boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)', // Sombra suave
    borderRadius: '5px'
  },
  inputContainer: {
    marginBottom: '24px'
  },
  textField: {
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#4BBFEF' // Cor da borda
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#4BBFEF' // Cor da borda ao passar o mouse
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#4BBFEF' // Cor da borda quando focado
    }
  },
  button: {
    marginTop: '16px'
  },
  loginTitle: {
    color: 'var(--Dark-Laranja---dark, #F26A1D)',
    fontFamily: 'Poppins',
    fontSize: '30px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '40px',
    marginBottom: '48px',
    textAlign: 'center'
  },
  label: {
    color: 'var(--Gray-900, var(--900, #383838))',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: '24px',
    marginBottom: '8px'
  },
  labelMore: {
    color: 'var(--Dark-Preto, #222)',
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: '200%',
    textAlign: 'center',
    marginTop: '48px'
  },

  buttonLogin: {
    display: 'flex',
    padding: '16px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: "1rem",
    //flex: '1 0 0',
    borderRadius: '8px',
    background: 'var(--Dark-Laranja---dark, #F26A1D)',
    marginTop: '24px',
    color: 'var(--Dark-Branco, #FFF)',
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontStyle: 'normal'
  },
  buttonMore: {
    display: 'flex',
    padding: '12px 16px',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flex: '1 0 0',
    borderRadius: '4px',
    background: '#88C54B',
    marginTop: '16px',
    color: '#222',
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontStyle: 'normal'
  }
};

export default useStyles;
