import * as data from "utils/formatDate";

export const organizeByMonth = (items) => {
    if (items.length > 0) {
      return items.reduce((acc, item) => {
        const date = data.monthYear(item.created_date);

        // Se não houver uma chave para este mês/ano, crie um array vazio
        if (!acc[date]) {
          acc[date] = [];
        }
        // Adicione o item ao array correspondente ao mês/ano
        acc[date].push(item);

        return acc;
      }, {});

    }
  };


export const filterByYear = (year, data) => {
    return Object.keys(data)
      .filter(key => key.includes(year))
      .reduce((acc, key) => {
        acc[key] = data[key];
        return acc;
      }, {});
  };