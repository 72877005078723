import * as S from './styles';


const WarningStatus = () => {
    return (
        <S.WarningStatus>
            Advertência
        </S.WarningStatus>
    );
};

export default WarningStatus;