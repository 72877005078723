import React, { useEffect, useState } from "react";
import * as S from "./styles";
import Title from "components/Title";
import PregnantForms from "components/forms/PregnantForms";
import ChildForms from "components/forms/ChildForms";
import { Select, MenuItem, Grid } from "@mui/material";
// import ChildBeneficiaryEdition from "components/ChildBeneficiaryEdition/ChildBeneficiary";
// import GeminiBeneficiaryEdition from "components/ChildBeneficiaryEdition/GeminiBeneficiary";
import { useParams } from "react-router-dom";
import {
  getBeneficiaryType,
  getBeneficiaryStatus,
  convertDateFromApi,
} from "utils";

import { useApi } from "hooks/useApis";
import { useForm, FormProvider } from "react-hook-form";

import ActiveStatus from "components/UserStatus/ActiveStatus";
import BlockedStatus from "components/UserStatus/BlockedStatus";
import CanceledStatus from "components/UserStatus/CanceledStatus";
import SuspendedStatus from "components/UserStatus/SuspendedStatus";
import WaitingStatus from "components/UserStatus/WaitingStatus";
import WarningStatus from "components/UserStatus/WarningStatus";
import VersionStatus from "components/UserStatus/VersionStatus";
import { textsCria } from "utils/constants";
import AgentType from "utils/agentType";
import * as palette from "styles/variables";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import CustomLabel from "components/Label";
const theme = createTheme({
  palette: {
    primary: {
      main: palette.lightAzul,
    },
  },

  // components: {
  //   MuiOutlinedInput: {
  //     styleOverrides: {
  //       notchedOutline: {
  //         borderColor: 'var(--TextField-brandBorderColor)'
  //       },
  //       "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
  //         borderColor: palette.lightAzul,
  //       },
  //       // root: {
  //       //   // background: 'white',
  //       //   height: "40px",
  //       //   [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
  //       //     borderColor: palette.lightAzul,
  //       //   },
  //       //   [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
  //       //       borderColor: 'var(--TextField-brandBorderFocusedColor)'
  //       //   },
  //       // },
  //     },
  //   },
  // },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: palette.lightAzul,
        },
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: palette.lightAzul,
        },
        root: {
          //       //   // background: 'white',
          height: "40px",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: palette.lightAzul,
        },
      },
    },
  },
});

const BeneficiaryRegistration = ({ isNew = false, isEdit = false }) => {
  const [selectedOption, setSelectedOption] = useState(textsCria.pregnant);
  const [showMinorPregnant, setShowMinorPregnant] = useState(false);

  const [beneficiaryData, setBeneficiaryData] = useState({});
  const [beneficiaryStatusComponent, setBeneficiaryStatusComponent] =
    useState("Ativo");
  const [benefitType, setbenefitType] = useState(textsCria.pregnant);
  const [versionDate, setVersionDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [isCurrentVersion, setIsCurrentVersion] = useState(true);

  const agent_type = AgentType();
  const api = useApi();

  const methods = useForm(); 
  const { setValue } = methods;

  const isView = !isNew && !isEdit;


  const handlebenefitTypeChange = (event) => {
    setbenefitType(event.target.value);
    setSelectedOption(event.target.value);
    if (event.target.value === textsCria.pregnant) {
      setShowMinorPregnant(true);
    } else {
      setShowMinorPregnant(false);
    }
  };
  const [isLower16, setIsLower16] = useState(false);

  const handleLower16 = (event) => {
    setIsLower16(event.target.value === "Sim" ? true : false);
  };

  const statusComponents = {
    "Ativo": <ActiveStatus />,
    "Bloqueado": <BlockedStatus />,
    "Cancelado": <CanceledStatus />,
    "Suspenso": <SuspendedStatus />,
    "Lista de Espera": <WaitingStatus />,
    "Advertência": <WarningStatus />
  };

  const { id } = useParams();

  const getBeneficiaryData = async () => {
    try {
      setIsLoading(true)
      const data = await api.getBeneficiaryById(id);
      const beneficiaryType = getBeneficiaryType(data?.b_type);
      const beneficiaryStatus = getBeneficiaryStatus(data?.b_status);
      console.log('BENEFICARIO DATA: ', data)
      setBeneficiaryData(data);
      setbenefitType(beneficiaryType);
      setSelectedOption(beneficiaryType);
      setBeneficiaryStatusComponent(beneficiaryStatus);
      setValue("name", data.name || "");

      if (beneficiaryType === textsCria.pregnant) {
        setIsLower16(data?.lower_16 ? true : false);
        setShowMinorPregnant(true);
      } else {
        setShowMinorPregnant(false);
      }
      setIsLoading(false)
    } catch (err) {

      console.log(err);
    }
  };

  useEffect(() => {
    if (id) {
      getBeneficiaryData();
    }
    setShowMinorPregnant(benefitType === textsCria.pregnant);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleVersionChange = (date, b_status) => {
    const newStatus = getBeneficiaryStatus(b_status);
    setVersionDate(date);
    setBeneficiaryStatusComponent(newStatus);
    setIsCurrentVersion(false);
  };

  return isNew ? (
    <ThemeProvider theme={theme}>
      <S.Container>
        <Title
          titleText="Cadastro de beneficiário"
          subtitleTexts={["Início", "Cadastro de beneficiário"]}
        />

        <Grid container spacing={2} sx={{ marginLeft: "6px" }}>
          <Grid item xs={6}>
            <CustomLabel textLabel="TIPO DE BENEFÍCIO" required />
            <Select
              id="benefit_type"
              value={benefitType}
              onChange={handlebenefitTypeChange}
              defaultValue={textsCria.pregnant}
              fullWidth
            >
              <MenuItem value={textsCria.pregnant}>Gestante</MenuItem>
              <MenuItem value={textsCria.child}>Criança (0 a 6 anos)</MenuItem>
              <MenuItem value={textsCria.childWithSindrome}>
                Criança (síndrome congênita - 0 a 6 anos)
              </MenuItem>
              {!isNew && (
                <MenuItem value={textsCria.twins}>Criança Gêmeos</MenuItem>
              )}
            </Select>
          </Grid>

          {showMinorPregnant && (
            <Grid item xs={6}>
              <CustomLabel textLabel="GESTANTE MENOR DE 16 ANOS?" required />
              <Select
                id="is_minor"
                value={isLower16 ? "Sim" : "Não"}
                onChange={handleLower16}
                fullWidth
              >
                <MenuItem value="Não">Não</MenuItem>
                <MenuItem value="Sim">Sim</MenuItem>
              </Select>
            </Grid>
          )}

          <Grid item xs={6}>
            <CustomLabel textLabel="DATA DE CADASTRO" required />
            <Select value={0} fullWidth>
              <MenuItem value="0" selected>
                {new Date()?.toLocaleDateString("pt-BR")}
              </MenuItem>
            </Select>
          </Grid>
        </Grid>

        {(selectedOption === textsCria.child ||
          selectedOption === textsCria.childWithSindrome) && (
            <ChildForms
              isView={isView}
              isEdit={isEdit}
              benefitType={selectedOption}
              setBeneficiaryData={setBeneficiaryData}
              isLoading={isLoading}
            />
          )}
        {selectedOption === textsCria.twins && (
          <ChildForms
            isView={isView}
            isEdit={isEdit}
            benefitType={selectedOption}
            setBeneficiaryData={setBeneficiaryData}
            isLoading={isLoading}
          />
        )}
        {selectedOption === textsCria.pregnant && (
          <PregnantForms
            isView={isView}
            isEdit={isEdit}
            beneficiaryData={beneficiaryData}
            isLower16={isLower16}
            benefitType={benefitType}
            setBeneficiaryData={setBeneficiaryData}
            isLoading={isLoading}
          />
        )}
      </S.Container>
    </ThemeProvider>
  ) : (
    <ThemeProvider theme={theme}>
      <S.Container>
        <Title
          titleText={
            isEdit ? "Edição de beneficiário" : `Visualização de beneficiário`
          }
          subtitleTexts={[
            "Início",
            isEdit ? "Edição de beneficiário" : `Visualização de beneficiário`,
          ]}
        />

        <S.StatusContainer>
          {!isEdit && (
            <>
              {console.log("Beneficiary Status Component:", beneficiaryStatusComponent)}
              {statusComponents[beneficiaryStatusComponent] || null}
              <VersionStatus versionDate={versionDate} />
            </>
          )}
        </S.StatusContainer>

        <Grid container spacing={2} sx={{ marginTop: "5px" }}>
          <Grid item xs={12} sm={6}>
            <S.Label>TIPO DE BENEFÍCIO</S.Label>
            <Select
              id="benefit_type"
              value={benefitType}
              onChange={handlebenefitTypeChange}
              defaultValue={textsCria.pregnant}
              disabled={!isView ? ["2", "3"].includes(agent_type) : true}
              fullWidth
              sx={{
                background: isView ? "#fff1e8" : "#fff",
                // border: isView && "1px solid  #f9922a",
              }}
            >
              <MenuItem value={textsCria.pregnant}>Gestante</MenuItem>
              <MenuItem value={textsCria.child}>Criança (0 a 6 anos)</MenuItem>
              <MenuItem value={textsCria.childWithSindrome}>
                Criança (síndrome congênita - 0 a 6 anos)
              </MenuItem>
              <MenuItem value={textsCria.twins}>Criança Gêmeos</MenuItem>
            </Select>
          </Grid>

          {showMinorPregnant && (
            <Grid item xs={12} sm={6}>
              <S.Label>GESTANTE MENOR DE 16 ANOS?</S.Label>
              <Select
                id="is_minor"
                value={isLower16 ? "Sim" : "Não"}
                onChange={handleLower16}
                disabled={!isView ? ["2", "3"].includes(agent_type) : true}
                fullWidth
                sx={{
                  background: isView ? "#fff1e8" : "#fff",
                  // border: isView && "1px solid  #f9922a",
                }}
              >
                <MenuItem value="Não">Não</MenuItem>
                <MenuItem value="Sim">Sim</MenuItem>
              </Select>
            </Grid>
          )}

          <Grid item xs={12} sm={6}>
            <S.Label>DATA DE CADASTRO</S.Label>
            <Select
              value={0}
              disabled
              fullWidth
              sx={{
                background: isView ? "#fff1e8" : "#fff",
                // border: isView && "1px solid  #f9922a",
              }}
            >
              <MenuItem value={0} selected>
                {convertDateFromApi(
                  beneficiaryData.registration_date
                )?.toLocaleDateString("pt-BR")}
              </MenuItem>
            </Select>
          </Grid>
        </Grid>

        {(selectedOption === textsCria.child ||
          selectedOption === textsCria.childWithSindrome) && (
            <ChildForms
              isView={isView}
              beneficiaryData={beneficiaryData}
              isEdit={isEdit}
              benefitType={selectedOption}
              setBeneficiaryData={setBeneficiaryData}
              handleVersionChange={handleVersionChange}
              isLoading={isLoading}
              isCurrentVersion={isCurrentVersion}
            />
          )}
        {selectedOption === textsCria.twins && (
          <ChildForms
            benefitType={selectedOption}
            beneficiaryData={beneficiaryData}
            isView={isView}
            isEdit={isEdit}
            setBeneficiaryData={setBeneficiaryData}
            handleVersionChange={handleVersionChange}
            isLoading={isLoading}
            isCurrentVersion={isCurrentVersion}
          />
        )}
        {selectedOption === textsCria.pregnant && (
          <PregnantForms
            isView={isView}
            isEdit={isEdit}
            beneficiaryData={beneficiaryData}
            isLower16={isLower16}
            benefitType={benefitType}
            setBeneficiaryData={setBeneficiaryData}
            handleVersionChange={handleVersionChange}
            isLoading={isLoading}
            isCurrentVersion={isCurrentVersion}
          />
        )}
      </S.Container>
    </ThemeProvider>
  );
};

export default BeneficiaryRegistration;
