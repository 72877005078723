import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  Font,
  Link,
} from "@react-pdf/renderer";

import logoCria from "../../../assets/image/logocria.png";

import dayjs from "dayjs";
import "dayjs/locale/pt-br";
dayjs.locale("pt-br");

Font.register({
  family: "Work Sans",
  src: "https://fonts.gstatic.com/s/worksans/v2/z9rX03Xuz9ZNHTMg1_ghGS3USBnSvpkopQaUR-2r7iU.ttf",
});

Font.register({
  family: "Inter",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/inter/v11/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfAZFhjQ.ttf",
    },
    {
      src: "https://fonts.gstatic.com/s/inter/v11/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYAZFhjQ.ttf",
      fontWeight: "bold",
    },
  ],
});

const stylesTable = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#FFFFFF",
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  imgCria: {
    width: 125,
    height: 68,
    marginBottom: 6,
  },
  containerHeader: {
    // backgroundColor: "rgb(248, 163, 0)",
    // display: "flex",
    // justifyContent: "space-between",
    borderBottom: "1px solid rgb(248, 163, 0)",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    marginBottom: 55,
  },
  wrapperHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "stretch",
    flexWrap: "nowrap",
    alignItems: "stretch",
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: 35,
    padding: 16,
    paddingBottom: 12,
  },
  titleHeader: {
    fontSize: 20,
    fontFamily: "Work Sans",
    color: "#1259a5",
    marginBottom: 20,
    textAlign: "center",
    paddingTop: 90,
    // fontWeight: 'bold',
    // display: "inline",
  },
  title: {
    fontSize: 14,
    marginBottom: 6,
    textAlign: "center",
    lineHeight: 1.5,
    fontFamily: "Work Sans",
    color: "#667085",
  },
  text: {
    fontSize: 12,
    fontFamily: "Inter",
    color: "#667085",
    marginBottom: 16,
    // display: "inline",
  },
  em: {
    fontStyle: "bolder",
    color: "black",
    backgroundColor: "#3f8cd9",
  },
  table: {
    width: "100%",
    borderWidth: 2,
    display: "flex",
    flexDirection: "column",
    marginVertical: 12,
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
  },
  cell: {
    borderWidth: 1,
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    textAlign: "center",
    flexWrap: "wrap",
  },
  footer: {
    position: "absolute",
    fontSize: 12,
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
    fontFamily: "Inter",
  },
});

function capitalizeFirstLetter(val) {
  return String(val).charAt(0).toUpperCase() + String(val).slice(1);
}

export const RelatorioAtendimentoMesAtual = ({ children, col, th }) => (
  <Document>
    <Page style={stylesTable.body}>
      <View style={stylesTable.containerHeader} fixed>
        <View style={stylesTable.wrapperHeader}>
          <Image src={logoCria} style={stylesTable.imgCria} />
        </View>
      </View>

      <Text style={stylesTable.titleHeader}>
        Relatório de Atendimento do Mês Atual
      </Text>
      <Text>
        Número do Cartão Cria em{" "}
        {capitalizeFirstLetter(dayjs(new Date()).format("MMMM"))}
      </Text>
      <View style={stylesTable.table}>
        {children.map((row, ind) => (
          <>
            <View
              key={ind}
              style={[
                stylesTable.tableRow,
                th && ind === 0 ? stylesTable.em : {},
              ]}
            >
              {ind !== children.length - 1
                ? row.map((cell, j) => (
                    <View
                      key={j}
                      style={[stylesTable.cell, { width: col[j], height: 40 }]}
                    >
                      {typeof cell === "string" || typeof cell === "number" ? (
                        <Text>{cell}</Text>
                      ) : (
                        cell
                      )}
                    </View>
                  ))
                : null}
            </View>
          </>
        ))}
      </View>

      <Text style={stylesTable.footer} fixed>
        {`Realização: `}
        <Link
          style={{
            paddingLeft: 60,
            color: "grey",
          }}
          src={"https://sites.google.com/laccan.ufal.br/laccan"}
        >
          LaCCAN
        </Link>
      </Text>
    </Page>
  </Document>
);
