import * as React from 'react';
import Button from '@mui/material/Button';

export const OrangeBackButton = () => {
    const handleClick = () => {
        window.history.back();
    };

    return (
        <Button variant="contained"
            sx={{
                width: "87px",
                height: "54px",
                padding: "15px 16px",
                borderRadius: "8px",
                background: "rgba(249,146,42, 0.7)",
                border: "1px solid #F26A1D",

                fontFamily: 'Poppins, sans-serif',
                fontFize: '20px',
                fontWeight: 300,
                color: "rgba(0, 0, 0)",
                textTransform: 'none',

                '&:hover': {
                    backgroundColor: "rgba(249,146,42, 0.7)",
                },
            }}
            onClick={handleClick}
        >
            Voltar
        </Button>
    );
};


