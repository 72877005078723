// styles.js
import styled from 'styled-components';
import { Button as MuiButton } from '@mui/material';
import { TableCell as MuiTableCell } from '@mui/material';


export const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  color: var(--Dark-Verde---dark, #70AC17);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
`;

export const TableCell = styled(MuiTableCell)`
  &.MuiTableCell-root:first-child {
    padding-left: 50px; /* Ajusta o espaçamento apenas para o primeiro TableCell */
  }
`;

export const PageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const PageContent = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 80px;
`;

export const MainContainer = styled.div`
  flex: 1;
  padding-bottom: 100px;
  position: relative;
`;

export const TituloText = styled.h1`
  color: var(--light-amarelo-light, #F9922A);
  font-family: Poppins;
  font-size: 36px;
  font-weight: 700;
  line-height: 46px;
  margin-bottom: 0;
  margin-top: 0;
`;

export const FormDate = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 16px;
  position: relative;
  gap: 70px;
  align-items: center;
`;

export const FormLabel = styled.label`
  color: var(--Gray-900, var(--900, #383838));
  font-family: Roboto;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin-top: 0px;
  padding-top: 0px;
  text-transform: uppercase;
`;

export const DateInputField = styled.input`
  display: inline-flex;
  width: 100%;
  height: 40px;
  padding: 8px 14px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid var(--Light-Azul---light, #4BBFEF);
  background: var(--Dark-Branco, #FFF);
`;

export const DateInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 30%;
`;

export const ReportButton = styled(MuiButton)`
  display: flex;
  width: 128px;
  height: 54px;
  justify-content: flex-end;
  margin-top: 30px;
  border-radius: 8px;
  background: var(--Light-Verde---light, #88C54B);
  color: var(--Dark-Branco, #FFF);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const CabecalhoTabela = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  color: #F9922A;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  background:#FFF1E8;
  border-radius: 8px;
  padding: 0 15px;
  height: 53px;
  width: auto;
`;

// export const TableHeader = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   margin-bottom: 16px;
//   color: var(--Dark-Verde---dark, #70AC17);
//   font-family: Roboto;
//   font-size: 16px;
//   font-weight: 700;
//   line-height: 24px;
// `;

export const SelectContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  max-width: 1400px;
  width: 73vw;
`;

