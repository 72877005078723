import * as React from 'react';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { SubmitButton } from './SubmitButton';

const ActivationBeneficiary = ({ alertType, setAlertType, formState, dataTestid = "", onClick }) => {
  return (
    <SubmitButton
      alertType={alertType}
      setAlertType={setAlertType}
      disabled={formState.isSubmitting || alertType === "success"}
      isLoading={formState.isSubmitting}
      buttonText="Ativar beneficiário"
      succesMsg="Sucesso ao ativar beneficiário."
      onClick={onClick}
      startIcon={<CheckBoxOutlinedIcon />}
      routeToGoBack="/beneficiarios"
      sx={{ width: "260px" }}
    />
  );
};

export default ActivationBeneficiary;
