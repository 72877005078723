import * as React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";
import Header from "components/Header";
import Footer from "components/Footer";
// import FooterAdminLayout from "./Footer";

export default function AdminLayout() {
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Header />
        <Sidebar>
          <Outlet />
        </Sidebar>
      </Box>
      <Footer />
    </>
  );
}
