import * as S from "./PregnantForms/styles";
import CompTextField from "components/CompTextField";
import CompDateField from "components/CompDateField";
import { NisField } from "components/NisField";

const Forms = ({
  isView,
  isEdit,
  beneficiaryData = null,
  register,
  unregister,
  watch,
  setValue,
  errors,
}) => {
  const nis = watch('nis');

  const handleNis = (data) => {
    setValue("name", data.name);
    setValue("responsible_cpf", data.cpf);
    setValue("birth", data.birth);
    setValue("responsiblerg", data.rg);
    setValue("responsibleorgan", data["rg_issuer"]);
    setValue("responsibleuf", data["rg_uf"]);

    setValue('contact_number_one', data['family']['contact_number_one']);
    setValue('contact_number_two', data['family']['contact_number_two']);
    setValue('street_type', data['family']['street_type']);
    setValue('street_name', data['family']['street_name']);
    setValue('street_number', data['family']['street_number']);
    setValue('complement', data['family']['complement']);
    setValue('name_location', data['family']['name_location']);
    setValue('city', data['family']['city']);
    setValue('updated', data['updated'] ? 'Sim' : 'Não');
  }

  return (
    <S.Form>
      <S.FormRowWithMarginDados style={{ margin: 0 }}>
        {/* Linha 1 */}
        <NisField
          nis={nis}
          isView={isView}
          isEdit={isEdit}
          apiText={beneficiaryData?.nis}
          benefitNis={beneficiaryData?.nis}
          textLabel="NIS:"
          name="nis"
          register={register}
          unregister={unregister}
          error={errors.nis}
          helperText={errors.nis && errors.nis.message}
          handleNis={handleNis}
          setValue={setValue}
        />
        <CompTextField
          isView={isView}
          apiText={beneficiaryData?.cns}
          edit={isEdit}
          textLabel="CNS DO RESPONSÁVEL:"
          fromNis={true}
          type="number"
          required
          style={{ flexGrow: "2" }}
          name="cns"
          register={register}
          unregister={unregister}
          error={errors.cns}
          helperText={errors.cns && errors.cns.message}
        // enabled={isEdit && !isView}
        />

        <CompTextField
          isView={isView}
          apiText={beneficiaryData?.name}
          textLabel="NOME COMPLETO DO RESPONSÁVEL:"
          fromNis={true}
          type="text"
          required
          style={{ flexGrow: "5" }}
          name="name"
          register={register}
          unregister={unregister}
          error={errors.name}
          helperText={errors.name && errors.name.message}
        // enabled={false}
        />
      </S.FormRowWithMarginDados>
      <S.FormRowWithMarginDados>
        {/* Linha 2 */}
        <CompDateField
          isView={isView}
          labelText="DATA DE NASCIMENTO:"
          apiText={beneficiaryData?.birth}
          fromNis={true}
          required
          style={{ flexGrow: "1" }}
          name="birth"
          register={register}
          unregister={unregister}
          error={errors.birth}
          helperText={errors.birth && errors.birth.message}
          disabled={true}
        />
        <CompTextField
              isView={isView}
              fromNis={true}
              apiText={beneficiaryData?.provisional_contact_number}
              textLabel="CELULAR:"
              mask="(99) 9 9999-9999"
              placeholder="(99) 9 9999-9999"
              style={{ flexGrow: '1' }}
              name="provisional_contact_number"
              register={register}
              unregister={unregister}
              error={errors.provisional_contact_number}
              helperText={errors.provisional_contact_number && errors.provisional_contact_number.message}
            // enabled={false}
            />
        <CompTextField
          isView={isView}
          apiText={beneficiaryData?.person?.family?.contact_number_one}
          textLabel="CELULAR: (CadÚnico)"
          //mask="(99) 9 9999-9999"
          placeholder="(99) 9 9999-9999"
          fromNis={true}
          style={{ flexGrow: "1" }}
          name="contact_number_one"
          register={register}
          unregister={unregister}
          error={errors.contact_number_one}
          helperText={errors.contact_number_one && errors.contact_number_one.message}
        // enabled={false}
        />
        <CompTextField
          isView={isView}
          fromNis={true}
          apiText={beneficiaryData?.person?.family?.contact_number_two}
          textLabel="CELULAR SECUNDÁRIO: (CadÚnico)"
          //mask="(99) 9 9999-9999"
          placeholder="(99) 9 9999-9999"
          style={{ flexGrow: "1" }}
          name="contact_number_two"
          register={register}
          unregister={unregister}
          error={errors.contact_number_two}
          helperText={errors.contact_number_two && errors.contact_number_two.message}
        // enabled={false}
        />
      </S.FormRowWithMarginDados>
      <S.FormRowWithMarginDados>
        <CompTextField
          isView={isView}
          fromNis={true}
          apiText={beneficiaryData?.responsible_cpf}
          textLabel="CPF DO RESPONSÁVEL:"
          //mask="999.999.999-99"
          placeholder="xxx.xxx.xxx-xx"
          required
          style={{ flexGrow: "1" }}
          name={"responsible_cpf"}
          register={register}
          unregister={unregister}
          error={errors.responsible_cpf}
          helperText={errors.responsible_cpf && errors.responsible_cpf.message}
        // enabled={false}
        />
        <CompTextField
          isView={isView}
          fromNis={true}
          apiText={beneficiaryData?.person?.rg}
          textLabel="RG:"
          style={{ flexGrow: "2" }}
          name="rg"
          register={register}
          unregister={unregister}
          error={errors.rg}
          helperText={errors.rg && errors.rg.message}
        // enabled={false}
        />
        <CompTextField
          isView={isView}
          apiText={beneficiaryData?.person?.rg_issuer}
          style={{ flexGrow: "1" }}
          textLabel="ÓRGÃO EMISSOR:"
          fromNis={true}
          name="rg_issuer"
          register={register}
          unregister={unregister}
          error={errors.rg_issuer}
          helperText={errors.rg_issuer && errors.rg_issuer.message}
        // enabled={false}
        />
        <CompTextField
          isView={isView}
          fromNis={true}
          apiText={beneficiaryData?.person?.rg_uf}
          style={{ flexGrow: "1" }}
          textLabel="UF EMISSOR:"
          name="rg_uf"
          register={register}
          unregister={unregister}
          error={errors.rg_uf}
          helperText={errors.rg_uf && errors.rg_uf.message}
        // enabled={false}
        />
      </S.FormRowWithMarginDados>
    </S.Form>
  );
};

export default Forms;
