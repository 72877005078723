// @ts-nocheck
import React from 'react';
import * as S from './styles';
import Typography from '@mui/material/Typography';
import TableFilter from 'components/TableEmissions';
import CompDateField from 'components/CompDateField';
import Filter from 'components/ComFilter';
import Title from 'components/Title';
import { GenerateButton } from 'components/Buttons/GenerateButton';


const EmissionsConsultation = () => {

  return (
    <S.PageContainer>
      <Title
        titleText="Consulta de Emissões"
        subtitleTexts={['Início', '  Exportação de dados']}
      />
      <S.PageContent>
        <S.MainContainer>

            <S.FormDate>
              <CompDateField
                    labelText="DATA DE CADASTRO INICIAL:" required
                />

              <CompDateField
                    labelText="DATA DE CADASTRO FINAL:" required
                />
              <GenerateButton/>
            </S.FormDate>

            <S.CabecalhoTabela>
              <Typography variant="h6">Lista de relações geradas</Typography>
              <Filter  placeholder= "Ordem alfabética A.Z"/>
            </S.CabecalhoTabela>

            <TableFilter />
        </S.MainContainer>
      </S.PageContent>
    </S.PageContainer>
  );
};

export default EmissionsConsultation;
