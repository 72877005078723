import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  background: #ffffff;
  width: 640px;
  height: 360px;
  border-radius: 8px;
  padding: 20px;
  position: relative; /* Para permitir o posicionamento absoluto do ícone de fechar */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  color: #667085;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  flex-direction: column;
`;

export const ModalTitle = styled.h2`
  color: #4BBFEF;
  text-align: center;
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 28px;
  line-height: 40px;
  margin-bottom: 10px;
  margin-top: 5px;
  
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  padding-left: 50px;
  padding-right: 50px;
`;

export const JustificationText = styled.p`
  color: #383838;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  margin-bottom: 10px;
`;


export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  `;


export const ErrorMessage = styled.div`
  color: red;
  margin-top: 10px;
  font-size: 14px;
`;

export const Emoji = styled.img`
    width: 72px;
    height: 72px;
    flex-shrink: 0;
    margin-bottom: 0px;
`;