import React from "react";
import { Link } from "react-router-dom";
import logo from "assets/image/logoCria.svg"; 
import * as palette from "../../styles/variables";

const NotFoundPage = () => {
  return (
    <div style={styles.container}>
      <img src={logo} alt="Logo" style={styles.logo} />
      <h1 style={styles.heading}>404 - Página Não Encontrada</h1>
      <p style={styles.message}>A página que você está procurando não existe.</p>
      <Link to="/" style={styles.button}>
        Voltar para a página inicial
      </Link>
    </div>
  );
};

const styles = {
  container: {
    textAlign: "center",
    padding: "50px",
  },
  logo: {
    width: "150px",
    marginBottom: "20px",
  },
  heading: {
    fontSize: "2rem",
    margin: "20px 0",
  },
  message: {
    fontSize: "1.2rem",
    marginBottom: "30px",
  },
  button: {
    display: "inline-block",
    padding: "10px 20px",
    fontSize: "1rem",
    color: "#fff",
    backgroundColor: palette.darkLaranja, // Cor de fundo do botão
    borderRadius: "5px",
    textDecoration: "none",
    transition: "background-color 0.3s ease",
  },
  buttonHover: {
    backgroundColor: palette.lightAmarelo, // Cor de fundo do botão ao passar o mouse
  },
};

export default NotFoundPage;
