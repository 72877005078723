import React from 'react';
import * as S from './styles';

const Filter = ({ placeholder, onChange }) => {
    return (
      <S.FilterContainer>
        <S.StyledInput
          type="text"
          placeholder={placeholder}
          onChange={onChange}
        />
        <S.FilterIcon />
      </S.FilterContainer>
    );
  };
  
  export default Filter;