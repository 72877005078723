import React, { useState } from 'react';
import * as S from './styles';
import CompDropdown from 'components/CompDropdown';
import CompAutocomplete from 'components/CompAutocomplete';
import { distributionPoints } from 'utils/constants';
import { a_type_options } from 'utils/constants';

const Forms = ({ agentData, isView, register, unregister }) => {
  const getAgentTypeLabel = (value) => {
    const option = a_type_options.find(opt => opt.value === value);
    return option ? option.textLabel : ''; // Retorna o textLabel ou uma string vazia
  };

  return (
    <S.Form>
      <S.FormRowDatas>
        {/* Linha 1 */}
        <CompDropdown
          isView={isView}
          style={{ flexGrow: '1' }}
          textLabel="TIPO DE AGENTE:"
          name={'agent_type'}
          options={a_type_options}
          // register={register}
          // unregister={unregister}
          apiValue={getAgentTypeLabel(agentData?.agent_type)}
          required
        />
        <CompAutocomplete
          isView={isView}
          style={{ flexGrow: "1" }}
          defaultValue=""
          textLabel="LOCAL DE TRABALHO:"
          options={distributionPoints}
          name={"work_location"}
          placeholder="Selecione um município"
          register={register}
          unregister={unregister}
          apiValue={agentData?.work_city?.name}
          required
        />
      </S.FormRowDatas>
    </S.Form>
  );
};

export default Forms;
