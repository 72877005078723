import React, { useEffect, useMemo, useState } from "react";

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";

import {
  RelatorioAtendimentoMesAtual,
  RelatorioBeneficiaria,
  RelatorioEvolucaoPeriodo,
  RelatorioPobreza,
} from "../../../components/DashboardPDF";

import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import TextField from "@mui/material/TextField";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";

import apiDashboard from "services/apiDashboard";

import * as S from "./styles";

import { metadataOptions, sortArrayByProperty } from "utils";
import { useForm, useWatch } from "react-hook-form";
import {
  Button,
  Collapse,
  IconButton,
  MenuItem,
  Skeleton,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {
  Download,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import Title from "components/Title";
import { LoadingButton } from "@mui/lab";

import AgentCity from "utils/agentCity";
import AgentType from "utils/agentType";

function transformData(input) {
  const result = [];

  input.forEach((entry) => {
    const city = entry.city;

    const groupedFamilies = {};

    entry.data.forEach((childData) => {
      const familyId = childData.family_id;

      if (!groupedFamilies[familyId]) {
        groupedFamilies[familyId] = {
          city: city,
          ...childData,
          family_id: familyId,
          children: [],
        };
      }

      groupedFamilies[familyId].children.push({
        child_birth: childData.child_birth,
        child_education_level: childData.child_education_level,
        child_education_level_text: childData.child_education_level_text,
        child_family_id: childData.child_family_id,
        child_person_id: childData.child_person_id,
        child_race: childData.child_race,
        child_race_text: childData.child_race_text,
        child_gender: childData.child_gender,
        child_street_situation: childData.child_street_situation,
      });
    });

    result.push(...Object.values(groupedFamilies));
  });

  return result;
}

const shortcutsItems = [
  {
    label: "Último dia útil",
    getValue: () => {
      const today = dayjs();
      return [today.subtract(1, "day"), today];
    },
    onClick: () => {
      console.log("clickou");
    },
  },
  {
    label: "Últimos 6 meses",
    getValue: () => {
      const today = dayjs();
      return [today.subtract(6, "month"), today];
    },
  },
  { label: "Limpar", getValue: () => [null, null] },
];

const ReportsPage = () => {
  const [cityValue, setCityValue] = useState("Todos");

  const [tableData, setTableData] = useState([]);

  const [noTableDataFlag, setNoTableDataFlag] = useState(false);
  const [isLoadingTableData, setIsLoadingTableData] = useState(false);

  const [allOptions, setAllOptions] = useState({
    b_status: [],
    b_type: [],
    education_level: [],
    gender: [],
    race: [],
    street_situation: [],
    traditional_population_groups: [],
    type_of_domicile: [],
  });

  const [dateValue, setDateValue] = useState([null, null]);
  const [dateValueLastDay, setDateValueLastDay] = useState([null, null]);
  const [cadUnicoVersion, setCadUnicoVersion] = useState("");

  const [citiesData, setCitiesData] = useState([]);

  const agent_type = AgentType();
  const agent_city = AgentCity();

  useEffect(() => {
    if (["2", "3"].includes(agent_type)) {
      setValue("city", agent_city);
    }
  }, [agent_type]);

  const getCities = async () => {
    try {
      let { data } = await apiDashboard.get("api/v1/municipios/");

      data = sortArrayByProperty(data, "name");

      setCitiesData(data);
    } catch (err) {
      console.error(err);
    }
  };

  const getMetadata = async () => {
    try {
      const { data } = await apiDashboard.get(
        "api/v1/municipios/data/metadata"
      );

      setAllOptions(metadataOptions(data[0]));
    } catch (err) {
      console.error(err);
    }
  };

  const handleDateValueChange = (newValue, ctx) => {
    setDateValue(newValue);

    if (ctx.shortcut && ctx.shortcut.label === "Último dia útil") {
      setDateValue(dateValueLastDay);
    }
  };

  const getLastDayValue = async () => {
    try {
      const { data } = await apiDashboard.get("api/v1/municipios/last_day");
      let apiData = data;

      if (apiData.length > 0) {
        let registration_date = apiData.flat()[0]?.data[0]?.registration_date;
        const lastDay = dayjs(registration_date);
        setDateValueLastDay([lastDay, lastDay]);
        let ref_cad = apiData.flat()[0]?.data[0]?.ref_cad;
        setCadUnicoVersion(ref_cad);
      }
    } catch (err) {
      const today = dayjs();
      setDateValue([today.subtract(1, "day"), today]);
      console.error(err);
    }
  };

  const { register, control, handleSubmit, setValue } = useForm({
    defaultValues: useMemo(() => {
      return {
        city: "Todos",
        race: [],
        gender: [],
        education_level: [],
        b_type: [],
        b_status: [],
        type_of_domicile: [],
        traditional_population_groups: [],
        street_situation: [],
      };
    }, []),
  });

  let {
    race,
    gender,
    education_level,
    b_type,
    b_status,
    type_of_domicile,
    traditional_population_groups,
    street_situation,
  } = useWatch({
    control,
  });

  const clearAllOptions = () => {
    setValue("race", []);
    setValue("gender", []);
    setValue("education_level", []);
    setValue("b_type", []);
    setValue("b_status", []);
    setValue("type_of_domicile", []);
    setValue("traditional_population_groups", []);
    setValue("street_situation", []);

    setCityValue("Todos");
    setValue("city", "Todos");

    setDateValue([null, null]);

    setTableData([]);

    setNoTableDataFlag(false);
  };

  const onSubmit = async (data) => {
    let y = Object.fromEntries(
      Object.entries(data)
        .filter(
          ([key, value]) =>
            !(value === "" || value.length === 0) &&
            !(key === "city" && value === "Todos")
        )
        .map(([key, value]) => {
          if (value instanceof Array && value.length > 0) {
            let v = value.map((date) => `'${date}'`);

            return [key, v.join(",")];
          }
          return [key, value];
        })
    );

    let filters = decodeURIComponent(new URLSearchParams(y).toString());
    let [startDate, endDate] = dateValue;

    if (startDate) {
      filters = filters + `&start=${startDate.format("YYYY-MM-DD")}`;
    }

    if (endDate) {
      filters = filters + `&end=${endDate.format("YYYY-MM-DD")}`;
    }

    setIsLoadingTableData(true);
    setTableData([]);

    try {
      const { data } = await apiDashboard.get(
        `api/v1/municipios/data?${filters}`
      );

      let flatData = data;

      let table = [];

      flatData.forEach((objCityData) => {
        let newData = objCityData.data.map((d) => ({
          ...d,
          city: objCityData.city,
        }));
        table.push(...newData);
      });

      if (table.length === 0) {
        setNoTableDataFlag(true);
      }

      flatData = transformData(data);

      setTableData(flatData);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoadingTableData(false);
    }
  };

  const downloadTable = () => {
    const csvString = [
      [
        "Data de nascimento",
        "Raça do Responsável",
        "Gênero do Responsável",
        "Nível Educacional do Responsável",
        "Tipo de Beneficiário",
        "Status de Beneficiário",
        "Data de Registro",
        "Data de Encerramento",
        "Município",
        "Grupo Tradicional",
        "Família Quilombola",
        "Família Indígena",
        "Linha de Pobreza",
        "Situação de Rua?",
        "Tipo de Domicílio",
        "Data de Nascimento de Gêmeos",
        "Qtd. Crianças < 6 Anos na Família",
        "Data de Nascimento da Criança",
        "Raça da Criança",
        "Gênero da Criança",
        "Nível Educacional da Criança",
        "Criança em Situação de Rua?",
      ],
      ...tableData.map((row) => [
        row.birth?.split("-").reverse().join("/"),

        row.race_text?.toLowerCase(),
        row.gender_text?.toLowerCase(),
        row.education_level_text?.toLowerCase(),

        row.b_type_text?.charAt(0).toUpperCase() +
          row.b_type_text?.slice(1).toLowerCase(),

        row.b_status_text?.toLowerCase(),

        row.registration_date?.split("-").reverse().join("/"),

        row.end_date?.split("-").reverse().join("/"),

        row.city,
        row.traditional_population_groups_text?.toLowerCase(),

        row.quilombola_family === "f" ? "Não" : "Sim",

        row.indigenous_family === "f" ? "Não" : "Sim",

        row.linha_pobreza?.toLowerCase(),

        row.street_situation === "f" ? "Não" : "Sim",

        row.type_of_domicile_text?.toLowerCase(),

        row.twin_child_birth !== "1900-01-01" && row.twin_child_birth !== ""
          ? row.twin_child_birth?.split("-").reverse().join("/")
          : "Não há",

        row.qtd_crianca_menos_6_anos_na_familia,

        row.child_birth?.split("-").reverse().join("/"),

        row.child_race_text ? row.child_race_text?.toLowerCase() : "Sem dado",

        row.child_gender_text
          ? row.child_gender_text?.toLowerCase()
          : "Sem dado",

        row.child_education_level_text
          ? row.child_education_level_text?.toLowerCase()
          : "Sem dado",

        row.child_street_situation === "f"
          ? "Não"
          : row.child_street_situation === ""
          ? "Sem dado"
          : "Sim",
      ]),
    ]
      .map((e) => e.join(","))
      .join("\n");

    var downloadLink = document.createElement("a");
    var blob = new Blob(["\ufeff", csvString]);
    var url = URL.createObjectURL(blob);
    downloadLink.href = url;
    downloadLink.download = "dados-cria.csv";

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const downloadPDF = async (event, component, downloadName) => {
    event?.preventDefault(); // prevent page reload
    const blob = await pdf(component).toBlob();

    saveAs(blob, `${downloadName}.pdf`);
  };

  return (
    <S.ReportsContainer>
      <S.AllDataWrapper>
        <S.ReportsHeaderTexts>
          <Title
            titleText="Relatórios"
            subtitleTexts={["Início", "Relatórios"]}
            showCadVersion={false}
          />
          {cadUnicoVersion && (
            <span>
              Versão do CadÚnico:{" "}
              {cadUnicoVersion?.split("-").reverse().join("/")}
            </span>
          )}
        </S.ReportsHeaderTexts>
        <S.ReportContainer>
          <h3 className="title">Relatório de Atendimento do Mês Atual</h3>

          <div className="buttonGroup">
            <Button
              onClick={(e) =>
                downloadPDF(
                  e,
                  <RelatorioAtendimentoMesAtual
                    th
                    col={["10%", "50%", "40%"]}
                    children={[
                      ["Nº", "Mês", "Atendimentos"],
                      ["01", "Janeiro", "3"],
                      ["02", "Fevereiro", "6"],
                      ["03", "Março", "6"],
                      ["Total", "", "9"],
                    ]}
                  />,
                  "relatorio-atendimento-mensal"
                )
              }
              variant="contained"
              className="btn-search"
              startIcon={<Download />}
              sx={{
                background: " var(--Light-Verde---light, #88C54B)",
                width: "180px",
                height: "54px",
                padding: "15px 16px",
                borderRadius: "8px",
                fontFamily: "Poppins, sans-serif",
                fontWeight: 300,
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#88C54B",
                },
              }}
            >
              Baixar PDF
            </Button>
          </div>
        </S.ReportContainer>

        <S.ReportContainer>
          <h3 className="title">Relatório de Beneficiária</h3>
          <div className="buttonGroup">
            <Button
              onClick={(e) =>
                downloadPDF(
                  e,
                  <RelatorioBeneficiaria
                    th
                    col={["25%", "25%", "25%", "25%"]}
                    children={[
                      [
                        "Faixa Etária",
                        "Extrema Pobreza (até R$ 109)",
                        "Pobreza (de R$ 109 a R$ 218)",
                        "Total",
                      ],
                      ["Entre 0 e 4", "150.394", "13.365", "163.759"],
                      ["Entre 5 a 6", "63.379", "6.322", "69.071"],
                      ["Entre 7 e 15", "63.379", "6.322", "69.071"],
                      ["Entre 16 e 17", "63.379", "6.322", "69.071"],
                      ["Entre 18 a 24", "63.379", "6.322", "69.071"],
                      ["Entre 25 a 34", "63.379", "6.322", "69.071"],
                      ["Entre 35 a 39", "63.379", "6.322", "69.071"],
                      ["Entre 40 a 44", "63.379", "6.322", "69.071"],
                    ]}
                  />,
                  "relatorio-beneficiaria"
                )
              }
              variant="contained"
              className="btn-search"
              startIcon={<Download />}
              sx={{
                background: " var(--Light-Verde---light, #88C54B)",
                width: "180px",
                height: "54px",
                padding: "15px 16px",
                borderRadius: "8px",
                fontFamily: "Poppins, sans-serif",
                fontWeight: 300,
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#88C54B",
                },
              }}
            >
              Baixar PDF
            </Button>
          </div>
        </S.ReportContainer>
        <S.ReportContainer>
          <h3 className="title">Relatório de Pobreza</h3>

          <div className="buttonGroup">
            <Button
              onClick={(e) =>
                downloadPDF(
                  e,
                  <RelatorioPobreza
                    th
                    colTableOne={["50%", "50%"]}
                    tableOne={[
                      ["Faixa Etária", "Total"],
                      ["Entre 0 e 4 anos", "150.394"],
                      ["Entre 5 e 6 anos", "63.379"],
                      ["Total", "213.773"],
                    ]}
                    colTableTwo={["50%", "50%"]}
                    tableTwo={[
                      ["Faixa Etária", "Total"],
                      ["Entre 0 e 4 anos", "12.394"],
                      ["Entre 5 e 6 anos", "6.379"],
                      ["Total", "19.773"],
                    ]}
                  />,
                  "relatorio-pobreza"
                )
              }
              variant="contained"
              className="btn-search"
              startIcon={<Download />}
              sx={{
                background: " var(--Light-Verde---light, #88C54B)",
                width: "180px",
                height: "54px",
                padding: "15px 16px",
                borderRadius: "8px",
                fontFamily: "Poppins, sans-serif",
                fontWeight: 300,
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#88C54B",
                },
              }}
            >
              Baixar PDF
            </Button>
          </div>
        </S.ReportContainer>

        <S.ReportContainer>
          <h3 className="title">Relatório de Evolução por Período</h3>

          <div className="buttonGroup">
            <Button
              onClick={(e) =>
                downloadPDF(
                  e,
                  <RelatorioEvolucaoPeriodo
                    th
                    colTableOne={["20%", "40%", "40%"]}
                    tableOne={[
                      ["Ano", "Meses", "Valor investido"],
                      ["2023", "Janeiro a Dezembro", "R$ 185.949.050,00"],
                      ["2023", "Janeiro a Maio", "R$ 185.949.050,00"],
                      ["Total", "", "R$ 185.949.050,00"],
                    ]}
                    colTableTwo={["30%", "70%"]}
                    tableTwo={[
                      ["Meses", "Quantidade de Solicitações"],
                      ["Janeiro", "12.394"],
                      ["Fevereiro", "6.379"],
                      ["Março", "19.773"],
                    ]}
                  />,
                  "relatorio-evoluacao-periodo"
                )
              }
              variant="contained"
              className="btn-search"
              startIcon={<Download />}
              sx={{
                background: " var(--Light-Verde---light, #88C54B)",
                width: "180px",
                height: "54px",
                padding: "15px 16px",
                borderRadius: "8px",
                fontFamily: "Poppins, sans-serif",
                fontWeight: 300,
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#88C54B",
                },
              }}
            >
              Baixar PDF
            </Button>
          </div>
        </S.ReportContainer>
      </S.AllDataWrapper>
    </S.ReportsContainer>
  );
};

export default ReportsPage;
