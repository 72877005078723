import AgentType from "../agentType";

const disabledFieldsForAllAgents = [
  'pregnant_name', 'pregnant_cpf', 'name', 'contact_number_one', 'contact_number_two',
  'responsible_cpf', 'rg', 'rg_issuer', 'rg_uf', 'street_type', 'street_name',
  'street_number', 'complement', 'name_location', 'city', 'updated', 'child_name',
  'child_cpf', 'child_cns', 'twin_child_name', 'twin_child_cpf', 'end_date', 'limit_report', 'limitdate', 'informbirthdate',
  'imc', 'gestational_age', 'gestational_month', 'dpp'
];

const disabledFieldsForMunicipalWhenEdit = [
  'twin_child_nis', 'nis', 'pregnant_nis', 'child_nis', 'ufs_ubs', 'other_benefits', 'weight', 'height', 'imc', 'gestational_age', 
  'gestational_month', 'dum', 'dpp', 'dpp_eco_estimated', 'first_check_date'
];

export const evaluateTextField = (name, isEdit, fieldValue, fromNis) => {
  const agentType = AgentType();
  let enabled = true;
  let isAutomaticallyFilled = false
  if (fromNis && disabledFieldsForAllAgents.includes(name)) {
    enabled = false;
    isAutomaticallyFilled = true
  }

  if (isEdit && ["2", "3"].includes(agentType)) {
    if (disabledFieldsForMunicipalWhenEdit.includes(name)) {
      // Exceção para child_nis e twin_child_nis se estiverem vazios
      if ((name === 'child_nis' || name === 'twin_child_nis') && !fieldValue) {
        enabled = true;
      } else {
        enabled = false;
      }
    }
  }

  return { isAutomaticallyFilled: isAutomaticallyFilled,  enabled: enabled};
};

