import React, { useState, useEffect, useCallback } from 'react';
import * as S from './styles';
import CompTextField from 'components/CompTextField';
import CompDropdown from 'components/CompDropdown';
import { states } from 'utils/constants';
import { distributionPoints } from 'utils/constants';
import { useForm } from 'react-hook-form';
import { CircularProgress, InputAdornment } from '@mui/material';
import { debounce } from 'lodash';

const Forms = ({ agentData, isView, register, unregister, watch, setValue, errors }) => {
  const [cepError, setCepError] = useState('');
  const [isCepLoading, setIsCepLoading] = useState(false);

  const cep = watch('cep');

  const findCityValue = (localidade) => {
    if (!localidade) return '0';
    const city = distributionPoints.find(point => point.textLabel.toLowerCase() === localidade.toLowerCase());
    return city ? city.value : '0';
  };
  const findStateValue = (uf) => {
    if (!uf) return '0';
    const state = states.find(state => state.textLabel === uf);
    return state ? state.value : '0';
  };

  const checkCEP = useCallback(debounce((cepValue) => {
    console.log(cepValue, 'cepValueeee')
    const cep = cepValue.replace(/\D/g, '');
    console.log('CEP após limpeza:', cep);

    setIsCepLoading(true);
    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then(res => {
        if (!res.ok) {
          throw new Error('CEP não encontrado');
        }
        return res.json();
      })
      .then(data => {
        setIsCepLoading(false);
        if (data.erro) {
          throw new Error('CEP não encontrado');
        } else {
          setValue('street_name', data.logradouro);
          setValue('city_residence', findCityValue(data.localidade));
          setValue('state', findStateValue(data.uf));
          setValue('address_complement', data.complemento);
          setCepError('');
        }
      })
      .catch(error => {
        setIsCepLoading(false);
        console.error('Erro ao buscar CEP:', error.message);
        setCepError('CEP Inválido');
        setValue('street_name', '');
        setValue('city_residence', '');
        setValue('state', '');
        setValue('address_complement', '');
      });
  }, 500), []);

  useEffect(() => {
    if (cep && cep.replace(/\D/g, '').length === 8 && cep !== agentData?.cep) {
      checkCEP(cep);
    }
  }, [cep, checkCEP, agentData?.cep]);
  

  return (
    <S.Form>
      <S.FormRowEndereco>
        {/* Linha 1 */}
        <CompTextField
          isView={isView}
          textLabel="CEP:"
          required
          style={{ flexGrow: '1' }}
          name="cep"
          register={register}
          unregister={unregister}
          error={!!cepError || errors.cep}
          helperText={cepError || errors.cep && errors.cep.message}
          apiText={agentData?.cep}
          InputProps={{
            endAdornment: (
              isCepLoading && (
                <InputAdornment position="end">
                  <CircularProgress size={20} />
                </InputAdornment>
              )
            ),
          }}
        />
        <CompTextField
          isView={isView}
          textLabel="LOGRADOURO:"
          name="street_name"
          required
          style={{ flexGrow: '1' }}
          apiText={agentData?.street_name}
          register={register}
          unregister={unregister}
          helperText={errors.street_name && errors.street_name.message}
          error={errors.street_name}
        />
        <CompTextField
          isView={isView}
          textLabel="NÚMERO:"
          style={{ flexGrow: '1' }}
          name='address_number'
          apiText={agentData?.address_number}
          register={register}
          unregister={unregister}
          required
          helperText={errors.address_number && errors.address_number.message}
          error={errors.address_number}
        />

        <CompTextField
          isView={isView}
          textLabel="COMPLEMENTO:"
          style={{ flexGrow: '1' }}
          name='address_complement'
          apiText={agentData?.address_complement}
          register={register}
          unregister={unregister}
        />
      </S.FormRowEndereco>

      <S.FormRowWithMargin>
        {/* Linha 2 */}

        <CompDropdown
          isView={isView}
          defaultValue={agentData.length>0 ? findCityValue(agentData.city_residence) : null}
          textLabel="MUNICÍPIO:"
          name='city_residence'
          required
          style={{ flexGrow: '2' }}
          apiValue={agentData ? findCityValue(agentData.city_residence) : " "}
          options={distributionPoints}
          register={register}
          unregister={unregister}
          helperText={errors.city_residence && errors.city_residence.message}
          error={errors.city_residence}
        />

        <CompDropdown
          isView={isView}
          defaultValue={agentData.length>0 ? findStateValue(agentData.state) : null}
          style={{ flexGrow: '1' }}
          name={'state'}
          textLabel="UF:"
          required
          apiValue={agentData ? findStateValue(agentData.state) : " "}
          options={states}
          register={register}
          unregister={unregister}
          helperText={errors.state && errors.state.message}
          error={errors.state}
        />
      </S.FormRowWithMargin>
    </S.Form>
  );
};

export default Forms;
