import React, { useEffect } from 'react';
import * as S from './styles';
import CompTextField from 'components/CompTextField';
import CompDropdown from 'components/CompDropdown';
import CompDateField from 'components/CompDateField';
import { NisField } from 'components/NisField';

const Forms = ({
  isView,
  isEdit,
  beneficiaryData = null,
  register,
  unregister,
  errors,
  watch,
  setValue,
  isTwinPregnancy,
  setIsTwinPregnancy,
}) => {

  const options = [
    { value: "Sim", textLabel: 'Sim' },
    { value: "Não", textLabel: 'Não' },
  ]

  const nis = watch('child_nis');
  const nisTwin = watch('twin_child_nis');
  const gemelar = watch('gemelar');

  const handleNis = (data) => {
    setValue('child_name', data.name);
    setValue('child_cpf', data.cpf);
    setValue('child_birth', data.birth);
  }

  const handleNisTwin = (data) => {
    setValue('twin_child_name', data.name);
    setValue('twin_child_cpf', data.cpf);
    setValue('twin_child_birth', data.birth);
  }

  useEffect(() => {
    if (beneficiaryData?.gemelar === "Sim") {
      setIsTwinPregnancy(true);
    }
  }, [beneficiaryData]);

  useEffect(() => {
    setIsTwinPregnancy(gemelar === "Sim");
  }, [gemelar, setIsTwinPregnancy]);

  const handleTwinPregnancyChange = (event) => {
    setIsTwinPregnancy(event.target.value === "Sim");
  };

  return (
    <S.Form>
      <S.DadosBoxDiagnostico>
        <S.FormRowParto>
          {/* Linha 1 */}
          <CompDropdown
            isView={isView}
            apiValue={beneficiaryData?.gemelar ? "Sim" : "Não"}
            textLabel="GRAVIDEZ GEMELAR:"
            required
            //defaultValue="SELECIONE"
            options={options}
            style={{ flexGrow: '0.1' }}
            name={"gemelar"}
            register={register}
            unregister={unregister}
            onChange={handleTwinPregnancyChange}
            dataTestid="gemelar-drop"
          />
        </S.FormRowParto>

        <S.FormRowWithMarginParto>
          {/* Linha 2 */}
          <CompDateField
            isView={isView}
            labelText="DATA DO PARTO:"
            style={{ flexGrow: '1' }}
            name="child_birth"
            register={register}
            unregister={unregister}
            disabled
          />

          <CompDropdown
            isView={isView}
            apiValue={beneficiaryData?.has_syndrome ? "Sim" : "Não"}
            textLabel="POSSUÍ SÍNDROME:"
            required
            //defaultValue="SELECIONE"
            options={options}
            style={{ flexGrow: '0.5' }}
            name="has_syndrome"
            register={register}
            unregister={unregister}
            dataTestid="syndrome-drop"
          />
        </S.FormRowWithMarginParto>
        <S.FormRowWithMarginParto>
          {/* Linha 3 */}
          <CompTextField
            isView={isView}
            apiText={beneficiaryData?.child_name}
            textLabel="NOME DA CRIANÇA:"
            style={{ flexGrow: '6' }}
            name="child_name"
            register={register}
            unregister={unregister}
            error={errors.child_name}
            helperText={errors.child_name && errors.child_name.message}
            //enabled={false}
            fromNis={true}
          />
          <NisField
            textLabel="NIS:"
            nis={nis}
            isView={isView}
            isEdit={isEdit}
            apiText={beneficiaryData?.child_nis}
            benefitNis={beneficiaryData?.nis}
            name="child_nis"
            register={register}
            unregister={unregister}
            error={errors.child_nis}
            helperText={errors.child_nis && errors.child_nis.message}
            handleNis={handleNis}
            setValue={setValue}
            flexGrow="6"
          />
        </S.FormRowWithMarginParto>
        <S.FormRowWithMarginParto>
          {/* Linha  */}
          <CompTextField
            isView={isView}
            apiText={beneficiaryData?.child_cns}
            textLabel="CNS DA CRIANÇA:"
            type="number"
            required
            style={{ flexGrow: '1' }}
            name="child_cns"
            register={register}
            unregister={unregister}
            error={errors.child_cns}
            helperText={errors.child_cns && errors.child_cns.message}
          />
          <CompTextField
            isView={isView}
            apiText={beneficiaryData?.child_cpf}
            textLabel="CPF DA CRIANÇA:"
            mask="999.999.999-99"
            placeholder="xxx.xxx.xxx-xx"
            style={{ flexGrow: '1' }}
            name={'child_cpf'}
            register={register}
            unregister={unregister}
            error={errors.child_cpf}
            helperText={errors.child_cpf && errors.child_cpf.message}
            //enabled={false}
            fromNis={true}
          />
        </S.FormRowWithMarginParto>
      </S.DadosBoxDiagnostico>

      {isTwinPregnancy && (
        <S.DadosBoxDiagnostico>
          <S.TituloSub>Dados da criança 2 (Gêmeo)</S.TituloSub>
          <S.FormRowWithMarginParto>
            {/* Linha 2 */}
            <CompDateField
              isView={isView}
              labelText="DATA DO PARTO:"
              style={{ flexGrow: '1' }}
              name="twin_child_birth"
              register={register}
              unregister={unregister}
              disabled
            />

            <CompDropdown
              isView={isView}
              apiValue={beneficiaryData?.twin_has_syndrome ? "Sim" : "Não"}
              textLabel="POSSUÍ SÍNDROME:"
              required
              //defaultValue="SELECIONE"
              options={options}
              style={{ flexGrow: '0.5' }}
              name={"twin_has_syndrome"}
              register={register}
              unregister={unregister}
              dataTestid="twin-syndrome-drop"
            />
          </S.FormRowWithMarginParto>
          <S.FormRowWithMarginParto>
            {/* Linha 3 para Gêmeo 2 */}
            <CompTextField
              isView={isView}
              apiText={beneficiaryData?.twin_child_name}
              textLabel="NOME DA CRIANÇA 2:"
              style={{ flexGrow: '6' }}
              name="twin_child_name"
              register={register}
              unregister={unregister}
              error={errors.twin_child_name}
              helperText={errors.twin_child_name && errors.twin_child_name.message}
              //enabled={false}
              fromNis={true}
            />
            <NisField
              textLabel="NIS:"
              nis={nisTwin}
              isView={isView}
              isEdit={isEdit}
              apiText={beneficiaryData?.twin_child_nis || ""}
              benefitNis={beneficiaryData?.nis}
              name="twin_child_nis"
              register={register}
              unregister={unregister}
              error={errors.twin_child_nis}
              helperText={errors.twin_child_nis && errors.twin_child_nis.message}
              handleNis={handleNisTwin}
              setValue={setValue}
              flexGrow="6"
            />
          </S.FormRowWithMarginParto>
          <S.FormRowWithMarginParto>
            {/* Linha 4 para Gêmeo 2 */}
            <CompTextField
              isView={isView}
              apiText={beneficiaryData?.twin_child_cns}
              textLabel="CNS DA CRIANÇA 2:"
              type="number"
              required
              style={{ flexGrow: '1' }}
              name="twin_child_cns"
              register={register}
              unregister={unregister}
              error={errors.twin_child_cns}
              helperText={errors.twin_child_cns && errors.twin_child_cns.message}
            />
            <CompTextField
              isView={isView}
              apiText={beneficiaryData?.twin_child_cpf}
              textLabel="CPF DA CRIANÇA 2:"
              //mask="999.999.999-99"
              placeholder="xxx.xxx.xxx-xx"
              style={{ flexGrow: '1' }}
              name="twin_child_cpf"
              register={register}
              unregister={unregister}
              error={errors.twin_child_cpf}
              helperText={errors.twin_child_cpf && errors.twin_child_cpf.message}
              //enabled={false}
              fromNis={true}
            />
          </S.FormRowWithMarginParto>
        </S.DadosBoxDiagnostico>
      )}
    </S.Form>
  );
};

export default Forms;
