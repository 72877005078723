import React, { useEffect, useState } from "react";
import CompSearchField from "components/CompSearchField";
import CompDropdown from "components/CompDropdown";
import CompDateField from "components/CompDateField";
import CompTextField from "components/CompTextField";
import TableIrregularity from "components/TableIrregularity";
import Title from "components/Title";
import * as S from "./styles";
import { useApi } from "hooks/useApis";
import { z } from "zod";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { CircularProgress, Grid } from "@mui/material";
import {
  status_options,
  type_options,
  distributionPoints,
  irregularities,
} from "utils/constants";
import Typography from "@mui/material/Typography";
import CompAutocomplete from "components/CompAutocomplete";
import { lightAmarelo } from "styles/variables";
import { DownloadReportsButton } from "components/DownloadReportsButton";

const BeneficiaryIrregularity = () => {
  const api = useApi();

  const [beneficiaryList, setBeneficiaryList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [errorData, setErrorData] = useState(false);

  const [statusCounts, setStatusCounts] = useState({
    2: 0, // ADVERTÊNCIA
    4: 0, // SUSPENSO
    3: 0, // BLOQUEADO
    0: 0, // CANCELADO
  });

  // Captura o estado de todos os filtros usando o useForm
  const createBeneficiarySearchSchema = z
    .object({
      b_status: z.string().optional(),
      b_type: z.string().optional(),
      search: z.string().optional(),
      sort: z.string().optional(),
      start_date: z.string().optional(),
      end_date: z.string().optional(),
      city: z.string().optional(),
      code: z.string().optional(),
    })
    .refine(
      (data) => {
        if (data.start_date?.length > 0 && data.end_date?.length === 0) {
          return false;
        }
        return true;
      },
      {
        message: "Preencha este campo.",
        path: ["end_date"],
      }
    );

  const methods = useForm({
    resolver: zodResolver(createBeneficiarySearchSchema),
    mode: "all",
    criteriaMode: "all",
  });

  // Monitoramento dos valores dos filtros
  const b_status_value = methods.watch("b_status");
  const b_type_value = methods.watch("b_type");
  const search_value = methods.watch("search");
  const sort_value = methods.watch("sort");
  const start_date_value = methods.watch("start_date");
  const end_date_value = methods.watch("end_date");
  const city_value = methods.watch("city");
  const code_value = methods.watch("code");
  const filters = {
    b_status: b_status_value,
    b_type: b_type_value,
    search: search_value,
    sort: sort_value,
    start_date: start_date_value,
    end_date: end_date_value,
    city: city_value,
    code: code_value
  };

  useEffect(() => {
    if (start_date_value?.length > 0 && end_date_value?.length === 0) {
      setErrorData("Preencha o fim do intervalo da data");
    } else if (start_date_value?.length === 0 && end_date_value?.length > 0) {
      setErrorData("Preencha o início do intervalo da data");
    } else {
      setErrorData(false);
      getIrregularBeneficiaryListData(1, filters);
    }
  }, [
    b_status_value,
    b_type_value,
    sort_value,
    start_date_value,
    end_date_value,
    city_value,
    code_value
  ]);

  const benefitFilters = {
    b_status: b_status_value,
    b_type: b_type_value,
    search: search_value,
    city: city_value,
  };

  const irregularBenefitFilters = {
    code: code_value,
    start_date: start_date_value,
    end_date: end_date_value,
  }

  useEffect(() => {
    getIrregularBeneficiaryListData(currentPage, filters);
  }, [currentPage]);

  const getIrregularBeneficiaryListData = async (pageNumber, filters = {}) => {
    try {
      setLoading(true);

      const data = await api.listIrregularBenefits(
        pageNumber,
        filters.search,
        filters.sort,
        filters.city,
        filters.start_date,
        filters.end_date,
        filters.b_status,
        filters.b_type,
        filters.code
      );

      if (data && data.results) {
        setBeneficiaryList(data.results.benefits);
        setTotalPages(data.total_pages);
        setCurrentPage(pageNumber);

        if (data.results.status_counts) {
          const counts = data.results.status_counts.reduce((acc, item) => {
            acc[item.b_status] = item.count;
            return acc;
          }, {});
          setStatusCounts(counts);
        }
      } else {
        console.error("Dados da API inválidos:", data);
      }
    } catch (err) {
      console.error("Erro ao buscar dados:", err);
    } finally {
      setLoading(false);
    }
  };

  async function onSubmit(data) {
    try {
      const filters = {
        b_status: data.b_status,
        b_type: data.b_type,
        search: data.search,
        sort: data.sort,
        start_date: data.start_date,
        end_date: data.end_date,
        city: data.city,
      };
      getIrregularBeneficiaryListData(1, filters);
    } catch (err) {
      console.error(err);
    }
  }

  function onError(errors) {
    console.log("Failed to submit:", errors);
  }


  return (
    <S.PageContainer>
      <Title
        titleText="Beneficiários irregulares"
        subtitleTexts={["Início", "Beneficiários irregulares"]}
      />
      <S.PageContent>
        <S.MainContainer>
          <FormProvider {...methods}>
            <S.Form onSubmit={methods.handleSubmit(onSubmit, onError)}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={6}>
                  <S.DateFieldsTitle>
                    BUSCA DE BENEFICIÁRIO
                  </S.DateFieldsTitle>
                  <CompSearchField
                    placeholder="Digite o nome ou NIS ou CPF para buscar"
                    name="search"
                    register={methods.register}
                    unregister={methods.unregister}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <CompAutocomplete
                    style={{ flexGrow: "1" }}
                    defaultValue=""
                    textLabel="MUNICÍPIO:"
                    options={distributionPoints}
                    name="city"
                    placeholder="Selecione uma município"
                    register={methods.register}
                    unregister={methods.unregister}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <CompDropdown
                    style={{ flexGrow: "1" }}
                    defaultValue=""
                    textLabel="STATUS DO BENEFÍCIARIO"
                    options={status_options.filter(
                      (option) => option.value !== "1"
                    )}
                    name="b_status"
                    register={methods.register}
                    unregister={methods.unregister}
                  />
                </Grid>
              </Grid>

              {/* TIPO DE IRREGULARIDADE E TIPO DE BENEFÍCIO */}
              <Grid container spacing={2} alignItems="center" marginTop={2}>
                <Grid item xs={12} sm={3}>
                  <CompDropdown
                    style={{ flexGrow: "1" }}
                    defaultValue=""
                    textLabel="TIPO DE IRREGULARIDADE"
                    options={irregularities}
                    name="code"
                    register={methods.register}
                    unregister={methods.unregister}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <CompDropdown
                    style={{ flexGrow: "1" }}
                    defaultValue=""
                    textLabel="TIPO DE BENEFÍCIO:"
                    options={type_options}
                    name="b_type"
                    register={methods.register}
                    unregister={methods.unregister}
                  />
                </Grid>

                {/* DATA DA IRREGULARIDADE */}
                <Grid item xs={12} sm={3} spacing={0}>
                  <Grid item xs={12}>
                    <S.DateFieldsTitle style={{ margin: '0' }}>
                      DATA DA IRREGULARIDADE
                    </S.DateFieldsTitle>
                  </Grid>
                  <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', }}>
                    <Grid item xs={12} sm={8} textAlign="center">
                      <CompDateField
                        name="start_date"
                        register={methods.register}
                        unregister={methods.unregister}
                      />
                    </Grid>
                    <Typography style={{ margin: '0 6%' }} variant="body1">&rarr;</Typography>
                    <Grid item xs={12} sm={8}>
                      <CompDateField
                        name="end_date"
                        register={methods.register}
                        unregister={methods.unregister}
                      />
                    </Grid>
                  </Grid>

                  {errorData?.length > 0 && (
                    <Grid item xs={12}>
                      <Typography
                        variant="body2"
                        color="error"
                        textAlign="center"
                      >
                        {errorData}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>

              <S.CabecalhoTabela>
                <Typography
                  variant="h6"
                  style={{ marginTop: 0, marginBottom: 0 }}
                >
                  Contagem por status
                </Typography>
                <S.FormHeader>
                  <CompTextField
                    textLabel="ADVERTÊNCIA"
                    apiText={
                      statusCounts["2"] !== undefined
                        ? statusCounts["2"].toString().padStart(4, "0")
                        : "0000"
                    }
                    style={{ flexGrow: "5" }}
                    name="advertencia"
                    register={methods.register}
                    unregister={methods.unregister}
                    isView={true}
                  />
                  <CompTextField
                    textLabel="BLOQUEADO"
                    apiText={
                      statusCounts["3"] !== undefined
                        ? statusCounts["3"].toString().padStart(4, "0")
                        : "0000"
                    }
                    style={{ flexGrow: "5" }}
                    name="bloqueado"
                    register={methods.register}
                    unregister={methods.unregister}
                    isView={true}
                  />
                  <CompTextField
                    textLabel="SUSPENSO"
                    apiText={
                      statusCounts["4"] !== undefined
                        ? statusCounts["4"].toString().padStart(4, "0")
                        : "0000"
                    }
                    style={{ flexGrow: "5" }}
                    name="suspenso"
                    register={methods.register}
                    unregister={methods.unregister}
                    isView={true}
                  />

                  <CompTextField
                    textLabel="CANCELADO"
                    apiText={
                      statusCounts["0"] !== undefined
                        ? statusCounts["0"].toString().padStart(4, "0")
                        : "0000"
                    }
                    style={{ flexGrow: "5" }}
                    name="cancelado"
                    register={methods.register}
                    unregister={methods.unregister}
                    isView={true}
                  />
                </S.FormHeader>
              </S.CabecalhoTabela>
            </S.Form>
          </FormProvider>

          {loading ? (
            <div
              style={{
                width: "100%",
                height: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress style={{ color: lightAmarelo }} />
            </div>
          ) : (
            <TableIrregularity
              beneficiaryList={beneficiaryList}
              page={currentPage}
              totalPages={totalPages}
              setPage={setCurrentPage}
            />
          )}

          <DownloadReportsButton
            isIrregular
            benefitFilters={benefitFilters}
            irregularBenefitFilters={irregularBenefitFilters}
          />
        </S.MainContainer>
      </S.PageContent>
    </S.PageContainer>
  );
};

export default BeneficiaryIrregularity;
