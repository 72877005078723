import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: JSON.parse(localStorage.getItem('cria-web@user')) || null,
  // user: { agent_type: "4", work_location: "Maceió" },
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserAgent(state, action) {
      state.user = action.payload;
      localStorage.setItem('cria-web@user', JSON.stringify(state.user));
    },
    clearUserAgent(state) {
      state.user = null;
      localStorage.setItem('cria-web@user', JSON.stringify(state.user));
    },
  },
});

export const { setUserAgent, clearUserAgent } = userSlice.actions;

export default userSlice.reducer;
