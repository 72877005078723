import sobrePage from "assets/image/sobrePage.svg"

const styles = {
  aboutContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundImage: `url(${sobrePage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },

  aboutTextsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
  },

  title: {
    color: 'var(--Dark-Laranja---dark, #F26A1D)',
    fontFamily: 'Poppins',
    fontSize: '36px',
    fontStyle: 'normal',
    fontWeight: 700
  },

  titleProg: {
    color: 'var(--Dark-Laranja---dark, #F26A1D)',
    fontFamily: 'Poppins',
    fontSize: '36px',
    fontStyle: 'normal',
    fontWeight: 700
  },

  textSobre: {
    color: 'var(--Dark-Preto, #222)',
    TextAlign: 'justify',
    fontFamily: 'Roboto',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: '40px' /* 166.667% */,
    maxWidth: '1172px',
    marginTop: '24px'
  },

  list: {
    color: 'var(--Dark-Preto, #222)',
    fontFamily: 'Roboto',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: '40px' /* 166.667% */
  },
  logos: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '1rem'
  },
  logoGovContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '10rem',
    width: '15rem'
  },
  logo: {
    width: '10vw',
  },
  footer: {
    marginTop: '5rem',
    width: '100%',
    height: '3rem',
    display: 'flex',
    padding: '21px 0px 20px 0px',
    justifyContent: 'center',
    borderTop: '1px solid var(--Light-Azul---light, #4BBFEF)'
  },
  footerText: {
    width: '450px',
    height: '24px',
    color: 'var(--Dark-Preto, #222)',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: '24px',
    marginTop: '24px',
    alignText: 'center'
  }
};

export default styles;
