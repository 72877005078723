import axios from "axios";

const apiDashboard = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL_DASHBOARD,
  // timeout: 30000,
});

apiDashboard.interceptors.request.use(
  (config) => {
    // const token = localStorage.getItem("cria-web@token");

    // if (token && token !== "" && JSON.parse(token) !== "null" && JSON.parse(token) !== null) {
    //   config.headers.Authorization = `Bearer ${JSON.parse(token)}`;
    // }
    return config;
  },
  (error) => Promise.reject(error)
);

export default apiDashboard;
