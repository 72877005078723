import * as S from './styles';
import emojiNeutral from '../../../assets/image/neutralEmoji.svg';
import { Emoji, CloseButton, AlertContent } from '../alertStyles';

const standardMessage =
  "Algum campo foi esquecido ou teve problemas com o preenchimento. Voltar para verificar informações.";

const FillErrorAlert = ({
  open,
  onClose,
  title = "Ops! Erro ao preencher.",
  message = standardMessage,
}) => {
  if (open)
    return (
      <S.AlertBox>
        <CloseButton onClick={onClose}>x</CloseButton>
        <Emoji src={emojiNeutral} alt="Emoji Description" />
        <S.AlertTitle>{title}</S.AlertTitle>
        <AlertContent>{message}</AlertContent>
      </S.AlertBox>
    );
};

export default FillErrorAlert;
