import styled from 'styled-components';

export const DateInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: auto;
`;

export const FormLabel = styled.label`
  color: var(--Gray-900, var(--900, #383838));
  font-family: Roboto;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin-top: 0;
  padding-top: 0;
  text-transform: uppercase;
`;

export const DateInputField = styled.input`
  display: inline-flex;
  width: auto; 
  height: 40px;  
  padding: 10px 14px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid var(--Light-Azul---light, #4BBFEF);
  background: var(--Dark-Branco, #FFF);

`;
export const DateInputFieldView = styled.input`
  width: auto; 
  height: 43.5px;  
  padding: 10px 14px;
  border-radius: 6px;
  border: 1px solid #F9922A;
  background: #FFF1E8;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  color: black;
`;

export const RequiredIndicator = styled.label`
  color: var(--Dark-red---dark, #E02E1A);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
  margin-left: 4px;
`;

export const ViewContainer = styled.div`
  display: inline-flex;
  padding: 8px 14px;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #F9922A;
  background: #FFF1E8;
`;
export const ApiData = styled.div`
  display: flex;
  height: 24px;
  flex-direction: column;
  justify-content: center;
  font-family: Roboto;
  font-size: 1rem; //18px
  font-weight: 300;
`;
