import styled from 'styled-components';

export const PaginaConteiner = styled.div`
  display: 'flex';
  flexDirection: 'column';
  minHeight: '100vh';
`;
export const PaginaContent = styled.div`
  flex: 1;
  display: 'flex';
  flexDirection: 'row';
  marginBottom: '50px';
  //alignItems: 'flex-start',
`;


export const TituloItem = styled.div`
  display: flex;
  width: 25vw;
  padding: 15px 801px 18px 24px;
  flex-direction: column;
  gap: 24px;
  padding-top: 0px;
  margin-top: 20px;
`;

export const TituloText = styled.h1`
  color: var(--light-amarelo-light, #F9922A);
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 46px;
  margin-bottom: 0;
  margin-top: 0;
`;

export const DadosConteiner = styled.div`
  border-radius: 8px;
  background: ${props => (props.isView ? "none" : "var(--Light-Background, #FFF1E8)")};
  width: 76vw;
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 26px;
  margin-bottom: 25px;
  padding-bottom: 20px;
  max-width: 1400px;
  gap: 24px;
`;

export const TituloSub = styled.h2`
  color: var(--Light-Amarelo---light, #F9922A);
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  width: 79vw; 
  max-width: 1100px;
`;

export const InfoBotao = styled.img`
  width: 26px;
  height: 26px;
  flex-shrink: 1;
  cursor: pointer;
  margin-left: 10px;
`;

export const ContentContainer = styled.div`
  display: flex;
  width: 1193px;
  padding: 15px 801px 18px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const SelectContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  max-width: 1400px;
  width: 73vw;
`;

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SelectWrapperDate = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

export const Select = styled.select`
  width: 557px;
  height: auto;
  padding: 8px 14px;
  border-radius: 6px;
  border: 1px solid #4BBFEF;
  background: #FFF;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
`;

export const SelectDate = styled.select`
  width: 557px;
  height: auto;
  padding: 8px 14px;
  border-radius: 6px;
  border: 1px solid var(--Gray-400, #B8B8B8);
  background: #F3F3F3;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
`;

export const Label = styled.label`
  color: var(--Gray-900, var(--900, #383838));
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 8px;
`;

export const LineItem = styled.div`
  /* width: 1129px; */
  /* flex-shrink: 0; */
  margin-top: 39px;
  height: auto;
`;

// Forms Dados Pessoais

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const PhotoContainer = styled.img`
  width: 206px;
  height: 247px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 2px solid #F26A1D;
`;

export const FormGeral = styled.div`
  display: flex;
  flex-direction: column;
  flex-flow: row nowrap;
  height: 200px;
  gap: 18px;
`;

export const FormRow = styled.div`
  display: flex;
  gap: 2vw;
  width: 58vw;
  justify-content: start;
  max-width: 1100px;
`;

export const FormRowOutros = styled.div`
  display: flex;
  gap: 2vw;
  margin-bottom: 0px;
  width:73vw;
  max-width: 1400px;
`;

export const FormRowEndereco = styled.div`
  display: flex;
  gap: 2vw;
  margin-top: 3px;
`;

export const FormRowWithMargin = styled.div`
  display: flex;
  gap: 2vw;
  margin-top: 43px;
  padding-top: 0px;
  width: 73vw;
  max-width: 1400px;
`;

export const FormRowWithMarginDados = styled.div`
  display: flex;
  gap: 2vw;
  margin-top: 24px;
  padding-top: 0px;
`;

export const FormRowWithMarginDadosCns = styled.div`
  display: flex;
  gap: 2vw;
  margin-top: 74px;
  padding-top: 0px;
`;

export const FormRowWithMarginDadosSemCad = styled.div`
  display: flex;
  gap: 100px;
  margin-top: 20px;
  padding-top: 0px;
`;

export const FormRowWithMarginEndereco = styled.div`
  display: flex;
  gap: 2vw;
  margin-top: 33px;
  padding-top: 0px;
`;

export const FormItem = styled.div`
  width: 361px;
  height: 40px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const FormItemData = styled.div`
  width: 361px;
  height: 73px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const FormLabel = styled.label`
  color: var(--Gray-900, var(--900, #383838));
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-top: 0px;
  padding-top: 0px;
`;

export const FormInput = styled.input`
  width: 412px;
  height: 40px;
  padding: 8px 14px;
  justify-content: center;
  align-items: center;
  gap: 379px;
  border-radius: 6px;
  border: 1px solid var(--Light-Azul---light, #4BBFEF);
  background: var(--Dark-Branco, #FFF);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
`;

export const FormInputData = styled.input`
  padding: 8px 14px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid var(--Light-Azul---light, #4BBFEF);
  background: var(--Dark-Branco, #FFF);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 44px;
  width: 412px;
  height: 80px;
`;

export const FormInputEndereco = styled.input`
  width: 275px;
  height: 40px;
  padding: 8px 14px;
  justify-content: center;
  align-items: center;
  gap: 379px;
  border-radius: 6px;
  border: 1px solid var(--Light-Azul---light, #4BBFEF);
  background: var(--Dark-Branco, #FFF);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  `;

export const SelecaoMunicipio = styled.select`
  width: 300px;
  height: 40px;
  padding: 8px 14px;
  justify-content: center;
  align-items: center;
  gap: 379px;
  border-radius: 6px;
  border: 1px solid var(--Light-Azul---light, #4BBFEF);
  background: var(--Dark-Branco, #FFF);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
`;

export const FormNumVisualEndereco = styled.input`
  width: 275px;
  height: 40px;
  padding: 8px 14px;
  justify-content: center;
  align-items: center;
  gap: 379px;
  border-radius: 6px;
  border: 1px solid var(--Gray-400, #B8B8B8);
  background: #F3F3F3;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
`;

export const FormInputVisualEndereco = styled.input`
  /* display: 'inline-flex'; */
  width: 275px;
  height: 40px;
  padding: 8px 14px;
  justify-content: center;
  align-items: center;
  gap: 379px;
  border-radius: 6px;
  border: 1px solid var(--Gray-400, #B8B8B8);
  background: #F3F3F3;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
`;

export const RequiredIndicator = styled.div`
  color: var(--Dark-Vermelho---dark, #E02E1A);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
  margin-left: 4px;
`;

// Forms Endereco

export const SelectLogradouro = styled.select`
  width: 124px;
  height: 40px;
  /* padding: 8px 14px; */
  border-radius: 6px;
  border: 1px solid #4BBFEF;
  background: #FFF;
  display: flex;
  gap: 8px;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
`;

export const SelectLogradouroSemCad = styled.select`
  width: 124px;
  height: 40px;
  /* padding: 8px 14px; */
  border-radius: 6px;
  border: 1px solid var(--Gray-400, #B8B8B8);
  background: #F3F3F3;
  display: flex;
  gap: 8px;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
`;

export const FormItemLogradouro = styled.div`
  width: 406px;
  height: 40px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const FormItemNum = styled.div`
  width: 59vw;
  /* height: 40px; */
  display: flex;
  flex-direction: row;
  gap: 1vw;
`;

export const FormItemComp = styled.div`
  width: 275px;
  height: 40px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const FormItemBairro = styled.div`
  width: 426px;
  height: 40px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  /* marginTop: 0px; */
`;

export const SelectLogradouroUF = styled.select`
  width: 213px;
  height: 40px;
  /* padding: 8px 14px; */
  border-radius: 6px;
  border: 1px solid var(--Light-Azul---light, #4BBFEF);
  background: var(--Dark-Branco, #FFF);
  display: flex;
  /* gap: 8px; */
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
`;


export const SelectCrianca = styled.select`
  width: 190px;
  height: 40px;
  /* padding: 8px 14px; */
  border-radius: 6px;
  border: 1px solid var(--Gray-400, #B8B8B8);
  background: #F3F3F3;
  display: flex;
  /* gap: 8px; */
`;

export const FormInputUBS = styled.input`
  /* display: 'inline-flex'; */
  width: 800px;
  padding: 10px 14px;
  justify-content: center;
  align-items: center;
  gap: 354px;
  border-radius: 6px;
  border: 1px solid var(--Light-Azul---light, #4BBFEF);
  background: var(--Dark-Branco, #FFF);
  color: var(--Gray-900, var(--900, #383838));
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 28px;
  margin-top: 0px;
  padding-top: 0px;
  `;

export const SelectLogradouroUBS = styled.select`
  width: 124px;
  height: 40px;
  /* padding: 8px 14px; */
  border-radius: 6px;
  border: 1px solid var(--Light-Azul---light, #4BBFEF);
  background: var(--Dark-Branco, #FFF);
  display: flex;
  background: #FFF;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
`;

// Novas importações:

export const FormInputVisualCrianca = styled.input`
  display: inline-flex;
  padding: 8px 14px;
  /* justifyContent: 'center'; */
  align-items: center;
  gap: 379px;
  border-radius: 6px;
  border: 1px solid var(--Gray-400, #B8B8B8);
  background: #F3F3F3;
  width: 412px;
`;

export const FormInputVisualResponsavel = styled.input`
  display: inline-flex;
  padding: 8px 14px;
  /* justifyContent: 'center'; */
  align-items: center;
  gap: 379px;
  border-radius: 6px;
  border: 1px solid var(--Gray-400, #B8B8B8);
  background: #F3F3F3;
`;

export const FormVisual = styled.div`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  color: var(--Dark-Preto, #222);
`;

export const DadosVisual = styled.div`
  display: flex;
  width: 333px;
  height: 24px;
  flex-direction: column;
  justify-content: center;
`;

export const SelectOutros = styled.select`
  width: 124px;
  height: 40px;
  /* padding: 8px 14px; */
  border-radius: 6px;
  border: 1px solid #4BBFEF;
  background: #FFF;
  display: flex;
  gap: 8px;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
`;

export const BotoesConteiner = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  margin-top: 50px;
  width: 75vw; 
  max-width: 1400px;
`;

export const DadosBox = styled.div`
// padding-left: 25px;
// justify-content: space-around;
  
`;
// Forms Diagnóstico

export const DadosBoxDiagnostico = styled.div`
  width: 75vw;
  max-width: 1400px;
  height: 200px;
  margin-bottom: 0;
  padding-bottom: 0;
`;

export const FormRowDiagnostico = styled.div`
  display: flex;
  justify-content: space-evenly;
  gap: 2vw;
  align-content: center;
  margin-bottom: 0px;
  width: 75vw; 
  max-width: 1400px;
`;

export const FormRowWithMarginDiagnostico = styled.div`
  display: flex;
  margin-top: 40px;
  padding-top: 0px;
  gap: 2vw;
  width: 73vw; 
  max-width: 1400px;
`;

export const FormInputVisualDiagnostico = styled.input`
  display: inline-flex;
  padding: 8px 14px;
  align-items: center;
  border-radius: 6px;
  border: 1px solid var(--Gray-400, #B8B8B8);
  background: #F3F3F3;
  width: 200px;
  margin-right: 20px;
`;

export const FormItemDiagnostico = styled.div`
  width: 400px;
  height: 40px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const FormItemDiagnosticoData = styled.div`
  width: 400px;
  height: 70px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const FormInputDiagnostico = styled.input`
  /* display: 'inline-flex'; */
  width: 200px;
  height: 40px;
  padding: 8px 14px;
  justify-content: center;
  align-items: center;
  gap: 379px;
  border-radius: 6px;
  border: 1px solid var(--Light-Azul---light, #4BBFEF);
  background: var(--Dark-Branco, #FFF);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  margin-right: 20px;
  `;

// Datas Importantes

export const FormRowDatas = styled.div`
  display: flex;
  margin-bottom: 0px;
  gap: 2vw;
  width: 73vw; 
  max-width: 1400px;
`;

export const FormRowDatasTwo = styled.div`
  display: flex;
  margin-bottom: 0px;
  margin-top: 20px;
  gap: 1vw;
  width: 73vw; 
  max-width: 1400px;
`;

export const FormItemDatas = styled.div`
  width: 500px;
  height: 40px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const FormInputDatas = styled.input`
  /* display: 'inline-flex'; */
  width: 406px;
  height: 40px;
  padding: 8px 14px;
  justify-content: center;
  align-items: center;
  gap: 379px;
  border-radius: 6px;
  border: 1px solid var(--Gray-400, #B8B8B8);
  background: #F3F3F3;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  `;

// Forms Gestante Menor

export const FormInputMenor = styled.input`
  /* display: 'inline-flex'; */
  height: 40px;
  padding: 8px 14px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid var(--Light-Azul---light, #4BBFEF);
  background: var(--Dark-Branco, #FFF);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  width: 538px;
  `;

export const FormRowGestanteMenor = styled.div`
  display: flex;
  gap: 2vw;
  height: 50px;
  width: 73vw; 
  max-width: 1100px;
  margin-bottom: 40px;
`;

export const FormLabelDate = styled.label`
  color: var(--Gray-900, var(--900, #383838));
  font-family: Roboto;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin-top: 0;
  padding-top: 0;
  text-transform: uppercase;
`;

