import React from 'react';
import * as S from './styles';


const NewStatus = () => {
    return (
        <S.NewStatus>
            novo
        </S.NewStatus>
    );
};

export default NewStatus;