import React, { useState } from 'react';
import * as S from './styles';
import Title from 'components/Title';
import { DownloadButton } from 'components/Buttons/DownloadButton';
import { LinearProgress, Box, Typography } from '@mui/material';
import { useApi } from 'hooks/useApis';
import FillErrorAlert from 'components/Alerts/FillErrorAlert';
import axios, { AxiosError } from 'axios'; 


const EmissionsConsultation = () => {
  const [date, setDate] = useState(''); 
  const [progress, setProgress] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDownloadStarted, setIsDownloadStarted] = useState(false); 
  const [progressMessage, setProgressMessage] = useState('');
  const [progressColor, setProgressColor] = useState('#F26A1D');
  const [isError, setIsError] = useState(false); 
  const [downloadError, setDownloadError] = useState('');
  const api = useApi();

  const handleDownload = async () => {
    if (!date) {
      // alert('Por favor, insira uma data válida.');
      setDownloadError('Por favor, insira uma data válida.');
      return;
    }

    setIsDownloading(true);
    setProgress(0);
    setIsDownloadStarted(false); 
    setIsError(false); 
    setProgressMessage('O processamento pode demorar um pouco. Por favor, espere até que o download termine.');
    setProgressColor('#F26A1D');

    try {
      setProgress(50);
      
      await api.downloadPayroll(date);
      
      setIsDownloadStarted(true);
      setProgress(100);
      setProgressMessage('Download Completo!');
      setProgressColor('#70AC17');
    } // catch (error) {
    //   setIsError(true);
    //   let errorMessage = 'Falha ao baixar o arquivo. Por favor, tente novamente.';
    //   let errorProgressColor = '#E02E1A';

    //   if (error.response) {
    //     switch (error.response.status) {
    //       case 401:
    //         errorMessage = 'Agente não tem permissão para realizar essa ação.';
    //         break;
    //       case 404:
    //         errorMessage = 'Sem beneficiários para as datas especificadas.';
    //         break;
    //       case 406:
    //         errorMessage = 'Agente não autenticado.';
    //         break;
    //       case 412:
    //         errorMessage = 'Nenhum arquivo foi gerado.';
    //         break;
    //       case 429:
    //         errorMessage = 'Serviço em uso. Por favor, aguarde alguns instantes e tente novamente.';
    //         break;
    //       default:
    //         errorMessage = 'Erro desconhecido. Por favor, tente novamente.';
    //     }
    //   }

    //   setProgressMessage(errorMessage);
    //   setProgressColor(errorProgressColor);
    //   setProgress(25); 
    //   console.error('Erro ao baixar a folha de pagamento:', error);
    // } 
    catch (error) {
      let errorMessage = 'Erro desconhecido. Por favor, tente novamente.';
      let errorProgressColor = '#E02E1A';
      
      if (error instanceof AxiosError && error.response?.status >= 400) {
        try {
          const data = await error.response.data.text(); 
          const responseData = JSON.parse(data);
          errorMessage = responseData.detail || errorMessage;
          setIsError(errorMessage)
        } catch (parseError) {
          console.error('Erro ao analisar a resposta da API:', parseError);
        }
      } else if (error.message) {
        errorMessage = error.message;
      }
      // console.log()
      // setIsError(error.response.detail ? error.response.detail : false);
      // setProgressMessage(errorMessage);
      // setProgressColor(errorProgressColor);
      setProgress(25); 
      console.error('Erro ao baixar a folha de pagamento:', error);
    }
    finally {
      setIsDownloading(false);
    }
  };

  console.log('Erro', isError)
  
  return (
    <S.PageContainer>
      <Title
        titleText="Extração de Dados dos Beneficiários"
        subtitleTexts={['Início', 'Exportação de dados']}
      />
      <S.PageContent>
        <S.MainContainer>
          <S.FormDate>
            <S.LeftContainer>
              <S.FormLabel>Cadastro Final:</S.FormLabel>
              <S.DateInputField
                type="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                max={new Date().toISOString().split('T')[0]}
              />
            {isError &&
          <p style={{color: 'red'}}>{isError}</p>}
            </S.LeftContainer>
            <S.RightContainer>
              <DownloadButton onClick={handleDownload} disabled={isDownloading} />
            </S.RightContainer>
          </S.FormDate>

          {downloadError && <FillErrorAlert message={downloadError} />}

          {isDownloading && !isDownloadStarted && (
            <Box sx={{ width: '100%', mt: 2 }}>
              <Typography variant="body2" color="text.secondary">
                Carregando, isso pode demorar um pouco!
              </Typography>
              <LinearProgress sx={{ mt: 1, backgroundColor: '#e0e0e0' }} />
            </Box>
          )}

          {(isDownloadStarted) && (
            <Box sx={{ width: '100%', mt: 2 }}>
              <Typography variant="body2" color="text.secondary">
                {progressMessage}
              </Typography>
              <LinearProgress
                variant="determinate"
                value={progress}
                sx={{
                  mt: 1,
                  backgroundColor: '#e0e0e0',
                  '& .MuiLinearProgress-bar': { backgroundColor: progressColor },
                }}
              />
              <Typography variant="body2" color="text.secondary">
                {`${Math.round(progress)}%`}
              </Typography>
            </Box>
          )}
          
        </S.MainContainer>
      </S.PageContent>
    </S.PageContainer>
  );
};

export default EmissionsConsultation;
