import React, { useEffect, useState } from "react";
import * as S from "./styles";
import { Link } from "react-router-dom";
import FormsDadosCrianca from "./formsDadosCrianca";
import FormsDadosTwin from "./formsDadosTwin";
import FormsEndereco from "../formsEndereco";
import FormsOutros from "../formsOutros";
import FormsDatas from "./formsDatas";
import FormsDadosResp from "../formsDadosResp";
import { CreateBeneficiaryBtn } from "components/Buttons/CreateBeneficiaryBtn";
import { BackButton } from "components/Buttons/BackButton";
import { SaveButton } from 'components/Buttons/SaveButton';
import { EditButton } from 'components/Buttons/EditButton';
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "utils/zod-pt";
import { beneficiaryTypeToApi, convertDateToApi, childFormDefaultValues, scrollToTop, getBeneficiaryStatus } from "utils";
import { useApi } from 'hooks/useApis';
import InfoButton from "components/Buttons/InfoButton";
import { serverErrorMsg, textsCria } from "utils/constants";
import HistoryView from "components/HistoryView";
import AgentType from "utils/agentType"; import axios from "axios";
import { APIErrorAlert } from "components/Alerts/APIErrorAlert";
import DeleteModal from "components/ModalConfirmCancellation";
import DisableBeneficiary from 'components/Buttons/DisableBeneficiary';
import ActivationBeneficiary from "components/Buttons/ActivationBeneficiary"
import LoadingBox from "components/Loading";

const ChildForms = ({
  benefitType,
  isView = false,
  beneficiaryData = null,
  isEdit = false,
  setBeneficiaryData = () => { },
  handleVersionChange,
  isLoading = true,
  isCurrentVersion = true,
}) => {
  const api = useApi();
  const [submitStatus, setSubmitStatus] = useState('')
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [cancelStatus, setCancelStatus] = useState('');
  const openCancelModal = () => setIsCancelModalOpen(true);
  const closeCancelModal = () => setIsCancelModalOpen(false);
  const [cancelError, setCancelError] = useState(() => '');
  const [isActivation, setActivation] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [beneficiaryStatusComponent, setBeneficiaryStatusComponent] = useState("Ativo");

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [apiCreationSubmitionError, setApiCreationSubmitionError] = useState("");
  const closeImpedimentAlert = () => setApiCreationSubmitionError("");

  const agentType = AgentType();

  const createChildBeneficiarySchema = z.object({

    // Dados da Criança
    child_nis: z.string().length(11, { message: "Campo obrigatório" }),
    child_name: z.string().min(1, { message: "Campo obrigatório" }),
    child_cpf: z.string().length(11, { message: "Campo obrigatório" }),
    child_birth: z.string().transform((value) => convertDateToApi(value)),
    child_cns: z.string().min(1, { message: "Campo obrigatório" }),

    // Dados da Criança 2 ( Gêmeos )
    twin_child_nis: z.preprocess((val) => {
      return benefitType === "Gêmeos" ? val : undefined;
    }, z.string().nonempty("Campo obrigatório").length(11).optional()),

    twin_child_name: z.preprocess((val) => {
      return benefitType === "Gêmeos" ? val : undefined;
    }, z.string().nonempty("Campo obrigatório").optional()),

    twin_child_cpf: z.preprocess((val) => {
      return benefitType === "Gêmeos" ? val : undefined;
    }, z.string().nonempty("Campo obrigatório").min(11).optional()),

    twin_child_birth: z.preprocess((val) => {
      return benefitType === "Gêmeos" ? val : undefined;
    }, z.string().nonempty("Campo obrigatório")
      .transform((value) => convertDateToApi(value))
      .optional()),

    twin_child_cns: z.preprocess((val) => {
      return benefitType === "Gêmeos" ? val : undefined;
    }, z.string().nonempty("Campo obrigatório").optional()),


    // Dados Responsável
    nis: z.string().length(11, { message: "Campo obrigatório" }),
    name: z.string().min(1, { message: "Campo obrigatório" }),
    cns: z.string().min(1, { message: "Campo obrigatório" }),
    birth: z.string().transform((value) => convertDateToApi(value)),
    responsible_cpf: z.string().length(11, { message: "Campo obrigatório" }),
    contact_number_one: z.string().optional(),
    contact_number_two: z.string().optional(),
    provisional_contact_number: z.preprocess(
      (val) => (val === "" || val === null || val === undefined ? undefined : val),
      z.string().optional()
    ),
    rg: z.string().optional(),
    rg_issuer: z.string().optional(),
    rg_uf: z.string().optional(),

    // Endereço
    street_type: z.string().optional(),
    street_name: z.string().optional(),
    street_number: z.coerce.number().optional(),
    complement: z.string().optional(),
    name_location: z.string().optional(),
    city: z.string().optional(),
    uf: z.string().optional(),
    ufs_ubs: z.string().min(1, { message: "Campo obrigatório" }),
    updated: z
      .string()
      .transform((value) => (value === "Sim" ? true : false))
      .optional(),

    // Outros
    bf: z.string().transform((value) => (value === "Sim" ? true : false)).optional(),
    bvg: z.string().transform((value) => (value === "Sim" ? true : false)).optional(),
    bcn: z.string().transform((value) => (value === "Sim" ? true : false)).optional(),
    pcf: z.string().transform((value) => (value === "Sim" ? true : false)).optional(),
    bpc: z.string().transform((value) => (value === "Sim" ? true : false)).optional(),
    paif: z.string().transform((value) => (value === "Sim" ? true : false)).optional(),
    paefi: z.string().transform((value) => (value === "Sim" ? true : false)).optional(),
    pl: z.string().transform((value) => (value === "Sim" ? true : false)).optional(),
    mcmv: z.string().transform((value) => (value === "Sim" ? true : false)).optional(),
    weight: z.string().optional(),
    height: z.string().optional(),

    // Datas
    end_date: z.string().transform((value) => convertDateToApi(value)),
    other_benefits: z.string().optional(),
  })

  const methods = useForm({
    resolver: zodResolver(createChildBeneficiarySchema),
    mode: "all",
    criteriaMode: "all",
  });

  useEffect(() => { // Add values on edit form
    if (isEdit && beneficiaryData) {
      if (isCurrentVersion) {
        const defaultValues = childFormDefaultValues(beneficiaryData);
        defaultValues.name = beneficiaryData.name || ""; 
        methods.reset(defaultValues);
      }
    }
  }, [isEdit, beneficiaryData, methods.reset]);

  function removeFieldsForSubmit(obj, fieldsToRemove) {
    const filteredObj = { ...obj };
    fieldsToRemove.forEach((field) => {
      delete filteredObj[field];
    });
    return filteredObj;
  }

  async function onSubmit(data) {
    const fieldsToRemove = [
      "contact_number_one",
      "contact_number_two",
      "rg",
      "rg_issuer",
      "rg_uf",
      "street_name",
      "street_type",
      "street_number",
      "complement",
      "name_location",
      "city",
      "updated",
    ];
    const filteredData = removeFieldsForSubmit(data, fieldsToRemove);
    filteredData.b_type = beneficiaryTypeToApi(benefitType);
    filteredData.b_status = isEdit ? beneficiaryData.b_status : "1";
    filteredData.has_syndrome = filteredData.b_type === "2" ? true : false;
    filteredData.provisional_contact_number = filteredData.provisional_contact_number?.replace(/\D/g, '');
    // console.log("Formulario:", filteredData);

    // console.log("Formulario:", filteredData);
    try {
      setIsSubmitting(true);
      if (isActivation) {
        filteredData.b_status = "1";
        await api.activateBeneficiary(beneficiaryData.id, filteredData);
        console.log('Beneficiário ativado com sucesso!');
        setSubmitStatus('success');
      }
      else if (isEdit) {
        await api.updateBeneficiary(beneficiaryData.id, filteredData);
      }
      else {
        await api.createBeneficiary(filteredData);
      }
      setSubmitStatus('success')
    } catch (error) {
      if (error instanceof axios.AxiosError) {
        const { response } = error;
        setApiCreationSubmitionError(
          response?.status === 500 ? serverErrorMsg : response?.data?.detail
        );
        return;
      }

      setSubmitStatus('error')
      console.error('Erro ao submeter o formulário:', error);
    } finally {
      setIsSubmitting(false);
    }
  }

  function onError(errors) {
    setSubmitStatus('error')
    console.log('Failed to submit:', errors);
  }

  const handleChangeVersion = async (version) => {
    try {
      const versionData = childFormDefaultValues(version);
      const status = getBeneficiaryStatus(version.b_status);
      setBeneficiaryData(versionData); // Update to visualization form

      handleVersionChange(version.date_history, version.b_status);
    } catch (error) {
      console.error('Erro ao buscar a versão:', error);
    }
  };

  const [isSubmittingCancel, setIsSubmittingCancel] = useState(false);
  const handleConfirmCancel = async (justification) => {
    if (justification.length < 15 || justification.length > 200) {
      setCancelError('A justificativa deve conter entre 15 e 200 caracteres.');
      return;
    }
    setCancelError('');
    try {
      setIsSubmittingCancel(true);
      await api.cancelBeneficiary(beneficiaryData.id, justification);
      setAlertType('success');
      // closeCancelModal();
      // window.location.href = "/beneficiarios";
    } catch (error) {
      setAlertType('error');
      console.error('Erro ao cancelar o beneficiário:', error);
    } finally {
      setIsSubmittingCancel(false);
    }
  };

  const handleEditBeneficiary = async () => {
    scrollToTop();
    try {
      const currentVersionData = await api.getBeneficiaryById(beneficiaryData.id);
      if (currentVersionData) {
        window.location.href = `/beneficiarios/${currentVersionData.id}/editar`;
      }
    } catch (error) {
      console.error('Erro ao buscar a versão atual:', error);
    }
  };

  return (
    <FormProvider {...methods}>
      {isEdit && isLoading ? <LoadingBox /> : (<form onSubmit={methods.handleSubmit(onSubmit, onError)}>
        <S.TituloItem>
          {benefitType !== textsCria.twins && (
            <>
              <S.DadosConteiner
                // @ts-ignore 
                isView={isView}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <S.TituloSub style={{ width: "auto" }}>Dados da Criança</S.TituloSub>
                  <InfoButton />
                </div>
                <FormsDadosCrianca
                  beneficiaryData={beneficiaryData}
                  isView={isView}
                  isEdit={isEdit}
                  register={methods.register}
                  unregister={methods.unregister}
                  watch={methods.watch}
                  setValue={methods.setValue}
                  errors={methods.formState.errors}
                />

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                    marginTop: "64px",
                  }}
                >
                  <S.TituloSub style={{ width: "auto" }}>Dados pessoais do responsável</S.TituloSub>
                  <InfoButton />
                </div>
                <FormsDadosResp isEdit={isEdit} isView={isView} register={methods.register} unregister={methods.unregister}
                  watch={methods.watch} setValue={methods.setValue} errors={methods.formState.errors}
                  beneficiaryData={beneficiaryData} />

                <S.TituloSub>Endereço da família</S.TituloSub>
                <FormsEndereco isEdit={isEdit} isView={isView} register={methods.register} unregister={methods.unregister}
                  errors={methods.formState.errors} beneficiaryData={beneficiaryData} />
              </S.DadosConteiner>
            </>
          )}
          {benefitType === textsCria.twins && (
            <>
              <S.DadosConteiner
                // @ts-ignore
                isView={isView}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <S.TituloSub style={{ width: "auto" }}>Dados da Criança 1</S.TituloSub>
                  <InfoButton />
                </div>
                <FormsDadosCrianca
                  beneficiaryData={beneficiaryData}
                  isView={isView}
                  isEdit={isEdit}
                  register={methods.register}
                  unregister={methods.unregister}
                  watch={methods.watch}
                  setValue={methods.setValue}
                  errors={methods.formState.errors}
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                    marginTop: "64px",
                  }}
                >
                  <S.TituloSub style={{ width: "auto" }}>Dados da Criança 2</S.TituloSub>
                  <InfoButton />
                </div>
                <FormsDadosTwin
                  beneficiaryData={beneficiaryData}
                  isView={isView}
                  isEdit={isEdit}
                  register={methods.register}
                  unregister={methods.unregister}
                  watch={methods.watch}
                  setValue={methods.setValue}
                  errors={methods.formState.errors}
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                    marginTop: "64px",
                  }}
                >
                  <S.TituloSub style={{ width: "auto" }}>Dados pessoais do responsável</S.TituloSub>
                  <InfoButton />
                </div>
                <FormsDadosResp
                  isEdit={isEdit}
                  isView={isView}
                  register={methods.register}
                  unregister={methods.unregister}
                  watch={methods.watch}
                  setValue={methods.setValue}
                  errors={methods.formState.errors}
                  beneficiaryData={beneficiaryData}
                />

                <S.TituloSub>Endereço da família</S.TituloSub>
                <FormsEndereco isEdit={isEdit} isView={isView} register={methods.register} unregister={methods.unregister}
                  errors={methods.formState.errors} beneficiaryData={beneficiaryData} />
              </S.DadosConteiner>
            </>
          )}


          <S.TituloSub>Serviços, Programas e Benefícios</S.TituloSub>
          <FormsOutros
            isEdit={isEdit}
            isView={isView}
            register={methods.register}
            unregister={methods.unregister}
            errors={methods.formState.errors}
            beneficiaryData={beneficiaryData} />
          <S.TituloSub>Datas Importantes</S.TituloSub>
          <FormsDatas
            isEdit={isEdit}
            isView={isView}
            beneficiaryData={beneficiaryData}
            register={methods.register}
            unregister={methods.unregister}
            errors={methods.formState.errors}
          />

          {isView && (
            <HistoryView
              benefitId={beneficiaryData?.id}
              changeVersion={handleChangeVersion}
            />
          )}
          <S.BotoesConteiner>
            <BackButton />
            {!isView && !isEdit ? (
              <CreateBeneficiaryBtn
                alertType={submitStatus}
                setAlertType={setSubmitStatus}
                isLoading={isSubmitting}
              />
            ) : isEdit ? (
              <>
                {beneficiaryData.b_status === "0" ? (
                  <ActivationBeneficiary
                    alertType={submitStatus}
                    setAlertType={setSubmitStatus}
                    onClick={() => {
                      setActivation(true)
                    }}
                    formState={methods.formState}
                  />
                ) : ["1", "2", "3", "4"].includes(beneficiaryData.b_status) ? (
                  <>
                    <SaveButton
                      isLoading={isSubmitting}
                      alertType={submitStatus}
                      setAlertType={setSubmitStatus}
                    />
                    {["1", "4"].includes(agentType) && (
                      <>
                        <DisableBeneficiary onClick={openCancelModal} />
                        <DeleteModal
                          isLoading={isSubmittingCancel}
                          isOpen={isCancelModalOpen}
                          onClose={closeCancelModal}
                          onConfirm={handleConfirmCancel}
                          cancelError={cancelError}
                          alertType={alertType}
                          setAlertType={setAlertType}
                        />
                      </>
                    )}
                  </>
                ) : (
                  <SaveButton
                    isLoading={isSubmitting}
                    alertType={submitStatus}
                    setAlertType={setSubmitStatus}
                  />
                )}
              </>
            ) : (
              !["6"].includes(agentType) && (
                <div onClick={handleEditBeneficiary} style={{ cursor: 'pointer' }}>
                  <EditButton />
                </div>
              )
            )}
          </S.BotoesConteiner>
        </S.TituloItem>
      </form>)}
      <APIErrorAlert
        open={apiCreationSubmitionError !== ""}
        message={apiCreationSubmitionError}
        handleClose={closeImpedimentAlert}
      />
    </FormProvider>
  );
};

export default ChildForms;
