import * as S from './styles';
import PhotoBenef from '../../../assets/image/beneficiaryPhoto.svg';
import CompTextField from 'components/CompTextField';
import CompDateField from '../../CompDateField';
import { NisField } from 'components/NisField';

const Forms = ({
  beneficiaryData,
  isView,
  isEdit,
  register,
  unregister,
  watch,
  setValue,
  errors,
}) => {
  const nis = watch('twin_child_nis');

  const handleNis = (data) => {
    setValue('twin_child_name', data.name);
    setValue('twin_child_cpf', data.cpf);
    setValue('twin_child_birth', data.birth);
  }

  return (
    <S.Form>
      <S.FormGeral>
        <S.PhotoContainer src={PhotoBenef} alt="Logo" />

        <S.DadosBox>
          <S.FormRow>
            {/* Linha 1 */}
            <NisField
              nis={nis}
              textLabel="NIS DA CRIANÇA:"
              isView={isView}
              isEdit={isEdit}
              apiText={beneficiaryData?.twin_child_nis}
              benefitNis={beneficiaryData?.twin_child_nis}
              name="twin_child_nis"
              register={register}
              unregister={unregister}
              error={errors.twin_child_nis}
              helperText={errors.twin_child_nis && errors.twin_child_nis.message}
              handleNis={handleNis}
              setValue={setValue}
            />

            <CompTextField
              textLabel="NOME COMPLETO DA CRIANÇA:"
              isView={isView}
              fromNis={true}
              apiText={beneficiaryData?.twin_child_name}
              placeholder="00000000000"
              required
              name="twin_child_name"
              register={register}
              unregister={unregister}
              style={{ flexGrow: "2" }}
              error={errors.twin_child_name}
              helperText={
                errors.twin_child_name && errors.twin_child_name.message
              }
            // enabled={isEdit && !isView}
            />
          </S.FormRow>
          <S.FormRowWithMarginDados>
            {/* Linha 2 */}
            <CompTextField
              textLabel="CPF DA CRIANÇA:"
              isView={isView}
              fromNis={true}
              apiText={beneficiaryData?.twin_child_cpf}
              //mask="999.999.999-99"
              placeholder="xxx.xxx.xxx-xx"
              required
              name="twin_child_cpf"
              register={register}
              unregister={unregister}
              style={{ flexGrow: "1" }}
              error={errors.twin_child_cpf}
              helperText={
                errors.twin_child_cpf && errors.twin_child_cpf.message
              }
            // enabled={isEdit && !isView}
            />
            <CompDateField
              isView={isView}
              labelText="DATA DE NASCIMENTO:"
              apiText={beneficiaryData?.twin_child_birth}
              required
              name="twin_child_birth"
              register={register}
              unregister={unregister}
              error={errors.twin_child_birth}
              helperText={
                errors.twin_child_birth && errors.twin_child_birth.message
              }
              disabled={!isEdit && !isView}
            />
          </S.FormRowWithMarginDados>
          <S.FormRowWithMarginDados>
            <CompTextField
              textLabel="CNS DA CRIANÇA:"
              type='number'
              isView={isView}
              apiText={beneficiaryData?.twin_child_cns}
              edit={isEdit}
              placeholder="00000000000"
              required
              name="twin_child_cns"
              register={register}
              unregister={unregister}
              style={{ flexGrow: "1" }}
              error={errors.twin_child_cns}
              helperText={
                errors.twin_child_cns && errors.twin_child_cns.message
              }
            // enabled={true}
            />
          </S.FormRowWithMarginDados>
        </S.DadosBox>
      </S.FormGeral>
    </S.Form>
  );
};

export default Forms;
