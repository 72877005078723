const styles = {
  tituloText: {
    color: "var(--light-amarelo-light, #F9922A)",
    fontFamily: "Poppins",
    fontSize: "36px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "46px",
    marginBottom: "0",
    marginTop: "0",
  },
  breadcrumbAndCadContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  breadcrumbsItem: {
    color: "var(--gray-800, var(--800, #515151))",
    fontFamily: "Poppins",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 300,
    lineHeight: "18px",
    marginBottom: "14px",
  },
  titleAndSubtitleSpacing: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    width: "73vw",
    maxWidth: "1100px",
  },
};

export default styles;
