import { useEffect, useState } from "react";
import { Breadcrumbs, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import styles from "./styles.js";
import { useApi } from "hooks/useApis.js";

export default function Title({
  titleText,
  subtitleTexts = ["Início"],
  showCadVersion = true,
}) {
  const api = useApi();
  const [cadVersion, setCadVersion] = useState("");

  const formatDate = (cad) => new Date(cad).toLocaleString("pt-BR", {
    dateStyle: "short"
  });

  useEffect(() => {
    if (showCadVersion && cadVersion === "") {
      api.getCadVersion()
        .then((cad) => setCadVersion(formatDate(cad)))
        .catch((error) => console.log(error));
    }
  }, [showCadVersion, cadVersion]);

  return (
    <div style={styles.titleAndSubtitleSpacing}>
      <h1 style={styles.tituloText}>{titleText}</h1>
      <div style={styles.breadcrumbAndCadContainer}>
        <Breadcrumbs
          aria-label="breadcrumbs"
          separator={
            <NavigateNextIcon
              fontSize="small"
              sx={{ marginBottom: "14px" }}
            />
          }
        >
          {subtitleTexts.map((text, idx) => (
            <Typography key={idx} style={styles.breadcrumbsItem}>
              {text}
            </Typography>
          ))}
        </Breadcrumbs>
        <Typography style={styles.breadcrumbsItem}>
          {showCadVersion && cadVersion && `Versão do CadÚnico: ${cadVersion}`}
        </Typography>
      </div>
    </div>
  );
}
