import styled from 'styled-components';

export const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
`;

export const StyledTable = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

export const StyledTableHead = styled.thead`
  margin-bottom: 16px;
  color: var(--Dark-Verde---dark, #70AC17);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 900;
  line-height: 24px;
`;

export const StyledTableBody = styled.tbody`
  tr:last-child td {
    border-bottom: 2px solid #70AC17; 
  }
`;


export const StyledTableRow = styled.tr`
  background-color: #ffffff;
  margin-bottom: 16px;
  color: var(#222222);
  font-family: sans-serif;
  font-size: 14px;
`;

export const StyledTableCell = styled.td`
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
`;

export const StyledTableHeaderCell = styled.th`
  border-bottom: 2px solid #fff;
  text-align: left;
  padding: 8px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 8px; // Espaço entre os botões
`;
export const TableFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 68px;

  .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    background: rgba(112,172,23, 0.4);
  }
`;