import * as React from 'react';
import Button from '@mui/material/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useState } from 'react';


export const AddBirthInfoBtn = ({ onClick }) => {

  return (
    <>
      <Button
        variant="contained"
        onClick={onClick}
        startIcon={<AddCircleOutlineIcon />}
        sx={{
          background: " #F9922A",
          width: "250px",
          height: "35px",
          padding: "15px 16px",
          borderRadius: "8px",
          fontFamily: 'Poppins, sans-serif',
          fontFize: '20px',
          fontWeight: 300,
          textTransform: 'none',

          '&:hover': {
            backgroundColor: "#c45f47",
          },
        }}
        data-testid="AddBirthInfoBtn"
      >
        Informar data do parto
      </Button>
    </>
  );
};


