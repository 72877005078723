import React, { useEffect, useState } from 'react';
import styles from './styles';
import TextField from '@mui/material/TextField';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import InputMask from 'react-input-mask';

import { outlinedInputClasses } from '@mui/material/OutlinedInput';

import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const customTheme = outerTheme =>
    createTheme({
        palette: {
            mode: outerTheme.palette.mode
        },
        components: {
            MuiTextField: {
                styleOverrides: {
                    root: {
                        '--TextField-brandBorderColor': '#4BBFEF',
                        '--TextField-brandBorderHoverColor': '#4BBFEF',
                        '--TextField-brandBorderFocusedColor': '#4BBFEF',
                        '& label.Mui-focused': {
                            color: 'var(--TextField-brandBorderFocusedColor)'
                        }
                    }
                }
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    notchedOutline: {
                        borderColor: 'var(--TextField-brandBorderColor)'
                    },
                    root: {
                        background: 'white',
                        height: '50px',
                        [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                            borderColor: 'var(--TextField-brandBorderHoverColor)'
                        },
                        [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                            borderColor: 'var(--TextField-brandBorderFocusedColor)'
                        }
                    }
                }
            },
            MuiFilledInput: {
                styleOverrides: {
                    root: {
                        '&::before, &::after': {
                            borderBottom: '2px solid var(--TextField-brandBorderColor)'
                        },
                        '&:hover:not(.Mui-disabled, .Mui-error):before': {
                            borderBottom: '2px solid var(--TextField-brandBorderHoverColor)'
                        },
                        '&.Mui-focused:after': {
                            borderBottom: '2px solid var(--TextField-brandBorderFocusedColor)'
                        }
                    }
                }
            },
            MuiInput: {
                styleOverrides: {
                    root: {
                        '&::before': {
                            borderBottom: '2px solid var(--TextField-brandBorderColor)'
                        },
                        '&:hover:not(.Mui-disabled, .Mui-error):before': {
                            borderBottom: '2px solid var(--TextField-brandBorderHoverColor)'
                        },
                        '&.Mui-focused:after': {
                            borderBottom: '2px solid var(--TextField-brandBorderFocusedColor)'
                        }
                    }
                }
            }
        }
    });
/**
/**
 * Componente para renderizar um campo de texto personalizado.
 * @component
 * @param {Object} props - Propriedades do componente.
 * @param {string} [props.textLabel=""] - Texto superior ao campo de texto.
 * @param {boolean} [props.required=false] - Indica se o campo é obrigatório.
 * @param {string} [props.placeholder=null] - Texto padrão exibido no campo até ser digitado.
 * @param {boolean} [props.enabled=true] - Indica se o campo está habilitado.
 * @param {string} [props.apiText=null] - Texto estático vindo da API.
 * @param {string} [props.type=null] 
 * @param {Object} [props.style={}] - Estilo personalizado para o componente.
 * @param {string} props.name - Nome do campo.
 * @param {function} [props.register] - Função de registro do campo.
 * @param {function} [props.unregister] - Função de desregistro do campo.
 * @param {boolean} [props.isView=false] - Indica se o campo está em modo de visualização.
 * @param {string} [props.mask=null] - Máscara para o campo de texto.
 * @param {string} [props.helperText=""]
 * @param {Object} [props.error]
 * @returns {JSX.Element} Retorna o componente do campo de texto personalizado.
 */
const CompPassword = ({
    textLabel = '',
    required = false,
    placeholder = null,
    apiText = null,
    style = {},
    name,
    register = () => { },
    unregister = () => { },
    type = 'password',
    helperText = '',
    error,
    onBlur
}) => {
    const outerTheme = useTheme();

    useEffect(() => {
        register(name);

        return () => {
            unregister(name);
        };
    }, [register, unregister, name]);

    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    return (
        <div style={{ ...style, ...styles.textFieldContainer }}>
            <label style={styles.textFieldLabel}>
                {textLabel ? textLabel : <div style={styles.heightNormalizer} />}
            </label>

            <ThemeProvider theme={customTheme(outerTheme)}>
            <TextField
                {...register(name)}
                type={showPassword ? 'text' : 'password'}
                placeholder={placeholder}
                error={!!error}
                helperText={error && helperText}
                onBlur={onBlur}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
            </ThemeProvider>

        </div>
    );
};

export default CompPassword;
