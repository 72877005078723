import api from 'services/api';
import { getAgentListSort, getBeneficiaryListSort } from "utils";
import { distributionPoints } from 'utils/constants';
export const useApi = () => ({

  login: async (cpf, password) => {
    try {
      const response = await api.post('/controller/auth/login/', {
        username: cpf,
        password: password
      });
      return response;
    } catch (err) {
      console.log(err.message);
      return err.response;
    }
  },

  loginDashboard: async (email, password) => {
    try {
      const response = await api.post('/api/v1/auth/login', {
        email,
        password
      });
      return response;
    } catch (err) {
      console.log(err.message);
      return err.response;
    }
  },

  getNisInfo: async (nis) => {
    try {
      const response = await api.get('/benefits/has_register/' + nis);
      return {
        status: response.status,
        data: response.data,
      };
    } catch (err) {
      if (err.response) {
        // Caso tenha uma resposta do servidor
        return {
          status: err.response.status,
          message: err.response.data.detail,
        };
      } else {
        // Caso não tenha resposta (problema de rede ou outro erro)
        return {
          status: 500,
          message: 'Erro na comunicação com o servidor',
        };
      }
    }
  },


  getImportantDates: async (data) => {
    const response = await api.post('/benefits/important_dates/', data);
    return response.data;
  },

  createBeneficiary: async (data) => {
    const response = await api.post('/benefits/', data);
    return response;
  },

  updateBeneficiary: async (id, data) => {
    const response = await api.patch('/benefits/' + id, data);
    return response.data;
  },
  createAgent: async (data) => {
    const response = await api.post('/controller/auth/register/', data);
    return response.data;
  },
  updateAgent: async (id, data) => {
    const response = await api.patch('/controller/agents/' + id + '/', data);
    return response.data;
  },
  getBeneficiaryById: async (id) => {
    const response = await api.get('/benefits/' + id);
    return response.data;
  },
  getAgentById: async (id) => {
    try {
      const response = await api.get('/controller/agents/' + id + '/');
      return response.data;
    } catch (err) {
      console.log(err.message);
      throw err;
    }
  },

  listBeneficiary: async (b_status, b_type, search, sort, city = "", page = 1, irregularOnly = false) => {
    try {
      let baseUrl = irregularOnly ? '/benefits/irregular/' : '/benefits/';
      let params = new URLSearchParams();

      if (b_status) params.append('b_status', b_status);
      if (b_type && b_type !== '0') params.append('b_type', b_type);

      params.append('ordering', getBeneficiaryListSort(sort));

      if (search) {
        if (/\d/.test(search)) {
          // Contains numbers, so it's a search for nis and cpf, remove all non-numeric characters
          params.append('search', search.replace(/[^0-9]/g, ''));
        } else {
          // It is a name search, turn uppercase
          params.append('search', search.toUpperCase());
        }
      }

      if (city) {
        const response = await api.get('/storage/city/all/');
        const codes = response.data;

        const cityName = distributionPoints.find(point => point.value === city)
        const cityExists = codes.results.some(result => result.name === cityName.textLabel)

        if (cityExists) {
          const cityData = codes.results.find(result => result.name === cityName.textLabel)
          const cityCode = cityData.code;
          params.append('city', cityCode);
        }
      }

      params.append('page', page);
      const response = await api.get(`${baseUrl}?${params.toString()}`);
      return response.data;
    } catch (err) {
      console.log(err.message);
      return err;
    }
  },

  listAgent: async (a_status, agent_type, search, sort, city, page = 1) => {
    try {
      let url = '/controller/agents/?';
      if (a_status) url += 'a_status=' + a_status + '&';
      if (agent_type && agent_type !== '0') url += 'agent_type=' + agent_type + '&';
      url += getAgentListSort(sort);
  
      if (search) {
        if (/\d/.test(search)) {
          url += 'search=' + search.replace(/[^0-9]/g, '') + '&';
        } else {
          url += 'search=' + search.toUpperCase() + '&';
        }
      }
  
      if (city && city !== "") {
        const response = await api.get('/storage/city/all/');
        const codes = response.data;
  
        const cityName = distributionPoints.find(point => point.value === city);
        const cityExists = codes.results.some(result => result.name === cityName.textLabel);
  
        if (cityExists) {
          const cityData = codes.results.find(result => result.name === cityName.textLabel);
          const cityCode = cityData.code;
          url += 'city=' + cityCode + '&';
        }
      }
  
      url += 'page=' + page;
      const response = await api.get(url);
      return response.data;
    } catch (err) {
      console.log(err.message);
      throw err;
    }
  },
  

  updatePassword: async (id, data) => {
    const response = await api.patch(`/controller/auth/change_password/${id}/`, data);
    return response.data;
  },

  RecoverPassword: async (cpf) => {
    try {
      const treat_cpf = cpf.replace(/[.-]/g, "")
      const data_cpf = {
        cpf: treat_cpf
      };

      const response = await api.post('/controller/auth/forgot_password/', data_cpf);
      return response.data;
    } catch (err) {
      console.log(err.message);
      return err.message;
    }

  },

  createNutritionalData: async (data) => {
    try {
      const response = await api.post('/nutritionalData/', data);
      return response.data;
    } catch (err) {
      console.log(err.message);
      throw err.message;
    }
  },

  updateNutritionalData: async (id, data) => {
    try {
      const nutritionalData = {
        gestational_age: data.gestational_age,
        gestational_month: data.gestational_month,
        weight: data.weight,
        height: data.height,
        dpp: data.dpp,
        dum: data.dum,
        dpp_eco_estimated: data.dpp_eco_estimated,
        first_check_date: data.first_check_date,
      };
      const response = await api.put('/nutritionalData/' + id + '/', nutritionalData);
      return response.data;
    } catch (err) {
      console.log(err.message);
      throw err.message;
    }
  },

  fetchBenefitHistory: async (benefitId) => {
    try {
      const response = await api.get(`/benefits/history/${benefitId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  fetchAgentHistory: async (agentId) => {
    try {
      const response = await api.get(`/controller/agents/history/${agentId}/`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  historyCondicionality: async (benefitId) => {
    try {
      const response = await api.get(`/conditionality/?benefit=${benefitId}`);
      return response.data;
    } catch (error) {
      console.error('Erro ao buscar o histórico de acompanhamento do beneficiário:', error);
      throw error;
    }
  },

  createCondicionality: async (data) => {
    try {
      const response = await api.post(`/conditionality/`, data);
      return 'Acompanhamento cadastrado!';
    } catch (error) {
      console.error('Erro', error);
      throw error;
    }
  },

  downloadReport: async (
    {
      b_status = null,
      b_type = null,
      search = null,
      city = null,
    },
    {
      irregular = false,
      code = null,
      start_date = null,
      end_date = null,
    },
  ) => {
    const baseUrl = irregular
      ? '/storage/reports/irregular/'
      : '/storage/reports/';

    let params = new URLSearchParams();

    if (b_status) {
      params.append('status', b_status);
    }

    if (b_type && b_type !== '0') {
      params.append('type', b_type);
    } else {
      params.append('type', 'all');
    }

    if (irregular) {
      if (code) {
        params.append('irregularity', code);
      }

      if (start_date && end_date) {
        params.append('start_date', start_date);
        params.append('end_date', end_date);
      }
    }

    if (search) {
      if (/\d/.test(search)) {
        params.append('search', search.replace(/[^0-9]/g, ''));
      } else {
        params.append('search', search.toUpperCase());
      }
    }

    if (city) {
      const response = await api.get('/storage/city/all/');
      const codes = response.data;

      const cityName = distributionPoints.find(point => point.value === city)
      const cityExists = codes.results.some(result => result.name === cityName.textLabel)

      if (cityExists) {
        const cityData = codes.results.find(result => result.name === cityName.textLabel)
        const cityCode = cityData.code;
        params.append('city', cityCode);
      }
      else {
        params.append('city', 'all');
      }
    }

    const route = `${baseUrl}?${params.toString()}`;
    const response = await api.get(route, { responseType: 'blob' });
    const blob = new Blob([response.data], { type: response.headers['content-type'] });

    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;

    let baseFileName = "relatório_beneficiários";
    if (irregular) {
      baseFileName += "_irregulares"
    }
    a.download = `${baseFileName}.xlsx`
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(url);
    return response;
  },

  cancelBeneficiary: async (id, justification) => {
    try {
      const response = await api.patch(`/benefits/activate_or_cancel/${id}`, {
        b_status: "0", // Cancelado
        notes_on_deactivation: justification,
      });
      return response.data;
    } catch (err) {
      console.log('Erro ao cancelar o beneficiário:', err);
      throw err;
    }
  },

  activateBeneficiary: async (id, data) => {
    try {
      const response = await api.patch(`/benefits/activate_or_cancel/${id}`, data);
      return response.data;
    } catch (err) {
      console.log('Erro ao ativar o beneficiário:', err);
      throw err;
    }
  },
  getCityCodeByName: async (cityName) => {
    try {
      const response = await api.get('/storage/city/all/');
      const codes = response.data.results;
      const cityData = codes.find(result => result.name === cityName);
      return cityData ? cityData.code : null;
    } catch (err) {
      console.log("Erro ao buscar código da cidade:", err);
      throw err;
    }
  },

  listIrregularBenefits: async (
    page,
    search = '',
    ordering = '',
    city = '',
    irregularity_date_first = '',
    irregularity_date_second = '',
    b_status = '',
    b_type = '',
    code = ''
  ) => {
    try {
      const params = new URLSearchParams({
        page,
        // search,
        ordering,
        // city,
        irregularity_date_first,
        irregularity_date_second,
        code
      });

      // Adicione os filtros de b_status e b_type, se estiverem presentes
      if (b_status) params.append('b_status', b_status);
      // if (b_type) params.append('b_type', b_type);
      if (b_type && b_type !== '0') params.append('b_type', b_type);

      if (search) {
        if (/\d/.test(search)) {
          // Contains numbers, so it's a search for nis and cpf, remove all non-numeric characters
          params.append('search', search.replace(/[^0-9]/g, ''));
        } else {
          // It is a name search, turn uppercase
          params.append('search', search.toUpperCase());
        }
      }

      if (city) {
        const response = await api.get('/storage/city/all/');
        const codes = response.data;

        const cityName = distributionPoints.find(point => point.value === city)
        const cityExists = codes.results.some(result => result.name === cityName.textLabel)

        if (cityExists) {
          const cityData = codes.results.find(result => result.name === cityName.textLabel)
          const cityCode = cityData.code;
          params.append('city', cityCode);
        }
      }

      const response = await api.get(`/benefits/irregular/?${params.toString()}`);
      return response.data;
    } catch (error) {
      console.error('Erro ao buscar benefícios irregulares:', error);
      throw error;
    }
  },

  // downloadPayroll: async (to_date) => {
  //   let baseUrl = '/storage/payroll/';
  //   let params = new URLSearchParams();

  //   if (to_date) {
  //     params.append('to_date', to_date);
  //   }

  //   try {
  //     const response = await api.get(`${baseUrl}?${params.toString()}`, {
  //       responseType: 'blob', 
  //     });

  //     if (response.status === 200) {
  //       const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  //       const url = window.URL.createObjectURL(blob);
  //       const link = document.createElement('a');
  //       link.href = url;
  //       link.download = 'folha_de_pagamento.xlsx'; 
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //       URL.revokeObjectURL(url);
  //     } else {
  //       throw new Error('Erro ao baixar o arquivo.');
  //     }
  //   } catch (err) {
  //     console.error('Erro ao baixar a folha de pagamento:', err);
  //     throw err;
  //   }
  // },

  downloadPayroll: async (to_date) => {
    let baseUrl = '/storage/payroll/';
    let params = new URLSearchParams();
  
    if (to_date) {
      params.append('to_date', to_date);
    }
  
    const response = await api.get(`${baseUrl}?${params.toString()}`, {
      responseType: 'blob', 
    });
  
    if (response.status === 200) {
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'folha_de_pagamento.xlsx'; 
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } else {
      throw new Error('Erro ao baixar o arquivo.');
    }
  },

  getCadVersion: async () => {
    const response = await api.get("/storage/ref_cad/");
    return response.data.detail;
  },

});
