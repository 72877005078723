import React from 'react';
import { SubmitButton } from './SubmitButton';

export const CreateBeneficiaryBtn = ({
  alertType,
  setAlertType,
  isLoading = false,
}) => {

  return (
    <SubmitButton
      alertType={alertType}
      setAlertType={setAlertType}
      isLoading={isLoading}
      buttonText="Criar beneficiário"
      routeToGoBack="/beneficiarios"
      successMsg="Sucesso ao criar beneficiário."
      sx={{ width: "236px" }}
    />
  );
};
