import * as React from 'react';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";

export const BackButton = () => {
    
  const navigate = useNavigate();
  
  const handleClick = () => {
      navigate(-1); 
  };

    return(
        <Button variant="contained" 
         sx={{
            width: "87px",
            height: "54px",
            padding: "15px 16px",
            borderRadius: "8px",
            background: "rgba(75, 191, 239, 0.24)",

            fontFamily: 'Poppins, sans-serif',
            fontFize: '20px',
            fontWeight: 300,
            color: "var(--Dark-Azul---dark, #0090CE)",
            textTransform: 'none', 

            '&:hover': {
                backgroundColor: "rgba(75, 191, 239, 0.24)",
              },
              }}
              onClick={handleClick}
              >
        Voltar
      </Button>
    );
};


