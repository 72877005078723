import React from "react";
import ReactDOM from "react-dom";
import GlobalStyle from "./styles/global";
// import { BrowserRouter } from "react-router-dom";
// import 'antd/dist/antd.css';
import App from "./App";
import { AuthProvider } from "hooks/useAuth";
// import { AuthProvider } from "./hooks/useAuth.tsx";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pt-br";
import { Provider } from "react-redux";
import store from "./store/store.js";
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <GlobalStyle />
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
        {/* <BrowserRouter> */}
        <AuthProvider>
          <App />
        </AuthProvider>
        {/* </BrowserRouter> */}
      </LocalizationProvider>
    </Provider>
  </React.StrictMode>,

  document.getElementById("root")
);
