import React, { useState, useEffect } from "react";
import * as S from "./styles";
import FormsDados from "./formsDados";
import FormsEndereco from "./formsEndereco";
import FormsDatas from "./formsDatas";
import FormsDadosProf from "./formsDadosProf";
import { BackButton } from "components/Buttons/BackButton";
import { SaveButton } from "components/Buttons/SaveButton";
import { EditAgentButton } from "components/Buttons/EditAgentButton";
import { DeleteAgentBtn } from "components/Buttons/DeleteAgentBtn";
import { CreateAgentBtn } from "components/Buttons/CreateAgentBtn";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import {
  convertDateFromApi,
  agentFormDefaultValues,
  getAgentStatus,
} from "utils";
import { useApi } from "hooks/useApis";
import { distributionPoints } from "utils/constants";
import { Link, useParams } from "react-router-dom";
import { SubmitButton } from "components/Buttons/SubmitButton";
import HistoryAgentView from "components/HistoryAgentView";
import DeleteModal from "components/ModalConfirmAgentCancel";
import ActivationModal from "components/ModalConfirmAgentActivation";
import ActivationAgent from "components/Buttons/ActivationAgent";

import ActivationBeneficiary from "components/Buttons/ActivationBeneficiary";
import { APIErrorAlert } from "components/Alerts/APIErrorAlert";
import { serverErrorMsg } from "utils/constants";
import axios from "axios";
import AgentType from "utils/agentType";
const AgentForms = ({
  //tipoAgente,
  isView = false,
  agentData,
  isEdit = false,
  handleVersionChange,
  setAgentData = () => {},
}) => {
  const [submitStatus, setSubmitStatus] = useState("");
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);

  const [isActivationModalOpen, setIsActivationModalOpen] = useState(false);
  const [isActivated, setIsActivated] = useState(false);
  const agent_type = AgentType();
  const openActivationModal = () => setIsActivationModalOpen(true);
  const closeActivationModal = () => setIsActivationModalOpen(false);

  const [alertType, setAlertType] = useState("");

  const openCancelModal = () => setIsCancelModalOpen(true);
  const closeCancelModal = () => setIsCancelModalOpen(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isLoadingActivation, setIsLoadingActivation] = useState(false);
  const [apiCreationSubmitionError, setApiCreationSubmitionError] =
    useState("");
  const closeImpedimentAlert = () => setApiCreationSubmitionError("");

  const api = useApi();
  const { id } = useParams();

  const formatDate = (dateString) => {
    if (!dateString || !dateString.includes("/")) return dateString;
    const [day, month, year] = dateString.split("/");
    return `${year}-${month}-${day}`;
  };

  const createAgentSchema = (isEdit) =>
    z
      .object({
        agent_type: z.string().min(1, { message: "Campo obrigatório" }),
        work_location: z.string().min(1, { message: "Campo obrigatório" }),

        // Dados
        name: z.string().min(1, { message: "Campo obrigatório" }),
        CPF: z.string().min(11),
        rg: z.string().min(1, { message: "Campo obrigatório" }),
        birth_day: z
          .string()
          .min(1, { message: "Campo obrigatório" })
          .transform((date) => (date ? formatDate(date) : date)),
        mobile_phone_number: z
          .string()
          .min(1, { message: "Campo obrigatório" }),
        phone_number: z
          .string()
          .optional()
          .transform((number) => (number ? cleanPhoneNumber(number) : number)),
        email: z.string().email().min(1, { message: "Campo obrigatório" }),
        password: isEdit
          ? z.string().optional()
          : z.string().min(1, { message: "Campo obrigatório" }),
        repeat_password: isEdit
          ? z.string().optional()
          : z.string().min(1, { message: "Campo obrigatório" }),

        // Endereço
        cep: z.string().min(1, { message: "Campo obrigatório" }),
        street_name: z.string().min(1, { message: "Campo obrigatório" }),
        address_number: z.coerce
          .number()
          .min(1, { message: "Campo obrigatório" }),
        address_complement: z.string().optional(),
        city_residence: z.string().min(1, { message: "Campo obrigatório" }),
        state: z.string().min(1, { message: "Campo obrigatório" }),

        // Dados profissionais
        professional_qualification: z
          .string()
          .min(1, { message: "Campo obrigatório" }),
        role: z.string().min(1, { message: "Campo obrigatório" }),
        labor_link: z.string().min(1, { message: "Campo obrigatório" }),
        access_expiration_date: z
          .string()
          .min(1, { message: "Campo obrigatório" })
          .transform((date) => (date ? formatDate(date) : date)),

        // Campos adicionais
        a_status: z.string().optional(),
        city_name: z.string().optional(),
        region: z.string().optional(),

        // Username
        username: z.string().optional(),

        // professional data is missing?
      })
      .refine(({ password, repeat_password }) => password === repeat_password, {
        message: "As senhas precisam ser iguais.",
        path: ["repeatpassword"],
      });

  const methods = useForm({
    resolver: zodResolver(createAgentSchema(isEdit)),
    mode: "all",
    criteriaMode: "all",
  });

  const cleanCPF = (cpf) => cpf.replace(/\D/g, "");
  const cleanCep = (cep) => cep.replace(/\D/g, "");
  const cleanPhoneNumber = (phoneNumber) => phoneNumber.replace(/\D/g, "");
  const workLocationValueToTextLabel = async (value) => {
    const location = distributionPoints.find(
      (location) => location.value === value
    );
    let locationCode = await api.getCityCodeByName(location.value);
    return locationCode ? locationCode : "";
  };

  useEffect(() => {
    // Add values on edit form
    if (isEdit && agentData) {
      const defaultValues = agentFormDefaultValues(agentData);
      methods.reset(defaultValues);
    }
  }, [isEdit, agentData, methods.reset]);

  function getErrorMessage(responseData) {
    const possibleErrorKeys = ["detail", "password", "message", "error"];

    for (const key of possibleErrorKeys) {
      if (responseData[key]) {
        return responseData[key];
      }
    }
    return "Ocorreu um erro desconhecido. Tente novamente.";
  }
  async function onSubmit(data) {
    setIsSubmitting(true);

    // Limpa o CPF e define o username
    const cleanedCPF = cleanCPF(data.CPF);
    const cleanedCep = cleanCep(data.cep);
    // Buscar o código da cidade baseado no nome selecionado
    let workLocationCode = null;
    if (data.work_location) {
      const cityName = distributionPoints.find(
        (point) => point.value === data.work_location
      )?.textLabel;
      workLocationCode = await api.getCityCodeByName(cityName);
    }
    const finalData = {
      ...data,
      CPF: cleanedCPF,
      username: cleanedCPF,
      cep: cleanedCep,
      mobile_phone_number: cleanPhoneNumber(data.mobile_phone_number),
      phone_number: cleanPhoneNumber(data.phone_number),
      work_location: await workLocationValueToTextLabel(data.work_location),
      a_status: isEdit ? data.a_status : "1",
      city_name: data.city_name || "Não informado",
      region: data.region || "Não informado",
      address_complement: data.address_complement || "Não informado",
    };
    try {
      if (isEdit) {
        await api.updateAgent(id, finalData);
      } else {
        await api.createAgent(finalData);
      }

      methods.setError("apiError", {
        type: "manual",
        message: "",
      });

      setSubmitStatus("success");
    } catch (error) {
      if (error instanceof axios.AxiosError) {
        const { response } = error;
        const errorMessage = getErrorMessage(response?.data);
        setApiCreationSubmitionError(
          response?.status === 500 ? serverErrorMsg : errorMessage
        );
        return;
      }

      setSubmitStatus("error");
      console.error("Erro ao submeter o formulário:", error);
    } finally {
      setIsSubmitting(false);
    }
  }
  const handleChangeVersion = async (version) => {
    try {
      const versionData = agentFormDefaultValues(version);
      const status = getAgentStatus(version.a_status);
      setAgentData(versionData); // Update to visualization form

      handleVersionChange(version.date_history, version.a_status);
    } catch (error) {
      console.error("Erro ao buscar a versão:", error);
    }
  };

  async function handleConfirmCancel() {
    try {
      setIsCancelled(true);
      await api.updateAgent(id, { a_status: "0" });
      setAlertType("success");

      setTimeout(() => {
        closeCancelModal();
        window.location.reload();
      }, 3000);
    } catch (error) {
      setAlertType("error");
      console.error("Erro ao cancelar o agente:", error);
    }
  }
  async function handleConfirmActivation() {
    setIsLoadingActivation(true);
    setIsCancelled(false);

    try {
      setIsActivated(true);
      await api.updateAgent(id, { a_status: "1" });

      setAlertType("success");

      setTimeout(() => {
        closeActivationModal();
        window.location.reload();
      }, 3000);
    } catch (error) {
      setAlertType("error");
      console.error("Erro ao ativar o agente:", error);
    } finally {
      setIsLoadingActivation(false);
    }
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <S.TituloItem>
          <FormsDatas
            isView={isView}
            register={methods.register}
            unregister={methods.unregister}
            agentData={agentData}
          />
          <S.Separator />
          <S.TituloSub>Dados pessoais</S.TituloSub>
          <FormsDados
            agentData={agentData}
            isView={isView}
            isEdit={isEdit}
            register={methods.register}
            unregister={methods.unregister}
            watch={methods.watch}
            setValue={methods.setValue}
            errors={methods.formState.errors}
          />
          <S.Separator />
          <S.TituloSub>Endereço</S.TituloSub>
          <FormsEndereco
            isView={isView}
            register={methods.register}
            unregister={methods.unregister}
            agentData={agentData}
            watch={methods.watch}
            setValue={methods.setValue}
            errors={methods.formState.errors}
          />
          <S.Separator />
          <S.TituloSub>Dados profissionais</S.TituloSub>
          <FormsDadosProf
            isView={isView}
            register={methods.register}
            unregister={methods.unregister}
            watch={methods.watch}
            setValue={methods.setValue}
            agentData={agentData}
            errors={methods.formState.errors}
          />

          {isView && (
            <HistoryAgentView
              agentId={agentData?.id}
              changeVersion={handleChangeVersion}
            />
          )}

          <S.BotoesConteiner>
            <BackButton />
            {!isView && !isEdit ? (
              <CreateAgentBtn
                alertType={submitStatus}
                setAlertType={setSubmitStatus}
                isLoading={isSubmitting}
              />
            ) : isEdit ? (
              <>
                {agentData.a_status === "0" ? (
                  <>
                    <ActivationAgent onClick={openActivationModal} />
                    <ActivationModal
                      isOpen={isActivationModalOpen}
                      onClose={closeActivationModal}
                      onConfirm={handleConfirmActivation}
                      alertType={alertType}
                      setAlertType={setAlertType}
                      isLoading={isLoadingActivation}
                    />
                  </>
                ) : agentData.a_status === "1" ? (
                  <>
                    <SaveButton
                      isAgent
                      alertType={submitStatus}
                      setAlertType={setSubmitStatus}
                      isLoading={methods.formState.isSubmitting}
                      disabled={
                        methods.formState.isSubmitting ||
                        submitStatus === "success"
                      }
                    />
                    <>
                      <DeleteAgentBtn onClick={openCancelModal} />
                      <DeleteModal
                        isOpen={isCancelModalOpen}
                        onClose={closeCancelModal}
                        onConfirm={handleConfirmCancel}
                        alertType={alertType}
                        setAlertType={setAlertType}
                      />
                    </>
                  </>
                ) : (
                  <SaveButton
                    isAgent
                    alertType={submitStatus}
                    setAlertType={setSubmitStatus}
                    isLoading={methods.formState.isSubmitting}
                    disabled={
                      methods.formState.isSubmitting ||
                      submitStatus === "success"
                    }
                  />
                )}
              </>
            ) : (
              <Link
                style={{
                  display: ["2", "6"].includes(agent_type) ? "none" : "flex",
                }}
                to={`/agentes/${id}/editar`}
              >
                <EditAgentButton />
              </Link>
            )}
          </S.BotoesConteiner>
        </S.TituloItem>
      </form>
      <APIErrorAlert
        open={apiCreationSubmitionError !== ""}
        message={apiCreationSubmitionError}
        handleClose={closeImpedimentAlert}
      />
    </FormProvider>
  );
};

export default AgentForms;
