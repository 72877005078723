import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormControl, TextField, Tooltip } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { styled } from "@mui/system";
import * as palette from "../../styles/variables";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import CustomLabel from "components/Label"; // Importe o componente CustomLabel
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import { ptBR } from '@mui/x-date-pickers/locales';
dayjs.locale("pt-br");

const theme = createTheme({
  palette: {
    primary: {
      main: palette.lightAzul,
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          // borderColor: 'var(--TextField-brandBorderColor)'
        },
        root: {
          // background: 'white',
          height: "40px",
          [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: palette.lightAzul,
          },
          [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
            //   borderColor: 'var(--TextField-brandBorderFocusedColor)'
          },
        },
      },
    },
  },
  //   components: {
  //     MuiOutlinedInput: {
  //       styleOverrides: {
  //         notchedOutline: {
  //           borderColor: palette.lightAzul,
  //         },
  //         "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
  //           borderColor: palette.lightAzul,
  //         },
  //       },
  //     },
  //     MuiSelect: {
  //       styleOverrides: {
  //         icon: {
  //           color: palette.lightAzul,
  //         },
  //       },
  //     },
  //   },
});
const DatepickerWrapper = styled("div")`
  margin-bottom: 16px;
`;

const CompDatePickerMUI = ({
  name,
  textLabel,
  required = false,
  placeholder = "",
  sx = {},
  withBlueBorder = true, // Novo parâmetro
}) => {
  const { control } = useFormContext();

  return (
    <ThemeProvider theme={theme}>
      <DatepickerWrapper>
        {textLabel && <CustomLabel textLabel={textLabel} required={required} />}
        <LocalizationProvider dateAdapter={AdapterDayjs}  adapterLocale={ptBR}>
          <FormControl fullWidth margin="normal" sx={{ marginTop: "2px" }}>
            <Controller
              name={name}
              control={control}
              defaultValue={null}
              render={({ field: { onChange, value, ...field } }) => (
                <DatePicker
                  {...field}
                  value={value ? dayjs(value) : null}
                  sx={{ ...sx, height: "40px" }}
                  format="DD/MM/YYYY"
                  onChange={(date) => {
                    // Formatar a data para o formato desejado
                    const formattedDate = date
                      ? date.format("YYYY-MM-DD")
                      : null;
                    onChange(formattedDate);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      style={{ height: "40px", ...params.InputProps.style }}
                    />
                  )}
                />
              )}
            />
          </FormControl>
        </LocalizationProvider>
      </DatepickerWrapper>
    </ThemeProvider>
  );
};

export default CompDatePickerMUI;
