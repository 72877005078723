import FillErrorAlert from "components/Alerts/FillErrorAlert";
import CompTextField from "components/CompTextField"
import { useApi } from "hooks/useApis"
import { useEffect, useState } from "react"
import { CircularProgress, InputAdornment } from "@mui/material";

export const NisField = ({
  isView,
  apiText,
  isEdit,
  textLabel,
  name,
  register,
  unregister,
  errors,
  helperText,
  setValue,
  nis = "",
  handleNis = () => { },
  benefitNis,
  flexGrow = "1",
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isInvalidNis, setIsInvalidNis] = useState(false);
  const [isNotRegisteredNis, setIsNotRegisteredNis] = useState(false);

  const api = useApi();

  const getNisData = async () => {
    try {
      setIsLoading(true);
      const response = await api.getNisInfo(nis);
      if (response.status === 200 && response.data.results[0])
        return response.data.results[0];

      if (response.status === 400) {
        setIsInvalidNis(true);
      } else if (response.status === 404) {
        setIsNotRegisteredNis(true);
      }

      setValue(name, "");
      return false;
    } catch (err) {
    } finally { // it works like a defer
      setIsLoading(false);
    }
  }

  const shouldGetData = () => {
    const isValidNis = () => nis && nis.length >= 11;

    if (isEdit) {
      return isValidNis() && nis !== benefitNis;
      
    }
    if (!isView)
      return isValidNis();

    return false;
  }

  useEffect(() => {
    if (!nis)
      setValue(name, "");

    if (shouldGetData()) {
      getNisData().then((data) => {
        if (data)
          handleNis(data);
      });
    }
  }, [nis, benefitNis]);

  return (
    <>
      <CompTextField
        isView={isView}
        edit={isEdit}
        apiText={apiText || ""}
        textLabel={textLabel}
        mask={nis ? "99999999999" : null}
        placeholder="00000000000"
        required
        style={{ flexGrow }}
        name={name}
        register={register}
        unregister={unregister}
        error={errors}
        helperText={helperText}
        InputProps={{
          endAdornment: (
            isLoading && (
              <InputAdornment position="end">
                <CircularProgress size={20} />
              </InputAdornment>
            )
          ),
        }}
      />

      <FillErrorAlert
        open={isInvalidNis}
        onClose={() => setIsInvalidNis(false)}
        message="O NIS informado já possui registro."
      />
      <FillErrorAlert
        open={isNotRegisteredNis}
        onClose={() => setIsNotRegisteredNis(false)}
        message="Pessoa sem registro no CadÚnico."
      />
    </>
  );
}
