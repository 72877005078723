import { AlertBox, AlertTitle } from "../FillErrorAlert/styles";
import emojiNeutral from "../../../assets/image/neutralEmoji.svg";
import { Emoji, CloseButton, AlertContent } from "../alertStyles";

export const APIErrorAlert = ({ handleClose, message = "", open = false }) => {
  if (open) {
    return (
      <AlertBox>
        <CloseButton onClick={handleClose}>x</CloseButton>
        <Emoji src={emojiNeutral} alt="Emoji de rosto neutro" />
        <AlertTitle>Erro ao enviar dados</AlertTitle>
        <AlertContent>{message}</AlertContent>
      </AlertBox>
    );
  }
};
