import AgentType from "../agentType";

const disabledFieldsForMunicipalWhenEdit = [
  'dum','dpp_eco_estimated', 'first_check_date'
];

const disabledFieldsForAllAgents = [
    'pregnant_birth', 'birth', 'child_birth', 'twin_child_birth'
  ];

export const evaluateDateField = (name, isEdit, fieldValue) => {
  const agentType = AgentType();
  let enabled = true;

  if (disabledFieldsForAllAgents.includes(name)) {
    enabled = false;
  }

  if (isEdit && ["2", "3"].includes(agentType)) {
    if (disabledFieldsForMunicipalWhenEdit.includes(name)) {
        enabled = false;
    }
  }

  return enabled;
};
