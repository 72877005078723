import React from 'react';
import * as S from './styles';


const CanceledStatus = () => {
    return (
        <S.CanceledStatus>
            Cancelado
        </S.CanceledStatus>
    );
};

export default CanceledStatus;