import React, { useEffect, useState } from "react";
import * as S from "./styles";
import { Link } from "react-router-dom";
import FormsDados from "./formsDados";
import FormsEndereco from "../formsEndereco";
import FormsOutros from "../formsOutros";
import { CreateBeneficiaryBtn } from "components/Buttons/CreateBeneficiaryBtn";
import { BackButton } from "components/Buttons/BackButton";
import { SaveButton } from "components/Buttons/SaveButton";
import { EditButton } from "components/Buttons/EditButton";
import { AddBirthInfoBtn } from "components/Buttons/AddBirthInfoBtn";
import FormsDiagnostico from "./formsDiagnostico";
import FormsDatas from "./formsDatas";
import FormsDadosGestante from "./formsDadosGestante";
import InfoButton from "components/Buttons/InfoButton";
import InfoButtonBirth from "components/Buttons/InfoButtonBirth";
import FormsDadosResp from "../formsDadosResp";
import FormsBirthInformation from "./formsBirthInformation";
import FormsDatasEdit from "./formsDatasEdit";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "utils/zod-pt";
import {
  convertDateToApi,
  pregnantFormDefaultValues,
  scrollToTop,
  getBeneficiaryStatus,
} from "utils";
import { useApi } from "hooks/useApis";
import { textsCria } from "utils/constants";
import DeleteModal from "components/ModalConfirmCancellation";
import DisableBeneficiary from "components/Buttons/DisableBeneficiary";
import HistoryView from "../../../components/HistoryView";
import AgentType from "utils/agentType";
import { APIErrorAlert } from "components/Alerts/APIErrorAlert";
import { serverErrorMsg } from "utils/constants";
import axios from "axios";
import LoadingBox from "components/Loading";
import ActivationBeneficiary from "components/Buttons/ActivationBeneficiary";

const PregnantForms = ({
  benefitType,
  isLower16,
  isView = false,
  beneficiaryData = null,
  isEdit = false,
  setBeneficiaryData = () => {},
  handleVersionChange,
  isLoading = true,
  isCurrentVersion = true,
}) => {
  const api = useApi();
  const [submitStatus, setSubmitStatus] = useState("");
  const [selectedVersion, setSelectedVersion] = useState(null);
  const agentType = AgentType();
  const isMunicipalAgent = ["2", "3"].includes(agentType);
  const [showBirthInformation, setShowBirthInformation] = useState(false);
  const [isTwinPregnancy, setIsTwinPregnancy] = useState(false);
  const [cancelError, setCancelError] = useState(() => "");
  const [isActivation, setActivation] = useState(false);
  const [beneficiaryStatusComponent, setBeneficiaryStatusComponent] =
    useState("Ativo");

  const handleTwinPregnancyChange = (event) => {
    setIsTwinPregnancy(event.target.value === "Sim");
  };

  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [cancelStatus, setCancelStatus] = useState("");
  const [alertType, setAlertType] = useState("");

  const openCancelModal = () => setIsCancelModalOpen(true);
  const closeCancelModal = () => setIsCancelModalOpen(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [apiCreationSubmitionError, setApiCreationSubmitionError] =
    useState("");
  const closeImpedimentAlert = () => setApiCreationSubmitionError("");

  const validateLower16Field = (field) => {
    // This function receives a field and make it required if lower16
    return (data) => {
      if (isLower16) {
        return data[field] ? true : new Error("Campo obrigatório");
      }
      return true;
    };
  };

  const createPregnantBeneficiarySchema = z.object({
    // Birth Information
    has_syndrome: z.preprocess(
      (val) => {
        return showBirthInformation ? val : undefined;
      },
      z
        .enum(["Sim", "Não"])
        .transform((value) => value === "Sim")
        .optional()
    ),
    child_nis: z.preprocess((val) => {
      return showBirthInformation ? val : undefined;
    }, z.string().nonempty("Campo obrigatório").length(11).optional()),

    child_name: z.preprocess((val) => {
      return showBirthInformation ? val : undefined;
    }, z.string().nonempty("Campo obrigatório").optional()),

    child_birth: z.preprocess(
      (val) => {
        return showBirthInformation ? val : undefined;
      },
      z
        .string()
        .nonempty("Campo obrigatório")
        .transform((value) => convertDateToApi(value))
        .optional()
    ),

    child_cns: z.preprocess((val) => {
      return showBirthInformation ? val : undefined;
    }, z.string().nonempty("Campo obrigatório").optional()),

    child_cpf: z.preprocess((val) => {
      return showBirthInformation ? val : undefined;
    }, z.string().nonempty("Campo obrigatório").min(11).optional()),
    // Twins
    // Dados da Criança 2 ( Gêmeos )
    gemelar: z.preprocess(
      (val) => {
        return showBirthInformation ? val : undefined;
      },
      z
        .enum(["Sim", "Não"])
        .transform((value) => value === "Sim")
        .optional()
    ),

    twin_has_syndrome: z.preprocess(
      (val) => {
        return showBirthInformation && isTwinPregnancy ? val : undefined;
      },
      z
        .enum(["Sim", "Não"])
        .transform((value) => value === "Sim")
        .optional()
    ),

    twin_child_nis: z.preprocess((val) => {
      return showBirthInformation && isTwinPregnancy ? val : undefined;
    }, z.string().nonempty("Campo obrigatório").length(11).optional()),

    twin_child_name: z.preprocess((val) => {
      return showBirthInformation && isTwinPregnancy ? val : undefined;
    }, z.string().nonempty("Campo obrigatório").optional()),

    twin_child_cpf: z.preprocess((val) => {
      return showBirthInformation && isTwinPregnancy ? val : undefined;
    }, z.string().nonempty("Campo obrigatório").min(11).optional()),

    twin_child_birth: z.preprocess(
      (val) => {
        return showBirthInformation && isTwinPregnancy ? val : undefined;
      },
      z
        .string()
        .nonempty("Campo obrigatório")
        .transform((value) => convertDateToApi(value))
        .optional()
    ),

    twin_child_cns: z.preprocess((val) => {
      return showBirthInformation && isTwinPregnancy ? val : undefined;
    }, z.string().nonempty("Campo obrigatório").optional()),

    // Dados
    nis: z.string().length(11, { message: "Campo obrigatório" }),
    name: z.string().min(1, { message: "Campo obrigatório" }),
    birth: z.string().transform((value) => convertDateToApi(value)),
    contact_number_one: z.string().optional(),
    contact_number_two: z.string().optional(),
    provisional_contact_number: z.preprocess(
      (val) =>
        val === "" || val === null || val === undefined ? undefined : val,
      z.string().optional()
    ),
    cns: z.string().min(1, { message: "Campo obrigatório" }),
    rg: z.string().optional(),
    rg_issuer: z.string().optional(),
    rg_uf: z.string().optional(),

    // Dados Gestante
    pregnant_nis: z.preprocess(
      (val) => {
        return isLower16 ? val : undefined;
      },
      z
        .string()
        .length(11, { message: "Campo obrigatório" })
        .refine(validateLower16Field("pregnant_nis"), {
          message: "Campo obrigatório",
        })
        .optional()
    ),

    pregnant_name: z.preprocess(
      (val) => {
        return isLower16 ? val : undefined;
      },
      z
        .string()
        .refine(validateLower16Field("pregnant_name"), {
          message: "Campo obrigatório",
        })
        .optional()
    ),

    pregnant_cpf: z.preprocess(
      (val) => {
        return isLower16 ? val : undefined;
      },
      z
        .string()
        .length(11, { message: "Campo obrigatório" })
        .refine(validateLower16Field("pregnant_cpf"), {
          message: "Campo obrigatório",
        })
        .optional()
    ),

    pregnant_birth: z.preprocess(
      (val) => {
        return isLower16 ? val : undefined;
      },
      z
        .string()
        .transform((value) => convertDateToApi(value))
        .refine(validateLower16Field("pregnant_birth"), {
          message: "Campo obrigatório",
        })
        .optional()
    ),

    // Dados Responsável
    responsible_cpf: z
      .string()
      .length(11, { message: "Campo obrigatório" })
      .refine(validateLower16Field("responsible_cpf")),

    // Datas
    end_date: z.string().transform((value) => convertDateToApi(value), {
      message: "Campo obrigatório",
    }),
    limit_report: z.string().transform((value) => convertDateToApi(value), {
      message: "Campo obrigatório",
    }),

    // Datas Edição
    informbirthdate: z
      .string()
      .transform((value) => convertDateToApi(value))
      .optional(),

    // Diagnóstico
    weight: z.coerce.number().positive({ message: "Campo obrigatório" }),
    height: z.coerce.number().positive({ message: "Campo obrigatório" }),
    imc: z.coerce.number().optional(),
    gestational_age: z.number({ message: "Campo obrigatório" }),
    gestational_month: z.number({ message: "Campo obrigatório" }),
    dum: z.string().transform((value) => convertDateToApi(value), {
      message: "Campo obrigatório",
    }),
    dpp: z.string().transform((value) => convertDateToApi(value), {
      message: "Campo obrigatório",
    }),
    dpp_eco_estimated: z
      .string()
      .transform((value) => convertDateToApi(value), {
        message: "Campo obrigatório",
      }),
    first_check_date: z.string().transform((value) => convertDateToApi(value), {
      message: "Campo obrigatório",
    }),

    // Endereço
    street_type: z.string().optional(),
    street_name: z.string().optional(),
    street_number: z.coerce.number().optional(),
    complement: z.string().optional(),
    neighborhood: z.string().optional(),
    city: z.string().optional(),
    // uf: z.string(),
    ufs_ubs: z.string().min(1, { message: "Campo obrigatório" }),
    updated: z
      .string()
      .transform((value) => (value === "Sim" ? true : false))
      .optional(),

    // Outros
    bf: z
      .enum(["Sim", "Não"])
      .transform((value) => (value === "Sim" ? true : false)),
    bvg: z
      .enum(["Sim", "Não"])
      .transform((value) => (value === "Sim" ? true : false)),
    bcn: z
      .enum(["Sim", "Não"])
      .transform((value) => (value === "Sim" ? true : false)),
    pcf: z
      .enum(["Sim", "Não"])
      .transform((value) => (value === "Sim" ? true : false)),
    bpc: z
      .enum(["Sim", "Não"])
      .transform((value) => (value === "Sim" ? true : false)),
    paif: z
      .enum(["Sim", "Não"])
      .transform((value) => (value === "Sim" ? true : false)),
    paefi: z
      .enum(["Sim", "Não"])
      .transform((value) => (value === "Sim" ? true : false)),
    pl: z
      .enum(["Sim", "Não"])
      .transform((value) => (value === "Sim" ? true : false)),
    mcmv: z
      .enum(["Sim", "Não"])
      .transform((value) => (value === "Sim" ? true : false)),
    other_benefits: z.string().optional(),
  });

  const methods = useForm({
    resolver: zodResolver(createPregnantBeneficiarySchema),
    mode: "all",
    criteriaMode: "all",
  });

  useEffect(() => {
    // Add values on edit form
    if (isEdit && beneficiaryData) {
      if (isCurrentVersion) {
        const defaultValues = pregnantFormDefaultValues(beneficiaryData);
        defaultValues.name = beneficiaryData.name || "";

        // Verifique se estamos mudando de criança ou criança com síndrome para gestante
        const isChildOrSyndrome =
          beneficiaryData.b_type === "2" || beneficiaryData.b_type === "3";

        // Se o b_status é cancelado e estamos mudando de criança/síndrome para gestante
        if (beneficiaryData.b_status === "0" && isChildOrSyndrome) {
          // Limpar os dados de informações da criança e dos gêmeos
          console.log("to aqui");
          defaultValues.has_syndrome = "";
          defaultValues.child_nis = "";
          defaultValues.child_name = "";
          defaultValues.child_birth = "";
          defaultValues.child_cns = "";
          defaultValues.child_cpf = "";
          defaultValues.gemelar = "";
          defaultValues.twin_has_syndrome = "";
          defaultValues.twin_child_nis = "";
          defaultValues.twin_child_name = "";
          defaultValues.twin_child_cpf = "";
          defaultValues.twin_child_birth = "";
          defaultValues.twin_child_cns = "";
          // Limpar os dados de informações nutricionais
          defaultValues.weight = "";
          defaultValues.height = "";
          defaultValues.dpp = "";

          defaultValues.pregnant_nis = "";
          defaultValues.pregnant_cpf = "";
          defaultValues.pregnant_name = "";
          defaultValues.pregnant_birth = "";
        }
        methods.reset(defaultValues);
      }
    }
  }, [isEdit, beneficiaryData]);

  function removeFieldsForSubmit(obj, fieldsToRemove) {
    const filteredObj = { ...obj };
    fieldsToRemove.forEach((field) => {
      delete filteredObj[field];
    });
    return filteredObj;
  }

  async function onSubmit(data) {
    setIsSubmitting(true);
    const gemelar = data.gemelar;
    const fieldsToRemoveFromRegister = [
      "contact_number_one",
      "contact_number_two",
      "rg",
      "rg_issuer",
      "rg_uf",
      "street_name",
      "street_type",
      "street_number",
      "complement",
      "name_location",
      "city",
      "updated",
      "gestational_age",
      "gestational_month",
      "weight",
      "height",
      "dpp",
      "dum",
      "dpp_eco_estimated",
      "first_check_date",
      "imc",
      "gemelar",
    ];

    const filteredData = removeFieldsForSubmit(
      data,
      fieldsToRemoveFromRegister
    );

    filteredData.lower_16 = isLower16;
    filteredData.b_type = "1";
    filteredData.b_status = "1";
    const hasBirthInfo =
      gemelar ||
      filteredData.has_syndrome ||
      filteredData.child_nis ||
      filteredData.child_name ||
      filteredData.child_cns ||
      filteredData.child_cpf;
    if (hasBirthInfo) {
      if (gemelar === true) {
        filteredData.b_type = "4"; // Gemelar
      } else if (filteredData.has_syndrome === true) {
        filteredData.b_type = "2"; // Síndrome
      } else {
        filteredData.b_type = "3"; // Criança sem síndrome e não gemelar
      }
    }
    filteredData.provisional_contact_number =
      filteredData.provisional_contact_number?.replace(/\D/g, "");
    //console.log("Formulario:", filteredData);
    try {
      setIsSubmitting(true);
      let id;
      if (isActivation) {
        filteredData.b_status = "1";
        await api.activateBeneficiary(beneficiaryData.id, filteredData);
        if(beneficiaryData.nutritional_data){
          id = beneficiaryData?.nutritional_data?.id;
          await api.updateNutritionalData(id, data);
        }else{
          id = beneficiaryData.id
          const nutritionalData = {
            benefit: id,
            gestational_age: data.gestational_age,
            gestational_month: data.gestational_month,
            weight: data.weight,
            height: data.height,
            dpp: data.dpp,
            dum: data.dum,
            dpp_eco_estimated: data.dpp_eco_estimated,
            first_check_date: data.first_check_date,
          };
          await api.createNutritionalData(nutritionalData);
        }
        setSubmitStatus("success");
      } else if (isEdit) {
        const update = await api.updateBeneficiary(
          beneficiaryData.id,
          filteredData
        );
        id = beneficiaryData.nutritional_data?.id;
        const updateNutri = await api.updateNutritionalData(id, data);
        setSubmitStatus("success");
      } else {
        // console.log(filteredData)
        const createdBenefit = await api.createBeneficiary(filteredData);
        id = createdBenefit.data.id;
        const nutritionalData = {
          benefit: id,
          gestational_age: data.gestational_age,
          gestational_month: data.gestational_month,
          weight: data.weight,
          height: data.height,
          dpp: data.dpp,
          dum: data.dum,
          dpp_eco_estimated: data.dpp_eco_estimated,
          first_check_date: data.first_check_date,
        };
        await api.createNutritionalData(nutritionalData);
        setSubmitStatus("success");
      }
    } catch (error) {
      if (error instanceof axios.AxiosError) {
        const { response } = error;
        setApiCreationSubmitionError(
          response?.status === 500 ? serverErrorMsg : response?.data?.detail
        );
        return;
      }

      setSubmitStatus("error");
      console.error("Erro ao submeter o formulário:", error);
    } finally {
      setIsSubmitting(false);
    }
  }

  function onError(errors) {
    setSubmitStatus("error");
    console.log("Failed to submit:", errors);
  }

  const handleChangeVersion = async (version) => {
    try {
      const versionData = pregnantFormDefaultValues(version);
      const status = getBeneficiaryStatus(version.b_status);
      setBeneficiaryData(versionData); // Update to visualization form

      handleVersionChange(version.date_history, version.b_status);
    } catch (error) {
      console.error("Erro ao buscar a versão:", error);
    }
  };

  const [isSubmittingCancel, setIsSubmittingCancel] = useState(false);
  const handleConfirmCancel = async (justification) => {
    if (justification.length < 15 || justification.length > 200) {
      setCancelError(
        () => "A justificativa deve conter entre 15 e 200 caracteres."
      );
      return;
    }
    setCancelError("");
    try {
      setIsSubmittingCancel(true);
      await api.cancelBeneficiary(beneficiaryData.id, justification);
      setAlertType("success");
      // closeCancelModal();
      // window.location.href = "/beneficiarios";
    } catch (error) {
      setAlertType("error");
      console.error("Erro ao cancelar o beneficiário:", error);
    } finally {
      setIsSubmittingCancel(false);
    }
  };

  const handleEditBeneficiary = async () => {
    scrollToTop();
    try {
      const currentVersionData = await api.getBeneficiaryById(beneficiaryData.id);
      if (currentVersionData) {
        window.location.href = `/beneficiarios/${currentVersionData.id}/editar`;
      }
    } catch (error) {
      console.error('Erro ao buscar a versão atual:', error);
    }
  };

  return (
    <FormProvider {...methods}>
      {isEdit && isLoading ? (
        <LoadingBox />
      ) : (
        <form onSubmit={methods.handleSubmit(onSubmit, onError)}>
          <S.TituloItem>
            {benefitType === textsCria.pregnant && isLower16 && (
              <>
                <S.TituloSub>Dados da gestante</S.TituloSub>
                <FormsDadosGestante
                  beneficiaryData={beneficiaryData}
                  isView={isView}
                  isEdit={isEdit}
                  register={methods.register}
                  unregister={methods.unregister}
                  watch={methods.watch}
                  setValue={methods.setValue}
                  errors={methods.formState.errors}
                />
                <S.DadosConteiner
                  // @ts-ignore
                  isView={isView}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <S.TituloSub style={{ width: "auto" }}>
                      Dados pessoais do responsável
                    </S.TituloSub>
                    <InfoButton />
                  </div>
                  <FormsDadosResp
                    isEdit={isEdit}
                    isView={isView}
                    beneficiaryData={beneficiaryData}
                    register={methods.register}
                    unregister={methods.unregister}
                    watch={methods.watch}
                    setValue={methods.setValue}
                    errors={methods.formState.errors}
                  />

                  <S.TituloSub>Endereço da família</S.TituloSub>
                  <FormsEndereco
                    isEdit={isEdit}
                    isView={isView}
                    beneficiaryData={beneficiaryData}
                    register={methods.register}
                    unregister={methods.unregister}
                    errors={methods.formState.errors}
                  />
                </S.DadosConteiner>
              </>
            )}

            {benefitType === textsCria.pregnant && !isLower16 && (
              <>
                <S.DadosConteiner
                  // @ts-ignore
                  isView={isView}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <S.TituloSub style={{ width: "auto" }}>
                      Dados Pessoais
                    </S.TituloSub>
                    <InfoButton />
                  </div>

                  <FormsDados
                    beneficiaryData={beneficiaryData}
                    isView={isView}
                    isEdit={isEdit}
                    register={methods.register}
                    unregister={methods.unregister}
                    watch={methods.watch}
                    setValue={methods.setValue}
                    errors={methods.formState.errors}
                  />

                  <S.TituloSub>Endereço da família</S.TituloSub>
                  <FormsEndereco
                    isEdit={isEdit}
                    isView={isView}
                    beneficiaryData={beneficiaryData}
                    register={methods.register}
                    unregister={methods.unregister}
                    errors={methods.formState.errors}
                  />
                </S.DadosConteiner>
              </>
            )}

            <S.TituloSub>Serviços, Programas e Benefícios</S.TituloSub>
            <FormsOutros
              isEdit={isEdit}
              isView={isView}
              beneficiaryData={beneficiaryData}
              register={methods.register}
              unregister={methods.unregister}
              errors={methods.formState.errors}
            />
            <S.TituloSub>
              Diagnóstico de Saúde Nutricional da Gestante
            </S.TituloSub>
            <FormsDiagnostico
              isEdit={isEdit}
              isView={isView}
              beneficiaryData={beneficiaryData}
              register={methods.register}
              unregister={methods.unregister}
              watch={methods.watch}
              setValue={methods.setValue}
              errors={methods.formState.errors}
            />
            <S.TituloSub>Datas Importantes</S.TituloSub>
            {isEdit ? (
              <FormsDatasEdit
                isView={isView}
                register={methods.register}
                unregister={methods.unregister}
              />
            ) : (
              <FormsDatas
                isView={isView}
                register={methods.register}
                unregister={methods.unregister}
                beneficiaryData={beneficiaryData}
                errors={methods.formState.errors}
              />
            )}

            {isEdit ? (
              <>
                <AddBirthInfoBtn
                  onClick={() => setShowBirthInformation(!showBirthInformation)}
                />
                {showBirthInformation && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        position: "relative",
                        width: "80vw",
                      }}
                    >
                      <S.TituloSub style={{ width: "auto" }}>
                        Informações do parto
                      </S.TituloSub>
                      <InfoButtonBirth />
                    </div>
                    <FormsBirthInformation
                      isView={isView}
                      isEdit={isEdit}
                      beneficiaryData={beneficiaryData}
                      register={methods.register}
                      unregister={methods.unregister}
                      errors={methods.formState.errors}
                      watch={methods.watch}
                      setValue={methods.setValue}
                      isTwinPregnancy={isTwinPregnancy}
                      setIsTwinPregnancy={setIsTwinPregnancy}
                      handleTwinPregnancyChange={handleTwinPregnancyChange}
                    />
                  </>
                )}
              </>
            ) : (
              beneficiaryData?.child_nis && (
                <>
                  <S.TituloSub>Informações do parto</S.TituloSub>
                  <FormsBirthInformation
                    isView={isView}
                    isEdit={isEdit}
                    beneficiaryData={beneficiaryData}
                    register={methods.register}
                    unregister={methods.unregister}
                    errors={methods.formState.errors}
                    watch={methods.watch}
                    setValue={methods.setValue}
                    isTwinPregnancy={isTwinPregnancy}
                    setIsTwinPregnancy={setIsTwinPregnancy}
                    handleTwinPregnancyChange={handleTwinPregnancyChange}
                  />
                </>
              )
            )}

            {isView && (
              <HistoryView
                benefitId={beneficiaryData?.id}
                changeVersion={handleChangeVersion}
              />
            )}

            <S.BotoesConteiner>
              <BackButton />
              {!isView && !isEdit ? (
                <CreateBeneficiaryBtn
                  alertType={submitStatus}
                  setAlertType={setSubmitStatus}
                  isLoading={isSubmitting}
                />
              ) : isEdit ? (
                <>
                  {beneficiaryData.b_status === "0" ? (
                    <ActivationBeneficiary
                      alertType={submitStatus}
                      setAlertType={setSubmitStatus}
                      onClick={() => {
                        setActivation(true);
                      }}
                      formState={methods.formState}
                    />
                  ) : ["1", "2", "3", "4"].includes(
                      beneficiaryData.b_status
                    ) ? (
                    <>
                      <SaveButton
                        isLoading={isSubmitting}
                        alertType={submitStatus}
                        setAlertType={setSubmitStatus}
                      />
                      {["1", "4"].includes(agentType) && (
                        <>
                          <DisableBeneficiary onClick={openCancelModal} />
                          <DeleteModal
                            isLoading={isSubmittingCancel}
                            isOpen={isCancelModalOpen}
                            onClose={closeCancelModal}
                            onConfirm={handleConfirmCancel}
                            cancelError={cancelError}
                            alertType={alertType}
                            setAlertType={setAlertType}
                          />
                        </>
                      )}
                    </>
                  ) : (
                    <SaveButton
                      isLoading={isSubmitting}
                      alertType={submitStatus}
                      setAlertType={setSubmitStatus}
                      formState={methods.formState}
                    />
                  )}
                </>
              ) : (
                !["6"].includes(agentType) && (
                  <div onClick={handleEditBeneficiary} style={{ cursor: 'pointer' }}>
                  <EditButton />
                </div>
                )
              )}
            </S.BotoesConteiner>
          </S.TituloItem>
        </form>
      )}
      <APIErrorAlert
        open={apiCreationSubmitionError !== ""}
        message={apiCreationSubmitionError}
        handleClose={closeImpedimentAlert}
      />
    </FormProvider>
  );
};

export default PregnantForms;
