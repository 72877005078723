import React, { useState, useEffect } from "react";
import { useFormContext, Controller } from "react-hook-form";
import {
  Select,
  MenuItem,
  FormControl,
  Tooltip,
  FormHelperText,
} from "@mui/material";
import * as S from "./styles"; // Assumindo que S contém os estilos personalizados
import { evaluateDropdown } from "utils/PermissionsLogic/dropdown";
import OutlinedInput from "@mui/material/OutlinedInput";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import * as palette from "../../styles/variables";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: palette.lightAzul,
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: palette.lightAzul,
        },
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: palette.lightAzul,
        },
        root: {
          height: "40px",
          borderRadius: "6px",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: palette.lightAzul,
        },
      },
    },
  },
});

const CompDropdown = ({
  defaultValue = "",
  apiValue = "",
  options = [],
  required = false,
  style = {},
  textLabel = "",
  isView = false,
  isEdit = false,
  // register = (name) => {},
  // unregister = (name) => {},
  name = "",
  placeholder = "Selecione",
  dataTestid = "",
}) => {
  const {
    control,
    register,
    unregister,
    formState: { errors },
    setValue,
  } = useFormContext();
  const [selectedOption, setSelectedOption] = useState(defaultValue);

  useEffect(() => {
    register(name);
    return () => {
      unregister(name);
    };
  }, [register, unregister, name, defaultValue]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedOption(value, textLabel);
  };

  const error = errors[name];

  const { options: filteredOptions, enabled } = evaluateDropdown(
    name,
    options,
    isEdit
  );

  if (isView) {
    return (
      <S.SelectWrapper style={style}>
        {textLabel && (
          <S.Label>
            {textLabel}
            {required && <S.RequiredIndicator>*</S.RequiredIndicator>}
          </S.Label>
        )}
        {isView && (
        <S.viewContainer>
          <S.apiData>{apiValue}</S.apiData>
        </S.viewContainer>)}
      </S.SelectWrapper>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <S.SelectWrapper style={style}>
        {textLabel && (
          <S.Label>
            {textLabel}
            {required && <S.RequiredIndicator>*</S.RequiredIndicator>}
          </S.Label>
        )}
        {enabled ? (
          <FormControl fullWidth error={!!error}>
            <Controller
              name={name}
              control={control}
              defaultValue={defaultValue}
              render={({ field }) => (
                <Select
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    setValue(name, e.target.value);
                  }}
                  displayEmpty
                  data-testid={dataTestid} 
                >
                  {/* {console.log(dataTestid, 'AQUIIIIIII DATA')} */}
                  {!defaultValue && (
                    <MenuItem value="" disabled>
                      {placeholder}
                    </MenuItem>
                  )}
                  {filteredOptions.map((option, index) => (
                    <MenuItem key={index} data-testid={`${index}`} value={option.value}>
                      {option.textLabel} 
                    </MenuItem>
                  ))}
                </Select>
              )}  
            />
            {error && <FormHelperText>{error.message}</FormHelperText>}
          </FormControl>
        ) : (
          <Tooltip
            title={isEdit && "Você não tem permissão para editar este campo"}
          >
            <span>
              <FormControl fullWidth>
                <Select
                  value={selectedOption}
                  onChange={handleChange}
                  disabled
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    {placeholder}
                  </MenuItem>
                  {filteredOptions.map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                      {option.textLabel}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </span>
          </Tooltip>
        )}
      </S.SelectWrapper>
    </ThemeProvider>
  );
};

export default CompDropdown;
