import styled from 'styled-components';

export const AlertBox = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 556px;
    height: 231px;
    border-radius: 8px;
    border: 1px solid var(--dark-verde-dark, #70AC17);
    background: var(--light-background, #FFF1E8);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 9999; /* Z-index alto para garantir que fique acima dos outros elementos */
`
export const AlertTitle = styled.h2`
    color: var(--dark-verde-dark, #70AC17);
    text-align: center;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 10px;
`;

