import * as React from 'react';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';

export const SearchHistory  = ({onClick}) => {

    return(
      <>
        <Button variant="contained" startIcon={<SearchIcon/>} 
            sx={{
            background: " var(--Light-Verde---light, #88C54B)",
            width: "182px",
            height: "54px",
            padding: "15px 16px",
            borderRadius: "8px",
            fontFamily: 'Poppins, sans-serif',
            fontFize: '20px',
            fontWeight: 300,
            textTransform: 'none', 

            '&:hover': {
                backgroundColor: "#88C54B",
              },
              }}
            
            onClick={onClick}
        >
        Verificar versão
      </Button>
      </>
    );
};

export default SearchHistory;