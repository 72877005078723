import React, { useState } from 'react';
import * as S from './PregnantForms/styles';
import CompDropdown from 'components/CompDropdown';
import CompTextField from 'components/CompTextField';

const Forms = ({isEdit, isView, beneficiaryData=null, register, unregister, errors}) => {
  
  const options = [
    // {value: "SELECIONE", textLabel: "SELECIONE"},
    {value: "Sim", textLabel: 'Sim'}, 
    {value: "Não", textLabel: 'Não'},
  ]

  return (
    <S.Form>
      <S.FormRowOutros>
        {/* Linha 1 */}
        <CompDropdown
          isView={isView}
          isEdit={isEdit}
          apiValue={beneficiaryData?.bf ? 'Sim' : 'Não'}
          style={{ flexGrow: '1' }}
          // defaultValue="SELECIONE"
          options={options}
          textLabel="BOLSA FAMÍLIA:"
          required
          name="bf"
          register={register}
          unregister={unregister}
          error={errors.bf}
          />
        <CompDropdown
          isView={isView}
          isEdit={isEdit}
          apiValue={beneficiaryData?.bvg ? 'Sim' : 'Não'}
          style={{ flexGrow: '1' }}
          // defaultValue="SELECIONE"
          options={options}
          textLabel="BENEFÍCIO VARIÁVEL GESTANTE:"
          name="bvg"
          required
          register={register}
          unregister={unregister}
          error={errors.bvg}
          />
        <CompDropdown
          isView={isView}
          isEdit={isEdit}
          apiValue={beneficiaryData?.bcn ? 'Sim' : 'Não'}
          style={{ flexGrow: '1' }}
          // defaultValue="SELECIONE"
          options={options}
          required
          textLabel="BENEFÍCIO VARIÁVEL NUTRIZ:"
          name="bcn"
          register={register}
          unregister={unregister}
          error={errors.bcn}
          />
        <CompDropdown
          isView={isView}
          isEdit={isEdit}
          required
          apiValue={beneficiaryData?.pcf ? 'Sim' : 'Não'}
          style={{ flexGrow: '1' }}
          // defaultValue="SELECIONE"
          options={options}
          textLabel="PROGRAMA CRIANÇA FELIZ:"
          name="pcf"
          register={register}
          unregister={unregister}
          error={errors.pcf}
          />
      </S.FormRowOutros>

      <S.FormRowOutrosWithMargin>
        {/* Linha 2 */}
        <CompDropdown
          isView={isView}
          required
          isEdit={isEdit}
          apiValue={beneficiaryData?.bpc ? 'Sim' : 'Não'}
          style={{ flexGrow: '1' }}
          // defaultValue="SELECIONE"
          options={options}
          textLabel="BPC:"
          name='bpc'
          register={register}
          unregister={unregister}
          error={errors.bpc}
          />
        <CompDropdown
          isView={isView}
          isEdit={isEdit}
          required
          apiValue={beneficiaryData?.paif ? 'Sim' : 'Não'}
          style={{ flexGrow: '1' }}
          // defaultValue="SELECIONE"
          options={options}
          textLabel="PAIF:"
          name='paif'
          register={register}
          unregister={unregister}
          error={errors.paif}
          />

        <CompDropdown
          isView={isView}
          isEdit={isEdit}
          required
          apiValue={beneficiaryData?.paefi ? 'Sim' : 'Não'}
          style={{ flexGrow: '1' }}
          // defaultValue="SELECIONE"
          options={options}
          textLabel="PAEFI:"
          name='paefi'
          register={register}
          unregister={unregister}
          error={errors.paefi}
          />

        <CompDropdown
          isView={isView}
          isEdit={isEdit}
          required
          apiValue={beneficiaryData?.pl ? 'Sim' : 'Não'}
          style={{ flexGrow: '1' }}
          // defaultValue="SELECIONE"
          options={options}
          textLabel="PROGRAMA DO LEITE:"
          name="pl"
          register={register}
          unregister={unregister}
          error={errors.pl}
          />

        <CompDropdown
          isView={isView}
          isEdit={isEdit}
          required
          apiValue={beneficiaryData?.mcmv ? 'Sim' : 'Não'}
          style={{ flexGrow: '1' }}
          // defaultValue="SELECIONE"
          options={options}
          textLabel="MINHA CASA MINHA VIDA"
          name="mcmv"
          register={register}
          unregister={unregister}
          error={errors.mcmv}
          />

        <CompTextField
          isView={isView}
          edit={isEdit}
          apiText={beneficiaryData?.other_benefits}
          textLabel="OUTROS:"
          placeholder='Especificar outros Benefícios'
          enabled
          style={{ flexGrow: '1' }}
          name="other_benefits"
          register={register}
          unregister={unregister}
          error={errors.other_benefits}
          helperText={errors.other_benefits && errors.other_benefits.message}
        />
      </S.FormRowOutrosWithMargin>
    </S.Form>
  );
};

export default Forms;
