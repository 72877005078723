import React from "react";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import styled from "styled-components";

// Estilos do componente usando styled-components
const Label = styled.label`
  color: var(--Gray-900, var(--900, #383838));
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
`;

const RequiredIndicator = styled.span`
  color: red;
  margin-left: 4px;
`;

const CustomLabel = ({ textLabel, required }) => (
  <Label>
    {textLabel}
    {required && (
      <Tooltip title="Campo Obrigatório">
        <RequiredIndicator>*</RequiredIndicator>
      </Tooltip>
    )}
  </Label>
);

CustomLabel.propTypes = {
  textLabel: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

CustomLabel.defaultProps = {
  required: false,
};

export default CustomLabel;
