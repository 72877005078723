import { parseISO, format } from "date-fns";
import { ptBR } from "date-fns/locale";

export const monthYear = (dateString) => {
  if (!dateString) return "N/A";
  // Converte a string de data ISO para um objeto Date
  const parsedDate = parseISO(dateString);

  // Formata a data para "MMMM/yyyy", onde "MMMM" é o nome completo do mês
  let formattedDate = format(parsedDate, "MMMM/yyyy", { locale: ptBR });

  // Capitaliza a primeira letra do mês
  formattedDate =
    formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);

  return formattedDate;
};

export const brDate = (dateString) => {
  if (!dateString) return "N/A";
  const parsedDate = parseISO(dateString);

  let formattedDate = format(parsedDate, "dd/MM/yyyy", { locale: ptBR });

  return formattedDate;
};
