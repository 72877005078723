import * as React from 'react';
import { SubmitButton } from './SubmitButton';

export const SaveButton = ({
  alertType,
  setAlertType,
  isLoading,
  dataTestid = "",
  isAgent = false
}) => {
  return (
    <SubmitButton
      alertType={alertType}
      setAlertType={setAlertType}
      isLoading={isLoading}
      dataTestid={dataTestid}
      buttonText="Salvar"
      successMsg={`Sucesso ao salvar ${isAgent ? "agente" : "beneficiário"}.`}
      reload
    />
  );
};


