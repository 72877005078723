import * as S from './styles';


const InfoBoxBirth = () => {
    return (
        <S.Box>
            <S.AlertTitle>
            <b>É obrigatório informar o NIS da criança para registrar o nascimento.</b> A partir do número do NIS, os dados de nome, CPF e a data do parto são preenchidos automaticamente. 
            </S.AlertTitle>
        </S.Box>
    );
}

export default InfoBoxBirth;
