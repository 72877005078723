import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  FormHelperText,
} from "@mui/material";
import { styled } from "@mui/system";
import * as palette from "../../styles/variables";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import CustomLabel from "components/Label"; // Importe o componente CustomLabel

const theme = createTheme({
  palette: {
    primary: {
      main: palette.lightAzul,
    },
  },

  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          // borderColor: 'var(--TextField-brandBorderColor)'
        },
        root: {
          // background: 'white',
          height: "40px",
          [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: palette.lightAzul,
          },
          [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
            //   borderColor: 'var(--TextField-brandBorderFocusedColor)'
          },
        },
      },
    },
  },
  // components: {
  //   MuiOutlinedInput: {
  //     styleOverrides: {
  //       notchedOutline: {
  //         borderColor: palette.lightAzul,
  //       },
  //       "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
  //         borderColor: palette.lightAzul,
  //       },
  //     },
  //   },
  //   MuiSelect: {
  //     styleOverrides: {
  //       icon: {
  //         color: palette.lightAzul,
  //       },
  //     },
  //   },
  // },
});

const CompDropdownMUI = ({
  name,
  textLabel,
  options,
  required = false,
  placeholder = "",
  sx = {},
  rules,
  helperText,
  value,
  noController = false,
  onChange = () => {}
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <ThemeProvider theme={theme}>
      {textLabel && <CustomLabel textLabel={textLabel} required={required} />}
      {noController ? (
        <div >
          <Select
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            sx={{ ...sx, height: "40px" }}
            value={value ? value : ""}
            onChange={onChange}
          >
            <MenuItem
              value=""
              disabled
              sx={{ display: value !== null ? "none" : "flex" }}
            >
              <em style={{ color: "GrayText" }}>{placeholder}</em>
            </MenuItem>
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.textLabel}
              </MenuItem>
            ))}
          </Select>
        </div>
      ) : (
        <FormControl
          fullWidth
          margin="normal"
          sx={{ marginTop: "2px", marginBottom: "0" }}
        >
          <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field }) => (
              <Select
                {...field}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={{ ...sx, height: "40px" }}
                value={field.value !== null ? field.value : value ? value : ""}
                error={!!errors[name]}
              >
                <MenuItem
                  value=""
                  disabled
                  sx={{ display: field.value !== null ? "none" : "flex" }}
                >
                  <em style={{ color: "GrayText" }}>{placeholder}</em>
                </MenuItem>
                {options.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.textLabel}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          {errors[name] && <FormHelperText error>{helperText}</FormHelperText>}
        </FormControl>
      )}
    </ThemeProvider>
  );
};

export default CompDropdownMUI;
