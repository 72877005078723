import * as S from "./styles";
import CompTextField from "components/CompTextField";
import CompDateField from "components/CompDateField";
import { NisField } from "components/NisField";

const Forms = ({
  beneficiaryData,
  isView,
  isEdit,
  register,
  unregister,
  watch,
  setValue,
  errors,
}) => {
  const nis = watch("pregnant_nis");

  const handleNis = (data) => {
    setValue("pregnant_name", data.name);
    setValue("pregnant_cpf", data.cpf);
    setValue("pregnant_birth", data.birth);
    setValue('rg', data.rg);
    setValue('rg_issuer', data['rg_issuer']);
    setValue('rg_uf', data['rg_uf']);
  }

  return (
    <S.Form>
      <S.FormGeral>
        <S.DadosBox>
          <S.FormRowGestanteMenor>
            {/* Linha 1 */}

            <NisField
              textLabel="NIS:"
              benefitNis={beneficiaryData?.pregnant_nis}
              nis={nis}
              isView={isView}
              isEdit={isEdit}
              apiText={beneficiaryData?.pregnant_nis}
              name="pregnant_nis"
              register={register}
              unregister={unregister}
              error={errors.pregnant_nis}
              helperText={errors.pregnant_nis}
              handleNis={handleNis}
              setValue={setValue}
            />

            <CompTextField
              isView={isView}
              apiText={beneficiaryData?.pregnant_name}
              textLabel="NOME COMPLETO:"
              required
              fromNis={true}
              // enabled={false}
              style={{ flexGrow: "1" }}
              name="pregnant_name"
              register={register}
              unregister={unregister}
              error={errors.pregnant_name}
              helperText={errors.pregnant_name && errors.pregnant_name.message}
            />
          </S.FormRowGestanteMenor>

          <S.FormRowGestanteMenor>
            {/* Linha 2 */}
            <CompTextField
              isView={isView}
              apiText={beneficiaryData?.pregnant_cpf}
              textLabel="CPF:"
              fromNis={true}
              //mask="999.999.999-99"
              placeholder="xxx.xxx.xxx-xx"
              required
              // enabled={false}
              name="pregnant_cpf"
              register={register}
              unregister={unregister}
              style={{ flexGrow: "1" }}
              error={errors.pregnant_cpf}
              helperText={errors.pregnant_cpf && errors.pregnant_cpf.message}
            />
            <CompDateField
              isView={isView}
              labelText="DATA DE NASCIMENTO:"
              apiText={beneficiaryData?.pregnant_birth}
              required
              disabled={true}
              name="pregnant_birth"
              fromNis={true}
              register={register}
              unregister={unregister}
              error={errors.pregnant_birth}
              helperText={
                errors.pregnant_birth && errors.pregnant_birth.message
              }
            />
          </S.FormRowGestanteMenor>
        </S.DadosBox>
      </S.FormGeral>
    </S.Form>
  );
};

export default Forms;
