import React, { useEffect, useState } from "react";
import * as S from "./styles";
import AgentForms from "components/forms/AgentForms";
import Title from "components/Title";
import {
    getAgentStatus,
} from "utils";

import { useApi } from "hooks/useApis";
import { useParams } from "react-router-dom";
import ActiveStatus from "components/UserStatus/ActiveStatus";
import InactiveStatus from "components/UserStatus/InactiveStatus";
import VersionStatus from "components/UserStatus/VersionStatus";

const AgentRegistration = ({
    isNew = false,
    isEdit = false,
    isView = false,
}) => {
    const [agentData, setAgentData] = useState({});
    const [agentStatusComponent, setAgentStatusComponent] =
        useState("Ativo");
    const [versionDate, setVersionDate] = useState(null);

    const api = useApi();

    const { id } = useParams();
    const getAgentData = async () => {
        try {
            const data = await api.getAgentById(id);
            const agentStatus = getAgentStatus(data?.a_status);
            setAgentData(data);
            setAgentStatusComponent(agentStatus);
        } catch (err) {
            console.log(err);
        }
    };
    const statusComponents = {
        "Ativo": <ActiveStatus />,
        "Inativo": <InactiveStatus />,
      };

    useEffect(() => {
        if (id) {
            getAgentData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleVersionChange = (date, a_status) => {
        const newStatus = getAgentStatus(a_status);
        setVersionDate(date);
        setAgentStatusComponent(newStatus);
    };

    return isNew ? (
        <S.Container>
            <Title
                titleText="Cadastro de agente"
                subtitleTexts={["Início", "Cadastro de agente"]}
            />
            <AgentForms
                isView={(!isNew && !isEdit) ? true : false}
                isEdit={isEdit}
                agentData={agentData}
            />
        </S.Container>
    ) : (
        <S.Container>
            <Title
                titleText={
                    isEdit ? "Edição de agente" : "Visualização de agente"
                }
                subtitleTexts={[
                    "Início",
                    isEdit ? "Edição de agente" : "Visualização de agente",
                ]}
            />
            <S.StatusContainer>
                {!isEdit && (
                    <>
                        {console.log("Agent Status Component:", agentStatusComponent)}
                        {statusComponents[agentStatusComponent] || null}
                        <VersionStatus versionDate={versionDate} />
                    </>
                )}
            </S.StatusContainer>
            <AgentForms
                isView={(!isNew && !isEdit) ? true : false}
                isEdit={isEdit}
                agentData={agentData}
                handleVersionChange={handleVersionChange}
                setAgentData={setAgentData}
            />
        </S.Container>
    );
};

export default AgentRegistration;
