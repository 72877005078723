import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  background: #ffffff;
  width: 640px;
  height: 400px;
  border-radius: 8px;
  padding: 20px;
  position: relative; /* Para permitir o posicionamento absoluto do ícone de fechar */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  color: #667085;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

export const ModalTitle = styled.h2`
  color: #f26a1d;
  text-align: center;
  font-family: 'Poppins';
  font-weight: 300;
  font-size: 28px;
  line-height: 40px;
  margin-bottom: 10px;
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const JustificationText = styled.p`
  color: #383838;
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  font-size: 18px;
  text-align: justify;
  margin-bottom: 10px;
`;

export const TextAreaWrapper = styled.div`
  width: 100%;
  //margin-bottom: 10px;
  align-items: center;

`;

export const CustomTextArea = styled.textarea`
  width: 592px;
  height: 116px;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #4BBFEF; /* Cor da borda */
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  resize: vertical; /* Permite que o textarea seja redimensionado verticalmente */
`;

export const CharLimit = styled.p`
  color: #515151;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  font-size: 12px;
  text-align: justify;
  width: 100%;
  margin-bottom: 20px;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;


export const ErrorMessage = styled.div`
  color: red;
  margin-top: 10px;
  font-size: 14px;
`;