import styled from "styled-components";

export const Label = styled.label`
  color: var(--Gray-900, var(--900, #383838));
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 8px;
`;

export const RequiredIndicator = styled.span`
  color: var(--Dark-red---dark, #e02e1a);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
  margin-left: 4px;
`;

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SelectEnabled = styled.select`
  //width: 557px;
  height: 40px;
  padding: 8px 14px;
  border-radius: 6px;
  border: 1px solid #4bbfef;
  background: #fff;
`;

export const SelectDisabled = styled.select`
  //width: 557px;
  height: auto;
  padding: 8px 14px;
  border-radius: 6px;
  border: 1px solid var(--Gray-400, #b8b8b8);
  background: #f3f3f3;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
`;
export const SelectView = styled.select`
  //width: 557px;
  height: auto;
  padding: 8px 14px;
  border-radius: 6px;
  border: 1px solid #f9922a;
  background: #fff1e8;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: black;
`;

export const viewContainer = styled.div`
  display: inline-flex;
  padding: 8px 14px;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #f9922a;
  background: #fff1e8;
`;

export const apiData = styled.div`
 display: flex;
  height: 24px;
  flex-direction: column;
  justify-content: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1rem; /* 18px */
  font-weight: 300;
`;
