import * as S from './styles';


const SuspendedStatus = () => {
    return (
        <S.SuspendedStatus>
            Suspenso
        </S.SuspendedStatus>
    );
};

export default SuspendedStatus;