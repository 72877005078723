import React, { forwardRef } from "react";
import { Button, CircularProgress } from "@mui/material";
import { TaskAltOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import SuccessAlert from "components/Alerts/SuccessAlert";
import FillErrorAlert from "components/Alerts/FillErrorAlert";

export const SubmitButton = forwardRef(({
  alertType,
  setAlertType,
  isLoading,
  buttonText,
  reload = false,
  routeToGoBack,
  successMsg,
  dataTestid = "",
  startIcon = <TaskAltOutlined />,
  endIcon,
  errorMsg = undefined,
  ...props
}, ref) => {

  const navigate = useNavigate();

  const handleCloseSuccessAlert = () => {
    setAlertType("");
    if (reload) {
      window.location.reload();
    } else if (routeToGoBack) {
      navigate(routeToGoBack);
    }
  };

  const Feedback = () => (
    <CircularProgress
      color="inherit"
      size="1.5rem"
    />
  );

  return (
    <>
      <Button
        ref={ref}
        type="submit"
        variant="contained"
        startIcon={startIcon}
        disabled={isLoading}
        endIcon={isLoading && <Feedback />}
        data-testid={dataTestid}
        {...props}
        sx={{
          backgroundColor: "#88C54B",
          width: "129px",
          height: "54px",
          padding: "15px 16px",
          borderRadius: "8px",
          fontFamily: 'Poppins, sans-serif',
          fontWeight: 300,
          textTransform: 'none',
          '&:hover': {
            backgroundColor: "#88C54B",
          },
          ...props.sx,
        }}
      >
        {buttonText}
      </Button>

      <SuccessAlert
        open={alertType && alertType === "success"}
        onClose={handleCloseSuccessAlert}
        alertText={successMsg}
      />

      <FillErrorAlert
        open={alertType && alertType === "error"}
        onClose={() => setAlertType("")}
      />
    </>
  );
});

