import { CircularProgress } from "@mui/material";
import * as palette from "styles/variables"

export const Spinning = () => {
  return (
    <CircularProgress
      style={{
        color: `${palette.lightAmarelo}`
      }}
    />);
}
