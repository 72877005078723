// CompTextField.js
import React, { useEffect, useState } from "react";
import styles from "./styles";
import TextField from "@mui/material/TextField";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import InputMask from "react-input-mask";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { evaluateTextField } from "utils/PermissionsLogic/textfield-permission";
import { Tooltip } from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";

import { CircularProgress, InputAdornment } from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
const customTheme = (outerTheme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode,
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "--TextField-brandBorderColor": "#4BBFEF",
            "--TextField-brandBorderHoverColor": "#4BBFEF",
            "--TextField-brandBorderFocusedColor": "#4BBFEF",
            "& label.Mui-focused": {
              color: "var(--TextField-brandBorderFocusedColor)",
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: "var(--TextField-brandBorderColor)",
          },
          root: {
            background: "white",
            height: "40px",
            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: "var(--TextField-brandBorderHoverColor)",
            },
            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: "var(--TextField-brandBorderFocusedColor)",
            },
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            "&::before, &::after": {
              borderBottom: "2px solid var(--TextField-brandBorderColor)",
            },
            "&:hover:not(.Mui-disabled, .Mui-error):before": {
              borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
            },
            "&.Mui-focused:after": {
              borderBottom:
                "2px solid var(--TextField-brandBorderFocusedColor)",
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            "&::before": {
              borderBottom: "2px solid var(--TextField-brandBorderColor)",
            },
            "&:hover:not(.Mui-disabled, .Mui-error):before": {
              borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
            },
            "&.Mui-focused:after": {
              borderBottom:
                "2px solid var(--TextField-brandBorderFocusedColor)",
            },
          },
        },
      },
    },
  });

const CompTextField = ({
  textLabel = "",
  required = false,
  placeholder = null,
  apiText = "",
  style = {},
  name,
  register = () => { },
  unregister = () => { },
  isView = false,
  edit = false,
  fromNis = false,
  type = "text",
  mask = null,
  helperText = "",
  error,
  onBlur = () => { },
  InputProps,
}) => {
  const outerTheme = useTheme();
  const { watch } = useFormContext();
  const value = watch(name)
  useEffect(() => {
    register(name);

    return () => {
      unregister(name);
    };
  }, [register, unregister, name]);

  useEffect(() => {
    setFieldValue(apiText);
  }, [apiText]);

  const [fieldValue, setFieldValue] = useState(apiText);
  const { isAutomaticallyFilled, enabled } = evaluateTextField(name, edit, fieldValue, fromNis);

  return (
    <div style={{ ...style, ...styles.textFieldContainer }}>
      <label style={styles.textFieldLabel} htmlFor={name}>
        {textLabel ? textLabel : <div style={styles.heightNormalizer} />}
        {textLabel && required ? (
          <span style={styles.requiredIndicator}>*</span>
        ) : null}
      </label>
      {isView || name === "benefit_irregularities" ? (
        <div
          style={
            edit
              ? {
                ...styles.viewContainer,
                background: "white",
                border: "1px solid #4BBFEF",
              }
              : styles.viewContainer
          }
        >
          <div style={styles.apiData}>{apiText}</div>
        </div>
      ) : enabled ? (
        <ThemeProvider theme={customTheme(outerTheme)}>
          {mask ? (
            <InputMask
              mask={mask}
              maskChar={null}
              maskPlaceholder={placeholder}
              {...register(name)}
              onBlur={onBlur}
              value={value || ''}
            >
              {() => (
                <TextField
                  id={name}
                  placeholder={placeholder}
                  {...register(name)}
                  type={type}
                  error={!!error}
                  helperText={error && helperText}
                  onBlur={onBlur}
                  InputProps={InputProps}
                />
              )}
            </InputMask>
          ) : (
            <TextField
              {...register(name)}
              type={type}
              id={name}
              placeholder={placeholder}
              error={!!error}
              helperText={error && helperText}
              onBlur={onBlur}
              InputProps={InputProps}
            />
          )}
        </ThemeProvider>
      ) : (
        <ThemeProvider theme={customTheme(outerTheme)}>
          <Tooltip title={isAutomaticallyFilled ? "Este campo é preenchido automaticamente" : "Você não tem permissão para editar este campo"}>
            <TextField disabled placeholder={placeholder} {...register(name)} error={!!error}
              helperText={error && helperText} />
          </Tooltip>
        </ThemeProvider>
      )}
    </div>
  );
};

export default CompTextField;
