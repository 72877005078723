import React, { useState } from "react";
import {
  Box,
  Collapse,
  IconButton,
  Typography,
  Card,
  CardHeader,
  CardContent,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const CollapsibleSection = ({ title, children }) => {
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen((prev) => !prev);
  };

  return (
    <Card>
      <CardHeader
        onClick={handleToggle}
        action={
          <IconButton>
            <ExpandMoreIcon
              sx={{
                transform: open ? "rotate(180deg)" : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            />
          </IconButton>
        }
        title={
          <Typography
            variant="h6"
            style={{ fontFamily: "Poppins", fontSize: "16px" }}
          >
            {title}
          </Typography>
        }
        style={{cursor: "pointer"}}
      />
      <Collapse in={open} timeout="auto" unmountOnExit>
        <CardContent>{children}</CardContent>
      </Collapse>
    </Card>
  );
};

export default CollapsibleSection;
