// Light

export const lightVerde = '#88C54B';
export const lightVermelho = '#EE3B5E';
export const lightAzul = '#4BBFEF';
export const lightAmarelo = '#F9922A';
export const lightBackground = '#FFF1E8';

//Dark

export const darkBranco = '#FFFFFF';
export const darkVermelho = '#E02E1A';
export const darkLaranja = '#F26A1D';
export const darkVerde = '#70AC17';
export const darkAzul = '#0090CE';
export const darkPreto = '#222222';
