import React, {useEffect} from "react";
import { useAuth } from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import agentType from "utils/agentType.js";

export default function ProtectedRoute({
  element: Element, // Corrigido: renomeia 'element' para 'Element'
  disallowedRoles = [],
  onlyGuest = false,
  isPrivate = false,
  ...rest
}) {
  const auth = useAuth();
  const navigate = useNavigate();
  const type = agentType();

  useEffect(() => {
    if (isPrivate && !auth.token) {
      navigate("/");
      return;
    }

    if (onlyGuest && auth.token) {
      navigate("/beneficiarios");
      return;
    }

    if (disallowedRoles.includes(type)) {
      navigate("/beneficiarios");
      return;
    }
  }, [auth.token, onlyGuest, isPrivate, type, navigate, disallowedRoles]);

  return <Element {...rest} />;
}

ProtectedRoute.propTypes = {
  element: PropTypes.oneOfType([PropTypes.elementType, PropTypes.func]).isRequired,
  disallowedRoles: PropTypes.arrayOf(PropTypes.string),
  onlyGuest: PropTypes.bool,
  isPrivate: PropTypes.bool,
};

ProtectedRoute.defaultProps = {
  onlyGuest: false,
  isPrivate: false,
  disallowedRoles: [],
};
