import { DateRangePicker } from "@mui/x-date-pickers-pro";
import styled from "styled-components";

export const CruzamentoHeaderTexts = styled.div`
  position: relative;


  span {
    font-size: 12px;
    font-family: "Poppins";
    font-weight: lighter;
    color: #515151;
    position: absolute;
    top: 4px;
    right: 0;
  }

  @media (max-width: 768px) {
    span {
      position: relative;
      top: -22px;
    }
  }
`;

export const CompareDataContainer = styled.div`
  min-width: 78vw;
  margin-top: -24px;
`;

export const CustomizedDateRangePicker = styled(DateRangePicker)`
  color: red;
`;

export const SelectContainer = styled.div`
  width: 100%;
  .MuiOutlinedInput-root {
    width: 100%;
  }

  .MuiInputBase-input {
    padding: 9px !important;
  }

  .MuiSvgIcon-root {
    margin-top: -3px;
  }

  label {
    margin-bottom: 13px;
  }
  legend {
    display: none;
  }
`;

export const TextFieldCityContainer = styled.div`
  width: 100%;
  .MuiFormLabel-root {
    margin-bottom: 3px;
  }
  .MuiFormControl-root {
    margin-top: 3px;
  }
`;

export const DateCalendarContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* place-content: center; */
  .MuiFormLabel-root {
    margin-bottom: 3px;
  }

  .MuiInputBase-root {
    width: 255px;
  }

  .MuiFormControl-root {
    margin-top: 3px;
  }
  .MuiDateRangeCalendar-root {
    border-radius: 17.961px;
    /* width: 100%; */
    max-width: min-content;
    border: 1.048px solid #d8dadf;
    font-family: Inter;
    & > div:first-child {
      display: none;
    }

    & > div:nth-child(2) {
      width: 100%;
    }
  }
  .MuiPickersCalendarHeader-root {
    /* padding: 0 3rem 0 4rem; */
    margin-bottom: 24px;
    @media (max-width: 768px) {
      padding: inherit;
      padding-left: 1.5rem;
    }
  }

  .MuiButtonBase-root.Mui-selected[aria-selected="true"] {
    background: #efad1b;
    color: #344054;
  }

  .MuiButtonBase-root {
    color: #344054;
  }

  .MuiPickersCalendarHeader-root,
  .MuiPickersFadeTransitionGroup-root,
  .MuiButtonBase-root svg {
    color: #1259a5;
    font-family: Inter !important;
  }

  .MuiPickersCalendarHeader-label {
    text-transform: capitalize;
    font-weight: 600;
    font-size: 21px;
  }
  .MuiPickersSlideTransition-root {
    /* min-height: 220px; */
  }

  .MuiDayCalendar-header,
  .MuiDayCalendar-weekContainer {
    width: 100%;
    /* place-content: space-evenly; */
  }
`;

export const MapSectionMapContainer = styled.div`
  padding: 20px 8px 20px 20px;
  display: flex;
  border-radius: 10px;
  /* background: #f7f7fb; */
  margin: auto;
  margin-top: 4px;
  justify-content: space-between;

  .leaflet-container {
    min-width: 47vw;
  }

  .map-tooltip {
    font-weight: bold;
    font-family: Work Sans;
  }
`;

export const TitleAndHr = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
  gap: 12px;
  margin-top: 32px;
  margin-bottom: 12px;

  h4 {
    font-family: "Poppins";
    color: #f26a1d;
    font-size: 28px;
    line-height: 40px;
    font-weight: 400;
  }

  hr {
    border: 1px solid #f26a1d;
    flex-grow: 1;
  }
`;

export const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
`;

export const StyledTable = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

export const StyledTableHead = styled.thead`
  margin-bottom: 16px;
  color: var(--Dark-Verde---dark, #70ac17);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 900;
  line-height: 24px;
`;

export const StyledTableBody = styled.tbody`
  tr:last-child td {
    border-bottom: 2px solid #70ac17;
  }
`;

export const StyledTableRow = styled.tr`
  background-color: #ffffff;
  margin-bottom: 16px;
  color: #222222;
  font-family: sans-serif;
  font-size: 14px;
`;

export const StyledTableCell = styled.td`
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
`;

export const StyledTableHeaderCell = styled.th`
  border-bottom: 2px solid #fff;
  text-align: left;
  padding: 8px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 8px; // Espaço entre os botões
`;

export const AllDataWrapper = styled.div`
  border-radius: 8px;
  /* padding: 12px; */
  padding-top: 0px;
  max-width: 1360px;
  margin: auto;
  margin-bottom: 64px;
  .firstRow {
    margin-top: 18px;
    & .MuiFormControl-root .MuiInputBase-root {
      width: 100%;
    }

    .MuiInputBase-root {
      height: 45px;
    }
  }

  @media (max-width: 768px) {
    padding: 48px 32px;
    .firstRow,
    .flex-column-mobile {
      flex-direction: column;
    }
  }

  label {
    text-transform: uppercase;
    font-weight: bold;
    color: #383838;
    font-size: 18px;
    line-height: 24px;
  }

  fieldset {
    border-color: #4bbfef;
    border-radius: 8px;
  }

  .MuiChip-root {
    background: transparent;
    border: 1px solid #c8b4ff;
  }
`;

export const TableWrapper = styled.div`
  .title {
    color: #101828;
    padding: 10px 8px;
    font-size: 22px;
    line-height: 28px;
    font-family: "Poppins";
    font-weight: 400;
    margin-top: 32px;
    margin-bottom: 14px;
  }

  /* thead > tr {
  } */
  thead > tr > th {
    background-color: #f9922a;
    color: #fff;
    height: 43px;
    border-color: transparent;
    border: none;
    /* border-bottom: 1px solid black; */
  }

  th {
    white-space: nowrap;
    /* border-bottom: 1px solid rgba(224, 224, 224, 1)!important; */
  }
  td {
    padding: 8px 12px;
    /* border-bottom: 1px solid rgba(224, 224, 224, 1)!important; */
  }
  td:not(.withoutCapitalize) {
    text-transform: capitalize;
  }

  .MuiTableContainer-root {
    max-height: 600px;
    max-width: 1920px;
    overflow: scroll;
    /* min-width: 78vw; */
  }

  .btn-search {
    float: inline-end;
    margin-top: 24px;
    /* height: 54px;
    font-size: 20px;
    border-radius: 8px;
    border: none;
    background-color: #88c54b;
    color: #ffffff;
    &:hover {
      background-color: #88c54b;
      opacity: 0.8;
    } */
  }

  &.table-children {
    thead > tr {
    }
    thead > tr > th {
      background-color: rgba(249, 146, 42, 0.6);
      color: #444444;
      /* border-bottom: 1px solid black; */
    }
    .MuiTableContainer-root {
      overflow: hidden;
      max-width: 1290px;
    }
  }
`;

export const NoDataTable = styled.div``;

export const ButtonGroup = styled.div`
  display: flex;
  gap: 12px;
  justify-content: end;

  button {
    /* height: 54px;
    font-size: 20px;
    border-radius: 8px;
    border: none;
    */
    margin-top: 24px;
  }

  .btn-clean {
    color: #0090ce;
    background-color: #4bbfef3d;
  }

  .btn-search {
    background-color: #88c54b;
    color: #ffffff;
    &:hover {
      background-color: #88c54b;
      opacity: 0.8;
    }
  }
`;
