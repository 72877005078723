import { styled } from '@mui/system';
import {Box, Button} from '@mui/material'
import * as palette from "styles/variables"

export const PageContent = styled('div')`
  display: flex;
  flex-direction: column;
  /* padding-left: 20px; */
  /* padding-right: 20px; */
  min-width: 60vw;
  max-width: 100%;
  /* min-height: 100vh; */
  .buttons{
    width: 100%;
    display: flex;
    justify-content: space-between;
   
    gap: 8px;
  }
`;

export const ButtonBack = styled(Button)({
  backgroundColor: palette.darkLaranja,
  color: 'white',
  fontFamily: 'Roboto',
  textTransform: 'capitalize',
  fontSize: '16px',
  '&:hover': {
    backgroundColor: palette.lightAmarelo,
  },
});

export const ButtonAdd = styled(Button)({
  backgroundColor: palette.darkVerde,
  color: 'white',
  fontFamily: 'Roboto',
  textTransform: 'capitalize',
  fontSize: '16px',
  '&:hover': {
    backgroundColor: palette.lightVerde,
  },
});




export const BoxForm = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-top: 2%;
  gap: 8px;
  h1{
    color: ${palette.lightAmarelo};
    font-size: 28px;
    font-family: 'Poppins';
  }
  .cras{
    text-transform: uppercase;
    font-size: 18px;
    color: #383838;
  }
`



export const DataContainer = styled('div')`
  border-radius: 8px;
  background: var(--Light-Background, #fff1e8);
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  padding-left: 25px;
  margin-bottom: 25px;
  padding-bottom: 20px;
`;

export const TitleSub = styled('div')`
  color: var(--Light-yellow---light, #f9922a);
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px; /* 142.857% */
`;

export const PhotoContainer = styled('img')`
  width: 206px;
  height: 247px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 2px solid #f26a1d;
`;

export const ButtonsContainer = styled('div')`
  display: flex;
  gap: 15px;
  margin-top: 45px;
`;

export const RowS = styled('div')`
  display: flex;
  justify-content: start;
  /* align-items: start; */
  gap: 16px;
  margin-top:1rem;
  width: 100%;
`;

export const RowSE = styled('div')`
  display: flex;
  justify-content: space-evenly;
  gap: 2rem;
  align-content: stretch;
  margin-top:1rem;
`;

export const RowSB = styled('div')`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin-top:1rem;
`;

export const FormColumn = styled('div')`
  /* maxwidth: ; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 10px;
  flex-grow: 1;
`;

export const ConditionalityFormHeader = styled('div')`
  font-family: Poppins;
  font-weight: 500;
  font-size: 28px;
  color: #f9922a;
`;

export const ConditionalityFormSubtitleContainer = styled('div')`
  display: flex;
  gap: 16px;
  padding-left: 14px;
  background-color: #4bbfef;
  color: #fff;
  border-radius: 6px;
  align-items: center;
  height: 40px;
`;

export const ConditionalityFormSubtitle = styled('p')`
  color: #fff;
  font-family: Poppins;
  font-weight: 100;
  font-size: 20px;
`;

export const ConditionalityForm = styled('div')`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const ConditionalityFormDropdown = styled('select')`
  /* Assuming it's a select */
  padding: 16px 0;
  width: 30vw;
`;

export const SelectWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  margin-bottom: 15px;
`;

export const Select = styled('select')`
  width: 557px;
  height: 40px;
  padding: 8px 14px;
  border-radius: 6px;
  background: '#fff';
  border: '1px solid #4bbfef';
`;
export const Label = styled('label')`
  color: var(--Gray-900, var(--900, #383838));
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 8px;
`;
