import React from 'react';
import * as S from './styles';
import SearchIcon from '@mui/icons-material/Search';

const CompSearchField = ({ placeholder, textLabel, name, register = () => {}, unregister }) => {
  return (
    <S.SearchContainer>
      <S.SearchInput
        type="text"
        textLabel={textLabel}
        placeholder={placeholder}
        {...register(name)}
      />
      <S.SearchIconButton type="submit">
        <SearchIcon />
      </S.SearchIconButton>
    </S.SearchContainer>
  );
};

export default CompSearchField;
