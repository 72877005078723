import React, { useEffect, useMemo, useState } from "react";

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import TextField from "@mui/material/TextField";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";

import apiDashboard from "services/apiDashboard";

import * as S from "./styles";

import { metadataOptions, sortArrayByProperty } from "utils";
import { useForm, useWatch } from "react-hook-form";
import {
  Button,
  Collapse,
  IconButton,
  MenuItem,
  Skeleton,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {
  Download,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import Title from "components/Title";
import { LoadingButton } from "@mui/lab";

import AgentCity from "utils/agentCity";
import AgentType from "utils/agentType";
const TableRowsLoader = ({ rowsNum, rowsCol }) => {
  return (
    <TableBody>
      {[...Array(rowsNum)].map((row, indexRow) => (
        <TableRow key={indexRow}>
          {[...Array(rowsCol)].map((row, indexCol) => (
            <TableCell key={indexRow + indexCol} component="th" scope="row">
              <Skeleton animation="wave" variant="text" />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  );
};

function ChildrenRow(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>{row.birth?.split("-").reverse().join("/")}</TableCell>
        <TableCell>{row.race_text?.toLowerCase()}</TableCell>
        <TableCell>{row.gender_text?.toLowerCase()}</TableCell>
        <TableCell>{row.education_level_text?.toLowerCase()}</TableCell>
        <TableCell component={"th"}>
          {row.b_type_text?.charAt(0).toUpperCase() +
            row.b_type_text?.slice(1).toLowerCase()}
        </TableCell>
        <TableCell>{row.b_status_text?.toLowerCase()}</TableCell>
        <TableCell>
          {row.registration_date?.split("-").reverse().join("/")}
        </TableCell>
        <TableCell>{row.end_date?.split("-").reverse().join("/")}</TableCell>
        <TableCell>{row.city}</TableCell>
        <TableCell>
          {row.traditional_population_groups_text?.toLowerCase()}
        </TableCell>
        <TableCell>{row.quilombola_family === "f" ? "Não" : "Sim"}</TableCell>
        <TableCell>{row.indigenous_family === "f" ? "Não" : "Sim"}</TableCell>
        <TableCell>{row.linha_pobreza?.toLowerCase()}</TableCell>
        <TableCell>{row.type_of_domicile_text?.toLowerCase()}</TableCell>
        <TableCell>{row.street_situation === "f" ? "Não" : "Sim"}</TableCell>
        <TableCell className="withoutCapitalize">
          {row.twin_child_birth !== "1900-01-01" && row.twin_child_birth !== ""
            ? row.twin_child_birth?.split("-").reverse().join("/")
            : "Não há"}
        </TableCell>
        <TableCell>{row.qtd_crianca_menos_6_anos_na_familia}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={18}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              {row.children && row.children.length ? (
                <>
                  <Typography variant="h6" gutterBottom component="div">
                    Criança{`${row.children.length > 1 ? "s" : ""}`}
                  </Typography>
                  <S.TableWrapper className="table-children">
                    <TableContainer component={Paper}>
                      <Table size="small" aria-label="purchases">
                        <TableHead>
                          <TableRow>
                            <TableCell>Raça</TableCell>
                            <TableCell>Gênero</TableCell>
                            <TableCell>Nível Educacional</TableCell>
                            <TableCell>Situação de Rua</TableCell>
                            <TableCell>Data de Nascimento da Criança</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {row.children?.map((childrenRow) => (
                            <TableRow key={childrenRow.date}>
                              <TableCell>
                                {childrenRow.child_race_text
                                  ? childrenRow.child_race_text?.toLowerCase()
                                  : "Sem dado"}
                              </TableCell>
                              <TableCell>
                                {childrenRow.child_gender_text
                                  ? childrenRow.child_gender_text?.toLowerCase()
                                  : "Sem dado"}
                              </TableCell>
                              <TableCell>
                                {childrenRow.child_education_level_text
                                  ? childrenRow.child_education_level_text?.toLowerCase()
                                  : "Sem dado"}
                              </TableCell>
                              <TableCell>
                                {childrenRow.child_street_situation === "f"
                                  ? "Não"
                                  : childrenRow.child_street_situation === ""
                                    ? "Sem dado"
                                    : "Sim"}
                              </TableCell>
                              <TableCell>
                                {childrenRow.child_birth
                                  ?.split("-")
                                  .reverse()
                                  .join("/")}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </S.TableWrapper>
                </>
              ) : (
                <Typography variant="h6" gutterBottom component="div">
                  Não há criança cadastrada.
                </Typography>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function transformData(input) {
  const result = [];

  input.forEach((entry) => {
    const city = entry.city;

    const groupedFamilies = {};

    entry.data.forEach((childData) => {
      const familyId = childData.family_id;

      if (!groupedFamilies[familyId]) {
        groupedFamilies[familyId] = {
          city: city,
          ...childData,
          family_id: familyId,
          children: [],
        };
      }

      groupedFamilies[familyId].children.push({
        child_birth: childData.child_birth,
        child_education_level: childData.child_education_level,
        child_education_level_text: childData.child_education_level_text,
        child_family_id: childData.child_family_id,
        child_person_id: childData.child_person_id,
        child_race: childData.child_race,
        child_race_text: childData.child_race_text,
        child_gender: childData.child_gender,
        child_street_situation: childData.child_street_situation,
      });
    });

    result.push(...Object.values(groupedFamilies));
  });

  return result;
}

const shortcutsItems = [
  {
    label: "Último dia útil",
    getValue: () => {
      const today = dayjs();
      return [today.subtract(1, "day"), today];
    },
    onClick: () => {
      console.log("clickou");
    },
  },
  {
    label: "Últimos 6 meses",
    getValue: () => {
      const today = dayjs();
      return [today.subtract(6, "month"), today];
    },
  },
  { label: "Limpar", getValue: () => [null, null] },
];

const CompareDataPage = () => {
  const [cityValue, setCityValue] = useState("Todos");

  const [tableData, setTableData] = useState([]);

  const [noTableDataFlag, setNoTableDataFlag] = useState(false);
  const [isLoadingTableData, setIsLoadingTableData] = useState(false);

  const [allOptions, setAllOptions] = useState({
    b_status: [],
    b_type: [],
    education_level: [],
    gender: [],
    race: [],
    street_situation: [],
    traditional_population_groups: [],
    type_of_domicile: [],
  });

  const [dateValue, setDateValue] = useState([null, null]);
  const [dateValueLastDay, setDateValueLastDay] = useState([null, null]);
  const [cadUnicoVersion, setCadUnicoVersion] = useState("");

  const [citiesData, setCitiesData] = useState([]);
  
  const agent_type = AgentType();
  const agent_city = AgentCity();

  useEffect(() => {
    if (["2", "3"].includes(agent_type)) {
      setValue('city',agent_city);

    }
  }, [agent_type]);
  


  const getCities = async () => {
    try {
      let { data } = await apiDashboard.get("api/v1/municipios/");

      data = sortArrayByProperty(data, "name");

      setCitiesData(data);
    } catch (err) {
      console.error(err);
    }
  };

  const getMetadata = async () => {
    try {
      const { data } = await apiDashboard.get(
        "api/v1/municipios/data/metadata"
      );

      setAllOptions(metadataOptions(data[0]));
    } catch (err) {
      console.error(err);
    }
  };

  const handleDateValueChange = (newValue, ctx) => {
    setDateValue(newValue);

    if (ctx.shortcut && ctx.shortcut.label === "Último dia útil") {
      setDateValue(dateValueLastDay);
    }
  };

  const getLastDayValue = async () => {
    try {
      const { data } = await apiDashboard.get("api/v1/municipios/last_day");
      let apiData = data;

      if (apiData.length > 0) {
        let registration_date = apiData.flat()[0]?.data[0]?.registration_date;
        const lastDay = dayjs(registration_date);
        setDateValueLastDay([lastDay, lastDay]);
        let ref_cad = apiData.flat()[0]?.data[0]?.ref_cad;
        setCadUnicoVersion(ref_cad);
      }
    } catch (err) {
      const today = dayjs();
      setDateValue([today.subtract(1, "day"), today]);
      console.error(err);
    }
  };

  useEffect(() => {
    getCities();
    getMetadata();
    getLastDayValue();
  }, []);

  const { register, control, handleSubmit, setValue } = useForm({
    defaultValues: useMemo(() => {
      return {
        city: "Todos",
        race: [],
        gender: [],
        education_level: [],
        b_type: [],
        b_status: [],
        type_of_domicile: [],
        traditional_population_groups: [],
        street_situation: [],
      };
    }, []),
  });

  let {
    race,
    gender,
    education_level,
    b_type,
    b_status,
    type_of_domicile,
    traditional_population_groups,
    street_situation,
  } = useWatch({
    control,
  });

  const clearAllOptions = () => {
    setValue("race", []);
    setValue("gender", []);
    setValue("education_level", []);
    setValue("b_type", []);
    setValue("b_status", []);
    setValue("type_of_domicile", []);
    setValue("traditional_population_groups", []);
    setValue("street_situation", []);

    setCityValue("Todos");
    setValue("city", "Todos");

    setDateValue([null, null]);

    setTableData([]);

    setNoTableDataFlag(false);
  };

  const onSubmit = async (data) => {
    let y = Object.fromEntries(
      Object.entries(data)
        .filter(
          ([key, value]) =>
            !(value === "" || value.length === 0) &&
            !(key === "city" && value === "Todos")
        )
        .map(([key, value]) => {
          if (value instanceof Array && value.length > 0) {
            let v = value.map((date) => `'${date}'`);

            return [key, v.join(",")];
          }
          return [key, value];
        })
    );

    let filters = decodeURIComponent(new URLSearchParams(y).toString());
    let [startDate, endDate] = dateValue;

    if (startDate) {
      filters = filters + `&start=${startDate.format("YYYY-MM-DD")}`;
    }

    if (endDate) {
      filters = filters + `&end=${endDate.format("YYYY-MM-DD")}`;
    }

    setIsLoadingTableData(true);
    setTableData([]);

    try {
      const { data } = await apiDashboard.get(
        `api/v1/municipios/data?${filters}`
      );

      let flatData = data;

      let table = [];

      flatData.forEach((objCityData) => {
        let newData = objCityData.data.map((d) => ({
          ...d,
          city: objCityData.city,
        }));
        table.push(...newData);
      });

      if (table.length === 0) {
        setNoTableDataFlag(true);
      }

      flatData = transformData(data);

      setTableData(flatData);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoadingTableData(false);
    }
  };

  const downloadTable = () => {
    const csvString = [
      [
        "Data de nascimento",
        "Raça do Responsável",
        "Gênero do Responsável",
        "Nível Educacional do Responsável",
        "Tipo de Beneficiário",
        "Status de Beneficiário",
        "Data de Registro",
        "Data de Encerramento",
        "Município",
        "Grupo Tradicional",
        "Família Quilombola",
        "Família Indígena",
        "Linha de Pobreza",
        "Situação de Rua?",
        "Tipo de Domicílio",
        "Data de Nascimento de Gêmeos",
        "Qtd. Crianças < 6 Anos na Família",
        "Data de Nascimento da Criança",
        "Raça da Criança",
        "Gênero da Criança",
        "Nível Educacional da Criança",
        "Criança em Situação de Rua?",
      ],
      ...tableData.map((row) => [
        row.birth?.split("-").reverse().join("/"),

        row.race_text?.toLowerCase(),
        row.gender_text?.toLowerCase(),
        row.education_level_text?.toLowerCase(),

        row.b_type_text?.charAt(0).toUpperCase() +
        row.b_type_text?.slice(1).toLowerCase(),

        row.b_status_text?.toLowerCase(),

        row.registration_date?.split("-").reverse().join("/"),

        row.end_date?.split("-").reverse().join("/"),

        row.city,
        row.traditional_population_groups_text?.toLowerCase(),

        row.quilombola_family === "f" ? "Não" : "Sim",

        row.indigenous_family === "f" ? "Não" : "Sim",

        row.linha_pobreza?.toLowerCase(),

        row.street_situation === "f" ? "Não" : "Sim",

        row.type_of_domicile_text?.toLowerCase(),

        row.twin_child_birth !== "1900-01-01" && row.twin_child_birth !== ""
          ? row.twin_child_birth?.split("-").reverse().join("/")
          : "Não há",

        row.qtd_crianca_menos_6_anos_na_familia,

        row.child_birth?.split("-").reverse().join("/"),

        row.child_race_text ? row.child_race_text?.toLowerCase() : "Sem dado",

        row.child_gender_text
          ? row.child_gender_text?.toLowerCase()
          : "Sem dado",

        row.child_education_level_text
          ? row.child_education_level_text?.toLowerCase()
          : "Sem dado",

        row.child_street_situation === "f"
          ? "Não"
          : row.child_street_situation === ""
            ? "Sem dado"
            : "Sim",
      ]),
    ]
      .map((e) => e.join(","))
      .join("\n");

    var downloadLink = document.createElement("a");
    var blob = new Blob(["\ufeff", csvString]);
    var url = URL.createObjectURL(blob);
    downloadLink.href = url;
    downloadLink.download = "dados-cria.csv";

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const { onChange: onChangeCity } = register("city");

  return (
    <S.CompareDataContainer>
      <S.AllDataWrapper>
        <S.CruzamentoHeaderTexts>
          <Title
            titleText="Cruzamento de dados"
            subtitleTexts={["Início", "Cruzamento de dados"]}
            showCadVersion={false}
          />
          {cadUnicoVersion && (
            <span>
              Versão do CadÚnico:{" "}
              {cadUnicoVersion?.split("-").reverse().join("/")}
            </span>
          )}
        </S.CruzamentoHeaderTexts>
        <div style={{ display: "flex", gap: 32 }} className="firstRow">
          <S.DateCalendarContainer>
            <InputLabel>Período</InputLabel>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="pt-br"
            >
              {/* <DateRangeCalendar
                calendars={1}
                value={dateValue}
                onChange={(newValue) => setDateValue(newValue)}
              /> */}
              <DateRangePicker
                slots={{ field: SingleInputDateRangeField }}
                disableFuture
                slotProps={{
                  shortcuts: {
                    items: shortcutsItems,
                  },
                  actionBar: { actions: [] },
                }}
                localeText={{
                  fieldMonthPlaceholder: () => "mm",
                  fieldDayPlaceholder: () => "dd",
                  fieldYearPlaceholder: () => "aaaa",
                }}
                value={dateValue}
                onChange={handleDateValueChange}
                views={["year", "month", "day"]} // ver porque ta dando erro
                calendars={1}
                name="allowedRange"
              />
            </LocalizationProvider>
          </S.DateCalendarContainer>

          <S.TextFieldCityContainer>
            <InputLabel>Município</InputLabel>
            <TextField
              select
              id="outlined-select-type"
              margin="normal"
              fullWidth
              required
              placeholder="Digite o indicador"
              disabled={["2", "3"].includes(agent_type)}
              value={ ["2", "3"].includes(agent_type) ? agent_city : cityValue}
              defaultValue="Todos"
              InputLabelProps={{ shrink: true }}
              {...register("city")}
              onChange={(event) => {
                onChangeCity(event);
                const {
                  target: { value },
                } = event;
                setCityValue(value);
              }}
            >
              {[{ name: "Todos", value: "Todos" }, ...citiesData].map(
                (item) => (
                  <MenuItem key={item.id} value={item.name}>
                    {item.name}
                  </MenuItem>
                )
              )}
            </TextField>
          </S.TextFieldCityContainer>
        </div>

        <S.TitleAndHr>
          <h4>Selecione os indicadores</h4>
          <hr />
        </S.TitleAndHr>

        <div
          style={{ display: "flex", gap: 32, marginTop: 20 }}
          className="flex-column-mobile"
        >
          <S.SelectContainer sx={{ m: 1, width: 300 }}>
            <InputLabel id="demo-multiple-checkbox-label">
              Tipo de beneficiário
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox--s"
              id="demo-multiple-checkbox"
              multiple
              // label="selecione"
              value={b_type}
              // onChange={handleChangeRace}
              {...register("b_type")}
              input={<OutlinedInput label="Tag" />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((name) => (
                    <Chip
                      key={name}
                      label={
                        allOptions["b_type"].find(
                          (current) => current.value === name
                        )?.label
                      }
                    />
                  ))}
                </Box>
              )}
            // MenuProps={MenuProps}
            >
              {allOptions["b_type"].map((name) => (
                <MenuItem key={name.value} value={name.value}>
                  <Checkbox checked={b_type.indexOf(name.value) > -1} />
                  <ListItemText primary={name.label} />
                </MenuItem>
              ))}
            </Select>
          </S.SelectContainer>
          <S.SelectContainer sx={{ m: 1, width: 300 }}>
            <InputLabel id="demo-multiple-checkbox-label">
              Identidade Racial
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox--s"
              id="demo-multiple-checkbox"
              multiple
              // label="selecione"
              value={race}
              // onChange={handleChangeRace}
              {...register("race")}
              input={<OutlinedInput label="Tag" />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((name) => (
                    <Chip
                      key={name}
                      label={
                        allOptions["race"].find(
                          (current) => current.value === name
                        )?.label
                      }
                    />
                  ))}
                </Box>
              )}
            // MenuProps={MenuProps}
            >
              {allOptions["race"].map((name) => (
                <MenuItem key={name.value} value={name.value}>
                  <Checkbox checked={race.indexOf(name.value) > -1} />
                  <ListItemText primary={name.label} />
                </MenuItem>
              ))}
            </Select>
          </S.SelectContainer>

          <S.SelectContainer sx={{ m: 1, width: 300 }}>
            <InputLabel id="demo-multiple-checkbox-label">Gênero</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={gender}
              {...register("gender")}
              // onChange={handleChangeGender}
              input={<OutlinedInput label="Tag" />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((name) => (
                    <Chip
                      key={name}
                      label={
                        allOptions["gender"].find(
                          (current) => current.value === name
                        )?.label
                      }
                    />
                  ))}
                </Box>
              )}
            // MenuProps={MenuProps}
            >
              {allOptions["gender"].map((name) => (
                <MenuItem key={name.value} value={name.value}>
                  <Checkbox checked={gender.indexOf(name.value) > -1} />
                  <ListItemText primary={name.label} />
                </MenuItem>
              ))}
            </Select>
          </S.SelectContainer>
        </div>

        <div
          style={{ display: "flex", gap: 32, marginTop: 30 }}
          className="flex-column-mobile"
        >
          <S.SelectContainer sx={{ m: 1, width: 300 }}>
            <InputLabel id="demo-multiple-checkbox-label">
              Nível de escolaridade
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={education_level}
              {...register("education_level")}
              input={<OutlinedInput label="Tag" />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((name) => (
                    <Chip
                      key={name}
                      label={
                        allOptions["education_level"].find(
                          (current) => current.value === name
                        )?.label
                      }
                    />
                  ))}
                </Box>
              )}
            // MenuProps={MenuProps}
            >
              {allOptions["education_level"].map((name) => (
                <MenuItem key={name.value} value={name.value}>
                  <Checkbox
                    checked={education_level.indexOf(name.value) > -1}
                  />
                  <ListItemText primary={name.label} />
                </MenuItem>
              ))}
            </Select>
          </S.SelectContainer>

          <S.SelectContainer sx={{ m: 1, width: 300 }}>
            <InputLabel id="demo-multiple-checkbox-label">
              Status do beneficiário
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={b_status}
              {...register("b_status")}
              // onChange={handleChangeGender}
              input={<OutlinedInput label="Tag" />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((name) => (
                    <Chip
                      key={name}
                      label={
                        allOptions["b_status"].find(
                          (current) => current.value === name
                        )?.label
                      }
                    />
                  ))}
                </Box>
              )}
            // MenuProps={MenuProps}
            >
              {allOptions["b_status"].map((name) => (
                <MenuItem key={name.value} value={name.value}>
                  <Checkbox checked={b_status.indexOf(name.value) > -1} />
                  <ListItemText primary={name.label} />
                </MenuItem>
              ))}
            </Select>
          </S.SelectContainer>

          <S.SelectContainer sx={{ m: 1, width: 300 }}>
            <InputLabel id="demo-multiple-checkbox-label">
              Em situação de rua?
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={street_situation}
              {...register("street_situation")}
              input={<OutlinedInput label="Tag" />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((name) => (
                    <Chip
                      key={name}
                      label={
                        allOptions["street_situation"].find(
                          (current) => current.value === name
                        )?.label
                      }
                    />
                  ))}
                </Box>
              )}
            // MenuProps={MenuProps}
            >
              {allOptions["street_situation"].map((name) => (
                <MenuItem key={name.value} value={name.value}>
                  <Checkbox
                    checked={street_situation.indexOf(name.value) > -1}
                  />
                  <ListItemText primary={name.label} />
                </MenuItem>
              ))}
            </Select>
          </S.SelectContainer>
        </div>

        <div
          style={{ display: "flex", gap: 32, marginTop: 30 }}
          className="flex-column-mobile"
        >
          <S.SelectContainer sx={{ m: 1, width: 300 }}>
            <InputLabel id="demo-multiple-checkbox-label">
              Tipo de família
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox--s"
              id="demo-multiple-checkbox"
              multiple
              // label="selecione"
              value={traditional_population_groups}
              // onChange={handleChangeRace}
              {...register("traditional_population_groups")}
              input={<OutlinedInput label="Tag" />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((name) => (
                    <Chip
                      key={name}
                      label={
                        allOptions["traditional_population_groups"].find(
                          (current) => current.value === name
                        )?.label
                      }
                    />
                  ))}
                </Box>
              )}
            // MenuProps={MenuProps}
            >
              {allOptions["traditional_population_groups"].map((name) => (
                <MenuItem key={name.value} value={name.value}>
                  <Checkbox
                    checked={
                      traditional_population_groups.indexOf(name.value) > -1
                    }
                  />
                  <ListItemText primary={name.label} />
                </MenuItem>
              ))}
            </Select>
          </S.SelectContainer>

          <S.SelectContainer sx={{ m: 1, width: 300 }}>
            <InputLabel id="demo-multiple-checkbox-label">
              Tipo de domicílio
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={type_of_domicile}
              {...register("type_of_domicile")}
              // onChange={handleChangeGender}
              input={<OutlinedInput label="Tag" />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((name) => (
                    <Chip
                      key={name}
                      label={
                        allOptions["type_of_domicile"].find(
                          (current) => current.value === name
                        )?.label
                      }
                    />
                  ))}
                </Box>
              )}
            // MenuProps={MenuProps}
            >
              {allOptions["type_of_domicile"].map((name) => (
                <MenuItem key={name.value} value={name.value}>
                  <Checkbox
                    checked={type_of_domicile.indexOf(name.value) > -1}
                  />
                  <ListItemText primary={name.label} />
                </MenuItem>
              ))}
            </Select>
          </S.SelectContainer>
        </div>

        <S.ButtonGroup>
          <Button
            onClick={clearAllOptions}
            variant="contained"
            sx={{
              width: "87px",
              height: "54px",
              padding: "15px 16px",
              borderRadius: "8px",
              background: "rgba(75, 191, 239, 0.24)",

              fontFamily: "Poppins, sans-serif",
              fontFize: "20px",
              fontWeight: 300,
              color: "var(--Dark-Azul---dark, #0090CE)",
              textTransform: "none",

              "&:hover": {
                backgroundColor: "rgba(75, 191, 239, 0.24)",
              },
            }}
          >
            Limpar
          </Button>

          <LoadingButton
            onClick={handleSubmit(onSubmit)}
            variant="contained"
            loading={isLoadingTableData}
            loadingPosition="start"
            startIcon={<SearchIcon />}
            sx={{
              background: " var(--Light-Verde---light, #88C54B)",
              width: "180px",
              height: "54px",
              padding: "15px 16px",
              borderRadius: "8px",
              fontFamily: "Poppins, sans-serif",
              fontWeight: 300,
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#88C54B",
              },
            }}
          >
            Buscar dados
          </LoadingButton>
        </S.ButtonGroup>

        <S.TableWrapper>
          <h3 className="title">Dados do Responsável</h3>
          <TableContainer component={Paper}>
            <Table stickyHeader size="small" aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Data de nascimento</TableCell>
                  <TableCell>Raça</TableCell>
                  <TableCell>Gênero</TableCell>
                  <TableCell style={{ paddingRight: 60 }}>
                    Nível Educacional
                  </TableCell>
                  <TableCell>Tipo de Beneficiário</TableCell>
                  <TableCell>Status de Beneficiário</TableCell>
                  <TableCell>Data de Registro</TableCell>
                  <TableCell>Data de Encerramento</TableCell>
                  <TableCell style={{ paddingRight: 60 }}>Município</TableCell>
                  <TableCell style={{ paddingRight: 92 }}>
                    Grupo Tradicional
                  </TableCell>
                  <TableCell>Família Quilombola</TableCell>
                  <TableCell>Família Indígena</TableCell>
                  <TableCell>Linha de Pobreza</TableCell>
                  <TableCell>Tipo de Domicílio</TableCell>
                  <TableCell>Situação de Rua?</TableCell>
                  <TableCell>Data de Nascimento de Gêmeos</TableCell>
                  <TableCell>Qtd. Crianças &lt; 6 Anos na Família</TableCell>
                </TableRow>
              </TableHead>

              {tableData.length > 0 ||
                (noTableDataFlag && !isLoadingTableData) ? (
                <TableBody>
                  {tableData.map((row, i) => (
                    <ChildrenRow key={i} row={row} />
                  ))}
                </TableBody>
              ) : isLoadingTableData ? (
                <TableRowsLoader rowsNum={10} rowsCol={18} />
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell className="withoutCapitalize" colSpan={7}>
                      Selecione os indicadores.
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
              {tableData.length === 0 &&
                noTableDataFlag &&
                !isLoadingTableData && (
                  <TableBody>
                    <TableRow>
                      <TableCell className="withoutCapitalize" colSpan={7}>
                        Não há dados com a junção dos indicadores selecionados.
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
            </Table>
          </TableContainer>
          {tableData.length > 0 &&
            !(tableData.length === 0 && noTableDataFlag) && (
              <Button
                onClick={downloadTable}
                variant="contained"
                className="btn-search"
                startIcon={<Download />}
                sx={{
                  background: " var(--Light-Verde---light, #88C54B)",
                  width: "180px",
                  height: "54px",
                  padding: "15px 16px",
                  borderRadius: "8px",
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: 300,
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#88C54B",
                  },
                }}
              >
                Baixar tabela
              </Button>
            )}
        </S.TableWrapper>
      </S.AllDataWrapper>
    </S.CompareDataContainer>
  );
};

export default CompareDataPage;
