import React, { useState } from 'react';
import * as S from './styles';
import CloseIcon from '@mui/icons-material/Close';
import { SubmitButton } from 'components/Buttons/SubmitButton';
import { CheckBoxOutlined } from '@mui/icons-material';
import alertBlue from '../../assets/image/blueAlertEmoji.svg';

const DeleteModal = ({
  isOpen,
  onClose,
  onConfirm,
  cancelError,
  alertType,
  setAlertType,
  isLoading,
}) => {

  if (!isOpen) {
    return null;
  }

  const handleConfirm = () => {
    onConfirm();
  };

  const ConfirmCancellationBeneficiary = ({ onClick, alertType, setAlertType, }) => {
    return (
      <SubmitButton
        isLoading={isLoading}
        onClick={onClick}
        alertType={alertType}
        setAlertType={setAlertType}
        buttonText="Confirmar cancelamento"
        successMsg="Agente cancelado com sucesso."
        errorMsg="Erro ao cancelar o agente."
        startIcon={<CheckBoxOutlined />}
        sx={{ width: "260px" }}
      />
    );
  };

  return (
    <S.ModalOverlay>
      <S.ModalContent>
        <S.CloseButton onClick={onClose}>
          <CloseIcon />
        </S.CloseButton>
        <S.ModalHeader>
          <S.Emoji src={alertBlue} alt="Emoji de alerta azul"></S.Emoji>
          <S.ModalTitle>Tem certeza que deseja Desativar o Agente?</S.ModalTitle>
        </S.ModalHeader>
        <S.ModalBody>
          <S.JustificationText>O Agente será desativado sendo necessário ativá-lo novamente.</S.JustificationText>
        </S.ModalBody>
        <S.ModalFooter>
          <ConfirmCancellationBeneficiary
            onClick={handleConfirm}
            alertType={alertType}
            setAlertType={setAlertType}
          />
        </S.ModalFooter>
      </S.ModalContent>
    </S.ModalOverlay>
  );
};

export default DeleteModal;
