const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  content: {
    flex: "1",
  },
  footer: {
    // position: ' fixed',
    // bottom: '0',
    // left: '0',
    // right: '0',
    padding: "10px",
    width: "100%",
    // height: '100%',
    //alignItems: 'center',
    //gap: '10px',
    borderTop: "1px solid var(--dark-verde-dark, #70AC17)",
    backgroundColor: "#FFF",
    marginTop: "auto",
  },
  imagesContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "23px",
    marginTop: "10px",
  },

  copyrightText: {
    color: "var(--dark-preto, #222)",
    textAlign: "center",
    fontFamily: "sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 300,
    lineHeight: "150%",
  },
  criaStyles: {
    width: "64px",
    height: "39px",
    flexShrink: 0,
  },
  laccanStyles: {
    width: "101px",
    height: "39px",
    flexShrink: 0,
  },
  govStyles: {
    width: "102px",
    height: "56px",
    flexShrink: 0,
  },
  orionStyles: {
    width: "102px",
    height: "auto",
    flexShrink: 0,
  },
};

export default styles;
