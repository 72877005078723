import * as React from 'react';
import Button from '@mui/material/Button';
import LightbulbIcon from '@mui/icons-material/Lightbulb';



export const GenerateButton = () => {
    const handleClick = () => {
    };

    return(
        <Button variant="contained" startIcon={<LightbulbIcon />} sx={{
            background: " var(--Light-Verde---light, #88C54B)",
            width: "129px",
            height: "54px",
            padding: "15px 16px",
            borderRadius: "8px",
            fontFamily: 'Poppins, sans-serif',
            fontFize: '45px',
            fontWeight: 600,
            textTransform: 'none', 

            '&:hover': {
                backgroundColor: "#88C54B",
              },
              }}
              onClick={handleClick}
              >
        Gerar
      </Button>
    );
};


