import React, { useState, useEffect } from "react";
import * as S from "./styles";
import {
  Grid,
  FormControl,
  TextField,
  CircularProgress,
  Button,
} from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import Title from "components/Title";
import FotoBenef from "assets/image/beneficiaryPhoto.svg";
import * as util from "../../../utils/constants";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CompDropdownMUI from "components/CompDropdownMUI";
import * as F from "../formsTypes.jsx";
import * as palette from "styles/variables";
import { useApi } from "hooks/useApis";
import { Link, useParams } from "react-router-dom";
import CustomLabel from "components/Label";
import Loading from "components/Loading";
import * as formatDate from "utils/formatDate";
import RestoreIcon from "@mui/icons-material/Restore";
import { useSnackbar } from "notistack";
import SuccessAlert from "components/Alerts/SuccessAlert";
import { APIErrorAlert } from "components/Alerts/APIErrorAlert";
import { useNavigate } from "react-router-dom";

export default function Condicionalidades() {
  const api = useApi();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const methods = useForm({
    defaultValues: {
      conditionality_type: null,
      warning: null,
      social_assistance: false,
      current_gestational_month: null,
      current_gestational_age: null,
      check_current: null,
      check_first: null,
      check_last: null,
      check_number: null,
      showed_up: false,
      observation: null,
      weight: null,
      height: null,
      imc: null,
      nutritional_graph_check: false,
      mark_count: null,
      type_justification: null,
      justification: null,
      month_justification_choice: null,
      vaccine_name: null,
      other_vaccine_name: null,
      vaccine_dose: null,
      other_vaccine_dose: null,
      vaccination_date: null,
      child_nis: null,
      child_name: null,
      child_cpf: null,
      created_date: null,
      benefit: id ? id : null,
      pre_registration: null,
      benefit_history: null,
    },
  });

  const {
    handleSubmit,
    watch,
    register,
    unregister,
    setValue,
    reset,
    formState: { errors },
  } = methods;

  const [pregnant, setPregnant] = useState([]);
  const [loading, setLoading] = useState(true);
  const [alertType, setAlertType] = useState("");
  const navigate = useNavigate();

  const handleCloseAlert = () => {
    if (alertType === "error") {
      setAlertType("");
      return;
    }
    setAlertType("");
    navigate(`/acompanhamento/historico/${id}`);
  };

  const getBeneficiaryData = async () => {
    try {
      const data = await api.getBeneficiaryById(id);
      setPregnant(data);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (id) {
      getBeneficiaryData();
    }
  }, []);

  const theme = createTheme({
    palette: {
      primary: {
        main: palette.lightAzul,
      },
      secondary: {
        main: palette.darkAzul,
      },
    },
  });

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      await api.createCondicionality(data);
      setAlertType("success");
      reset();
    } catch (err) {
      console.log(err);
      setAlertType("error");
    } finally {
      setLoading(false)
    }
  };
  const tipo = watch("conditionality_type");

  return (
    <ThemeProvider theme={theme}>
      <S.PageContent>
        <Title
          titleText="Acompanhamento de gestante"
          subtitleTexts={[
            "Início",
            "Gerência de beneficiário",
            "Acompanhamentos",
          ]}
        />
        {loading ? (
          <Loading />
        ) : (
          <S.RowS>
            <S.PhotoContainer src={FotoBenef} alt="Logo" />
            <Grid container spacing={2}>
              <Grid
                item
                md={6}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <CustomLabel textLabel="NIS:" />
                <TextField
                  fullWidth
                  sx={{ backgroundColor: "#F3F3F3" }}
                  inputProps={{ "aria-label": "Without label" }}
                  size="small"
                  value={pregnant?.nis ? pregnant?.nis : "--"}
                  disabled
                />
              </Grid>
              <Grid
                item
                md={6}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <CustomLabel textLabel="NOME COMPLETO:" />
                <TextField
                  fullWidth
                  sx={{ backgroundColor: "#F3F3F3" }}
                  inputProps={{ "aria-label": "Without label" }}
                  size="small"
                  value={pregnant?.name ? pregnant?.name : "--"}
                  disabled
                />
              </Grid>
              <Grid
                item
                md={4}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <CustomLabel textLabel="DATA DE NASCIMENTO:" />
                <TextField
                  fullWidth
                  sx={{ backgroundColor: "#F3F3F3" }}
                  inputProps={{ "aria-label": "Without label" }}
                  size="small"
                  value={
                    pregnant?.birth ? formatDate.brDate(pregnant?.birth) : "--"
                  }
                  disabled
                />
              </Grid>
              <Grid
                item
                md={4}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <CustomLabel textLabel="MÊS GESTACIONAL:" />
                <TextField
                  fullWidth
                  sx={{ backgroundColor: "#F3F3F3" }}
                  inputProps={{ "aria-label": "Without label" }}
                  size="small"
                  value={
                    pregnant?.nutritional_data?.gestational_month
                      ? pregnant.nutritional_data.gestational_month + "°"
                      : "--"
                  }
                  disabled
                />
              </Grid>
              <Grid
                item
                md={4}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <CustomLabel textLabel="IDADE GESTACIONAL ATUAL:" />
                <TextField
                  fullWidth
                  sx={{ backgroundColor: "#F3F3F3" }}
                  inputProps={{ "aria-label": "Without label" }}
                  size="small"
                  value={
                    pregnant?.nutritional_data?.gestational_age
                      ? pregnant.nutritional_data.gestational_age
                      : "--"
                  }
                  disabled
                />
              </Grid>
            </Grid>
          </S.RowS>
        )}
        <FormProvider {...methods}>
          <S.BoxForm component="form" onSubmit={methods.handleSubmit(onSubmit)}>
            <FormControl sx={{ width: "50%" }}>
              <CompDropdownMUI
                name="conditionality_type"
                textLabel="ADICIONE O PROCEDIMENTO REALIZADO:"
                options={util.monitoringTypesPregnant}
                required={true}
                // onChange={handleTypeChange}
                placeholder="Selecione o procedimento"
                sx={{ backgroundColor: "#F3F3F3" }}
              />
            </FormControl>
            {tipo === "5" && <F.FormType5 />}
            {tipo === "1" && <F.FormType1 />}
            {tipo === "3" && <F.FormType3 />}
            {tipo === "8" && <F.FormType8 pregnant={true} />}

            <div className="buttons">
              <div style={{ display: "flex", gap: "7px" }}>
                <Link to={`/beneficiarios/`}>
                  <S.ButtonBack variant="contained" color="primary">
                    Voltar
                  </S.ButtonBack>
                </Link>
                {tipo && (
                  <S.ButtonAdd
                    variant="contained"
                    color="primary"
                    type="submit"
                    endIcon={loading && <CircularProgress color="inherit" size="1.5rem" />}
                    disabled={loading}
                  >
                    Cadastrar
                  </S.ButtonAdd>
                )}
              </div>
              <Link to={`/acompanhamento/historico/${id}`}>
              <Button
                  variant="contained"
                  color="primary"
                  sx={{color: 'white'}}
                  startIcon={<RestoreIcon />}
                >
                  Histórico de acompanhamentos
                </Button>
              </Link>
            </div>
          </S.BoxForm>
        </FormProvider>
        <SuccessAlert
          open={alertType && alertType === "success"}
          onClose={handleCloseAlert}
          alertText={"O acompanhamento da gestante foi criado!"}
        />
        <APIErrorAlert
          handleClose={handleCloseAlert}
          message={'Tente novamente! Se persistir, fale com a equipe de suporte do CRIA.'}
          open={alertType && alertType === "error"}
        />
      </S.PageContent>
    </ThemeProvider>
  );
}
