import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL
  // timeout: 30000,
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("cria-web@token");

    if (token && token !== "" && JSON.parse(token) !== "null" && JSON.parse(token) !== null) {
      config.headers.Authorization = `Bearer ${JSON.parse(token)}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default api;
