import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Checkbox, CircularProgress, FormControlLabel, Grid, InputLabel, TextField, Typography } from '@mui/material';
import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import brasaoImg from 'assets/image/brasao.png';
import FooterAlt from '../../components/FooterAlt';
import useStyles from './styles';
import { useAuth } from 'hooks/useAuth';
import CompTextField from 'components/CompTextField';
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "utils/zod-pt";

const LoginScreen = () => {
  const auth = useAuth();
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const loginSchema = z.object({
    cpf: z.string().nonempty('Por favor, digite o CPF/Login.'),
    password: z.string().nonempty('Por favor, digite a senha.'),
  });

  const methods = useForm({
    resolver: zodResolver(loginSchema),
    mode: "all",
    criteriaMode: "all",
  });

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    const lookForToken = async () => {
      if (auth.token) {
        const isTokenValid = await auth.checkToken();
        console.log(isTokenValid)
        if (isTokenValid) {
          navigate('/dashboard');
        }
        else {
          auth.logout();
        }
      }
    }
    lookForToken();
  }, [auth.token, navigate]);

  async function onSubmit(data) {
    const { cpf, password } = data;
    try {
      setIsSubmitting(true);
      const warningMsg = "Data de expiração do acesso ao sistema está proxima, procure seu Coordenador.";
      const { isLogged, response } = await auth.login(cpf, password);
      if (isLogged) {
        if (response.data.detail === warningMsg) {
          auth.setExpirationWarning(true);
        }
        navigate('/dashboard');
      } else {
        const error = response.data;
        const errorMsg = "No active account found with the given credentials";
        if (error.detail === errorMsg) {
          // Esse if trata uma exceção para o único detail que está em inglês
          setErrorMessage("CPF ou senha incorretos");
        }
        else {
          setErrorMessage(error.detail);
        }
        methods.reset();
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsSubmitting(false);
    }
  };

  async function onError(data) {
    console.log(data);
  };

  const Spinning = () => (
    <CircularProgress
      color="inherit"
      size="1.8rem"
    />
  )

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit, onError)}>
        <div style={useStyles.container}>
          <img src={brasaoImg} alt="Logo" style={useStyles.logo} />
          <div style={useStyles.formContainer}>
            <Typography style={useStyles.loginTitle} variant="h4" gutterBottom>
              Gestão Cartão CRIA
            </Typography>
            <div style={useStyles.inputContainer}>
              <CompTextField
                isView={false}
                textLabel="CPF/Login"
                mask="999.999.999-99"
                required
                name="cpf"
                register={methods.register}
                unregister={methods.unregister}
                error={methods.formState.errors.cpf}
                helperText={methods.formState.errors.cpf && methods.formState.errors.cpf.message}
              />
            </div>
            <div style={useStyles.inputContainer}>
              <CompTextField
                isView={false}
                textLabel="Senha"
                required
                name="password"
                type={showPassword ? 'text' : 'password'}
                register={methods.register}
                unregister={methods.unregister}
                error={methods.formState.errors.password}
                helperText={methods.formState.errors.password && methods.formState.errors.password.message}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleTogglePasswordVisibility}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </div>

            <Grid container alignItems="center">
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={rememberMe}
                      onChange={() => setRememberMe(!rememberMe)}
                    />
                  }
                  label={
                    <Typography style={useStyles.label}>
                      Permanecer logado
                    </Typography>
                  }
                />
              </Grid>
              <Grid item xs={6} textAlign="right">
                <Link to="/recuperar-senha">
                  <Typography variant="body2">
                    Esqueceu a senha?
                  </Typography>
                </Link>

              </Grid>
            </Grid>

            {errorMessage && (
              <Typography style={{ color: 'red', marginTop: 20, marginBottom: 20 }}>
                {errorMessage}
              </Typography>
            )}

            {/*TODO: set to disabled when loading */}
            <Button
              endIcon={isSubmitting && <Spinning />}
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              id='submitLogin'
              style={useStyles.buttonLogin}
            >
              Entrar
            </Button>

            <Grid container alignItems="center">
              <Grid item xs={6}>
                <Typography style={useStyles.labelMore}>
                  Quer conhecer mais?
                </Typography>
              </Grid>
            </Grid>

            <Link to="/sobre" style={{ textDecoration: 'none' }}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                style={useStyles.buttonMore}
              >
                Sobre o CRIA
              </Button>
            </Link>
          </div>
          <FooterAlt />
        </div>
      </form>
    </FormProvider >
  );
};

export default LoginScreen;
