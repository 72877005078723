import React, { useState, useEffect } from "react";
import * as S from "./styles";
import { convertDateToApi } from "utils";
import { evaluateDateField } from "utils/PermissionsLogic/datefield"; // Importar a função
import { Tooltip } from "@mui/material";

const CompDateField = ({
  labelText,
  required,
  isView,
  isEdit = false,
  disabled = false,
  apiText = null,
  name,
  register = (name) => {},
  unregister = (name) => {},
  error,
}) => {
  const [dateValue, setDateValue] = useState("");
  useEffect(() => {
    if (apiText) {
      const formattedDate = convertDateToApi(apiText);
      setDateValue(formattedDate);
    }
  }, [apiText]);

  useEffect(() => {
    register(name);

    return () => {
      unregister(name);
    };
  }, [register, unregister, name]);

  const handleChange = (event) => {
    setDateValue(event.target.value);
  };

  const isFieldEnabled = evaluateDateField(name, isEdit, dateValue);

  if (isView) {
    return (
      <S.DateInputContainer>
        <S.FormLabel>
          {labelText}
          {required && <S.RequiredIndicator>*</S.RequiredIndicator>}
        </S.FormLabel>
        <S.DateInputFieldView
          disabled
          type="date"
          placeholder="dd/mm/aaaa"
          required
          value={dateValue}
          {...register(name)}
        />
      </S.DateInputContainer>
    );
  }

  return (
    <S.DateInputContainer>
      <S.FormLabel>
        {labelText}
        {required && <S.RequiredIndicator>*</S.RequiredIndicator>}
      </S.FormLabel>
      <Tooltip title={!isFieldEnabled && "Você não tem permissão para editar este campo"}>
        <S.DateInputField
          type="date"
          placeholder="dd/mm/aaaa"
          // required
          disabled={!isFieldEnabled} // Aqui usamos a variável isFieldEnabled
          onChange={handleChange}
          {...register(name, { onChange: (e) => handleChange(e) })}
        />
      </Tooltip>
      {error && <p style={{ color: "red", fontSize: '10px' }}>{error.message? error.message : error}</p>}
    </S.DateInputContainer>
  );
};

export default CompDateField;
