// @ts-nocheck
import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link,  useLocation } from "react-router-dom";
import * as S from "./styles";
import { useState } from "react";
import { useEffect } from "react";
import Collapse from "@mui/material/Collapse";
import agentType from "utils/agentType.js";

import {
  HomeOutlined,
  ExpandLess,
  ExpandMore,
  PersonAddAltOutlined,
} from "@mui/icons-material";

import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
const drawerWidth = 240;

const CustomListItemButtonDrawer = ({
  text,
  icon,
  // openDrawer,
  href,
  pathname,
}) => (
  <Link to={href}>
    <S.ListItem
      isactive={String(pathname === href)}
      key={text}
      // openDrawer={String(openDrawer)}
      iscollapse={"false"}
      disablePadding
      sx={{ display: "block" }}
    >
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: "initial",
          px: 2.5,
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: 3,
            justifyContent: "center",
          }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText primary={text} sx={{ opacity: 1 }} />
      </ListItemButton>
    </S.ListItem>
  </Link>
);

const CustomListItemButtonCollapse = ({ text, href, pathname }) => (
  <Link to={href}>
    <S.ListItem
      isactive={String(pathname === href)}
      iscollapse={"true"}
      key={text}
      disablePadding
      sx={{ display: "block" }}
    >
      <ListItemButton sx={{ pl: 4 }}>
        {/* <ListItemIcon>{icon}</ListItemIcon> */}
        <ListItemText primary={text} />
      </ListItemButton>
    </S.ListItem>
  </Link>
);

const pathsInsideSistemasLista = [
  "/admin/hoteis-previstos",
  "/admin/diaria-media",
  "/admin/oferta-hoteleira",
  "/admin/ocupacao-hoteleira",
];

const pathsInsideDashboardList = [
  "/dashboard",
  "/dashboard/cruzamento-de-dados",
];

export default function Sidebar({ children }) {
  const { pathname } = useLocation();
  const agent_type = agentType();

  const [openCollapseDashboardList, setOpenCollapseDashboardList] =
    useState(false);

  const [openCollapseSistemasLista, setOpenCollapseSistemasLista] =
    useState(false);

  const [openCollapseGestaoLista, setOpenCollapseGestaoLista] = useState(false);

  const isInsideSistemasLista =
    pathsInsideSistemasLista.find((path) => path === pathname) ?? false;

  const isInsideDashboardList =
    pathsInsideDashboardList.find((path) => path === pathname) ?? false;

  useEffect(() => {
    if (isInsideDashboardList) {
      setOpenCollapseDashboardList(true);
    }
    if (isInsideSistemasLista) {
      setOpenCollapseSistemasLista(true);
    }
  }, [pathname]);

  const toggleCollapseSistemasLista = () => {
    setOpenCollapseSistemasLista(!openCollapseSistemasLista);
  };
  const toggleCollapseGestaoLista = () => {
    setOpenCollapseGestaoLista(!openCollapseGestaoLista);
  };

  const toggleCollapseDashboardList = () => {
    setOpenCollapseDashboardList(!openCollapseDashboardList);
  };

  return (
    <S.SidebarContainer>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        <Toolbar style={{ minHeight: "107px" }} />
        <Box sx={{ overflow: "auto" }}>
          <List>
            {/* <CustomListItemButtonDrawer
              pathname={pathname}
              href="#"
              icon={<HomeOutlined />}
              text={"Início"}
              //   openDrawer={openDrawer}
            /> */}

            <S.CustomListItemButton
              sx={{
                minHeight: 48,
                justifyContent: "initial",
                px: 2.5,
              }}
              openCollapse={openCollapseDashboardList}
              onClick={toggleCollapseDashboardList}
              style={
                isInsideDashboardList || openCollapseDashboardList
                  ? {
                      backgroundColor: "#F9922A",
                      color: "#FFF",
                    }
                  : {}
              }
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: 3,
                  justifyContent: "center",
                }}
              >
                <HomeOutlined />
              </ListItemIcon>
              <ListItemText primary={"Início"} sx={{ opacity: 1 }} style={{}} />
              {openCollapseDashboardList ? <ExpandLess /> : <ExpandMore />}
            </S.CustomListItemButton>

            <Collapse
              in={openCollapseDashboardList}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                <CustomListItemButtonCollapse
                  style={{ margin: 0 }}
                  pathname={pathname}
                  href="/dashboard"
                  text={"Dashboard"}
                />
                {![].includes(agent_type) && (
                  <CustomListItemButtonCollapse
                    pathname={pathname}
                    href="/dashboard/cruzamento-de-dados"
                    text={"Cruzamento de dados"}
                  />
                )}
                {/* {![].includes(agent_type) && (
                  <CustomListItemButtonCollapse
                    pathname={pathname}
                    href="/dashboard/relatorios"
                    text={"Relatórios"}
                  />
                )} */}
              </List>
            </Collapse>

            <S.CustomListItemButton
              sx={{
                minHeight: 48,
                justifyContent: "initial",
                px: 2.5,
              }}
              openCollapse={openCollapseSistemasLista}
              onClick={toggleCollapseSistemasLista}
              style={
                isInsideSistemasLista || openCollapseSistemasLista
                  ? {
                      backgroundColor: "#F9922A",
                      color: "#FFF",
                    }
                  : {}
              }
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: 3,
                  justifyContent: "center",
                }}
              >
                <PersonAddAltOutlined />
              </ListItemIcon>
              <ListItemText
                primary={"Sistema"}
                sx={{ opacity: 1 }}
                style={{}}
              />
              {openCollapseSistemasLista ? <ExpandLess /> : <ExpandMore />}
            </S.CustomListItemButton>

            <Collapse
              in={openCollapseSistemasLista}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
              {!["6"].includes(agent_type) && (
                <CustomListItemButtonCollapse
                  pathname={pathname}
                  href="/beneficiarios/cadastrar"
                  text={"Cadastro de beneficiário"}
                />)}

                <CustomListItemButtonCollapse
                  pathname={pathname}
                  href="/beneficiarios"
                  text={"Gestão de beneficiário"}
                />
              </List>
            </Collapse>
            <S.CustomListItemButton
              sx={{
                minHeight: 48,
                justifyContent: "initial",
                px: 2.5,
              }}
              openCollapse={openCollapseGestaoLista}
              onClick={toggleCollapseGestaoLista}
              style={
                isInsideSistemasLista || openCollapseGestaoLista
                  ? {
                      backgroundColor: "#F9922A",
                      color: "#FFF",
                    }
                  : {}
              }
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: 3,
                  justifyContent: "center",
                }}
              >
                <VerifiedUserOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={"Gestão"} sx={{ opacity: 1 }} style={{}} />
              {openCollapseGestaoLista ? <ExpandLess /> : <ExpandMore />}
            </S.CustomListItemButton>
            <Collapse in={openCollapseGestaoLista} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <CustomListItemButtonCollapse
                  pathname={pathname}
                  href="/beneficiarios/irregularidade"
                  text={"Beneficiários irregulares"}
                />
                {!["2","3", "6"].includes(agent_type) && (
                  <CustomListItemButtonCollapse
                    pathname={pathname}
                    href="/agentes/cadastrar"
                    text={"Cadastro de agentes"}
                  />
                )}
                {!["3"].includes(agent_type) && (
                  <CustomListItemButtonCollapse
                    pathname={pathname}
                    href="/agentes"
                    text={"Gerência de agentes"}
                  />
                )} 
                
                  {!["2","3","6","5"].includes(agent_type) && (
                  <CustomListItemButtonCollapse
                    pathname={pathname}
                    href="/gestao/exportar"
                    text={"Exportação de dados"}
                  />
                )}
              </List>
            </Collapse>
          </List>
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar style={{ minHeight: "150px" }} />
        {children}
      </Box>
    </S.SidebarContainer>
  );
}
