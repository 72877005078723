import React, { useState } from "react";
import * as S from "./styles";
import CompTextField from "components/CompTextField";
import CompDropdown from "components/CompDropdown";
import CompDateField from "components/CompDateField";

const Forms = ({
  isView,
  register,
  unregister,
  watch,
  setValue,
  agentData,
  errors,
}) => {
  const labor_link_options = [
    //{ value: 'Selecione', textLabel: "Selecione" },
    { value: "Concursado", textLabel: "Concursado" },
    { value: "Celetista", textLabel: "Celetista" },
    { value: "Comissionado", textLabel: "Comissionado" },
    { value: "Contratado", textLabel: "Contratado" },
    { value: "Outro Vínculo", textLabel: "Outro Vínculo" },
  ];
  return (
    <S.Form>
      <S.FormRow>
        {/* Linha 1 */}
        <CompTextField
          isView={isView}
          textLabel="FORMAÇÃO PROFISSIONAL:"
          type="text"
          required
          style={{ flexGrow: "1" }}
          name={"professional_qualification"}
          apiText={agentData?.professional_qualification}
          register={register}
          unregister={unregister}
          error={errors.professional_qualification}
          helperText={
            errors.professional_qualification &&
            errors.professional_qualification.message
          }
        />

        <CompTextField
          isView={isView}
          textLabel="FUNÇÃO NO MUNICÍPIO:"
          type="text"
          required
          style={{ flexGrow: "1" }}
          name={"role"}
          apiText={agentData?.role}
          register={register}
          unregister={unregister}
          error={errors.role}
          helperText={errors.role && errors.role.message}
        />
      </S.FormRow>
      <S.FormRowWithMarginDados>
        {/* Linha 2 */}
        <CompDropdown
          isView={isView}
          style={{ flexGrow: "2" }}
          textLabel="VÍNCULO TRABALHISTA"
          required
          name={"labor_link"}
          apiValue={agentData?.labor_link}
          options={labor_link_options}
          dataTestid="labor_link_dropdown"
        />
        <CompDateField
          isView={isView}
          labelText="VALIDADE DAS CREDENCIAIS DE ACESSO:"
          style={{ flexGrow: "1" }}
          required
          name={"access_expiration_date"}
          apiText={agentData?.access_expiration_date}
          placeholder="dd/mm/aaaa"
          register={register}
          unregister={unregister}
          error={errors.access_expiration_date}
          helperText={
            errors.access_expiration_date &&
            errors.access_expiration_date.message
          }
        />
      </S.FormRowWithMarginDados>
    </S.Form>
  );
};

export default Forms;
