import { useApi } from "hooks/useApis";
import FillErrorAlert from "components/Alerts/FillErrorAlert";
import IconButton from "components/Buttons/IconButton"

import { useState } from "react";
import { CircularProgress } from "@mui/material"
import { FileDownload as FileDownloadIcon } from "@mui/icons-material";
import { AxiosError } from "axios";

export const DownloadReportsButton = ({
  isIrregular = false,
  benefitFilters,
  irregularBenefitFilters,
}) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadError, setDownloadError] = useState("");

  const api = useApi();

  const handleDownload = async () => {
    setIsDownloading(true);
    try {
      if (isIrregular) {
        await api.downloadReport(benefitFilters, {
          ...irregularBenefitFilters,
          irregular: true,
        });
      } else {
        await api.downloadReport(benefitFilters, {});
      }
    } catch (error) {
      if (error instanceof AxiosError && error.response.status >= 400) {
        const data = await error.response.data.text(); // response is blob
        setDownloadError(JSON.parse(data).detail);
      }
    } finally {
      setIsDownloading(false);
    }
  }

  return (
    <>
      {isDownloading ? (
        <CircularProgress />
      ) : (
        <IconButton
          onClick={handleDownload}
          text="Baixar"
          icon={<FileDownloadIcon></FileDownloadIcon>}
        />
      )}

      <FillErrorAlert
        open={downloadError !== ""}
        onClose={() => setDownloadError("")}
        title="Erro ao baixar planilha."
        message={downloadError}
      />
    </>
  );
}
