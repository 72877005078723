import * as React from 'react';
import Button from '@mui/material/Button';
import BlockIcon from '@mui/icons-material/Block';

const DisableBeneficiary = ({ onClick }) => {
  return (
    <Button
      onClick={onClick}
      startIcon={<BlockIcon />}
      sx={{
        background: " var(--Light-Verde---light, #EE3B5E)",
        width: "281px",
        height: "54px",
        padding: "15px 16px",
        borderRadius: "8px",
        fontFamily: 'Poppins, sans-serif',
        color: '#FFFFFF',
        fontSize: '16px',
        fontWeight: 200,
        textTransform: 'none',
        '&:hover': {
          backgroundColor: "#EE3B5E",
        },
      }}
    >
      Cancelar beneficiário
    </Button>
  );
};

export default DisableBeneficiary;
