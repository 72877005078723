import * as S from './styles';


const BlockedStatus = () => {
    return (
        <S.BlockedStatus>
            Bloqueado
        </S.BlockedStatus>
    );
};

export default BlockedStatus;