// @ts-nocheck
import React, { useState, useEffect } from "react";
import CompSearchField from "components/CompSearchField";
import CompDropdown from "components/CompDropdown";
import Typography from "@mui/material/Typography";
import TableConsultation from "components/TableAgent";
import Title from "components/Title";
import * as S from "./styles";
import { useApi } from "hooks/useApis";
import { z } from "zod";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  distributionPoints,
  a_type_options,
  a_status_options,
  sort_options,
} from "utils/constants";
import { CircularProgress } from "@mui/material";
import * as palette from "styles/variables";
import CompAutocomplete from "components/CompAutocomplete";

const AgentManagement = () => {
  const api = useApi();
  const [agentList, setAgentList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [changePage, setChangePage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [totalAgents, setTotalAgents] = useState(0);

  useEffect(() => {
    const savedFilters = JSON.parse(sessionStorage.getItem("cria-web@agentFilters"));
    if (savedFilters) {
      methods.reset(savedFilters);
    }
  }, [currentPage]);

  const createAgentSearchSchema = z.object({
    a_status: z.string().optional(),
    agent_type: z.string().optional(),
    search: z.string().optional(),
    sort: z.string(),
    city: z.string().optional(),
  });

  const methods = useForm({
    resolver: zodResolver(createAgentSearchSchema),
    mode: "all",
    criteriaMode: "all",
  });

  const a_status_value = methods.watch("a_status");
  const agent_type_value = methods.watch("agent_type");
  const search_value = methods.watch("search");
  const sort_value = methods.watch("sort");
  const city_value = methods.watch("city");

  useEffect(() => {
    setChangePage(currentPage);
    methods.handleSubmit((data) => onSubmit(data, currentPage), onError)();
  }, [currentPage]);


  useEffect(() => {
    methods.handleSubmit((data) => onSubmit(data, 1), onError)();
  }, [a_status_value, agent_type_value, sort_value, city_value]);


  async function onSubmit(data, pageNumber) {
    try {
      setLoading(true);
      const filteredList = await api.listAgent(
        data.a_status,
        data.agent_type,
        data.search,
        data.sort,
        data.city,
        pageNumber
      );
      setChangePage(pageNumber);
      setAgentList(filteredList.results);
      setTotalPages(filteredList.total_pages);
      setTotalAgents(filteredList?.count);
      sessionStorage.setItem("cria-web@agentFilters", JSON.stringify(data));
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  function onError(errors) {
    console.log("Failed to submit:", errors);
  }

  // @ts-ignore
  const [searchTerm, setSearchTerm] = useState("");

  // @ts-ignore
  const handleSearch = () => {
    //onSearch(searchTerm);
  };

  // @ts-ignore
  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // @ts-ignore
  const handleKeyPress = (e) => {
    // if (e.key === 'Enter') {
    //   handleSearch();
    // }
  };

  return (
    <S.PageContainer>
      <Title
        titleText="Gerência de agentes"
        subtitleTexts={["Início", "Gerência de agentes"]}
      />
      <S.PageContent>
        <S.MainContainer>
          <FormProvider {...methods}  >
            <S.Form onSubmit={methods.handleSubmit((data) => onSubmit(data, 1))}>
              <S.FormRowOutros>
                <S.DateFieldsContainer>
                  <S.DateFieldsTitle>BUSCA DO AGENTE</S.DateFieldsTitle>
                  <CompSearchField
                    placeholder="Digite o nome ou CPF para buscar"
                    name="search"
                    register={methods.register}
                    unregister={methods.unregister}
                  />
                </S.DateFieldsContainer>

                <CompDropdown
                  style={{ flexGrow: "1" }}
                  defaultValue="0"
                  textLabel="TIPO DE AGENTE"
                  options={a_type_options}
                  name={"agent_type"}
                  register={methods.register}
                  unregister={methods.unregister}
                />
              </S.FormRowOutros>

              <S.FormRowWithMargin>
                <CompAutocomplete
                  style={{ flexGrow: "1" }}
                  defaultValue=""
                  textLabel="MUNICÍPIO:"
                  options={distributionPoints}
                  name="city"
                  placeholder="Selecione um município"
                  register={methods.register}
                  unregister={methods.unregister}
                />

                <CompDropdown
                  style={{ flexGrow: "1" }}
                  textLabel="STATUS DO AGENTE"
                  defaultValue="1"
                  options={a_status_options}
                  name={"a_status"}
                  register={methods.register}
                  unregister={methods.unregister}
                />
              </S.FormRowWithMargin>

              <S.CabecalhoTabela>
                <div>
                  <Typography variant="h6">Lista de Agentes</Typography>
                  <p style={{ fontWeight: "normal" }}>
                    {totalAgents?.toLocaleString("pt-BR")} agentes
                  </p>
                </div>
                <CompDropdown
                  style={{ flexGrow: "0" }}
                  defaultValue="0"
                  options={sort_options}
                  name={"sort"}
                  register={methods.register}
                  unregister={methods.unregister}
                />
              </S.CabecalhoTabela>
            </S.Form>
          </FormProvider>

          {loading ? (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress style={{ color: `${palette.lightAmarelo}` }} />
            </div>
          ) : (
            <TableConsultation
              agentList={agentList}
              page={changePage}
              totalPages={totalPages}
              setPage={setCurrentPage}
              loading={loading}
            />
          )}
        </S.MainContainer>
      </S.PageContent>
    </S.PageContainer>
  );
};

export default AgentManagement;
