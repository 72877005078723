import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Sobre from "../pages/About";
import ChangePassword from "../pages/ChangePassword";
import PregnantMonitoring from "../pages/Monitoring/pregnant";
import ChildMonitoring from "../pages/Monitoring/child/";
import RecoverPassword from "pages/RecoverPassword";
import AgentRegistration from "pages/Agent/forms";
import Login from "../pages/Login";
import BeneficiaryForms from "pages/beneficiarios/forms";
import BeneficiaryManagement from "pages/beneficiarios/list";
import EmissionsConsultation from "../pages/EmissionsConsultation";
import AgentManagement from "pages/Agent/list";
import AdminLayout from "layouts/AdminLayout";
import DashboardPage from "pages/Dashboard";
import BeneficiaryIrregularity from "pages/BeneficiaryIrregularity/list";
import CompareDataPage from "pages/Dashboard/cruzamento-de-dados";
import ReportsPage from "pages/Dashboard/relatorios";
import HistoryConditionality from "pages/Monitoring/History";
import NotFound from "pages/NotFound";
import ProtectedRoute from "./ProtectedRoute";
import ExportData from "pages/ExportData";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* Pages without layout */}
        {/* Paginas que nao podem ser acessadas ao estar logado, somente deslogados */}
        <Route
          path="/"
          element={<ProtectedRoute element={Login} onlyGuest />}
        />
        <Route
          path="/sobre"
          element={<ProtectedRoute element={Sobre} />}
          onlyGuest
        />
        <Route
          path="/alterar-senha"
          element={<ProtectedRoute element={ChangePassword} />}
        />
        <Route
          path="/recuperar-senha"
          element={<ProtectedRoute element={RecoverPassword} onlyGuest />}
        />

        <Route element={<AdminLayout />}>
          {/* Beneficiary */}
          <Route
            path="/beneficiarios"
            element={
              <ProtectedRoute element={BeneficiaryManagement} isPrivate />
            }
          />
          <Route
            path="/beneficiarios/cadastrar"
            element={
              <ProtectedRoute
                element={BeneficiaryForms}
                isPrivate
                disallowedRoles={["6"]}
                isNew
              />
            }
          />
          <Route
            path="/beneficiarios/:id"
            element={<ProtectedRoute element={BeneficiaryForms} isPrivate />}
          />
          <Route
            path="/beneficiarios/:id/editar"
            element={
              <ProtectedRoute
                element={BeneficiaryForms}
                isPrivate
                disallowedRoles={["6"]}
                isEdit
              />
            }
          />
          <Route
            path="/beneficiarios/irregularidade"
            element={
              <ProtectedRoute element={BeneficiaryIrregularity} isPrivate />
            }
          />

          {/* Agent */}
          <Route
            path="/agentes/cadastrar"
            element={
              <ProtectedRoute
                element={AgentRegistration}
                isPrivate
                disallowedRoles={["2", "3", "6"]}
                isNew
              />
            }
          />
          <Route
            path="/agentes/:id"
            element={<ProtectedRoute element={AgentRegistration} isPrivate />}
          />
          <Route
            path="/agentes/:id/editar"
            element={
              <ProtectedRoute
                element={AgentRegistration}
                disallowedRoles={["2", "3", "6"]}
                isPrivate
                isEdit
              />
            }
          />
          <Route
            path="/agentes"
            element={
              <ProtectedRoute
                element={AgentManagement}
                isPrivate
                disallowedRoles={["3"]}
              />
            }
          />

          {/* Conditionalities */}
          <Route
            path="/acompanhamento/historico/:id"
            element={
              <ProtectedRoute element={HistoryConditionality} isPrivate />
            }
          />
          <Route
            path="/acompanhamento/gestante/:id"
            element={
              <ProtectedRoute
                element={PregnantMonitoring}
                isPrivate
                disallowedRoles={["6"]}
              />
            }
          />
          <Route
            path="/acompanhamento/crianca/:id"
            element={
              <ProtectedRoute
                element={ChildMonitoring}
                isPrivate
                disallowedRoles={["6"]}
              />
            }
          />

          {/* Dashboard */}
          <Route
            path="/dashboard"
            element={<ProtectedRoute element={DashboardPage} isPrivate />}
          />
          <Route
            path="/dashboard/cruzamento-de-dados"
            element={<ProtectedRoute element={CompareDataPage} isPrivate />}
          />
          {/* <Route
            path="/dashboard/relatorios"
            element={<ProtectedRoute element={ReportsPage} isPrivate />}
          /> */}
          <Route
            path="/gestao/dados"
            element={
              <ProtectedRoute element={EmissionsConsultation} isPrivate />
            }
          />
          <Route
            path="/gestao/exportar"
            element={<ProtectedRoute element={ExportData} isPrivate />}
          />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
