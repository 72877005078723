import { ListItemButton, ListItem as ListItemMaterial } from "@mui/material";
import styled, { css } from "styled-components";

export const SidebarContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  .topSide {
    padding-top: 20px;
  }
  .MuiDrawer-paper {
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* justify-content: space-between; */

    /* border-radius: 0px 15px 15px 0px; */
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.5) 0%,
        rgba(255, 255, 255, 0.5) 100%
      ),
      rgb(248, 163, 0);
    color: #fff;

    svg {
      color: #f26a1d;
    }
  }

  .MuiDrawer-root {
    position: relative;
    overflow: initial;
  }

  .MuiPaper-root {
  }
  .MuiList-root a {
    color: #fff;
    text-decoration: none;
  }
  .MuiListItemButton-root {
    padding: 8px 12px;
    /* width: fit-content; */
    display: flex;
    margin: 0;
    height: fit-content;
    max-height: 35px;
    min-height: auto;
    border-radius: 6px;
    /* background: var(--azul-700, #265587); */
    gap: 8px;
    margin: 8px 12px;
  }
  .MuiCollapse-wrapper .MuiListItemButton-root {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 16px;
    padding-left: 32px;
    display: flex;
    height: fit-content;
    max-height: fit-content;
    min-height: auto;
    border-radius: 6px;
    margin: auto;
    background: transparent;

    &:hover {
      color: #fff;

      .MuiListItemIcon-root svg {
        color: #fff;
      }
    }
  }
  .MuiCollapse-wrapper a,
  .MuiCollapse-wrapper .MuiListItemIcon-root svg {
    /* color: #67ce86; */
    color: #6b8392;
  }
  .MuiListItemText-root span {
    font-size: 16px;
    color: #f26a1d;
  }
  .MuiListItemIcon-root {
    margin-right: 0;
  }
  .MuiCollapse-wrapper .MuiListItemIcon-root {
    min-width: auto;
  }

  .MuiDivider-root {
    margin: 0 20px;
    border-color: #265587;
  }
`;

export const ButtonToggleDrawer = styled.div`
  width: 62px;
  transition: all 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  position: fixed;
  z-index: 1299;

  button.toggleDrawer {
    position: absolute;
    right: -20px;
    border-radius: 100%;
    background: var(--azul-100, #c9e8f6);
    width: 32px;
    height: 32px;
    color: #0d3a69;
    top: 48px;
    z-index: 1299;

    svg {
      color: #0d3a69;
    }
  }

  ${(props) => {
    if (props.open === "true") {
      return css`
        width: 300px;

        button.toggleDrawer {
          top: 64px;
        }
      `;
    }
  }}
`;

export const SidebarLogo = styled.div`
  display: flex;
  align-self: center;
  place-content: center;
  img {
    width: 216px;
    height: 64px;
  }

  ${(props) => {
    if (props.openDrawer !== "true") {
      return css`
        img {
          width: 42px;
          height: 42px;
        }
      `;
    }
  }}
`;

export const ListItem = styled(ListItemMaterial)`
  .MuiButtonBase-root {
    min-width: 202px;
  }
  ${(props) => {
    if (props.isactive === "true" && props.iscollapse !== "true") {
      return css`
        .MuiListItemButton-root {
          background: #f9922a;
          color: #fff;
        }
      `;
    }

    if (props.isactive === "true" && props.iscollapse === "true") {
      return css`
        .MuiListItemButton-root {
          background-color: rgb(249, 146, 42) !important;
          color: rgb(255, 255, 255);
          width: fit-content;
          padding-left: 14px !important;
          /* padding-right: 30px !important; */
        }
        .MuiListItemText-root {
          width: inherit;
          flex: none;
        }
        .MuiListItemText-root span,
        .MuiListItemIcon-root svg {
          color: #fff !important;
        }
      `;
    }
  }}

  ${(props) => {
    if (props.openDrawer && props.openDrawer !== "true") {
      return css`
        .MuiListItemText-root {
          display: none;
        }
      `;
    }
  }}
`;

export const CustomListItemButton = styled(ListItemButton)`
  ${(props) => {
    if (props.openCollapse && props.openCollapse !== "true") {
      return css`
        .MuiListItemIcon-root svg,
        .MuiListItemText-root span,
        .MuiDrawer-paper svg,
        .MuiSvgIcon-root {
          color: #fff !important;
        }
      `;
    }
  }}
`;

export const SidebarUserContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 16px 20px 20px;
  color: var(--grays-100, #f5f5f5);

  ${(props) => {
    if (props.open !== "true") {
      return css`
        padding-left: 8px;
      `;
    }
  }}

  .user {
    display: flex;
    gap: 12px;

    svg {
      font-size: 48px;
    }

    .userData {
      display: flex;
      flex-direction: column;
      /* Text md/Semibold */
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      line-height: 24px; /* 150% */
      .name {
        color: var(--grays-100, #f5f5f5);
        font-weight: 600;
      }
      .email {
        color: var(--neutral-600, #92a6c0);
        font-weight: 400;
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 165px;
      }
    }
  }

  .logout {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    padding: 8px;
    align-self: center;
    justify-content: center;
    cursor: pointer;

    font-size: 12px;

    svg {
      font-size: 16px;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.06);
      border-radius: 4px;
    }
  }
`;

export const FooterContainer = styled.div`
  padding: 40px;
  padding-bottom: 16px;
  margin: auto;
  display: flex;
  place-content: center;
  flex-direction: column;
  align-items: center;
`;

export const FooterImage = styled.img`
  width: 101px;
  margin-bottom: 14px;
`;

export const Copyright = styled.div`
  color: #686868;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;

  a {
    margin-left: 5px;
    color: #686868;
  }
`;
