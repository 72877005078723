// @ts-nocheck
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StatusContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

export const Row = styled.div`
  display: flex;
  gap: 20px;
`;
export const SelectWrapperDate = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;
export const SelectContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  max-width: 1400px;
  width: 73vw;
  /* border: 1px solid red; */
`;

export const SelectDate = styled.select`
  width: 557px;
  height: auto;
  padding: 8px 14px;
  border-radius: 6px;
  border: 1px solid var(--Gray-400, #b8b8b8);
  /* background: #f3f3f3; */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
`;

export const Label = styled.label`
  color: var(--Gray-900, var(--900, #383838));
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 8px;
`;

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  //   gap: 10px;
  margin-top: 30px;
`;

export const Select = styled.select`
  width: 557px;
  height: 40px;
  padding: 8px 14px;
  border-radius: 6px;
  background: ${props => (props.isView ? '#fff1e8' : '#fff')};
  border: ${props =>
    props.isView
      ? '1px solid var(--Light-Amarelo---light, #f9922a)'
      : '1px solid #4bbfef'};
  /* border: 1px solid #4bbfef;
  background: #fff; */
`;

export const DateInputField = styled.input`
  width: 557px;
  height: 40px;
  padding: 8px 14px;
  border-radius: 6px;
  border: 1px solid #4bbfef;
  background: #fff;
`;

export const FormLabel = styled.label`
  color: #383838;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin-top: 0px;
  margin-bottom: 8px;
  text-transform: uppercase;
`;

export const VersionStatus = styled.div`
  display: inline-block;
  padding: 5px 10px;
  background-color: #e0f7fa;
  border-radius: 4px;
  color: #00796b;
  margin-left: 10px;
`;