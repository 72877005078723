import React from 'react';
import styles from './styles';
import logoCria from '../../assets/image/logoCria.svg';
import laccanLogo from '../../assets/image/laccanLogo.svg';
import logoGov from '../../assets/image/logoGov.svg';
import orionLogo from '../../assets/image/orionLogo.png';
import { FooterText } from 'components/FooterText';


const FooterAlt = () => {
  return (
    <div style={styles.footer}>
      <div style={styles.imagesContainer}>
        <div>
          <img src={logoGov} style={styles.logoGov} alt="" />
        </div>

        <div>
          <img src={logoCria} style={styles.criaStyles} alt="" />
        </div>

        <div>
          <img src={laccanLogo} style={styles.laccanStyles} alt="" />
        </div>
        <div>
          <img src={orionLogo} style={styles.orionStyles} alt="" />
        </div>
      </div>

      <div style={styles.copyrightText}>
        <FooterText />
      </div>
    </div>
  );
};

export default FooterAlt;
