const styles = {
  topBar: {
    left: 0,
    right: 0,
    height: "112px",
    backgroundColor: "#fff",
    borderBottom: "1px solid var(--dark-laranja-dark, #F26A1D)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 16px",
  },
  logo: {
    width: "125px",
    height: "68px",
    flexShrink: 0,
  },
  userSection: {
    display: "flex",
    alignItems: "center",
  },
  avatarCircle: {
    width: "64px",
    height: "64px",
    flexShrink: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    backgroundColor: "#F26A1D",
  },
  avatarText: {
    color: "#FFF",
    textAlign: "center",
    fontFamily: "sans-serif",
    fontStyle: "normal",
    fontSize: "30px",
    fontWeight: 700,
    lineHeight: "40px",
    backgroundColor: "#F26A1D",
    marginBottom: "8px",
  },
  userText: {
    textAlign: "right",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: 300,
    lineHeight: "24px",
    color: "#F26A1D",
  },
  subtitle1: {
    color: "#F26A1D",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
  },
  secondLine: {
    color: "#F26A1D",
    textAlign: "right",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontSize: "20px",
    fontWeight: 600,
  },

  userTextContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  menuItem: {
    display: "flex",
    width: "218px",
    height: "54px",
    padding: "15px 16px",
    alignItems: "center",
    flexShrink: 0,
    borderRadius: "8px",
    marginBottom: "8px",
  },
  menuItemIcon: {
    fontSize: "24px",
    color: "#FFFFFF",
  },
  menuItemText: {
    color: "#FFFFFF",
    fontFamily: "sans-serif",
    fontStyle: "normal",
    fontSize: "20px",
    fontWeight: 300,
    lineHeight: "24px",
    marginLeft: "8px",
  },
  link: {
    textDecoration: "none",
  },
};

export default styles;
