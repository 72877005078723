import React, { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import * as S from "./styles";
import {
  DownloadButton,
  VisibilityButton,
} from "components/Buttons/ActionButton";
import { getAgentType, scrollToTop } from "utils";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";
import AgentType from "utils/agentType";
import { Tooltip } from "@mui/material";

const TableAgent = ({
  agentList,
  page = 1,
  totalPages,
  setPage,
}) => {
  const [checkedItems, setCheckedItems] = useState({});
  const agentType = AgentType();

  const handleCheckboxChange = (id) => {
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [id]: !prevCheckedItems[id],
    }));
  };

  const handleChange = (event, value) => {
    setPage(value);
    scrollToTop();
  };

  const count = totalPages;

  return (
    <S.TableContainer>
      <S.StyledTable>
        <S.StyledTableHead>
          <tr>
            <S.StyledTableHeaderCell>
              {/* <Checkbox
                indeterminate={Object.values(checkedItems).some((value) => value)}
                checked={Object.values(checkedItems).every((value) => value)}
              /> */}
            </S.StyledTableHeaderCell>
            <S.StyledTableHeaderCell>Nome</S.StyledTableHeaderCell>
            <S.StyledTableHeaderCell>Email</S.StyledTableHeaderCell>
            <S.StyledTableHeaderCell>
              Centro de distribuição
            </S.StyledTableHeaderCell>
            <S.StyledTableHeaderCell>Função</S.StyledTableHeaderCell>
            <S.StyledTableHeaderCell>Usuário</S.StyledTableHeaderCell>
            <S.StyledTableHeaderCell>Ação</S.StyledTableHeaderCell>
          </tr>
        </S.StyledTableHead>
        <S.StyledTableBody>
          {agentList?.map((row) => {
            return (
              <S.StyledTableRow key={row.id}>
                <S.StyledTableCell>
                  {/* <Checkbox
                    checked={checkedItems[row.id] || false}
                    onChange={() => handleCheckboxChange(row.id)}
                  /> */}
                </S.StyledTableCell>
                <S.StyledTableCell>{row.name}</S.StyledTableCell>
                <S.StyledTableCell>{row.user.email}</S.StyledTableCell>
                <S.StyledTableCell>{row?.work_city?.name}</S.StyledTableCell>
                <S.StyledTableCell>{row.role}</S.StyledTableCell>
                <S.StyledTableCell>
                  {getAgentType(row.agent_type)}
                </S.StyledTableCell>
                <S.StyledTableCell>
                  <S.ButtonsContainer>
                    {!["2", "6"].includes(agentType) && (
                      <Link to={`/agentes/${row.id}/editar`}>
                        <DownloadButton />
                      </Link>
                    )}
                    <Tooltip title="Visualizar agente">
                      <Link to={`/agentes/${row.id}`}>
                        <VisibilityButton />
                      </Link>
                    </Tooltip>
                  </S.ButtonsContainer>
                </S.StyledTableCell>
              </S.StyledTableRow>
            );
          })}
        </S.StyledTableBody>
      </S.StyledTable>
      <S.TableFooter>
        <Typography>
          Página {page} de {count}
        </Typography>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handleChange}
          renderItem={(item) => (
            <PaginationItem
              slots={{ previous: WestIcon, next: EastIcon }}
              {...item}
            />
          )}
        />
      </S.TableFooter>
    </S.TableContainer>
  );
};

export default TableAgent;
