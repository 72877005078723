import React, { useState } from "react";
import CaixaDeInfo from "../../Alerts/InfoBoxBirth";
import infoBotao from "assets/image/help.svg";
//import * as S from "./styles";


import styled from 'styled-components';

const InfoButtonBirth = () => {
    const [mostrarInfo, setMostrarInfo] = useState(false);

    return (
        <Container onMouseEnter={() => setMostrarInfo(true)} onMouseLeave={() => setMostrarInfo(false)}>
            <InfoBotao src={infoBotao} alt="Interrogação" />
            {mostrarInfo && <CaixaDeInfo />}
        </Container>
    );
};

const Container = styled.div`
    position: relative;
`;

const InfoBotao = styled.img`
    width: 26px;
    height: 26px;
    flex-shrink: 1;
    cursor: default; /* Alterado para cursor padrão */
    margin-left: 10px;
`;

export default InfoButtonBirth;
