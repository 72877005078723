import React from 'react';
import * as S from './styles';
import CompTextField from 'components/CompTextField';

const Forms = ({ isView, register, unregister, beneficiaryData }) => {

  return (
    <S.Form>
      <S.FormRowDatas>
        {/* Linha 1 */}
        <CompTextField
          isView={isView}
          fromNis={true}
          textLabel="DATA DE EXPIRAÇÃO DO BENEFÍCIO:"
          required
          enabled={false}
          style={{ flexGrow: '1' }}
          name="end_date"
          register={register}
          unregister={unregister}
          apiText={beneficiaryData?.end_date}
        />
        <CompTextField
          isView={isView}
          fromNis={true}
          textLabel="DATA LIMITE PARA INFORMAR PARTO:"
          required
          enabled={false}
          style={{ flexGrow: '1' }}
          name="limit_report"
          register={register}
          unregister={unregister}
          apiText={beneficiaryData?.limit_report}
        />
      </S.FormRowDatas>
      {/* {showBirthInformation && <FormsBirthInformation />} */}
    </S.Form>
  );
};

export default Forms;
