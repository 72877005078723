import styled from 'styled-components';

export const VersionStatus = styled.div`
   width: 228px;
    height: 42px;
    border-radius: 16px;
    border: 1px solid #0090CE;
    background: #99E1FF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 9px 30px 9px 30px;
    margin-left: 10px;

    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
`