// @ts-nocheck
import React, { useEffect, useState } from "react";
import CompSearchField from "components/CompSearchField";
import CompDropdown from "components/CompDropdown";
import TableBeneficiary from "components/TableBeneficiary";
import Title from "components/Title";
import * as S from "./styles";
import { useApi } from "hooks/useApis";
import { z } from "zod";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { CircularProgress } from "@mui/material";
import {
  distributionPoints,
  status_options,
  type_options,
  sort_options,
} from "utils/constants";
import Typography from "@mui/material/Typography";
import ExpirationWarning from "components/ExpirationWarning";
import CompAutocomplete from "components/CompAutocomplete";
import * as palette from "styles/variables"
import { DownloadReportsButton } from "components/DownloadReportsButton";

const BeneficiaryManagement = () => {
  const api = useApi();

  const [beneficiaryList, setBeneficiaryList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [changePage, setChangePage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [totalBenefits, setTotalBenefits] = useState(0);

  useEffect(() => {
    const savedFilters = JSON.parse(sessionStorage.getItem("cria-web@beneficiaryFilters"));
    if (savedFilters) {
      methods.reset(savedFilters);
    }

    // getBeneficiaryListData(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);


  const createBeneficiarySearchSchema = z.object({
    // Search Information
    b_status: z.string().optional(),
    b_type: z.string().optional(),
    search: z.string().optional(),
    sort: z.string().optional(),
    city: z.string().optional(),
  });

  const methods = useForm({
    resolver: zodResolver(createBeneficiarySearchSchema),
    mode: "all",
    criteriaMode: "all",
  });

  const b_status_value = methods.watch("b_status");
  const b_type_value = methods.watch("b_type");
  const search_value = methods.watch("search");
  const sort_value = methods.watch("sort");
  const city_value = methods.watch("city");

  const benefitFilters = {
    b_status: b_status_value,
    b_type: b_type_value,
    search: search_value,
    city: city_value
  };

  useEffect(() => {
    setChangePage(currentPage)
      methods.handleSubmit((data) => onSubmit(data, currentPage), onError)();
  }, [currentPage]);

  useEffect(() => {
    methods.handleSubmit((data) => onSubmit(data, 1), onError)();
  }, [b_status_value, b_type_value, sort_value, city_value]);

 
  async function onSubmit(data, pageNumber) {
    try {
      setLoading(true);
      const filteredList = await api.listBeneficiary(
        data.b_status,
        data.b_type,
        data.search,
        data.sort,
        data.city,
        pageNumber
      );

      setChangePage(pageNumber);
      setBeneficiaryList(filteredList?.results);
      setTotalPages(filteredList?.total_pages);

      sessionStorage.setItem("cria-web@beneficiaryFilters", JSON.stringify(data));
      setTotalBenefits(filteredList?.count);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  function onError(errors) {
    console.log("Failed to submit:", errors);
  }


  return (
    <S.PageContainer>
      <Title
        titleText="Gerência de beneficiário"
        subtitleTexts={["Início", " Gerência de beneficiário"]}
      />
      <S.PageContent>
        <S.MainContainer>
          <FormProvider {...methods}>
            <S.Form onSubmit={methods.handleSubmit((data) => onSubmit(data, 1), onError)}>
              <S.FormRowOutros>
                {/* Linha 1 */}

                <S.DateFieldsContainer style={{ width: '40vw' }}>
                  <S.DateFieldsTitle>BUSCA DE BENEFICIÁRIO</S.DateFieldsTitle>
                  <CompSearchField
                    placeholder="Digite o nome ou NIS ou CPF para buscar"
                    name="search"
                    register={methods.register}
                    unregister={methods.unregister}
                  />
                </S.DateFieldsContainer>

                <CompDropdown
                  style={{ flexGrow: "1" }}
                  defaultValue="0"
                  textLabel="TIPO DE BENEFÍCIO:"
                  options={type_options}
                  name={"b_type"}
                  // required={true}
                  register={methods.register}
                  unregister={methods.unregister}
                  id="test"
                />

              </S.FormRowOutros>

              <S.FormRowWithMargin>
                {/* Linha 2 */}
                <S.FormConteiner>
                  <CompDropdown
                    style={{ flexGrow: "1" }}
                    defaultValue="1"
                    textLabel="STATUS DO BENEFÍCIARIO"
                    options={status_options}
                    name={"b_status"}
                    register={methods.register}
                    unregister={methods.unregister}
                  />
                  {/* <CompDropdown
                  style={{ flexGrow: '1' }}
                  defaultValue=""
                  textLabel="MOTIVOS DE DESATIVAÇÃO"
                  options={[
                    "Criança fora do CadÚnico", 
                    "Responsável fora do CadÚnico",
                    "Gestante menor fora do CadÚnico",
                    "Responsável e criança não pertencem a mesma família",
                    "Responsável e gestante menor não pertencem a mesma família",
                    "Responsável menor de idade",
                    "Gestante menor acima da idade permitida",
                    "Criança com mais de 6 anos",
                    "Família com excedente de beneficiários",
                    "Responsável com mais de um cadastro",
                    "Gestante menor com mais de um cadastro",
                    "Criança com mais de um cadastro",
                    "Beneficiário fora do perfil de renda",
                    "Aborto",
                    "Óbito",
                  ]}
                /> */}
                  {/* <CompDropdown
                  style={{ flexGrow: '1' }}
                  // defaultValue="Selecione"
                  textLabel="MUNICÍPIO:"
                  options={distributionPoints}
                  name = {'city'}
                  register={methods.register}
                  unregister={methods.unregister}

                /> */}
                  <CompAutocomplete
                    style={{ flexGrow: "1" }}
                    defaultValue=""
                    textLabel="MUNICÍPIO:"
                    options={distributionPoints}
                    name={"city"}
                    placeholder="Selecione uma município"
                    register={methods.register}
                    unregister={methods.unregister}
                  />
                </S.FormConteiner>
              </S.FormRowWithMargin>
              <S.CabecalhoTabela>
                <div >
                  <Typography variant="h6">Lista de beneficiários</Typography>
                  <p style={{ fontWeight: 'normal' }} >{totalBenefits?.toLocaleString('pt-BR')} benefícios</p>
                </div>
                <CompDropdown
                  style={{ flexGrow: "0" }}
                  defaultValue="0"
                  // defaultValue="0"
                  options={sort_options}
                  name={"sort"}
                  register={methods.register}
                  unregister={methods.unregister}
                />
              </S.CabecalhoTabela>
            </S.Form>
          </FormProvider>

          {loading ? (
            <S.LoadingBoxContainer>
              <CircularProgress style={{ color: `${palette.lightAmarelo}` }} />
            </S.LoadingBoxContainer>
            // <LoadingBox/>
          ) : (
            <TableBeneficiary
              beneficiaryList={beneficiaryList}
              page={changePage}
              totalPages={totalPages}
              setPage={setCurrentPage}
            />
          )}

          <DownloadReportsButton benefitFilters={benefitFilters} />
          <ExpirationWarning />
        </S.MainContainer>
      </S.PageContent>
    </S.PageContainer>
  );
};

export default BeneficiaryManagement;
