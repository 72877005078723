import React, { useEffect, useState } from 'react';
import { useAuth } from 'hooks/useAuth';
import { Snackbar, Alert } from '@mui/material';

const ExpirationWarning = () => {
  const auth = useAuth();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (auth.expirationWarning) {
      setOpen(true);
    }
  }, [auth.expirationWarning]);

  const handleClose = () => {
    setOpen(false);
    auth.setExpirationWarning(false);
  };

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}
      sx={{
        width: '100%', // Adjust width as needed
        maxWidth: '800px', // Set a maximum width
        margin: '0 auto', // Center the Snackbar
      }}
    >
      <Alert onClose={handleClose} severity="warning"
          sx={{
            width: '100%',
            fontSize: '1.2rem', // Increase font size
            padding: '16px', // Increase padding
            boxSizing: 'border-box',
          }}
          >
      Data de expiração do acesso ao sistema está proxima, procure seu Coordenador.
      </Alert>
    </Snackbar>
  );
};

export default ExpirationWarning;
