import React, { useEffect } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { TextField, FormControl, Grid } from "@mui/material";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import * as palette from "../../styles/variables";
import CompDropdownMUI from "components/CompDropdownMUI";
import CompDateFieldMUI from "components/CompDateFieldMUI";
import { PatternFormat, NumericFormat } from "react-number-format";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import * as C from "utils/constants";
import CustomLabel from "components/Label";
import FormHelperText from "@mui/material/FormHelperText";

const theme = createTheme({
  palette: {
    primary: {
      main: palette.lightAzul,
    },
  },

  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          //   borderColor: palette.lightAzul,
        },
        root: {
          // background: 'white',
          [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: palette.lightAzul,
          },
          //   [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
          //     borderColor: palette.lightAzul,
          //   },
        },
      },
    },
  },
});
export const FormType1 = () => {
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext();
  const options = Array.from({ length: 13 }, (_, i) => ({
    value: `${i + 2}° Consulta Pré-Natal`,
    textLabel: `${i + 2}° Consulta Pré-Natal`,
  }));
  return (
    <ThemeProvider theme={theme}>
      <Grid container spacing={2} sx={{ maxWidth: "100%" }}>
        <Grid item md={4}>
          <CompDateFieldMUI
            name="check_last"
            textLabel="Data da última consulta de Pré Natal"
            sx={{ backgroundColor: "#F3F3F3" }}
          />
        </Grid>
        <Grid item md={4}>
          <CompDropdownMUI
            name="check_number"
            textLabel="N° da Consulta"
            options={options}
            required={true}
            placeholder="Escolha uma opção"
            rules={{ required: true }}
            helperText={"Campo Obrigatório."}
            sx={{ backgroundColor: "#F3F3F3" }}
          />
        </Grid>

        <Grid item md={4}>
          <CustomLabel textLabel="Idade Gestacional" />
          <FormControl fullWidth margin="normal" sx={{ marginTop: "2px" }}>
            <TextField
              type="number"
              placeholder="0"
              sx={{ backgroundColor: "#F3F3F3" }}
              inputProps={{ "aria-label": "Without label" }}
              size="small"
              {...register("current_gestational_age")}
            />
          </FormControl>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};
export const FormType2 = () => {
  const {
    control,
    register,
    watch,
    formState: { errors },
  } = useFormContext();

  const vaccine_name = watch("vaccine_name");
  const vaccine_dose = watch("vaccine_dose");

  return (
    <ThemeProvider theme={theme}>
      <Grid container spacing={2} sx={{ maxWidth: "100%" }}>
        <Grid item md={4}>
          <CompDropdownMUI
            name="vaccine_name"
            textLabel="Nome da Vacina"
            options={C.vaccines}
            required={true}
            rules={{ required: true }}
            helperText={"Campo Obrigatório."}
            placeholder="Escolha uma opção"
            sx={{ backgroundColor: "#F3F3F3" }}
          />
          {vaccine_name === "16" && (
            <div style={{ marginTop: "10px" }}>
              <CustomLabel textLabel="Outra vacina" required />
              <FormControl fullWidth margin="normal" sx={{ marginTop: "2px" }}>
                <Controller
                  rules={{ required: true }}
                  name="other_vaccine_name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      placeholder="nome da vacina"
                      sx={{ backgroundColor: "#F3F3F3" }}
                      error={!!errors["other_vaccine_name"]}
                      inputProps={{ "aria-label": "Without label" }}
                      size="small"
                      {...register("other_vaccine_name")}
                    />
                  )}
                />
                {errors["other_vaccine_name"] && (
                  <FormHelperText error>Campo Obrigatório.</FormHelperText>
                )}
              </FormControl>
            </div>
          )}
        </Grid>
        <Grid item md={4}>
          <CompDropdownMUI
            name="vaccine_dose"
            textLabel="Dose da Vacina"
            options={C.vaccines_doses}
            required={true}
            rules={{ required: true }}
            helperText={"Campo Obrigatório."}
            placeholder="Escolha uma opção"
            sx={{ backgroundColor: "#F3F3F3" }}
          />
          {vaccine_dose === "6" && (
            <div style={{ marginTop: "10px" }}>
              <CustomLabel textLabel="Outra dose" required />
              <FormControl fullWidth margin="normal" sx={{ marginTop: "2px" }}>
                <Controller
                  rules={{ required: true }}
                  name="other_vaccine_dose"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      placeholder="escreva a dose"
                      sx={{ backgroundColor: "#F3F3F3" }}
                      error={!!errors["other_vaccine_dose"]}
                      inputProps={{ "aria-label": "Without label" }}
                      size="small"
                      {...register("other_vaccine_dose")}
                    />
                  )}
                />
                {errors["other_vaccine_dose"] && (
                  <FormHelperText error>Campo Obrigatório.</FormHelperText>
                )}
              </FormControl>
            </div>
          )}
        </Grid>

        <Grid item md={4}>
          <CompDateFieldMUI
            name="vaccination_date"
            textLabel="Data da vacinação"
            sx={{ backgroundColor: "#F3F3F3" }}
          />
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export const FormType3 = () => {
  const {
    register,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useFormContext();
  const options1 = [
    { value: true, textLabel: "Preenchido" },
    { value: false, textLabel: "Não Preenchido" },
  ];

  const options2 = Array.from({ length: 9 }, (_, i) => ({
    value: `${i + 1} Marcações`,
    textLabel: `${i + 1} Marcações`,
  }));
  const weight = watch("weight");
  const height = watch("height");
  const calculateIMC = () => {
    if (weight && height) {
      const weightInKg = parseFloat(weight);
      const heightInMeters = parseFloat(height);

      if (
        !isNaN(weightInKg) &&
        !isNaN(heightInMeters) &&
        heightInMeters !== 0
      ) {
        const imcValue = (
          weightInKg /
          (heightInMeters * heightInMeters)
        ).toFixed(2);
        setValue("imc", imcValue);
      } else {
        setValue("imc", null);
      }
    } else {
      setValue("imc", null);
    }
  };

  useEffect(() => {
    calculateIMC();
  }, [weight, height]);
  return (
    <ThemeProvider theme={theme}>
      <Grid container spacing={2} sx={{ maxWidth: "100%" }}>
        <Grid item md={4}>
          <CustomLabel textLabel="Aferição de Peso" />
          <FormControl fullWidth margin="normal" sx={{ marginTop: "2px" }}>
            <Controller
              name="weight"
              control={control}
              render={({ field }) => (
                <NumericFormat
                  {...field}
                  customInput={TextField}
                  decimalScale={1}
                  fixedDecimalScale={false}
                  placeholder="Peso em kg"
                  sx={{ backgroundColor: "#F3F3F3" }}
                  inputProps={{ "aria-label": "Without label" }}
                  size="small"
                  format={(value) =>
                    value.replace(/^(\d{1})(\.\d{0,1})?.*/, "$1$2")
                  }
                  onValueChange={({ floatValue }) => field.onChange(floatValue)}
                  isNumericString
                />
              )}
            />
          </FormControl>
        </Grid> 
        <Grid item md={4}>
          <CustomLabel textLabel="Altura" />
          <FormControl fullWidth margin="normal" sx={{ marginTop: "2px" }}>
            <Controller
              name="height"
              control={control}
              render={({ field }) => (
                <PatternFormat
                  {...field}
                  customInput={TextField}
                  format="#.##"
                  mask="_"
                  placeholder="Altura em metros"
                  sx={{ backgroundColor: "#F3F3F3" }}
                  inputProps={{ "aria-label": "Without label" }}
                  size="small"
                  onValueChange={({ floatValue }) => field.onChange(floatValue)}
                  // Permitir a movimentação com as setas
                  isNumericString
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item md={4}>
          <CustomLabel textLabel="IMC Gestacional" />
          <FormControl fullWidth margin="normal" sx={{ marginTop: "2px" }}>
            <TextField
              type="number"
              disabled
              placeholder="imc"
              inputProps={{ "aria-label": "Without label" }}
              size="small"
              {...register("imc")}
            />
          </FormControl>
        </Grid>
        <Grid item md={4}>
          <CompDropdownMUI
            name="nutritional_graph_check"
            textLabel="Gráfico Acomp.Nutricional"
            options={options1}
            required={true}
            placeholder="Escolha uma opção"
            sx={{ backgroundColor: "#F3F3F3" }}
          />
        </Grid>
        <Grid item md={4}>
          <CompDropdownMUI
            name="mark_count"
            textLabel="Quantas Marcações"
            options={options2}
            required={true}
            rules={{ required: true }}
            helperText={"Campo Obrigatório."}
            placeholder="Escolha uma opção"
            sx={{ backgroundColor: "#F3F3F3" }}
          />
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};
export const FormType5 = () => {
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext();
  const opShowdUp = [
    { value: true, textLabel: "Sim" },
    { value: false, textLabel: "Não" },
  ];
  return (
    <ThemeProvider theme={theme}>
      <Grid container spacing={2} sx={{ maxWidth: "100%" }}>
        <Grid item md={4}>
          <CompDateFieldMUI
            name="check_current"
            textLabel="Data Acompanhamento CRAS"
            placeholder="dd/mm/aaaa"
            sx={{ backgroundColor: "#F3F3F3" }}
          />
        </Grid>

        <Grid item md={4}>
          <CompDropdownMUI
            name="showed_up"
            textLabel="Compareceu?"
            options={opShowdUp}
            required={true}
            placeholder="Selecione"
            sx={{ backgroundColor: "#F3F3F3" }}
          />
        </Grid>
        <Grid item md={12}>
          <CustomLabel textLabel="Observação" />
          <FormControl fullWidth margin="normal" sx={{ marginTop: "2px" }}>
            <TextField
              multiline
              placeholder="Digite aqui"
              inputProps={{ "aria-label": "Without label" }}
              rows={2}
              {...register("observation")}
            />
          </FormControl>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};
export const FormType6 = () => {
  const { control, register } = useFormContext();
  const options = Array.from({ length: 13 }, (_, i) => ({
    value: `${i + 2}`,
    textLabel: `${i + 2}° Consulta Puericultura`,
  }));

  return (
    <ThemeProvider theme={theme}>
      <Grid container spacing={2} sx={{ maxWidth: "100%" }}>
        <Grid item md={4}>
          <CompDateFieldMUI
            name="check_last"
            textLabel="Data da última consulta"
            sx={{ backgroundColor: "#F3F3F3" }}
          />
        </Grid>

        <Grid item md={4}>
          <CompDropdownMUI
            name="check_number"
            textLabel="N° da consulta"
            options={options}
            required={true}
            rules={{ required: true }}
            helperText={"Campo Obrigatório."}
            placeholder="Escolha o N° da consulta"
            sx={{ backgroundColor: "#F3F3F3" }}
          />
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};
export const FormType7 = () => {
  const {
    register,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useFormContext();
  const options1 = [
    { value: true, textLabel: "Preenchido" },
    { value: false, textLabel: "Não Preenchido" },
  ];

  const options2 = Array.from({ length: 9 }, (_, i) => ({
    value: `${i + 1} Marcações`,
    textLabel: `${i + 1} Marcações`,
  }));
  const weight = watch("weight");
  const height = watch("height");
  const calculateIMC = () => {
    if (weight && height) {
      const weightInKg = parseFloat(weight);
      const heightInMeters = parseFloat(height);

      if (
        !isNaN(weightInKg) &&
        !isNaN(heightInMeters) &&
        heightInMeters !== 0
      ) {
        const imcValue = (
          weightInKg /
          (heightInMeters * heightInMeters)
        ).toFixed(2);
        setValue("imc", imcValue);
      } else {
        setValue("imc", "");
      }
    } else {
      setValue("imc", "");
    }
  };

  useEffect(() => {
    calculateIMC();
  }, [weight, height]);
  return (
    <ThemeProvider theme={theme}>
      <Grid container spacing={2} sx={{ maxWidth: "100%" }}>
        <Grid item md={4}>
          <CustomLabel textLabel="Aferição de Peso" />
          <FormControl fullWidth margin="normal" sx={{ marginTop: "2px" }}>
            <Controller
              name="weight"
              control={control}
              render={({ field }) => (
                <NumericFormat
                  {...field}
                  customInput={TextField}
                  decimalScale={2}
                  fixedDecimalScale
                  placeholder="Peso em kg"
                  sx={{ backgroundColor: "#F3F3F3" }}
                  inputProps={{ "aria-label": "Without label" }}
                  size="small"
                  // Para permitir até 3 dígitos antes do decimal e 2 dígitos após o decimal
                  format={(value) =>
                    value.replace(/^(\d{1,3})(\.\d{0,2})?.*/, "$1$2")
                  }
                  onValueChange={({ floatValue }) => field.onChange(floatValue)}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item md={4}>
          <CustomLabel textLabel="Altura" />
          <FormControl fullWidth margin="normal" sx={{ marginTop: "2px" }}>
            <Controller
              name="height"
              control={control}
              render={({ field }) => (
                <PatternFormat
                  {...field}
                  customInput={TextField}
                  format="#.##"
                  mask="_"
                  placeholder="Altura em metros"
                  sx={{ backgroundColor: "#F3F3F3" }}
                  inputProps={{ "aria-label": "Without label" }}
                  size="small"
                  onValueChange={({ floatValue }) => field.onChange(floatValue)}
                  // Permitir a movimentação com as setas
                  isNumericString
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item md={4}>
          <CustomLabel textLabel="IMC" />
          <FormControl fullWidth margin="normal" sx={{ marginTop: "2px" }}>
            <TextField
              type="number"
              disabled
              placeholder="imc"
              inputProps={{ "aria-label": "Without label" }}
              size="small"
              {...register("imc")}
            />
          </FormControl>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};
export const FormType8 = ({pregnant}) => {
  const { control, register, watch } = useFormContext();

  const type_justification = watch("type_justification");
  const opJustification = () => {
    if (type_justification) {
      const options = pregnant? C.justification_types_pregnant : C.justification_types_child
      const option = options.find(
        (j) => j.value === type_justification
      );
      return option.justifications;
    }
    return;
  };

  const opMonth = Array.from({ length: 9 }, (_, i) => ({
    value: `${i + 1}° Mês de Acompanhamento`,
    textLabel: `${i + 1}° Mês de Acompanhamento`,
  }));

  return (
    <ThemeProvider theme={theme}>
      <Grid container spacing={2} sx={{ maxWidth: "100%" }}>
        <Grid item md={12}>
          <CompDropdownMUI
            name="type_justification"
            textLabel="Escolha o tipo da justificativa"
            options={pregnant? C.justification_types_pregnant : C.justification_types_child}
            placeholder="Selecione"
            required={true}
            rules={{ required: true }}
            helperText={"Campo Obrigatório."}
            sx={{ backgroundColor: "#F3F3F3" }}
          />
        </Grid>

        {type_justification !== null && (
          <>
            <Grid item md={6}>
              <CompDropdownMUI
                name="justification"
                textLabel="Escolha a Justificativa"
                options={opJustification()}
                required={true}
                rules={{ required: true }}
                helperText={"Campo Obrigatório."}
                placeholder="Selecione"
                sx={{ backgroundColor: "#F3F3F3" }}
              />
            </Grid>

            <Grid item md={6}>
              <CompDropdownMUI
                name="month_justification_choice"
                textLabel="Mês de acompanhamento da justificativa"
                options={opMonth}
                required={true}
                rules={{ required: true }}
                helperText={"Campo Obrigatório."}
                placeholder="Selecione"
                sx={{ backgroundColor: "#F3F3F3" }}
              />
            </Grid>
          </>
        )}
      </Grid>
    </ThemeProvider>
  );
};
