// import { update } from "cypress/types/lodash";
import { textsCria, irregularities } from "./constants";
import { brDate } from "./formatDate";

export const getBeneficiaryType = (type) => {
  switch (type) {
    case "1":
      return textsCria.pregnant;
    case "2":
      return textsCria.childWithSindrome;
    case "3":
      return textsCria.child;
    case "4":
      return textsCria.twins;
    default:
      return "";
  }
};

export const getBeneficiaryStatus = (type) => {
  switch (type) {
    case "0":
      return "Cancelado";
    case "1":
      return "Ativo";
    case "2":
      return "Advertência";
    case "3":
      return "Bloqueado'";
    case "4":
      return "Suspenso";
    default:
      return null;
  }
};

export const getAgentStatus = (type) => {
  switch (type) {
    case "0":
      return "Inativo";
    case "1":
      return "Ativo";
    default:
      return null;
  }
};

export const beneficiaryTypeToApi = (type) => {
  switch (type) {
    case textsCria.pregnant:
      return "1";
    case textsCria.childWithSindrome:
      return "2";
    case textsCria.child:
      return "3";
    case textsCria.twins:
      return "4";
    default:
      return "";
  }
};

export const convertDateFromApi = (date) => {
  if (!date) return null;

  if (date.includes("-")) {
    const [year, month, day] = date.split("-");
    return new Date(year, month - 1, day); // Month is -1 because it starts from 0
  } else if (date.includes("/")) {
    const [day, month, year] = date.split("/");
    return new Date(year, month - 1, day); // Month is -1 because it starts from 0
  }
};

export const convertDateToApi = (date) => {
  const dateObj = convertDateFromApi(date);

  if (!dateObj || isNaN(dateObj.getTime())) {
    return null;  
  }
  return dateObj.toISOString().split("T")[0];
};

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

export const getIrregularityTypeLabel = (value) => {
  const type = irregularities.find((item) => item.value === value);
  return type ? type.textLabel : "Desconhecido";
};

export const pregnantFormDefaultValues = (data) => {
  const dataObject = {};
  const personObject = {};
  const familyObject = {};
  const nutritionalDataObject = {};
  // Primeiro loop - objeto raiz
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      dataObject[key] = data[key];
    }
  }

  // Segundo loop - objeto "person"
  for (const key in data?.person) {
    if (data.person.hasOwnProperty(key)) {
      // Corrigido para verificar 'data.person'
      personObject[key] = data.person[key]; // Corrigido para acessar 'data.person[key]'
    }
  }

  // Terceiro loop - objeto "family"
  for (const key in data?.person?.family) {
    if (data.person.family.hasOwnProperty(key)) {
      // Corrigido para verificar 'data.person.family'
      familyObject[key] = data.person.family[key]; // Corrigido para acessar 'data.person.family[key]'
    }
  }

  // Quarto loop - objeto "nutritional_data"
  for (const key in data?.nutritional_data) {
    if (data.nutritional_data.hasOwnProperty(key)) {
      // Corrigido para verificar 'data.nutritional_data'
      nutritionalDataObject[key] = data.nutritional_data[key]; // Corrigido para acessar 'data.nutritional_data[key]'
    }
  }

  const defaultValues = {
    ...dataObject,
    ...personObject,
    ...familyObject,
    ...nutritionalDataObject,
    id: dataObject.id,
    bf: data.bf ? "Sim" : "Não",
    bvg: data.bvg ? "Sim" : "Não",
    bcn: data.bcn ? "Sim" : "Não",
    pcf: data.pcf ? "Sim" : "Não",
    bpc: data.bpc ? "Sim" : "Não",
    paif: data.paif ? "Sim" : "Não",
    paefi: data.paefi ? "Sim" : "Não",
    pl: data.pl ? "Sim" : "Não",
    mcmv: data.mcmv ? "Sim" : "Não",
    weight: data.nutritional_data?.weight
      ? String(data.nutritional_data?.weight)
      : "",
    height: data.nutritional_data?.height
      ? String(data.nutritional_data?.height)
      : "",
    updated: data.person?.updated ? "Sim" : "Não",
    dpp: brDate(data.nutritional_data?.dpp),
    end_date: brDate(data?.end_date),
    limit_report: brDate(data?.limit_report),
  };
  return defaultValues;
};

export const agentFormDefaultValues = (data) => {
  const defaultValues = {
    ...data,
    work_location: data?.work_city?.name
  }
  return defaultValues;
};

export const childFormDefaultValues = (data) => {
  const dataObject = {};
  const personObject = {};
  const familyObject = {};

  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      dataObject[key] = data[key];
    }
  }

  // Segundo loop - objeto "person"
  for (const key in data?.person) {
    if (data.person.hasOwnProperty(key)) {
      // Corrigido para verificar 'data.person'
      personObject[key] = data.person[key]; // Corrigido para acessar 'data.person[key]'
    }
  }

  // Terceiro loop - objeto "family"
  for (const key in data?.person?.family) {
    if (data.person.family.hasOwnProperty(key)) {
      // Corrigido para verificar 'data.person.family'
      familyObject[key] = data.person.family[key]; // Corrigido para acessar 'data.person.family[key]'
    }
  }

  const defaultValues = {
    ...dataObject,
    ...personObject,
    ...familyObject,
    id: dataObject.id,
    bf: data.bf ? "Sim" : "Não",
    bvg: data.bvg ? "Sim" : "Não",
    bcn: data.bcn ? "Sim" : "Não",
    pcf: data.pcf ? "Sim" : "Não",
    bpc: data.bpc ? "Sim" : "Não",
    paif: data.paif ? "Sim" : "Não",
    paefi: data.paefi ? "Sim" : "Não",
    pl: data.pl ? "Sim" : "Não",
    mcmv: data.mcmv ? "Sim" : "Não",
    updated: data.person?.updated ? "Sim" : "Não",
    end_date: brDate(data?.end_date),
  };
  return defaultValues;
};

export const getAgentType = (type) => {
  switch (type) {
    case "1":
      return "Gestão";
    case "2":
      return "Coordenador Municipal";
    case "3":
      return "Agente Municipal";
    case "4":
      return "Coordenador Estadual";
    case "5":
      return "Cheat";
    case "6":
      return "Convidado";
    default:
      return "";
  }
};

export const getBeneficiaryListSort = (sort) => {
  switch (sort) {
    case "0":
      return "name";
    case "1":
      return "-name";
    case "2":
      return "-registration_date";
    case "3":
      return "registration_date";
    default:
      return "name";
  }
};

export const getAgentListSort = (sort) => {
  switch (sort) {
    case "0":
      return "ordering=name&";
    case "1":
      return "ordering=-name&";
  }
};

export const calculateDpp = (dum) => {
  const today = new Date();
  const dataDum = new Date(dum);
  const dpp = new Date(dataDum);
  dpp.setDate(dpp.getDate() + 280 + 1); // 280 dias de gestação (+1 pra corrigir o offset de timezone )

  const milisecondDiff = today.getTime() - dataDum.getTime();

  const weekDiff = Math.floor(milisecondDiff / (1000 * 60 * 60 * 24 * 7));
  const monthDiff = Math.floor(milisecondDiff / (1000 * 60 * 60 * 24 * 7 * 4));

  return { dpp, weekDiff, monthDiff };
};
export const getColorRegionAdmin = (regiaoId) => {
  // eslint-disable-next-line default-case
  switch (regiaoId) {
    case 1:
      return "#5FE487";
    case 2:
      return "#e9e5cb";
    case 3:
      return "#36BCFF";
    case 4:
      return "#99F1EC";
    case 5:
      return "#FFB55E";
    case 6:
      return "#FFF743";
    case 7:
      return "#FF8FC5";
  }
};

export const getIndicatorsByType = (data, type) => {
  switch (type) {
    case "cidade":
      return Object.groupBy(data, ({ city }) => city);
    case "genero":
      return Object.groupBy(data, ({ gender_text }) => gender_text);
    case "domicilio":
      return Object.groupBy(
        data,
        ({ type_of_domicile_text }) => type_of_domicile_text
      );
    case "educacao":
      return Object.groupBy(
        data,
        ({ education_level_text }) => education_level_text
      );
    case "situacao-rua":
      return Object.groupBy(data, ({ street_situation }) => street_situation);
    default:
      return data;
  }
};

export const coloursProgressMap = {
  red: {
    colour: "#EE3B5E",
    backgroundColor: "rgba(238, 59, 94, 0.32)",
  },
  blue: {
    colour: "#4BBFEF",
    backgroundColor: "rgba(75, 191, 239, 0.32)",
  },
  green: {
    colour: "#88C54B",
    backgroundColor: "rgba(136, 197, 75, 0.32)",
  },
  orange: {
    colour: "#F9922A",
    backgroundColor: "rgba(249, 146, 42, 0.32)",
  },
};

export const getColorProgressMap = (percentage, hasFourColors) => {
  if (hasFourColors) {
    if (Number(percentage.toFixed(0)) >= 75) {
      return coloursProgressMap["green"];
    }
    if (Number(percentage.toFixed(0)) >= 50) {
      return coloursProgressMap["blue"];
    }
    if (Number(percentage.toFixed(0)) >= 25) {
      return coloursProgressMap["orange"];
    }
    return coloursProgressMap["red"];
  }

  if (Number(percentage.toFixed(0)) >= 66) {
    return coloursProgressMap["green"];
  }
  if (Number(percentage.toFixed(0)) >= 33) {
    return coloursProgressMap["blue"];
  }
  return coloursProgressMap["red"];
};

export const formatAndGetIndicatores = (data, typeLabel) => {
  let auxiliar = Object.entries(
    data[typeLabel].reduce(
      (acc, current) => ({ ...acc, [current.city]: current.data }),
      {}
    )
  )
    .map(([_key, dataValue]) => dataValue)
    .flat();

  let dataWithoutTodos = Object.fromEntries(
    Object.entries(
      data[typeLabel].reduce(
        (acc, current) => ({ ...acc, [current.city]: current.data }),
        {}
      )
    ).map(([key, dataValue]) => [
      key,
      dataValue.map((item) => ({
        label: item[`${typeLabel}_text`],
        value: item.count,
      })),
    ])
  );

  auxiliar = getIndicatorsValuesAllRoute(auxiliar, `${typeLabel}_text`);

  let all = dataWithoutTodos;
  all["Todos"] = auxiliar;

  if (typeLabel === "b_status") {
    all["Todos"].push({ label: "TOTAL", value: Number(data.total) });
  }

  return all;
};

export const getIndicatorsValuesAllRoute = (data, typeLabel, unknownLabel) => {
  let results = data.reduce(function (acc, element) {
    const type = element[typeLabel];
    if (type === "") {
      return acc;
    }
    const found = acc.findIndex((t) => t.label === type);

    if (found !== -1) {
      acc[found].value = acc[found].value + element.count;
    } else {
      acc = [...acc, { label: type, value: element.count }];
    }
    return acc;
  }, []);

  return results;
};

export const getIndicatorsValues = (data, typeLabel, unknownLabel) => {
  let results = data.reduce(function (r, element) {
    const type =
      element[typeLabel].toLowerCase() || unknownLabel || "Não Informado";
    const found = r.findIndex((t) => t.label === type);

    if (found !== -1) {
      r[found].value = r[found].value + 1;
    } else {
      r = [...r, { label: type, value: 1 }];
    }
    return r;
  }, []);

  return results;
};

export const getBeneficiaryStatusFromCityMap = (data, isAllRoute = false) => {
  if (!data) {
    return { ativo: 0, suspenso: 0 };
  }
  if (isAllRoute) {
    return {
      ativo: data.find((s) => s.label === "ATIVO")?.value,
      suspenso: data.find((s) => s.label === "SUSPENSO")?.value,
    };
  }
  return data.reduce(function (r, element) {
    const type = element["b_status_text"].toLowerCase() || "Não Informado";
    if (r[type]) {
      r[type] = r[type] + 1;
    } else {
      r[type] = 1;
    }
    return r;
  }, {});
};

export const getColorRangeMapSubtitle = (
  maxNumber,
  minNumber,
  actives
  // values,
  // currentCity,
  // isAllRoute = false
) => {
  // let maxNumber = 0;
  // let minNumber = 0;

  // let cities = {};

  // Object.keys(values).forEach((cityMap) => {
  //   const actives =  getBeneficiaryStatusFromCityMap(
  //     values[cityMap],
  //     isAllRoute
  //   ).ativo;
  //   console.log("actives", actives);
  //   if (actives && actives > maxNumber) {
  //     maxNumber = actives;
  //   }

  //   if (actives && actives < minNumber) {
  //     minNumber = actives;
  //   }

  //   cities[cityMap] = actives;
  // });

  // if (!cities[currentCity]) {
  //   return { color: "#9E9E9E" };
  // }

  // const actives = getBeneficiaryStatusFromCityMap(
  //   values[currentCity],
  //   isAllRoute
  // ).ativo;

  const percentage =
    10 + ((actives - minNumber) / (maxNumber - minNumber)) * 90;

  if (isNaN(percentage)) {
    return {
      beneficiariosAtivos: 0,
      fillColor: "rgba(158, 158, 158, 0.7)",
      color: "#9E9E9E",
    };
  }

  if (Number(percentage.toFixed(1)) >= 83.33) {
    return {
      beneficiariosAtivos: actives,
      fillColor: "rgba(0, 119, 193, 0.85)",
      color: "#0077C1",
    };
  }
  if (Number(percentage.toFixed(1)) >= 66.66) {
    return {
      beneficiariosAtivos: actives,
      fillColor: "rgba(0, 143, 236, 0.85)",
      color: "#008FEC",
    };
  }
  if (Number(percentage.toFixed(1)) >= 50) {
    return {
      beneficiariosAtivos: actives,
      fillColor: "rgba(52, 173, 246, 0.85)",
      color: "#34ADF6",
    };
  }
  if (Number(percentage.toFixed(1)) >= 33.33) {
    return {
      beneficiariosAtivos: actives,
      fillColor: "rgba(104, 194, 251, 0.85)",
      color: "#68C2FB",
    };
  }
  if (Number(percentage.toFixed(1)) >= 16.67) {
    return {
      beneficiariosAtivos: actives,
      fillColor: "rgba(149, 215, 255, 0.85)",
      color: "#95D7FF",
    };
  }
  return {
    beneficiariosAtivos: actives,
    fillColor: "rgba(182, 231, 251, 0.85)",
    color: "#B6E7FB",
  };
};

export const metadataOptions = (data) => {
  let newMetadata = {};

  Object.keys(data).forEach((key) => {
    const keyName = `${key}_text`;
    const currentMetadata = data[key]
      .filter((curr) => !(curr.id === "" || curr[keyName] === ""))
      .map((current) => {
        if (key === "street_situation") {
          return current.id === "f"
            ? {
                value: "f",
                label: "Não",
              }
            : {
                value: "t",
                label: "Sim",
              };
        }

        if (key === "b_status" || key === "b_type") {
          return {
            value: current[key],
            label: current[keyName],
          };
        }

        return {
          value: current.id,
          label: current[keyName],
        };
      });
    newMetadata[key] = currentMetadata;
  });
  console.log(newMetadata);

  return newMetadata;
};

export const sortArrayByProperty = (array, property) => {
  return array.sort((a, b) => a[property].localeCompare(b[property]));
};

