import bgAltSenha from '../../assets/image/bg-alterar-senha.svg';
import styled from 'styled-components';
import OutlinedInput from '@mui/material/OutlinedInput';

export const AlterarSenhaContainer = styled.div`
  background: url(${bgAltSenha});
  position: fixed;
  background-repeat: no-repeat;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background-size: cover;
  background-position: center;
  z-index: 1;
`;

export const Botoes = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  //background: red;
  margin-right: 20px;
  gap: 2vw;
`;

export const AlterarSenhaBox = styled.div`
  width: 1001px;
  height: 519px;
  border-radius: 8px;
  background: rgba(75, 191, 239, 0.24);
`;

export const HeaderText = styled.div`
  width: 1001px;
  height: 100px;
  color: #f26a1d;
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ContentContainer = styled.div`
  width: 1001px;
  height: 419px;
  display: flex;
`;
export const AlterarSenhaForms = styled.div`
  width: 50%;
  height: 419px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;
export const InputConteiner = styled.div`
  width: 416px;
  height: 88px;
`;

export const RecomendacoesConteiner = styled.div`
  width: 48%;
  height: 419px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  h3 {
    color: var(--dark-preto; #222);
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    -w: 700;
    line-height: 32px;
    padding-top: 20px;
    width: 70%;
    padding-left: 60px;
  }
  p {
    fontfamily: Roboto;
    font-size: 16px;
    line-height: 24px;
    width: 387px;
    padding-left: 60px;
    font-weight: 300;
    color: var(--dark-preto, #222);
  }
`;
export const CustomOutlinedInput = styled(OutlinedInput)`
  width: 416px;
  background: #fff;
  border-radius: 18px;
  border: 1px solid var(--light-azul-light, #4bbfef);
  height: 56px;
  padding: 16px;

  input {
    height: 12px;
    font-size: 16px;
  }
`;
export const styles = {};