import styled from 'styled-components';

export const Box = styled.div`
    position: absolute;
    width: 328px;
    height: 90px;
    border-radius: 8px;
    border: 1px solid var(--Dark-Azul---dark, #0090CE);
    background: rgba(75,191,239,0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    z-index: 1;
    top: 5px;
    left: 50px;
`

export const AlertTitle = styled.h2`
    color: var(--Dark-Preto, #222);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px; /* 150% */
`;
