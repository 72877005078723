import styled from 'styled-components';

export const AlertBox = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 8px;
    border: 1px solid var(--dark-laranja-dark, #F26A1D);
    background: var(--light-background, #FFF1E8);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    height: 30vh;
`

export const AlertTitle = styled.h2`
    color: var(--dark-laranja-dark, #F26A1D);
    text-align: center;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 10px;
`;
