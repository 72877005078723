import * as React from 'react';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import Button from '@mui/material/Button';

const ActivationAgent = ({ onClick, isLoading }) => {
  return (
    <Button
      onClick={onClick}
      isLoading={isLoading}
      variant="contained"
      startIcon={<CheckBoxOutlinedIcon />}
      sx={{
        background: "var(--Light-Verde---light, #88C54B)",
        width: "236px",
        height: "54px",
        padding: "15px 16px",
        borderRadius: "8px",
        fontFamily: 'Poppins, sans-serif',
        fontFize: '20px',
        fontWeight: 300,
        textTransform: 'none',

        '&:hover': {
          backgroundColor: "#88C54B",
        },
      }}
    >
      Ativar agente
    </Button>
  );
};

export default ActivationAgent;
