import * as React from 'react';
import { SubmitButton } from './SubmitButton';


export const CreateAgentBtn = ({ 
  alertType, 
  setAlertType,
  isLoading = false, }) => {
  
  return (
      <SubmitButton
        alertType={alertType}
        setAlertType={setAlertType}
        isLoading={isLoading}
        buttonText="Criar agente"
        routeToGoBack="/agentes"
        successMsg="Sucesso ao criar agente."
        sx={{ width: "236px" }}
      />
  );
};


