const styles = {
  textFieldContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  textFieldLabel: {
    color: "var(--Gray-900, var(--900, #383838))",
    fontFamily: "Roboto",
    fontSize: "1.3rem",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "1.5rem",
    marginTop: "0px",
    paddingTop: "0px",
  },
  textField: {
    height: "20px",
    padding: "10px 14px",
    borderRadius: "6px",
    border: "1px solid var(--Light-blue---light, #4BBFEF)",
    background: "var(--Dark-white, #FFF)",
    fontFamily: "Roboto",
    fontSize: "1rem", //18px
    fontWeight: 300,
  },
  disabledContainer: {
    display: "inline-flex",
    padding: "8px 14px",
    //justifyContent: 'center',
    alignItems: "center",
    borderRadius: "6px",
    border: "1px solid var(--Gray-400, #B8B8B8)",
    background: "#F3F3F3",
  },
  viewContainer: {
    display: "inline-flex",
    padding: "8px 14px",
    //justifyContent: 'center',
    alignItems: "center",
    borderRadius: "6px",
    border: "1px solid #F9922A",
    background: "#FFF1E8",
  },
  apiData: {
    display: "flex",
    height: "24px",
    flexDirection: "column",
    justifyContent: "center",
    fontFamily: "Roboto",
    fontSize: "1rem", //18px
    fontWeight: 300,
  },
  requiredIndicator: {
    color: "var(--Dark-red---dark, #E02E1A)",
    fontFamily: "Roboto",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "24px",
    textTransform: "uppercase",
    marginLeft: "4px",
  },
  heightNormalizer: {
    height: "1.5rem",
  },
};

export default styles;
