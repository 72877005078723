import styled from 'styled-components';
import { Button as MuiButton, LinearProgress as MuiLinearProgress } from '@mui/material';

export const PageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const PageContent = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 80px;
`;

export const MainContainer = styled.div`
  flex: 1;
  padding-bottom: 100px;
  position: relative;
`;

export const FormDate = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  gap: 70px;
  align-items: flex-start;
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RightContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 80px;
  margin-rigth: 80px;
  padding: 20px 80px;
`;

export const FormLabel = styled.label`
  color: var(--Gray-900, var(--900, #383838));
  font-family: Roboto;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 8px;
`;

export const DateInputField = styled.input`
  display: inline-flex;
  width: 500px;
  height: 40px;
  padding: 8px 14px;
  border-radius: 6px;
  border: 1px solid var(--Light-Azul---light, #4BBFEF);
  background: var(--Dark-Branco, #FFF);
  text-align: left; /* Alinha o texto dentro da caixa de entrada à esquerda */
`;

export const LinearProgress = styled(MuiLinearProgress)`
  width: 100%;
  margin-top: 10px;
`;

export const DownloadButtonContainer = styled(MuiButton)`
  width: 250px;
  height: 50px;
  background-color: var(--Light-Verde---light, #88C54B);
  color: white;
  font-size: 16px;
  font-weight: bold;
  &:hover {
    background-color: var(--Light-Verde---dark, #70AC17);
  }
`;
