import React, { useEffect } from 'react';
import * as S from './styles';
import CompTextField from 'components/CompTextField';
import CompDateField from 'components/CompDateField';
import { useApi } from 'hooks/useApis';
import { calculateDpp, convertDateFromApi } from 'utils';

const Forms = ({isEdit,isView, beneficiaryData=null, register, unregister, watch, setValue, errors}) => {

  const peso =isView? beneficiaryData?.nutritional_data?.weight :watch('weight');
  const altura = isView? beneficiaryData?.nutritional_data?.height: watch('height');
  const imc = watch('imc');
  const dum =  isView? beneficiaryData?.nutritional_data?.dum :  watch('dum');
  const gestational_age = watch('gestational_age')
  const gestational_month = watch('gestational_month')

  const api = useApi();
  useEffect(() => {
    // Cálculo do IMC
    if(!isView){
      if (peso) setValue('weight', peso.replace(',', '.'));
      if (altura) setValue('height', altura.replace(',', '.'));
    }

    const imc = (peso / (altura * altura)).toFixed(2);
    if (imc !== 'NaN' && imc !== 'Infinity')
      setValue('imc', (peso / (altura * altura)).toFixed(2));
  }, [peso, altura, setValue]);

  useEffect(() => {
      const updateDates = async () => {
        // Cálculo da idade gestacional
        // if(dum !== beneficiaryData?.nutritional_data?.dum){
          const { dpp, weekDiff, monthDiff } = calculateDpp(dum);
          if (dpp?.toLocaleDateString('pt-BR') != 'Invalid Date') {
            setValue('dpp', dpp?.toLocaleDateString('pt-BR'));
            const dateInfo = await api.getImportantDates({ b_type: "1", dpp: dpp?.toISOString().split('T')[0] });
            setValue('end_date', convertDateFromApi(dateInfo['end_date'])?.toLocaleDateString('pt-BR'));
            setValue('limit_report', convertDateFromApi(dateInfo['limit_report'])?.toLocaleDateString('pt-BR'));
          }

          if (weekDiff>=0)
            setValue('gestational_age', weekDiff);
          if (monthDiff >= 0)
            setValue('gestational_month', monthDiff);
        }
      // }
      updateDates();
  }, [dum, setValue, isView]);

  return (
    <S.Form>
      <S.FormGeral>
        <S.DadosBoxDiagnostico>
          <S.FormRowDiagnostico>
            {/* Linha 1 */}
            <CompTextField
              isView={isView}
              edit={isEdit}
              required
              apiText={beneficiaryData.nutritional_data?.weight}
              textLabel="PESO ANTERIOR"
              placeholder='Peso em kg'
              enabled
              style={{ flexGrow: '1' }}
              name="weight"
              register={register}
              unregister={unregister}
              error={errors.weight}
              helperText={errors.weight && errors.weight.message}
            />
            <CompTextField
              isView={isView}
              edit={isEdit}
              required
              apiText={beneficiaryData.nutritional_data?.height}
              textLabel="ALTURA"
              enabled
              placeholder="Altura em metros"
              style={{ flexGrow: '1' }}
              name="height"
              register={register}
              unregister={unregister}
              error={errors.height}
              helperText={errors.height && errors.height.message}
            />
            <CompTextField
              isView={isView}
              edit={isEdit}
              apiText={imc}
              textLabel="IMC PRÉ-GESTACIONAL:"
              fromNis={true}
              enabled={false}
              style={{ flexGrow: '1' }}
              name="imc"
              register={register}
              unregister={unregister}
              error={errors.imc}
              helperText={errors.imc && errors.imc.message}
            />
            <CompTextField
              isView={isView}
              edit={isEdit}
              apiText={gestational_age}
              textLabel="IDADE GESTACIONAL:"
              placeholder='Idade em semanas'
              enabled={false}
              fromNis={true}
              required
              style={{ flexGrow: '1' }}
              name='gestational_age'
              register={register}
              unregister={unregister}
              error={errors.gestational_age}
              helperText={errors.gestational_age && errors.gestational_age.message}
            />
            <CompTextField
              isView={isView}
              edit={isEdit}
              apiText={gestational_month}
              textLabel="MÊS GESTACIONAL:"
              placeholder='Idade em meses'
              fromNis={true}
              enabled={false}
              required
              style={{ flexGrow: '1' }}
              name='gestational_month'
              register={register}
              unregister={unregister}
              error={errors.gestational_month}
              helperText={errors.gestational_month && errors.gestational_month.message}
            />
          </S.FormRowDiagnostico>

          <S.FormRowWithMarginDiagnostico>
            {/* Linha 2 */}
            <CompDateField
              isView={isView}
              isEdit={isEdit}
              labelText="DUM:"
              required
              style={{ flexGrow: '1' }}
              name="dum"
              register={register}
              unregister={unregister}
              error={errors.dum}
              helperText={errors.dum && errors.dum.message}
              apiText={beneficiaryData?.nutritional_data?.dum}
            />
            <CompTextField
              isView={isView}
              edit={isEdit}
              apiText={convertDateFromApi(beneficiaryData?.nutritional_data?.dpp)?.toLocaleDateString("pt-BR")}
              textLabel="DPP:"
              required
              fromNis={true}
              enabled={false}
              style={{ flexGrow: '1' }}
              name="dpp"
              register={register}
              unregister={unregister}
              error={errors.dpp}
              helperText={errors.dpp && errors.dpp.message}
            />
            <CompDateField
              isView={isView}
              isEdit={isEdit}
              apiText={beneficiaryData?.nutritional_data?.dpp_eco_estimated}
              labelText="DPP ECO/ESTIMADA:"
              required
              style={{ flexGrow: '1' }}
              name="dpp_eco_estimated"
              register={register}
              unregister={unregister}
              error={errors.dpp_eco_estimated}
              helperText={errors.dpp_eco_estimated && errors.dpp_eco_estimated.message}
            />
            <CompDateField
              isView={isView}
              isEdit={isEdit}
              apiText={beneficiaryData?.nutritional_data?.first_check_date}
              labelText="1 CONSULTA PRÉ-NATAL:"
              required
              style={{ flexGrow: '1' }}
              name="first_check_date"
              register={register}
              unregister={unregister}
              error={errors.first_check_date}
              helperText={errors.first_check_date && errors.first_check_date.message}
            />
          </S.FormRowWithMarginDiagnostico>
        </S.DadosBoxDiagnostico>
      </S.FormGeral>
    </S.Form>
  );
};

export default Forms;
