import React, { useEffect } from "react";
import { useFormContext, Controller } from "react-hook-form";
import {
  Autocomplete,
  TextField,
  FormControl,
  FormHelperText,
  Tooltip,
} from "@mui/material";
import * as S from "./styles"; // Assumindo que S contém os estilos personalizados
import { evaluateDropdown } from "utils/PermissionsLogic/dropdown";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import * as palette from "../../styles/variables";

const theme = createTheme({
  palette: {
    primary: {
      main: palette.lightAzul,
    },
  },
  components: {
    MuiAutocomplete: {
      defaultProps: {
        clearText: "Limpar",
        closeText: "Fechar",
        openText: "Abrir",
        loadingText: "Carregando...",
        noOptionsText: "Nenhuma opção",
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: palette.lightAzul,
        },
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: palette.lightAzul,
        },
        root: {
          height: "40px",
          borderRadius: "6px",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: palette.lightAzul,
        },
      },
    },
  },
});

const CompAutocomplete = ({
  defaultValue = "",
  options = [],
  apiValue = "",
  required = false,
  style = {},
  textLabel = "",
  isView = false,
  isEdit = false,
  name = "",
  placeholder = "Digite para buscar...",
}) => {
  const { control, register, unregister, setValue, formState, watch } =
    useFormContext();
  const { errors } = formState; // Acesso aos erros

  useEffect(() => {
    register(name);
    setValue(name, defaultValue); // Set initial value

    return () => {
      unregister(name);
    };
  }, [register, unregister, setValue, name, defaultValue]);

  const { options: filteredOptions, enabled } = evaluateDropdown(
    name,
    options,
    isEdit
  );

  // Obtendo o erro específico para este campo
  const error = errors[name];

  if (isView) {
    return (
      <S.SelectWrapper style={style}>
        {textLabel && (
          <S.Label>
            {textLabel}
            {required && <S.RequiredIndicator>*</S.RequiredIndicator>}
          </S.Label>
        )}
        {isView && (
        <S.viewContainer>
          <S.apiData>{apiValue}</S.apiData>
        </S.viewContainer>)}
        {/* <FormControl fullWidth>
          <TextField
            value={defaultValue}
            placeholder={placeholder}
            disabled
            InputProps={{ readOnly: true }}
            variant="outlined"
          />
        </FormControl> */}
      </S.SelectWrapper>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <S.SelectWrapper style={style}>
        {textLabel && (
          <S.Label>
            {textLabel}
            {required && <S.RequiredIndicator>*</S.RequiredIndicator>}
          </S.Label>
        )}
        <FormControl fullWidth error={!!error}>
          <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({ field }) => (
              <Autocomplete
                {...field}
                options={filteredOptions}
                getOptionLabel={(option) => option.textLabel || option}
                onChange={(event, value) => {
                  field.onChange(value);
                  setValue(name, value?.value || "");
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={placeholder}
                    variant="outlined"
                    error={!!error}
                    helperText={error ? error.message : ""}
                  />
                )}
                disabled={!enabled}
              />
            )}
          />
        </FormControl>
      </S.SelectWrapper>
    </ThemeProvider>
  );
};

export default CompAutocomplete;
