import styled from 'styled-components';

export const PageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const PageContent = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 80px;
`;

export const MainContainer = styled.div`
  flex: 1;
  padding-bottom: 100px;
  position: relative;
`;

export const CabecalhoTabela = styled.div`
  display: flex;
  flex-direction: column; 
  align-items: flex-start; 
  margin-top: 45px;
  margin-bottom: 16px;
  color: #F9922A;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  background: #FFF1E8;
  border-radius: 8px;
  padding: 15px;
  width: 100%;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 30px;
`;

export const FormRowOutros = styled.div`
  display: flex;
  gap: 2vw;
  margin-bottom: 0px;
  width: 73vw;
  max-width: 1400px;
`;

export const FormRowWithMargin = styled.div`
  display: flex;
  gap: 2vw;
  margin-top: 25px;
  padding-top: 0px;
  width: 73vw;
  max-width: 1400px;
`;

export const FormConteiner = styled.div`
  display: flex;
  gap: 2vw;
  width: 741px;
  
`;

export const DateFieldsContainer = styled.div`
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   //margin-top: 10px;
    height: 40px;
    padding: 5px 3px;
    border-radius: 6px;
  
`;

export const DateFieldsContainerTwo = styled.div`
   display: flex;
   flex-direction: column;
   align-items: flex-start;
    border-radius: 6px;
  
`;

// Estilizando a seta
export const Arrow = styled.span`
  margin: 0 10px;
  font-size: 24px;
`;

export const DateInputWrapper = styled.div`
  display: flex; /* Alteração */
  align-items: center; /* Alteração */
  //margin-right: 10px;
  margin-top: 0px;
`;

export const DateFieldsTitle = styled.h3`
  color: var(--Gray-900, var(--900, #383838));
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 8px;
`;

export const FormHeader = styled.div`
  display: flex;
  gap: 2vw;
  margin-top: 25px;
  padding-top: 0px;
  width: 705px; 
  height: auto; 
`;