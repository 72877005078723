import styled from 'styled-components';

export const Separator = styled.hr` 
  color: #9E9E9E;
  width: 73vw; 
  max-width: 1400px;
`;
export const TituloItem = styled.div`
  display: flex;
  width: 25vw;
  padding: 15px 801px 18px 24px;
  flex-direction: column;
  gap: 24px;
  padding-top: 0px;
  margin-top: 20px;
`;

export const TituloSub = styled.h2`
  color: var(--Light-Amarelo---light, #F9922A);
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  width: 79vw; 
  max-width: 1100px;
`;

export const BotoesConteiner = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  margin-top: 250px;
  width: 75vw; 
  max-width: 1400px;
`;
//Forms Dados pessoais
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const FormRow = styled.div`
  display: flex;
  gap: 2vw;
  justify-content: start;
  width: 73vw; 
  max-width: 1400px;
`;
export const FormRowWithMarginDados = styled.div`
  display: flex;
  gap: 2vw;
  margin-top: 24px;
  padding-top: 0px;
  width: 73vw; 
  max-width: 1400px;
`;
// Forms de Endereco
export const FormRowEndereco = styled.div`
  display: flex;
  gap: 2vw;
  margin-top: 3px;
  width: 73vw; 
  max-width: 1400px;
`;

export const FormRowWithMargin = styled.div`
  display: flex;
  gap: 2vw;
  margin-top: 43px;
  padding-top: 0px;
  width: 73vw;
  max-width: 1400px;
`;

//Forms de tipo de agente
export const FormRowDatas = styled.div`
  display: flex;
  margin-bottom: 0px;
  gap: 2vw;
  width: 73vw; 
  max-width: 1400px;
`;

export const CheckPassword = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;
  gap: 2px;
  margin-top: 3px;
  color: #9E9E9E;
`;