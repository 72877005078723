import styled from 'styled-components';
import { FilterList } from '@mui/icons-material';

export const FilterContainer = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
`;

export const StyledInput = styled.input`
  width: 360px;
  height: 36px;
  padding: 8px 36px 8px 12px;
  border: 1px solid #FFFFFF;
  background: #FFFFFF;
  border-radius: 8px;
  font-size: 16px;
  outline: none;
`;

export const FilterIcon = styled(FilterList)`
  position: absolute;
  right: 10px;
  color: #aaa;
  cursor: pointer;
`;