import React, { useEffect, useMemo, useState } from "react";
import { MapContainer, Tooltip, Polygon } from "react-leaflet";
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css";
import "leaflet-defaulticon-compatibility";
import "leaflet/dist/leaflet.css";

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import CallMadeIcon from '@mui/icons-material/CallMade';
import SouthEastIcon from '@mui/icons-material/SouthEast';

import TextField from "@mui/material/TextField";

import apiDashboard from "services/apiDashboard";

import GeoJSONAlagoas from "../../services/alagoas.json";

import * as S from "./styles";

import {
  formatAndGetIndicatores,
  getBeneficiaryStatusFromCityMap,
  getColorProgressMap,
  getColorRangeMapSubtitle,
  getIndicatorsValues,
  sortArrayByProperty,
} from "utils";
import { useForm } from "react-hook-form";
import { CircularProgress, InputLabel, MenuItem } from "@mui/material";

import { PieChart } from "@mui/x-charts/PieChart";

import Title from "components/Title";
import { useAuth } from "hooks/useAuth";

import AgentCity from "utils/agentCity";
import AgentType from "utils/agentType";

const shortcutsItems = [
  {
    label: "Último dia útil",
    getValue: () => {
      const today = dayjs();
      return [today.subtract(1, "day"), today];
    },
    onClick: () => {
      console.log("clickou");
    },
  },
  {
    label: "Últimos 6 meses",
    getValue: () => {
      const today = dayjs();
      return [today.subtract(6, "month"), today];
    },
  },
  { label: "Limpar", getValue: () => [null, null] },
];

const pieParams = { height: 200, margin: { right: 5 } };

const colorsBars = ["#F9922A", "#88C54B", "#EE3B5E", "#4BBFEF"];

const StatusCard = ({ title, number, description, percentageChange }) => {
  const percentageColor = percentageChange >= 0 ? "#27AE60" : "#EB5757";

  return (
    <S.StatusCard>
      <h3 className="status-number">{number}</h3>
      <p className="status-title">{title}</p>
      <span className="status-description">{description}</span>

      {/* <S.PercentageChangeContainer>
        {percentageChange >= 0 ? (
          <CallMadeIcon style={{ color: "#27AE60", fontSize: 16 }} />
        ) : (
          <SouthEastIcon style={{ color: "#EB5757", fontSize: 16 }} />
        )}
        <S.PercentageChange style={{ color: percentageColor }}>
          {percentageChange > 0 ? `+${percentageChange}%` : `${percentageChange}%`}
        </S.PercentageChange>
        <S.SubDescription>Desde o último mês</S.SubDescription>
      </S.PercentageChangeContainer> */}
    </S.StatusCard>
  );
};

const DashboardPage = () => {
  const auth = useAuth();
  const [alagoasCoordinates, setAlagoasCoordinates] = useState({});
  const [isLoadingAlagoasCoordinates, setIsLoadingAlagoasCoordinates] =
    useState(true);

  const [isAllRoute, setIsAllRoute] = useState(true);

  const [dataAllRoute, setDataAllRoute] = useState({
    genders: {},
    educationLevels: {},
    races: {},
    families: {},
    domiciles: {},
    beneficiaries: {},
    status: {},
    total: 0,
    total_ativo: 0,
    total_suspenso: 0,
  });

  const [isLoadingData, setIsLoadingData] = useState(true);

  const [cityDataMap, setCityDataMap] = useState({
    name: "",
    beneficiariosAtivos: 0,
  }); //mapa
  const [cityValue, setCityValue] = useState("Todos");

  const [beneficiaryStatusDataMap, setBeneficiaryStatusDataMap] = useState({});

  const [allData, setAllData] = useState([]);
  const [allDataAgeGroups, setAllDataAgeGroups] = useState([]);

  const [resultsCityData, setResultsCityData] = useState({
    genders: [],
    educationLevels: [],
    races: [],
    families: [],
    domiciles: [],
    beneficiaries: [],
  });

  const [citiesMapData, setCitiesMapData] = useState([]);
  const [beneficiaryStatus, setBeneficiaryStatus] = useState({
    ativo: 0,
    suspenso: 0,
  });

  const agent_type = AgentType();
  const agent_city = AgentCity();

  const getCurrentCoordinates = (municipio) => {
    let { coordinates: coords } = municipio.data;

    var paths = [];

    for (var i = 0; i < coords.length; i++) {
      var path = [];
      for (var k = 0; k < coords[i].length; k++) {
        path.push({
          lng: Number(coords[i][k][0]),
          lat: Number(coords[i][k][1]),
        });
      }
      paths.push(path);
    }

    return paths;
  };

  const getAlagoasCoordinates = async () => {
    setIsLoadingAlagoasCoordinates(true);
    try {
      let coordsAlagoas = GeoJSONAlagoas[0].geojson;

      let coordenadas = coordsAlagoas.coordinates[0];

      setAlagoasCoordinates(coordenadas);
    } catch (err) {
      console.error("erro", err);
    } finally {
      setIsLoadingAlagoasCoordinates(false);
    }
  };

  const getCities = async () => {
    try {
      let { data } = await apiDashboard.get("api/v1/municipios/");

      // data = data.map((city) =>
      //   city.name === "Tanque d'Arca"
      //     ? { ...city, name: "Tanque dArca" }
      //     : { ...city }
      // );

      data = sortArrayByProperty(data, "name");

      setCitiesMapData(data);
    } catch (err) {
      console.error(err);
    }
  };

  const getIndicators = async (isLastDay = false, dateValues = []) => {
    setIsLoadingData(true);
    let maxValue = 0;
    let minValue = "nan"; // corrigir
    try {
      let apiData = [];
      let [startDate, endDate] = dateValues;

      if (isLastDay) {
        const { data } = await apiDashboard.get("api/v1/municipios/last_day");
        apiData = data;
        setIsAllRoute(false);

        let results = apiData
          .flat()
          .reduce(
            (acc, current) => ({ ...acc, [current.city]: current.data }),
            {}
          );

        // addMapColors(results);
        let beneficiariesStatus = {};

        // minValue = getBeneficiaryStatusFromCityMap(
        //   results['Maceió']
        // )?.ativo

        citiesMapData.forEach((cityDataMap) => {
          const city = cityDataMap.name;
          const actives = getBeneficiaryStatusFromCityMap(
            results[cityDataMap.name]
          )?.ativo
            ? getBeneficiaryStatusFromCityMap(results[cityDataMap.name])?.ativo
            : 0;
          if (
            actives &&
            actives >= maxValue &&
            city !== "Todos" &&
            city !== "" &&
            city !== '""' &&
            city !== "Desconhecido" &&
            city !== "MACEIÓ"
          ) {
            maxValue = actives;
          }

          if (minValue === "nan" || actives === 0) {
            minValue = actives;
          }

          if (
            actives &&
            actives <= minValue &&
            city !== "Todos" &&
            city !== "" &&
            city !== '""' &&
            city !== "Desconhecido" &&
            city !== "MACEIÓ"
          ) {
            minValue = actives;
          }
          beneficiariesStatus[city] = actives;
        });
        setBeneficiaryStatusDataMap({
          ...beneficiariesStatus,
          maxValue,
          minValue,
        });

        setAllData(results);
        getRangeAges("Todos", startDate, endDate);
        handleIndicatorsData("Todos", results);
      } else if (!startDate && !endDate) {
        const { data } = await apiDashboard.get("api/v1/municipios/data/all");
        apiData = data[0];

        setIsAllRoute(true);

        let beneficiaries = formatAndGetIndicatores(apiData, "b_type");
        let domiciles = formatAndGetIndicatores(apiData, "type_of_domicile");
        let genders = formatAndGetIndicatores(apiData, "gender");
        let races = formatAndGetIndicatores(apiData, "race");
        let educationLevels = formatAndGetIndicatores(
          apiData,
          "education_level"
        );
        let families = formatAndGetIndicatores(
          apiData,
          "traditional_population_groups"
        );

        let status = formatAndGetIndicatores(apiData, "b_status");

        let beneficiariesStatus = {};

        Object.entries(status).forEach((cityDataMap) => {
          const city = cityDataMap[0];
          const actives = status[cityDataMap[0]]?.find(
            (s) => s.label === "ATIVO"
          )
            ? status[cityDataMap[0]].find((s) => s.label === "ATIVO").value
            : 0;
          if (
            actives &&
            actives >= maxValue &&
            city !== "Todos" &&
            city !== "" &&
            city !== '""' &&
            city !== "Desconhecido" &&
            city !== "MACEIÓ"
          ) {
            maxValue = actives;
          }

          if (minValue === "nan" || actives === 0) {
            minValue = actives;
          }

          if (
            actives &&
            actives <= minValue &&
            city !== "Todos" &&
            city !== "" &&
            city !== '""' &&
            city !== "Desconhecido" &&
            city !== "MACEIÓ"
          ) {
            minValue = actives;
          }
          beneficiariesStatus[city] = actives;
        });
        setBeneficiaryStatusDataMap({
          ...beneficiariesStatus,
          maxValue,
          minValue,
        });

        setDataAllRoute({
          beneficiaries,
          domiciles,
          genders,
          races,
          status,
          educationLevels,
          families,
          total: apiData.total,
          total_ativo: apiData.total_ativo,
          total_suspenso: apiData.total_suspenso,
        });
      } else {
        if (startDate) {
          startDate = `${startDate.format("YYYY-MM-DD")}`;
        }

        if (endDate) {
          endDate = `${endDate.format("YYYY-MM-DD")}`;
        }
        const { data } = await apiDashboard.get(
          `api/v0/municipios/data?start=${startDate}${
            endDate ? `&end=${endDate}` : ""
          }`
        );
        apiData = data;
        setIsAllRoute(false);

        let results = apiData
          .flat()
          .reduce(
            (acc, current) => ({ ...acc, [current.city]: current.data }),
            {}
          );

        // addMapColors(results);
        let beneficiariesStatus = {};

        citiesMapData.forEach((cityDataMap) => {
          const city = cityDataMap.name;
          const actives = getBeneficiaryStatusFromCityMap(
            results[cityDataMap.name]
          )?.ativo
            ? getBeneficiaryStatusFromCityMap(results[cityDataMap.name])?.ativo
            : 0;
          if (
            actives &&
            actives >= maxValue &&
            city !== "Todos" &&
            city !== "" &&
            city !== '""' &&
            city !== "Desconhecido" &&
            city !== "MACEIÓ"
          ) {
            maxValue = actives;
          }

          if (minValue === "nan" || actives === 0) {
            minValue = actives;
          }

          if (
            actives &&
            actives <= minValue &&
            city !== "Todos" &&
            city !== "" &&
            city !== '""' &&
            city !== "Desconhecido" &&
            city !== "MACEIÓ"
          ) {
            minValue = actives;
          }
          beneficiariesStatus[city] = actives;
        });
        setBeneficiaryStatusDataMap({
          ...beneficiariesStatus,
          maxValue,
          minValue,
        });

        setAllData(results);
        getRangeAges("Todos", startDate, endDate);
        handleIndicatorsData("Todos", results);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoadingData(false);
    }
  };

  const getRangeAges = async (city, startDate, endDate) => {
    try {
      const { data } = await apiDashboard.get(
        `api/v1/municipios/age_group${
          startDate
            ? `?start=${startDate}${endDate ? `&end=${endDate}` : ""}${
                city === "Todos" ? "" : `&city=${city}`
              }`
            : `${city === "Todos" ? "" : `?city=${city}`}`
        }`
      );

      if (data) {
        let resultsToShow = data
          .flat()
          .reduce(
            (acc, current) => ({ ...acc, [current.city]: current.data }),
            {}
          );

        resultsToShow = Object.values(resultsToShow).flat();

        let results = resultsToShow.reduce(function (r, element) {
          const type = element["age_group"].toLowerCase();
          const found = r.findIndex((t) => t.label === type);

          if (found !== -1) {
            r[found].value = r[found].value + 1;
          } else {
            r = [...r, { label: type, value: 1 }];
          }
          return r;
        }, []);

        results = results.sort(function (a, b) {
          return a.label.localeCompare(b.label, undefined, {
            numeric: true,
            sensitivity: "base",
          });
        });

        setAllDataAgeGroups(results);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (["2", "3"].includes(agent_type) && dateValue ) {
      let [startDate, endDate] = dateValue;
      if (startDate) {
        startDate = `${startDate.format("YYYY-MM-DD")}`;
      }

      if (endDate) {
        endDate = `${endDate.format("YYYY-MM-DD")}`;
      }
      getRangeAges(agent_city, startDate, endDate);
      handleIndicatorsData(agent_city);
    }
  }, [agent_type]);

  const [dateValue, setDateValue] = useState([null, null]);
  const [dateValueLastDay, setDateValueLastDay] = useState([null, null]);
  const [cadUnicoVersion, setCadUnicoVersion] = useState("");

  const handleDateValueChange = (newValue, ctx) => {
    setDateValue(newValue);

    if (ctx.shortcut && ctx.shortcut.label === "Último dia útil") {
      setDateValue(dateValueLastDay);
      getIndicators(true);
    } else {
      getIndicators(false, newValue);
    }
  };

  const getLastDayValue = async () => {
    try {
      const { data } = await apiDashboard.get("api/v1/municipios/last_day");
      let apiData = data;

      if (apiData.length > 0) {
        let registration_date = apiData.flat()[0]?.data[0]?.registration_date;
        const lastDay = dayjs(registration_date);
        setDateValueLastDay([lastDay, lastDay]);
        let ref_cad = apiData.flat()[0]?.data[0]?.ref_cad;
        setCadUnicoVersion(ref_cad);
      }
    } catch (err) {
      const today = dayjs();
      setDateValue([today.subtract(1, "day"), today]);
      console.error(err);
    }
  };

  const handleIndicatorsData = (cityName, data) => {
    let genders = [],
      families = [],
      domiciles = [],
      educationLevels = [],
      races = [],
      beneficiaries = [];
    let resultsToShow = [];

    setCityValue(cityName);

    if (data) {
      resultsToShow = Object.values(data).flat();
    } else {
      resultsToShow =
        cityName === "Todos"
          ? Object.values(allData).flat()
          : allData[cityName]
          ? allData[cityName]
          : [];
    }

    genders = getIndicatorsValues(resultsToShow, "gender_text");
    educationLevels = getIndicatorsValues(
      resultsToShow,
      "education_level_text"
    );
    races = getIndicatorsValues(resultsToShow, "race_text");
    beneficiaries = getIndicatorsValues(resultsToShow, "b_type_text");
    families = getIndicatorsValues(
      resultsToShow,
      "traditional_population_groups_text"
    );
    domiciles = getIndicatorsValues(
      resultsToShow,
      "type_of_domicile_text",
      "Em branco"
    );

    setBeneficiaryStatus(getBeneficiaryStatusFromCityMap(resultsToShow));

    setResultsCityData({
      genders: genders,
      educationLevels: educationLevels,
      races: races,
      families: families,
      domiciles: domiciles,
      beneficiaries: beneficiaries,
    });
    setCityValue(cityName);
  };

  useEffect(() => {
    if (!dateValue[0] && !dateValue[1]) {
      setIsAllRoute(true);
    }
  }, [dateValue]);

  useEffect(() => {
    getAlagoasCoordinates();
    getIndicators(false);
    getCities();
    getLastDayValue();
  }, []);

  const defaultProps = {
    center: { lat: -9.571306, lng: -36.78195 },
    // center: { lat: -9.14353194908792, lng: -35.4068344467753 },
    zoom: 8,
    zoomControl: false,
    attributionControl: false,
    doubleClickZoom: false,
    closePopupOnClick: false,
    dragging: false,
    trackResize: false,
    touchZoom: false,
    scrollWheelZoom: false,
  };

  const { register } = useForm({
    defaultValues: useMemo(() => {
      return {
        indicator: "",
        city: "Todos",
      };
    }, []),
  });

  const { onChange: onChangeCity } = register("city");

  return (
    <S.DashboardContainer>
      <S.AllDataWrapper>
        <S.DashboardHeaderTexts>
          <Title
            titleText={`Olá, ${
              auth.userAgentName ? auth.userAgentName : "Maria"
            }!`}
            subtitleTexts={["Início", "Dashboard"]}
            showCadVersion={false}
          />
          <h1 className="title">Distribuição Geográfica dos Beneficiários</h1>
          {cadUnicoVersion && (
            <span>
              Versão do CadÚnico:{" "}
              {cadUnicoVersion?.split("-").reverse().join("/")}
            </span>
          )}
          <p className="description">
            O mapa interativo considera o número total de beneficiários ativos
            em cada município, juntamente com o número de famílias registradas
            no CadÚnico que possuem uma renda de até R$218 por pessoa, e pelo
            menos uma criança com até 6 anos de idade.
          </p>

          <S.StatusCardContainer>
            <StatusCard
              title="Beneficiários ativos"
              number={
                isAllRoute
                  ? dataAllRoute["status"][cityValue]?.find((s) => s.label === "ATIVO")?.value || 0
                  : beneficiaryStatus.ativo || 0
              }
              // percentageChange={0}
            />
            <StatusCard
              title="Beneficiários advertidos"
              number={isAllRoute ? dataAllRoute["status"][cityValue]?.find(s => s.label === "ADVERTÊNCIA")?.value ||
                 0 : beneficiaryStatus.advertido || 0}
              // percentageChange={0}
            />
            <StatusCard
              title="Beneficiários bloqueados"
              number={isAllRoute ? dataAllRoute["status"][cityValue]?.find(s => s.label === "BLOQUEADO")?.value || 
                0 : beneficiaryStatus.bloqueado || 0}
              // percentageChange={0}
            />
            <StatusCard
              title="Beneficiários suspensos"
              number={isAllRoute ? dataAllRoute["status"][cityValue]?.find(s => s.label === "SUSPENSO")?.value ||
                 0 : beneficiaryStatus.suspenso || 0}
              // percentageChange={0}
            />
            <StatusCard
              title="Beneficiários cancelados"
              number={
                isAllRoute
                  ? dataAllRoute["status"][cityValue]?.find((s) => s.label === "CANCELADO")?.value || 0
                  : beneficiaryStatus.cancelado || 0
              }
              // percentageChange={0}
            />
            
         
        </S.StatusCardContainer>

        </S.DashboardHeaderTexts>
        <div style={{ display: "flex", gap: 32 }} className="firstRow">
          <S.DateCalendarContainer>
            <InputLabel>Período</InputLabel>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="pt-br"
            >
              {/* <DateRangeCalendar
                calendars={1}
                value={dateValue}
                onChange={(newValue) => setDateValue(newValue)}
              /> */}
              <DateRangePicker
                slots={{ field: SingleInputDateRangeField }}
                disableFuture
                slotProps={{
                  // layout: {
                  //   sx: {
                  //     [`.${pickersLayoutClasses.contentWrapper}`]: {
                  //       gridColumn: 1,
                  //       gridRow: 2,
                  //     },
                  //   },
                  // },
                  shortcuts: {
                    items: shortcutsItems,
                  },
                  actionBar: { actions: [] },
                }}
                localeText={{
                  fieldMonthPlaceholder: () => "mm",
                  fieldDayPlaceholder: () => "dd",
                  fieldYearPlaceholder: () => "aaaa",
                }}
                value={dateValue}
                onChange={handleDateValueChange}
                views={["year", "month", "day"]} // ver porque ta dando erro
                calendars={1}
                name="allowedRange"
              />
            </LocalizationProvider>
          </S.DateCalendarContainer>

          <S.TextFieldCityContainer>
            <InputLabel>Município</InputLabel>
            <TextField
              select
              id="outlined-select-type"
              margin="normal"
              fullWidth
              required
              disabled={["2", "3"].includes(agent_type)}
              // label="Município"
              value={["2", "3"].includes(agent_type) ? agent_city : cityValue}
              defaultValue="Todos"
              InputLabelProps={{ shrink: true }}
              {...register("city")}
              onChange={(event) => {
                onChangeCity(event);
                let {
                  target: { value },
                } = event;

                let [startDate, endDate] = dateValue;
                if (startDate) {
                  startDate = `${startDate.format("YYYY-MM-DD")}`;
                }

                if (endDate) {
                  endDate = `${endDate.format("YYYY-MM-DD")}`;
                }
                getRangeAges(value, startDate, endDate);
                handleIndicatorsData(value);
              }}
            >
              {[{ id: 0, name: "Todos", value: "Todos" }, ...citiesMapData].map(
                (item) => (
                  <MenuItem key={item.id} value={item.name}>
                    {item.name === "Tanque dArca" ? "Tanque d'Arca" : item.name}
                  </MenuItem>
                )
              )}
            </TextField>
          </S.TextFieldCityContainer>
        </div>
        <div style={{ minWidth: "max(1012px, 100%)" }} className="min-width">
          <S.MapSectionMapContainer>
            <div
              style={{
                // minWidth: "51.5vw", maxWidth: "600px",
                display: "flex",
                alignItems: "center",
                alignSelf: "center",
              }}
            >
              {/* {Object.keys(alagoasCoordinates).length > 0 ? ( */}
              {!isLoadingAlagoasCoordinates &&
              Object.keys(alagoasCoordinates).length > 0 &&
              allData ? (
                <div>
                  <MapContainer
                    key={JSON.stringify([
                      alagoasCoordinates,
                      citiesMapData,
                      isAllRoute,
                      beneficiaryStatusDataMap,
                      cityValue,
                    ])}
                    {...defaultProps}
                    center={defaultProps.center}
                    attributionControl={defaultProps.attributionControl}
                    zoomControl={defaultProps.zoomControl}
                    zoom={defaultProps.zoom}
                    style={{
                      height: "360px",
                      // maxWidth: "calc(100vw - 100px)",
                      background: "transparent",
                    }}
                  >
                    {/* <ImageOverlay
                      url={AlagoasMapBlue}
                      bounds={[
                        // [-8.8131267, -35.1518918],
                        // [-10.5004477,  -38.23759 ]
                        [-8.82, -35.155],
                        [-10.51, -38.22],
                      ]}
                    /> */}
                    {citiesMapData
                      ? citiesMapData.map((municipio, index) => {
                          let name =
                            municipio.name === "Tanque d'Arca"
                              ? "Tanque dArca"
                              : municipio.name;

                          const otherData = getColorRangeMapSubtitle(
                            // maxValue,
                            beneficiaryStatusDataMap["maxValue"],
                            beneficiaryStatusDataMap["minValue"],
                            // minNumber,
                            // isAllRoute ? dataAllRoute["status"] : allData,
                            // municipio.name,
                            // isAllRoute
                            beneficiaryStatusDataMap[name]
                          );
                          return (
                            <Polygon
                              key={index}
                              positions={getCurrentCoordinates(municipio)}
                              color={
                                municipio.name === cityValue
                                  ? "#f9922a"
                                  : otherData.color
                              }
                              fillColor={
                                municipio.name === cityValue
                                  ? "#f9922a"
                                  : otherData.fillColor
                              }
                              fillOpacity={1}
                              eventHandlers={{
                                click: (e) => {
                                  setCityValue(name);
                                  let [startDate, endDate] = dateValue;
                                  if (startDate) {
                                    startDate = `${startDate.format(
                                      "YYYY-MM-DD"
                                    )}`;
                                  }

                                  if (endDate) {
                                    endDate = `${endDate.format("YYYY-MM-DD")}`;
                                  }
                                  getRangeAges(name, startDate, endDate);
                                  handleIndicatorsData(name);
                                },
                                mouseover: (e) =>
                                  setCityDataMap({
                                    name: municipio.name,
                                    beneficiariosAtivos:
                                      otherData.beneficiariosAtivos,
                                  }),
                              }}
                            >
                              <Tooltip className="map-tooltip" sticky>
                                Município:{" "}
                                {cityDataMap?.name === "Tanque dArca"
                                  ? "Tanque d'Arca"
                                  : cityDataMap?.name}
                                <br />
                                Nº de beneficiários ativos:{" "}
                                {beneficiaryStatusDataMap &&
                                name &&
                                beneficiaryStatusDataMap[name]
                                  ? beneficiaryStatusDataMap[name]
                                  : 0}
                              </Tooltip>
                            </Polygon>
                          );
                        })
                      : null}
                  </MapContainer>

                  <S.MapSubtitle>
                    <div className="flex">
                      <div className="subtitle-card">
                        <div
                          className="color"
                          style={{ backgroundColor: "#B6E7FB" }}
                        ></div>
                        <span>16.6%</span>
                      </div>
                      <div className="subtitle-card">
                        <div
                          className="color"
                          style={{ backgroundColor: "#95D7FF" }}
                        ></div>
                        <span>16.6 - 33.3%</span>
                      </div>
                      <div className="subtitle-card">
                        <div
                          className="color"
                          style={{ backgroundColor: "#68C2FB" }}
                        ></div>
                        <span>33.3 - 50%</span>
                      </div>
                    </div>
                    <div className="flex">
                      <div className="subtitle-card">
                        <div
                          className="color"
                          style={{ backgroundColor: "#34ADF6" }}
                        ></div>
                        <span>50 - 66.6%</span>
                      </div>
                      <div className="subtitle-card">
                        <div
                          className="color"
                          style={{ backgroundColor: "#008FEC" }}
                        ></div>
                        <span>66.6 - 83.3%</span>
                      </div>
                      <div className="subtitle-card">
                        <div
                          className="color"
                          style={{ backgroundColor: "#0077C1" }}
                        ></div>
                        <span>83.3 - 100.0%</span>
                      </div>
                      <div className="subtitle-card">
                        <div
                          className="color"
                          style={{ backgroundColor: "#9E9E9E" }}
                        ></div>
                        <span>S/ calculo</span>
                      </div>
                    </div>
                  </S.MapSubtitle>
                </div>
              ) : (
                <div
                  style={{
                    height: 360,
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {!isLoadingAlagoasCoordinates &&
                  Object.keys(alagoasCoordinates).length === 0 ? (
                    <p>
                      O mapa não pode ser carregado. Atualize a página
                      novamente.
                    </p>
                  ) : (
                    //   <CircularProgress />
                    <div>loading</div>
                  )}
                </div>
              )}
            </div>

            <S.MapResultsContainer>
              <S.MapResultCardContainer>
                <span className="card-title">Beneficiários ativos:</span>
                {isLoadingData ? (
                  <CircularProgress thickness={5} />
                ) : (
                  <h3 className="card-number">
                    {isAllRoute
                      ? dataAllRoute["status"][cityValue]?.find(
                          (s) => s.label === "ATIVO"
                        )
                        ? dataAllRoute["status"][cityValue].find(
                            (s) => s.label === "ATIVO"
                          ).value
                        : 0
                      : beneficiaryStatus.ativo
                      ? beneficiaryStatus.ativo
                      : 0}
                  </h3>
                )}
              </S.MapResultCardContainer>
              <S.MapResultCardContainer>
                <span className="card-title">Beneficiários cancelado:</span>
                {isLoadingData ? (
                  <CircularProgress thickness={5} />
                ) : (
                  <h3 className="card-number">
                    {isAllRoute
                      ? dataAllRoute["status"][cityValue]?.find(
                          (s) => s.label === "CANCELADO"
                        )
                        ? dataAllRoute["status"][cityValue].find(
                            (s) => s.label === "CANCELADO"
                          ).value
                        : 0
                      : beneficiaryStatus.cancelado
                      ? beneficiaryStatus.cancelado
                      : 0}
                  </h3>
                )}
              </S.MapResultCardContainer>

              <S.MapResultCardContainer>
                <span className="card-title">Total:</span>
                {isLoadingData ? (
                  <CircularProgress thickness={5} />
                ) : (
                  <h3 className="card-number">
                    {isAllRoute
                      ? cityValue === "Todos"
                        ? dataAllRoute["status"][cityValue]?.find(
                            (s) => s.label === "TOTAL"
                          )
                          ? dataAllRoute["status"][cityValue].find(
                              (s) => s.label === "TOTAL"
                            ).value
                          : 0
                        : (dataAllRoute["status"][cityValue]?.find(
                            (s) => s.label === "ATIVO"
                          ).value
                            ? dataAllRoute["status"][cityValue]?.find(
                                (s) => s.label === "ATIVO"
                              ).value
                            : 0) +
                          (dataAllRoute["status"][cityValue]?.find(
                            (s) => s.label === "SUSPENSO"
                          )?.value
                            ? dataAllRoute["status"][cityValue]?.find(
                                (s) => s.label === "SUSPENSO"
                              ).value
                            : 0)
                      : (beneficiaryStatus.ativo
                          ? beneficiaryStatus.ativo
                          : 0) +
                        (beneficiaryStatus.suspenso
                          ? beneficiaryStatus.suspenso
                          : 0)}
                  </h3>
                )}
              </S.MapResultCardContainer>
            </S.MapResultsContainer>
          </S.MapSectionMapContainer>
        </div>

        <S.DemographicDataContainer>
          <S.DemographicDataTitle>
            <h4>
              Dados demográficos de{" "}
              {cityValue === "Todos" ? "Alagoas" : cityValue}
            </h4>
            <hr />
          </S.DemographicDataTitle>

          {isLoadingData ? (
            <S.GraphicContainer
              style={{
                // width: "100%",
                textAlign: "center",
                minHeight: 200,
                alignContent: "center",
                // minWidth: "100%",
              }}
            >
              <CircularProgress thickness={5} />
            </S.GraphicContainer>
          ) : !isAllRoute && resultsCityData["beneficiaries"].length === 0 ? (
            <>
              Não há dados com o período{" "}
              {cityValue === "Todos"
                ? "selecionado."
                : "e cidade selecionados."}
            </>
          ) : (
            <>
              <S.GraphicContainer style={{ maxWidth: "100%" }}>
                <p className="title">Tipo de família</p>

                {/* <PieChart
                series={[
                  {
                    data:
                      // [
                      //   { value: 20, color: "#EE3B5E", label: "Feminino" },
                      //   { value: 15, color: "#4BBFEF", label: "Masculino" },
                      // ]
                      resultsCityData["families"],
                  },
                ]}
                slotProps={{
                  legend: { hidden: true },
                }}
                {...pieParams}
              />
        
              <S.GraphicSubtitleContainer>
                <p>
                  <span className="circle red"></span>Feminino
                </p>
                <p>
                  <span className="circle blue"></span>Masculino
                </p>
              </S.GraphicSubtitleContainer> */}

                <span className="description">
                  Quantidade de pessoas que recebem benefícios organizadas por
                  tipos de família.
                </span>

                {isAllRoute ? (
                  <S.StepProgressBarContainer style={{ gap: 0 }}>
                    {dataAllRoute["families"][cityValue]?.map((curr, index) => {
                      const arrayValues = dataAllRoute["families"][
                        cityValue
                      ]?.map((el) => el.value);
                      const maxNumber = Math.max(...arrayValues);
                      const minNumber = Math.min(...arrayValues);
                      const percentage =
                        10 +
                        ((curr.value - minNumber) / (maxNumber - minNumber)) *
                          90;
                      return (
                        <S.CustomBarLine
                          key={curr.label}
                          color={colorsBars[index % 4]}
                          percentage={percentage}
                        >
                          <div className="content">
                            <h4 className="range" style={{ minWidth: 342 }}>
                              {curr.label}
                            </h4>
                            <div className="bar">
                              {/* <div className="bar-inner"></div> */}
                            </div>
                            <span className="value">{curr.value}</span>
                          </div>
                        </S.CustomBarLine>
                      );
                    })}
                  </S.StepProgressBarContainer>
                ) : (
                  <S.StepProgressBarContainer style={{ gap: 0 }}>
                    {resultsCityData["families"].map((curr, index) => {
                      const arrayValues = resultsCityData["families"].map(
                        (el) => el.value
                      );
                      const maxNumber = Math.max(...arrayValues);
                      const minNumber = Math.min(...arrayValues);
                      const percentage =
                        10 +
                        ((curr.value - minNumber) / (maxNumber - minNumber)) *
                          90;
                      return (
                        <S.CustomBarLine
                          key={curr.label}
                          color={colorsBars[index % 4]}
                          percentage={percentage}
                        >
                          <div className="content">
                            <h4 className="range" style={{ minWidth: 180 }}>
                              {curr.label}
                            </h4>
                            <div className="bar">
                              {/* <div className="bar-inner"></div> */}
                            </div>
                            <span className="value">{curr.value}</span>
                          </div>
                        </S.CustomBarLine>
                      );
                    })}
                  </S.StepProgressBarContainer>
                )}
              </S.GraphicContainer>
              <div
                style={{
                  display: "flex",
                  gap: 48,
                  justifyContent: "space-between",
                }}
                className="flex-column-mobile"
              >
                <S.GraphicContainer className="tipo-beneficiario">
                  <p className="title">Tipo de beneficiário</p>
                  <span className="description">
                    Total de pessoas que recebem benefícios por tipo de
                    beneficiário.
                  </span>
                  {isAllRoute ? (
                    <S.StepProgressBarContainer style={{ gap: 0 }}>
                      {dataAllRoute["beneficiaries"][cityValue]?.map(
                        (curr, index) => {
                          const arrayValues = dataAllRoute["beneficiaries"][
                            cityValue
                          ]?.map((el) => el.value);
                          const maxNumber = Math.max(...arrayValues);
                          const minNumber = Math.min(...arrayValues);
                          const percentage =
                            10 +
                            ((curr.value - minNumber) /
                              (maxNumber - minNumber)) *
                              90;
                          return (
                            <S.CustomBarLine
                              key={curr.label}
                              color={colorsBars[index % 4]}
                              percentage={percentage}
                            >
                              <div className="content">
                                <h4 className="range" style={{ minWidth: 192 }}>
                                  {curr.label}
                                </h4>
                                <div className="bar">
                                  {/* <div className="bar-inner"></div> */}
                                </div>
                                <span className="value">{curr.value}</span>
                              </div>
                            </S.CustomBarLine>
                          );
                        }
                      )}
                    </S.StepProgressBarContainer>
                  ) : (
                    <S.StepProgressBarContainer style={{ gap: 0 }}>
                      {resultsCityData["beneficiaries"].map((curr, index) => {
                        const arrayValues = resultsCityData[
                          "beneficiaries"
                        ].map((el) => el.value);
                        const maxNumber = Math.max(...arrayValues);
                        const minNumber = Math.min(...arrayValues);
                        const percentage =
                          10 +
                          ((curr.value - minNumber) / (maxNumber - minNumber)) *
                            90;
                        return (
                          <S.CustomBarLine
                            key={curr.label}
                            color={colorsBars[index % 4]}
                            percentage={percentage}
                          >
                            <div className="content">
                              <h4 className="range" style={{ minWidth: 180 }}>
                                {curr.label}
                              </h4>
                              <div className="bar">
                                {/* <div className="bar-inner"></div> */}
                              </div>
                              <span className="value">{curr.value}</span>
                            </div>
                          </S.CustomBarLine>
                        );
                      })}
                    </S.StepProgressBarContainer>
                  )}
                </S.GraphicContainer>

                <S.GraphicContainer className="max-width-28">
                  <p className="title">Sexo</p>

                  <span className="description">
                    Distribuição de beneficiários por sexo.
                  </span>

                  {isAllRoute &&
                  Object.entries(dataAllRoute["genders"]).length > 0 ? (
                    <PieChart
                      series={[
                        {
                          data: dataAllRoute["genders"]?.[cityValue]?.map(
                            (gender) => {
                              if (
                                gender.label === "FEMININO" ||
                                gender.label === "feminino"
                              ) {
                                return {
                                  value: gender.value,
                                  color: "#EE3B5E",
                                  label: "Feminino",
                                };
                              } else if (
                                gender.label === "MASCULINO" ||
                                gender.label === "masculino"
                              ) {
                                return {
                                  value: gender.value,
                                  color: "#4BBFEF",
                                  label: "Masculino",
                                };
                              } else {
                                return {
                                  value: gender.value,
                                  color: "#88C54B",
                                  label: "Não informado",
                                };
                              }
                            }
                          ),
                        },
                      ]}
                      slotProps={{
                        legend: { hidden: true },
                      }}
                      {...pieParams}
                    />
                  ) : (
                    <PieChart
                      series={[
                        {
                          data: resultsCityData["genders"].map((gender) => {
                            if (
                              gender.label === "FEMININO" ||
                              gender.label === "feminino"
                            ) {
                              return {
                                value: gender.value,
                                color: "#EE3B5E",
                                label: "Feminino",
                              };
                            } else if (
                              gender.label === "MASCULINO" ||
                              gender.label === "masculino"
                            ) {
                              return {
                                value: gender.value,
                                color: "#4BBFEF",
                                label: "Masculino",
                              };
                            } else {
                              return {
                                value: gender.value,
                                color: "#88C54B",
                                label: "Não informado",
                              };
                            }
                          }),
                        },
                      ]}
                      slotProps={{
                        legend: { hidden: true },
                      }}
                      {...pieParams}
                    />
                  )}

                  <S.GraphicSubtitleContainer>
                    <p>
                      <span className="circle red"></span>Feminino
                    </p>
                    <p>
                      <span className="circle blue"></span>Masculino
                    </p>
                  </S.GraphicSubtitleContainer>
                </S.GraphicContainer>
              </div>
              {false && (
                <div
                  style={{
                    display: "flex",
                    gap: 48,
                    justifyContent: "space-between",
                  }}
                >
                  <S.GraphicContainer style={{ width: "100%" }}>
                    <p className="title">Idade por responsável</p>
                    <span className="description">
                      Quantidade dos responsáveis agrupados por faixa etária
                    </span>
                    <S.StepProgressBarContainer style={{ gap: 0 }}>
                      {allDataAgeGroups.map((curr, index) => {
                        const arrayValues = allDataAgeGroups.map(
                          (el) => el.value
                        );
                        const maxNumber = Math.max(...arrayValues);
                        const minNumber = Math.min(...arrayValues);
                        const percentage =
                          10 +
                          ((curr.value - minNumber) / (maxNumber - minNumber)) *
                            90;
                        return (
                          <S.CustomBarLine
                            key={curr.label}
                            color={colorsBars[index % 4]}
                            percentage={percentage}
                          >
                            <div className="content">
                              <h4 className="range">{curr.label} anos</h4>
                              <div className="bar">
                                {/* <div className="bar-inner"></div> */}
                              </div>
                              <span className="value">{curr.value}</span>
                            </div>
                          </S.CustomBarLine>
                        );
                      })}
                    </S.StepProgressBarContainer>
                  </S.GraphicContainer>
                </div>
              )}

              <div
                style={{
                  display: "flex",
                  gap: 24,
                  justifyContent: "space-between",
                }}
                className="flex-column-mobile"
              >
                <S.GraphicContainer>
                  <p className="title">Identidade Racial</p>
                  <span className="description">
                    Distribuição dos beneficiários de acordo com sua identidade
                    racial.
                  </span>
                  <S.StepProgressBarContainer>
                    {(isAllRoute
                      ? dataAllRoute["races"][cityValue]
                      : resultsCityData["races"]
                    )?.map((curr) => {
                      const arrayValues = (
                        isAllRoute
                          ? dataAllRoute["races"][cityValue]
                          : resultsCityData["races"]
                      ).map((el) => el.value);
                      const maxNumber = Math.max(...arrayValues);
                      const minNumber = Math.min(...arrayValues);
                      const percentage =
                        10 +
                        ((curr.value - minNumber) / (maxNumber - minNumber)) *
                          90;
                      const colorMap = getColorProgressMap(percentage);
                      return (
                        <S.StepProgressBarLine
                          key={curr.label}
                          color={colorMap.colour}
                          backgroundColor={colorMap.backgroundColor}
                          percentage={percentage}
                        >
                          <div className="texts">
                            <h4 className="type">{curr.label}</h4>
                            <span className="percentage">{curr.value}</span>
                          </div>

                          <div className="progress-bar">
                            <div className="progress-bar-inner"></div>
                          </div>
                        </S.StepProgressBarLine>
                      );
                    })}
                  </S.StepProgressBarContainer>
                </S.GraphicContainer>

                <S.GraphicContainer className="max-width-40">
                  <p className="title">Nível de escolaridade</p>
                  <span className="description">
                    Quantidade de beneficiários em cada nível de escolaridade.
                  </span>
                  <S.StepProgressBarContainer>
                    {(isAllRoute
                      ? dataAllRoute["educationLevels"][cityValue]
                      : resultsCityData["educationLevels"]
                    )?.map((curr) => {
                      const arrayValues = (
                        isAllRoute
                          ? dataAllRoute["educationLevels"][cityValue]
                          : resultsCityData["educationLevels"]
                      )?.map((el) => el.value);
                      const maxNumber = Math.max(...arrayValues);
                      const minNumber = Math.min(...arrayValues);
                      const percentage =
                        10 +
                        ((curr.value - minNumber) / (maxNumber - minNumber)) *
                          90;
                      const colorMap = getColorProgressMap(percentage, true);
                      return (
                        <S.StepProgressBarLine
                          key={curr.label}
                          color={colorMap.colour}
                          backgroundColor={colorMap.backgroundColor}
                          percentage={percentage}
                        >
                          <div className="texts">
                            <h4 className="type">{curr.label}</h4>
                            <span className="percentage">{curr.value}</span>
                          </div>

                          <div className="progress-bar">
                            <div className="progress-bar-inner"></div>
                          </div>
                        </S.StepProgressBarLine>
                      );
                    })}
                  </S.StepProgressBarContainer>
                </S.GraphicContainer>

                <S.GraphicContainer className="max-width-28">
                  <p className="title">Tipo de domicílio</p>
                  <span className="description">
                    Quantidade de beneficiários por tipo de domicílio.
                  </span>

                  {isAllRoute &&
                  Object.entries(dataAllRoute["domiciles"]).length > 0 ? (
                    <PieChart
                      series={[
                        {
                          data: dataAllRoute["domiciles"][cityValue]?.map(
                            (domicile) => {
                              if (
                                domicile.label === "urbano" ||
                                domicile.label === "URBANO"
                              ) {
                                return {
                                  value: domicile.value,
                                  color: "#EE3B5E",
                                  label: "Urbano",
                                };
                              } else if (
                                domicile.label === "rural" ||
                                domicile.label === "RURAL"
                              ) {
                                return {
                                  value: domicile.value,
                                  color: "#4BBFEF",
                                  label: "Rural",
                                };
                              } else {
                                return {
                                  value: domicile.value,
                                  color: "#88C54B",
                                  label: "Em branco",
                                };
                              }
                            }
                          ),
                        },
                      ]}
                      slotProps={{
                        legend: { hidden: true },
                      }}
                      {...pieParams}
                    />
                  ) : (
                    <PieChart
                      series={[
                        {
                          data: resultsCityData["domiciles"]?.map(
                            (domicile) => {
                              if (
                                domicile.label === "urbano" ||
                                domicile.label === "URBANO"
                              ) {
                                return {
                                  value: domicile.value,
                                  color: "#EE3B5E",
                                  label: "Urbano",
                                };
                              } else if (
                                domicile.label === "rural" ||
                                domicile.label === "RURAL"
                              ) {
                                return {
                                  value: domicile.value,
                                  color: "#4BBFEF",
                                  label: "Rural",
                                };
                              } else {
                                return {
                                  value: domicile.value,
                                  color: "#88C54B",
                                  label: "Em branco",
                                };
                              }
                            }
                          ),
                        },
                      ]}
                      slotProps={{
                        legend: { hidden: true },
                      }}
                      {...pieParams}
                    />
                  )}

                  <S.GraphicSubtitleContainer
                    style={{ flexDirection: "column", gap: 12 }}
                  >
                    <div style={{ display: "flex", gap: 24 }}>
                      <p>
                        <span className="circle red"></span>Urbano
                      </p>
                      <p>
                        <span className="circle green"></span> Em branco
                      </p>
                    </div>
                    <p>
                      <span className="circle blue"></span>Rural
                    </p>
                  </S.GraphicSubtitleContainer>
                </S.GraphicContainer>
              </div>
            </>
          )}
        </S.DemographicDataContainer>
      </S.AllDataWrapper>
    </S.DashboardContainer>
  );
};

export default DashboardPage;
