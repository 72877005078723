import styled from 'styled-components';
import SearchIcon from '@mui/icons-material/Search';

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  border: 1px solid var(--Light-Azul---light, #4BBFEF);
  background: var(--Dark-Branco, #FFF);
  padding-left: 40px;
  border-radius: 8px;
  padding: 4px 6px;
  margin-right: 6px;
  margin-top: 3px;
`;

export const SearchInput = styled.input`
  flex: 1;
  border: none;
  background: transparent;
  outline: none;
  font-family: Roboto;
  font-size: 14px;
  line-height: 16px;
  color: var(--gray-900, #383838);
  ::placeholder {
    color: var(--gray-400, #B8B8B8);
  }
  display:flex;
`;

export const SearchIconButton = styled.button`
  background: none;
  border: none;
  padding: 16px;
  cursor: pointer;
  margin-right: 8px; /* Adicionando margem à direita */
`;
