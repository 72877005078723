import React, { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import * as S from "./styles";
import {VisibilityButton
} from "components/Buttons/ActionButton";
import { getBeneficiaryType, convertDateFromApi, scrollToTop } from "utils";
import { getIrregularityTypeLabel } from "utils";
import { Link } from "react-router-dom";
import NewStatus from "components/UserStatus/NewStatus";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";
import { isAfter, isBefore, addDays } from "date-fns";

const getNisAndBirthDate = (row) => {
  switch (row.b_type) {
    case "1": // Gestante
      return [{ nis: row.nis, birth: row.birth }];
    case "2": // Criança (Síndrome congênita)
      return [{ nis: row.child_nis, birth: row.child_birth }];
    case "3": // Criança (0 a 5 anos)
      return [{ nis: row.child_nis, birth: row.child_birth }];
    case "4": // Gêmeos
      return [
        { nis: row.child_nis, birth: row.child_birth },
        { nis: row.twin_child_nis, birth: row.twin_child_birth }
      ];
    default:
      return [{ nis: row.nis, birth: row.birth }];
  }
};

const TableIrregularity = ({
  beneficiaryList,
  page = 1,
  totalPages,
  setPage,
}) => {
  const handleChange = (event, value) => {
    setPage(value);
    scrollToTop();
  };

  const [latestIrregularity, setLatestIrregularity] = useState(new Date());
  // useEffect(() => {
  //   if (beneficiaryList.length > 0) {
  //     let currentLatestIrregularity = new Date("1900-01-01");
  //     beneficiaryList.forEach((benefit) => {
  //       benefit.benefit_irregularities.forEach((irregularity) => {
  //         const irregularityDate = new Date(irregularity.irregularity_date);
  //         currentLatestIrregularity = isAfter(
  //           irregularityDate,
  //           currentLatestIrregularity
  //         )
  //           ? irregularityDate
  //           : currentLatestIrregularity;
  //       });
  //       setLatestIrregularity(currentLatestIrregularity);
  //     });
  //   }
  // }, []);

  useEffect(() => {
    if (beneficiaryList.length > 0) {
      let currentLatestIrregularity = new Date("1900-01-01");
      beneficiaryList.forEach((benefit) => {
        benefit.benefit_irregularities.forEach((irregularity) => {
          const irregularityDate = new Date(irregularity.irregularity_date);
          currentLatestIrregularity = isAfter(
            irregularityDate,
            currentLatestIrregularity
          )
            ? irregularityDate
            : currentLatestIrregularity;
        });
        setLatestIrregularity(currentLatestIrregularity);
      });
    }
  }, []);

  

  return (
    <S.TableContainer>
      <S.CabecalhoTabela>
        <Typography variant="h6" style={{ marginTop: 0, marginBottom: 0 }}>
          Lista de todos os beneficiários irregulares
        </Typography>
      </S.CabecalhoTabela>

      <S.StyledTable>
        <S.StyledTableHead>
          <tr>
            <S.StyledTableHeaderCell>Nome</S.StyledTableHeaderCell>
            <S.StyledTableHeaderCell>NIS</S.StyledTableHeaderCell>
            <S.StyledTableHeaderCell>Município</S.StyledTableHeaderCell>
            <S.StyledTableHeaderCell>Tipo de Irregularidade </S.StyledTableHeaderCell>
            <S.StyledTableHeaderCell>Data da Irregularidade</S.StyledTableHeaderCell>
            <S.StyledTableHeaderCell></S.StyledTableHeaderCell>
            <S.StyledTableHeaderCell>Ação</S.StyledTableHeaderCell>
          </tr>
        </S.StyledTableHead>
        <S.StyledTableBody>
          {beneficiaryList?.map((benefit) => {
            const beneficiaryType = getBeneficiaryType(benefit.b_type);
            const beneficiaryName =
              benefit.pregnant_name || benefit.child_name || benefit.twin_child_name || "";
             const nisAndBirthDates = getNisAndBirthDate(benefit);
            const irregularity = benefit.benefit_irregularities[0] || {};

            const irregularityDate = new Date(irregularity.irregularity_date);

            const isRecentIrregularity =
              isBefore(new Date(), addDays(irregularityDate, 7)) &&
              isAfter(new Date(), irregularityDate);

            
            return (
              <S.StyledTableRow key={benefit.id}>
                <S.StyledTableCell>
                  {benefit?.name || ""}
                  {beneficiaryType && beneficiaryName && (
                    <>
                      <br />
                      <S.StyledTableBenef>
                        {beneficiaryType === 'Gêmeos' ? 'Criança': beneficiaryType}: 
                      </S.StyledTableBenef>
                      {beneficiaryName}
                      {beneficiaryType === 'Gêmeos' && (
                        <>
                          <br />
                          <S.StyledTableBenef>Criança: </S.StyledTableBenef>
                          {benefit.twin_child_name}
                        </>
                      )}
                    </>
                  )}
                </S.StyledTableCell>
                <S.StyledTableCell>
                  {nisAndBirthDates.map(({ nis }, index) => (
                    <div key={index}>{nis}</div>
                  ))}
                </S.StyledTableCell>
                <S.StyledTableCell>{benefit?.person?.family?.city || "Não informado"}</S.StyledTableCell>
                <S.StyledTableCell>{getIrregularityTypeLabel(irregularity.code)}</S.StyledTableCell>
                <S.StyledTableCell>{convertDateFromApi(irregularity.irregularity_date)?.toLocaleDateString("pt-BR")} 
                </S.StyledTableCell>
                <S.StyledTableCell>
                  {/* {latestIrregularity.toISOString().split("T")[0] ===
                    benefit.benefit_irregularities[0]?.irregularity_date && (
                    <NewStatus />
                  )} */}
                   {isRecentIrregularity && <NewStatus />}
                </S.StyledTableCell>

                <S.StyledTableCell>
                  <S.ButtonsContainer>
                    <Link to={`/beneficiarios/${benefit.id}`}>
                      <VisibilityButton />
                    </Link>
                  </S.ButtonsContainer>
                </S.StyledTableCell>
              </S.StyledTableRow>
            );
          })}
        </S.StyledTableBody>
      </S.StyledTable>
      <S.TableFooter>
        <Typography>
          Página {page} de {totalPages}
        </Typography>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handleChange}
          renderItem={(item) => (
            <PaginationItem
              slots={{ previous: WestIcon, next: EastIcon }}
              {...item}
            />
          )}
        />
      </S.TableFooter>
    </S.TableContainer>
  );
};

export default TableIrregularity;
