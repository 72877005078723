import styled, { css } from 'styled-components';

export const HeaderContainer = styled.div`
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  position: fixed;
  z-index: 1100;
  top: 0;
  left: auto;
  right: 0;
  background-color: #fff;
  color: orange;
  z-index: 1201;
`;
export const TransparentButton = styled.button`
  border: none;
  background: none;
  width: 100%;
  height: 100%;
  flex-shrink: 1;
  text-align: left;
  color: transparent;
  cursor: pointer;
`;
