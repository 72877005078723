import React, { useState } from "react";
import * as S from "./styles";
import CompTextField from "components/CompTextField";
import { convertDateFromApi } from "utils";
import { getIrregularityTypeLabel } from "utils";
import { lightBackground, lightVerde } from "styles/variables";
import stylesCompTextField from "components/CompTextField/styles";

const Forms = ({
  isEdit,
  isView,
  beneficiaryData = null,
  register,
  unregister,
  errors,
}) => {
    const renderIrregularities = () => {
      const styleBox = (active) => {
        return {
          backgroundColor: active ? lightVerde : lightBackground,
          padding: "1px 9px",
          borderRadius: "9px",
          color: "white",
          marginLeft: '9px'
        };
      };

      const irregularitiesTypes = () => {
        return (
          <div>
            {beneficiaryData?.benefit_irregularities?.map(
              (irregularity, index) => {
                const status = (
                  <div style={styleBox(irregularity.active)}>
                    {irregularity.active ? "Ativa" : "Inativa"}
                  </div>
                );

                return (
                  <div
                    key={index}
                    style={{
                      marginBottom: "10px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <h5 style={{fontWeight: 'normal'}} >{getIrregularityTypeLabel(irregularity.code)}</h5>
                    {status}
                  </div>
                );
              }
            )}
          </div>
        );
      };

    return (
      <div style={{ width: "73vw" }}>
        <div style={stylesCompTextField.textFieldLabel}>IRREGULARIDADES</div>
        <div style={{ ...stylesCompTextField.viewContainer, width: "100%" }}>
          {irregularitiesTypes()}
        </div>
      </div>
    );
  };

  return (
    <S.Form>
      <S.FormRowDatas>
        {/* Linha 1 */}
        <CompTextField
          isView={isView}
          edit={isEdit}
          apiText={convertDateFromApi(
            beneficiaryData?.end_date
          )?.toLocaleDateString("pt-BR")}
          textLabel="DATA DE EXPIRAÇÃO DO BENEFÍCIO:"
          fromNis={true}
          placeholder="dd/mm/aaaa"
          enabled={false}
          required
          style={{ flexGrow: "1" }}
          name="end_date"
          register={register}
          unregister={unregister}
          error={errors.end_date}
          helperText={errors.end_date && errors.end_date.message}
        />
      </S.FormRowDatas>
      {(isEdit || isView) && beneficiaryData?.benefit_irregularities?.length > 0 && renderIrregularities()}
    </S.Form>
  );
};

export default Forms;
