import React, { useState, useEffect } from "react";
import styles from "./stylesOld";
import Toolbar from "@mui/material/Toolbar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from '@mui/icons-material/Logout';
import logoCria from "../../assets/image/logoCria.svg";
import { useNavigate } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import * as S from "./styles";
import { useSelector } from "react-redux";
import * as C from "utils/constants";
import * as palette from "styles/variables";
import { setUserAgent } from "store/userSlice";
import { Link } from 'react-router-dom';

const getAcronym = (name) => {
  if (!name) return "EA"; // Default to 'EA' if name is not available
  const words = name.split(" ");
  const acronym = words
    .map((word) => word[0])
    .join("")
    .toUpperCase();
  return acronym.slice(0, 2); // Only take the first two letters
};

const Header = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [userLevel, setUserLevel] = useState(null);
  const { agent, logout } = useAuth();
  const user = useSelector((state) => state.user.user);


  useEffect(() => {
    if (user) {
      const user_level = C.a_type_options.find(
        (type) => user.agent_type === type.value
      );
      setUserLevel(user_level);
    }
  }, [user]);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    navigate("/");
    logout();
  };

  const handleEditProfile = () => {
    if (agent && agent.id) {
      navigate(`/agentes/${agent.id}/editar`);
    }
    handleMenuClose();
  };

  const handleChangePassword = () => {
    if (agent && agent.id) {
      navigate(`/alterar-senha`);
    }
    handleMenuClose();
  };

  const acronym = agent ? getAcronym(agent.name) : "EA";

  return (
    <S.HeaderContainer>
      <Toolbar style={styles.topBar}>
        <Link to='/dashboard' >
        <div>
          <img src={logoCria} alt="Logo" style={styles.logo} />
        </div>
        </Link>
        <div style={styles.userSection}>
          <div style={{ padding: "20px" }}>
            <div style={styles.userTextContainer}>
              <div style={styles.userText}>Gestão Cartão CRIA | </div>
              <div style={styles.subtitle1}> SECRIA</div>
            </div>
            <div style={styles.secondLine}>
              {agent
                ? `${agent.name} | ${agent.work_location}`
                : "Nome Município"}
            </div>
            <div
              style={{
                borderRadius: "15px",
                backgroundColor: palette.lightAzul,
                color: "white",
                width: "auto",
                display: "inline-block",
                padding: "2px 5px",
              }}
            >
              <h5>{userLevel?.textLabel}</h5>
            </div>
          </div>
          <div style={styles.avatarCircle}>
            <IconButton onClick={handleMenuClick} style={styles.avatarCircle}>
              <div style={styles.avatarText}>{acronym}</div>
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              PaperProps={{
                style: {
                  // width: "242px",
                  // height: "204px",
                  flexShrink: 0,
                  opacity: 0.8,
                  background: "var(--light-amarelo-light, #F9922A)",
                },
              }}
            >
              {/* <MenuItem style={styles.menuItem} onClick={handleEditProfile}>
                <AccountCircleIcon style={styles.menuItemIcon} />
                <span style={styles.menuItemText}>Editar perfil</span>
              </MenuItem> */}
              <MenuItem style={styles.menuItem} onClick={handleChangePassword}>
                <AccountCircleIcon style={styles.menuItemIcon} />
                <span style={styles.menuItemText}>Alterar senha</span>
              </MenuItem>
              <MenuItem style={styles.menuItem} onClick={handleLogout}>
                <LogoutIcon style={styles.menuItemIcon} />
                <S.TransparentButton>
                  <span style={styles.menuItemText}>Sair</span>
                </S.TransparentButton>
              </MenuItem>
            </Menu>
          </div>
        </div>
      </Toolbar>
    </S.HeaderContainer>
  );
};

export default Header;
