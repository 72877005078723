import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RestoreIcon from "@mui/icons-material/Restore";

const StyledIconButton = styled(Button)({
  borderRadius: '50%',
  width: '40px',
  height: '40px',
  minWidth: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: '2px solid #70AC17',
  backgroundColor: 'var(--Light-Verde---light, #88C54B)',
  '&:hover': {
    backgroundColor: '#88C54B',
  },
});

export const DownloadButton = () => {
  const handleClick = () => {};

  return (
    <StyledIconButton
      variant="contained"
      onClick={handleClick}
    >
      <BorderColorIcon />
    </StyledIconButton>
  );
};

export const VisibilityButton = () => {
  const handleClick = () => {};

  return (
    <StyledIconButton
      variant="contained"
      onClick={handleClick}
    >
      <VisibilityIcon />
    </StyledIconButton>
  );
};

export const AddButton = () => {
  const handleClick = () => {};

  return (
    <StyledIconButton
      variant="contained"
      onClick={handleClick}
    >
      <AddCircleOutlineIcon />
    </StyledIconButton>
  );
};

export const HistoryButton = () => {
  const handleClick = () => {};

  return (
    <StyledIconButton
      variant="contained"
      onClick={handleClick}
    >
      <RestoreIcon />
    </StyledIconButton>
  );
};
