import React, { createContext, useContext, useMemo } from "react";
import { useLocalStorage } from "./useLocalStorage";
import { useApi } from "hooks/useApis";
import { useDispatch } from "react-redux";
import { setUserAgent, clearUserAgent } from "store/userSlice";

const AuthContext = createContext({
  user: null,
  cpf: null,
  token: null,
  login: null,
  logout: null,
  expirationWarning: null,
  setExpirationWarning: null,
});
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("cria-web@user", null);
  const [cpf, setCpf] = useLocalStorage("cria-web@user", null);
  const [agent, setAgent] = useLocalStorage("cria-web@agent", null);
  const [userAgentName, setUserAgentName] = useLocalStorage(
    "cria-web@userAgentName",
    null
  );
  const [token, setToken] = useLocalStorage("cria-web@token", null);
  const [detail, setDetail] = useLocalStorage("cria-web@detail", null);
  const [expirationWarning, setExpirationWarning] = useLocalStorage(
    "cria-web@expirationWarning",
    false
  );
  const api = useApi();
  const dispatch = useDispatch();

  const login = async (cpf, password) => {
    const response = await api.login(cpf, password);
    const data = response.data;
    if (data.access) {
      setCpf(cpf);
      setToken(data.access);
      setAgent(data.agent);

      if (data.agent) {
        setUserAgentName(data.agent?.name);
      }
      dispatch(setUserAgent(data.agent));

      return { isLogged: true, response };
    }
    setDetail(data.detail);

    return { isLogged: false, response };
  };

  const checkToken = async () => {
    try {
      await api.getCadVersion()
      return true;
    } catch (error) {
      console.error(error)
      return false;
    }
  };

  const logout = async () => {
    sessionStorage.removeItem("cria-web@agentFilters");
    sessionStorage.removeItem("cria-web@beneficiaryFilters");
    setCpf(null);
    setUserAgentName(null);
    setUser(null);
    setAgent(null);
    setToken(null);
    setDetail(null);
    setExpirationWarning(false);
    dispatch(clearUserAgent());
  };

  const value = useMemo(
    () => ({
      user,
      token,
      cpf,
      agent,
      detail,
      login,
      logout,
      checkToken,
      userAgentName,
      expirationWarning,
      setExpirationWarning,
    }),
    [user, token, cpf, userAgentName, agent, detail, expirationWarning]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
