import React, { useState } from "react";
import * as S from "./styles";
import PhotoBenef from "../../../assets/image/beneficiaryPhoto.svg";
import CompTextField from "components/CompTextField";
import CompDateField from "../../CompDateField";
import { useApi } from "hooks/useApis";
import { convertDateFromApi } from "utils";
import AgentType from "utils/agentType";
import { NisField } from "components/NisField";

const Forms = ({
  isView,
  isEdit,
  beneficiaryData = null,
  register,
  unregister,
  watch,
  setValue,
  errors,
}) => {
  const nis = watch("child_nis");
  const api = useApi();
  const agent_type = AgentType();

  const handleNis = async (data) => {
    setValue('child_name', data.name);
    setValue('child_cpf', data.cpf);
    setValue('child_birth', data.birth);
    setValue('end_date', data.end_date)

    const dateInfo = await api.getImportantDates({
      b_type: "3",
      child_birth: data.birth
    });

    setValue('end_date', convertDateFromApi(dateInfo?.end_date)?.toLocaleDateString('pt-BR'));
  }

  return (
    <S.Form>
      <S.FormGeral>
        <S.PhotoContainer src={PhotoBenef} alt="Logo" />

        <S.DadosBox>
          <S.FormRow>
            {/* Linha 1 */}

            <NisField
              nis={nis}
              isView={isView}
              isEdit={isEdit}
              apiText={beneficiaryData?.child_nis}
              benefitNis={beneficiaryData?.child_nis}
              textLabel="NIS DA CRIANÇA:"
              name="child_nis"
              register={register}
              unregister={unregister}
              error={errors.child_nis}
              helperText={errors.child_nis && errors.child_nis.message}
              handleNis={handleNis}
              setValue={setValue}
            />
            <CompTextField
              isView={isView}
              apiText={beneficiaryData?.child_name}
              textLabel="NOME COMPLETO DA CRIANÇA:"
              required
              fromNis={true}
              name="child_name"
              register={register}
              unregister={unregister}
              style={{ flexGrow: "2" }}
              error={errors.child_name}
              helperText={errors.child_name && errors.child_name.message}
            // enabled={false}
            />
          </S.FormRow>
          <S.FormRowWithMarginDados>
            {/* Linha 2 */}
            <CompTextField
              isView={isView}
              apiText={beneficiaryData?.child_cpf}
              textLabel="CPF DA CRIANÇA:"
              //mask="999.999.999-99"
              fromNis={true}
              placeholder="xxx.xxx.xxx-xx"
              required
              name="child_cpf"
              register={register}
              unregister={unregister}
              style={{ flexGrow: "1" }}
              error={errors.child_cpf}
              helperText={errors.child_cpf && errors.child_cpf.message}
            // enabled={false}
            />
            <CompDateField
              isView={isView}
              labelText="DATA DE NASCIMENTO:"
              apiText={beneficiaryData?.child_birth}
              required
              name="child_birth"
              register={register}
              unregister={unregister}
              error={errors.child_birth}
              helperText={errors.child_birth && errors.child_birth.message}
              disabled={true}
            />
          </S.FormRowWithMarginDados>
          <S.FormRowWithMarginDados>
            <CompTextField
              textLabel="CNS DA CRIANÇA:"
              type='number'
              isView={isView}
              apiText={beneficiaryData?.child_cns}
              edit={isEdit}
              required
              name="child_cns"
              register={register}
              unregister={unregister}
              style={{ flexGrow: "1" }}
              error={errors.child_cns}
              helperText={errors.child_cns && errors.child_cns.message}
            // enabled={true}
            />
          </S.FormRowWithMarginDados>
        </S.DadosBox>
      </S.FormGeral>
    </S.Form>
  );
}

export default Forms;
