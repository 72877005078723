import * as S from './styles';
import emojiSuccess from '../../../assets/image/successemoji.svg';
import {
  Emoji,
  CloseButton,
  AlertContent,
} from "../alertStyles"

const SuccessAlert = ({ open, onClose, alertText }) => {
  if (open)
    return (
      <S.AlertBox>
        <CloseButton onClick={onClose}>x</CloseButton>
        <Emoji src={emojiSuccess} alt="Emoji Description" />
        <S.AlertTitle>Tudo certo!</S.AlertTitle>
        <AlertContent>{alertText}</AlertContent>
      </S.AlertBox>
    );
};

export default SuccessAlert;
