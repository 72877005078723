import bgAltSenha from '../../assets/image/bg-alterar-senha.svg';
import styled from 'styled-components';
import OutlinedInput from '@mui/material/OutlinedInput';

export const RecoverPasswordContainer = styled.div`
  background: url(${bgAltSenha});
  position: fixed;
  background-repeat: no-repeat;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background-size: cover;
  background-position: center;
  z-index: 1;
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  z-index: 2; 
`;

export const ButtonsConteiner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2vw;
`;
export const InputBox = styled.div`
  height: 200px;
  width: 416px;

`;
export const RecoverPasswordBox = styled.div`
  width: 512px;
  height: 581px;
  border-radius: 8px;
  background: rgba(75, 191, 239, 0.24);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
   z-index: 3;
`;

export const HeaderText = styled.div`
  color: #f26a1d;
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 46px;
`;

export const RecomendacoesConteiner = styled.div`
  p {
    fontfamily: Roboto;
    font-size: 16px;
    line-height: 24px;
    width: 387px;
    font-weight: 300;
    color: var(--dark-preto, #222);
    text-align: center;
  }
`;
