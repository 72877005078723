import * as S from './styles';
import FotoBenef from 'assets/image/beneficiaryPhoto.svg';
import CompTextField from 'components/CompTextField';
import CompDateField from 'components/CompDateField';
import AgentType from 'utils/agentType';
import { NisField } from 'components/NisField';

const Forms = ({
  beneficiaryData,
  isView,
  isEdit,
  register,
  unregister,
  watch,
  setValue,
  errors,
}) => {
  const nis = watch('nis');

  const handleNis = (data) => {
    setValue('name', data.name);
    setValue('responsible_cpf', data.cpf);
    setValue('birth', data.birth);
    setValue('rg', data.rg);
    setValue('rg_issuer', data['rg_issuer']);
    setValue('rg_uf', data['rg_uf']);

    setValue('contact_number_one', data['family']['contact_number_one']);
    setValue('contact_number_two', data['family']['contact_number_two']);
    setValue('street_type', data['family']['street_type']);
    setValue('street_name', data['family']['street_name']);
    setValue('street_number', data['family']['street_number']);
    setValue('complement', data['family']['complement']);
    setValue('name_location', data['family']['name_location']);
    setValue('city', data['family']['city']);
    setValue('updated', data['updated'] ? 'Sim' : 'Não');
  }

  return (
    <S.Form>
      <S.FormGeral>
        <S.PhotoContainer src={FotoBenef} alt="Logo" />

        <S.DadosBox>
          <S.FormRow>
            {/* Linha 1 */}
            <NisField
              nis={nis}
              benefitNis={beneficiaryData?.nis}
              textLabel="NIS:"
              isView={isView}
              isEdit={isEdit}
              apiText={beneficiaryData?.nis}
              name="nis"
              register={register}
              unregister={unregister}
              error={errors.nis}
              helperText={errors.nis && errors.nis.message}
              handleNis={handleNis}
              setValue={setValue}
            />

            <CompTextField
              isView={isView}
              apiText={beneficiaryData?.name}
              textLabel="NOME COMPLETO:"
              required
              fromNis={true}
              style={{ flexGrow: '2' }}
              name={'name'}
              register={register}
              unregister={unregister}
              error={errors.name}
              helperText={errors.name && errors.name.message}
            // enabled={false}
            />
          </S.FormRow>
          <S.FormRowWithMarginDados>
            {/* Linha 2 */}
            <CompTextField
              isView={isView}
              apiText={beneficiaryData?.responsible_cpf}
              textLabel="CPF:"
              required
              fromNis={true}
              placeholder="xxx-xxx-xxx-xx"
              //mask="999.999.999-99"
              style={{ flexGrow: '1' }}
              name={'responsible_cpf'}
              register={register}
              unregister={unregister}
              error={errors.responsible_cpf}
              helperText={errors.responsible_cpf && errors.responsible_cpf.message}
            // enabled={false}
            />
            <CompDateField
              isView={isView}
              fromNis={true}
              labelText="DATA DE NASCIMENTO:"
              apiText={beneficiaryData?.birth}
              required
              name="birth"
              register={register}
              unregister={unregister}
              error={errors.birth}
              helperText={errors.birth && errors.birth.message}
              disabled={true}
            />
          </S.FormRowWithMarginDados>
          <S.FormRowWithMarginDados>
          <CompTextField
              isView={isView}
              fromNis={true}
              apiText={beneficiaryData?.provisional_contact_number}
              textLabel="CELULAR:"
              mask="(99) 9 9999-9999"
              placeholder="(99) 9 9999-9999"
              style={{ flexGrow: '1' }}
              name="provisional_contact_number"
              register={register}
              unregister={unregister}
              error={errors.provisional_contact_number}
              helperText={errors.provisional_contact_number && errors.provisional_contact_number.message}
            // enabled={false}
            />
            <CompTextField
              isView={isView}
              fromNis={true}
              apiText={beneficiaryData?.person?.family?.contact_number_one}
              textLabel="CELULAR: (CadÚnico)"
              //mask="(99) 9 9999-9999"
              placeholder="(99) 9 9999-9999"
              style={{ flexGrow: '1' }}
              name="contact_number_one"
              register={register}
              unregister={unregister}
              error={errors.contact_number_one}
              helperText={errors.contact_number_one && errors.contact_number_one.message}
            // enabled={false}
            />
            <CompTextField
              isView={isView}
              fromNis={true}
              apiText={beneficiaryData?.person?.family?.contact_number_two}
              textLabel="CELULAR SECUNDÁRIO: (CadÚnico)"
              //mask="(99) 9 9999-9999"
              placeholder="(99) 9 9999-9999"
              style={{ flexGrow: '1' }}
              name="contact_number_two"
              register={register}
              unregister={unregister}
              error={errors.contact_number_two}
              helperText={errors.contact_number_two && errors.contact_number_two.message}
            // enabled={false}
            />
          </S.FormRowWithMarginDados>
        </S.DadosBox>
      </S.FormGeral>
      <S.FormRowWithMarginDadosCns>
        <CompTextField
          isView={isView}
          type='number'
          apiText={beneficiaryData?.cns}
          edit={isEdit}
          textLabel="CNS GESTANTE:"
          required
          style={{ flexGrow: '2' }}
          name="cns"
          register={register}
          unregister={unregister}
          error={errors.cns}
          helperText={errors.cns && errors.cns.message}
        // enabled={!(isEdit && ["2","3"].includes(agent_type))}
        />
        <CompTextField
          isView={isView}
          fromNis={true}
          apiText={beneficiaryData?.person?.rg}
          textLabel="RG:"
          style={{ flexGrow: '2' }}
          name="rg"
          register={register}
          unregister={unregister}
          error={errors.rg}
          helperText={errors.rg && errors.rg.message}
        // enabled={false}
        />
        <CompTextField
          isView={isView}
          fromNis={true}
          apiText={beneficiaryData?.person?.rg_issuer}
          style={{ flexGrow: '1' }}
          textLabel="ÓRGÃO EMISSOR:"
          name="rg_issuer"
          register={register}
          unregister={unregister}
          error={errors.rg_issuer}
          helperText={errors.rg_issuer && errors.rg_issuer.message}
        // enabled={false}
        />
        <CompTextField
          isView={isView}
          fromNis={true}
          apiText={beneficiaryData?.person?.rg_uf}
          style={{ flexGrow: '1' }}
          textLabel="UF EMISSOR:"
          name="rg_uf"
          register={register}
          unregister={unregister}
          error={errors.rg_uf}
          helperText={errors.rg_uf && errors.rg_uf.message}
        // enabled={false}
        />
      </S.FormRowWithMarginDadosCns>
    </S.Form>
  );
};

export default Forms;
