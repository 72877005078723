const styles = {
    textFieldContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "8px",
    },
    textFieldLabel: {
      color: "var(--Gray-900, var(--900, #383838))",
      fontFamily: "Roboto",
      fontSize: "1.1rem",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "1.5rem",
      marginTop: "0px",
      paddingTop: "0px",
    },
    textField: {
      height: "20px",
      padding: "10px 14px",
      borderRadius: "6px",
      border: "1px solid var(--Light-blue---light, #4BBFEF)",
      background: "var(--Dark-white, #FFF)",
      fontFamily: "Roboto",
      fontSize: "1rem", //18px
      fontWeight: 300,
    },
    apiData: {
      display: "flex",
      height: "24px",
      flexDirection: "column",
      justifyContent: "center",
      fontFamily: "Roboto",
      fontSize: "1rem", //18px
      fontWeight: 300,
    },
    heightNormalizer: {
      height: "1.5rem",
    },
  };
  
  export default styles;
  