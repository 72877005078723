import styled, { css } from "styled-components";

export const HistoricViewContainer = styled.div`
  width: 1129px;
  margin-bottom: 40px;
`;

export const HistoricViewHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 47px;
`;

export const HistoricViewBox = styled.div`
  flex: 1;
  max-width: 1129px;
  height: 40px;
  border-radius: 8px;
  background: var(--Light-Azul---light, #4bbfef);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;


  p.historicText {
    color: var(--Dark-Branco, #fff);
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  img.calendarIcon {
    width: 24px;
    height: 24px;
    margin-right: 16px;
    margin-left: 16px;
  }
`;

export const HistoricViewTable = styled.table`
  width: 100%;

  tr {
    height: 63px;
  }

  tr:first-child {
    height: auto;
  }

  th:first-child {
    width: 70px;
  }

  th {
    text-align: left;
    color: #88c54b;
    padding: 8px 0;
  }

  .changeDateCell {
    display: flex;
    flex-direction: column;
    font-weight: 700;
  }
`;
export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px; // Altura definida para centralizar o loading no espaço do histórico
  width: 100%;
  padding: 20px;
`;

export const TdTimeline = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;

  .dot {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-self: baseline;
    -ms-flex-item-align: baseline;
    align-self: baseline;
    border-style: solid;
    border-width: 2px;
    padding: 11px;
    border-radius: 50%;
    box-shadow: none;
    margin: 11.5px 0 0;
    background-color: transparent;
    color: #4bbfef;
    border-color: #4bbfef;
    align-self: center;
    margin-top: -1px;
  }

  .connector {
    width: 2px;
    background-color: #4bbfef;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    height: 41px;
    display: flex;
    align-self: center;
    position: absolute;
    margin-top: 65px;
  }

  ${(props) => {
    if (props.outlined) {
      return css`
        .dot {
          border-color: transparent;
          color: #fafafa;
          background-color: #4bbfef;
          /* margin-top: 12px; */
        }
        .connector {
          height: 46px;
          margin-top: 60px;
        }
      `;
    }
  }}
`;
