import * as S from './styles';


const InfoBox = () => {
    return (
        <S.Box>
            <S.AlertTitle>
                A partir do número do NIS, demais dados são preenchidos automaticamente. <b>Em exceção, o Nome da Unidade de saúde.</b>
            </S.AlertTitle>
        </S.Box>
    );
}

export default InfoBox;
