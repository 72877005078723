import * as React from 'react';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';


export const DownloadButton = ({ onClick }) => {
    const handleClick = () => {
    };

    return(
        <Button variant="contained" startIcon={<DownloadIcon/>} sx={{
            background: " var(--Light-Verde---light, #88C54B)",
            width: "92px",
            height: "40px",
            padding: "15px 16px",
            borderRadius: "8px",
            fontFamily: 'Roboto',
            fontFize: '20px',
            fontWeight: 600,
            textTransform: 'none', 

            '&:hover': {
                backgroundColor: "#88C54B",
              },
              }}
              onClick={onClick}
              >
        Baixar
      </Button>
    );
};


