import React from 'react';
import styled from 'styled-components'; // ou '@emotion/styled' se você preferir
import { Spinning } from 'components/Spinning';

const LoadingBoxContainer = styled.div`
  width: 130%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;


const LoadingBox = () => {
  return (
    <LoadingBoxContainer>
      <Spinning />
    </LoadingBoxContainer>
  );
};

export default LoadingBox;

