// import React, { useState } from 'react';
// import Checkbox from '@mui/material/Checkbox';
// import TableContainer from '@mui/material/TableContainer';
// import Paper from '@mui/material/Paper';
// import Table from '@mui/material/Table';
// import TableHead from '@mui/material/TableHead';
// import TableBody from '@mui/material/TableBody';
// import TableRow from '@mui/material/TableRow';
// import TableCell from '@mui/material/TableCell';
// import TablePagination from '@mui/material/TablePagination';
// import { DownloadButton } from 'components/Buttons/DownloadButton';
// import * as S from './styles';

// const TableConsultation = () => {
//   const [checkedItems, setCheckedItems] = useState({});
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(5);

//   const handleCheckboxChange = (id) => {
//     setCheckedItems((prevCheckedItems) => ({
//       ...prevCheckedItems,
//       [id]: !prevCheckedItems[id],
//     }));
//   };

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(+event.target.value);
//     setPage(0);
//   };

//   const rows = [
//     { id: 1, col1: 'Dado 1', col2: 'Dado 2', col3: 'Dado 3', col4: 'Dado 4' },
//     { id: 2, col1: 'Dado 1', col2: 'Dado 2', col3: 'Dado 3', col4: 'Dado 4' },
//     // Adicione mais linhas conforme necessário
//   ];

//   return (
//     <div>
//       <S.TableContainer component={Paper}>
//         <Table>
//           <TableHead>
//             <TableRow>
//               <TableCell>
//                 <Checkbox
//                   indeterminate={Object.values(checkedItems).some((value) => value)}
//                   checked={Object.values(checkedItems).every((value) => value)}
//                 />
//               </TableCell>
//               <TableCell>Solicitante</TableCell>
//               <TableCell>Período de consulta</TableCell>
//               <TableCell>Data da emissão</TableCell>
//               <TableCell>Ação</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
//               <S.StyledTableRow key={row.id}>
//                 <S.StyledTableCell>
//                   <Checkbox
//                     checked={checkedItems[row.id] || false}
//                     onChange={() => handleCheckboxChange(row.id)}
//                   />
//                 </S.StyledTableCell>
//                 <S.StyledTableCell>{row.col1}</S.StyledTableCell>
//                 <S.StyledTableCell>{row.col2}</S.StyledTableCell>
//                 <S.StyledTableCell>{row.col3}</S.StyledTableCell>
//                 <S.StyledTableCell> <DownloadButton/> </S.StyledTableCell>
//               </S.StyledTableRow>
//             ))}
//           </TableBody>
//         </Table>
//       </S.TableContainer>
//       <TablePagination
//          rowsPerPageOptions={[5, 10, 25]}
//          component="div"
//          count={rows.length}
//          rowsPerPage={rowsPerPage}
//          page={page}
//          onPageChange={handleChangePage}
//          onRowsPerPageChange={handleChangeRowsPerPage}
//          labelRowsPerPage="Página de"
//          labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
//       />
//     </div>
//   );
// };

// export default TableConsultation;

import React, { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import * as S from './styles';
import {DownloadButton} from 'components/Buttons/DownloadButton';


const TableConsultation = () => {
  const [checkedItems, setCheckedItems] = useState({});

  const handleCheckboxChange = (id) => {
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [id]: !prevCheckedItems[id],
    }));
  };

  const rows = [
    { id: 1, col1: 'Dado 1', col2: 'Dado 2', col3: 'Dado 3', col4: 'Dado 4' },
    { id: 2, col1: 'Dado 1', col2: 'Dado 2', col3: 'Dado 3', col4: 'Dado 4' },
  ];

  return (
    <S.TableContainer>
      <S.StyledTable>
        <S.StyledTableHead>
          <tr>
            <S.StyledTableHeaderCell>
              <Checkbox
                indeterminate={Object.values(checkedItems).some((value) => value)}
                checked={Object.values(checkedItems).every((value) => value)}
              />
              </S.StyledTableHeaderCell>
              <S.StyledTableHeaderCell>Solicitante</S.StyledTableHeaderCell>
              <S.StyledTableHeaderCell>Período de consulta</S.StyledTableHeaderCell>
            <S.StyledTableHeaderCell>Data da emissão</S.StyledTableHeaderCell>
            <S.StyledTableHeaderCell>Ação</S.StyledTableHeaderCell>
          </tr>
        </S.StyledTableHead>
        <S.StyledTableBody>
          {rows.map((row) => (
            <S.StyledTableRow key={row.id}>
              <S.StyledTableCell>
                <Checkbox
                  checked={checkedItems[row.id] || false}
                  onChange={() => handleCheckboxChange(row.id)}
                />
              </S.StyledTableCell>
              <S.StyledTableCell>{row.col1}</S.StyledTableCell>
              <S.StyledTableCell>{row.col2}</S.StyledTableCell>
              <S.StyledTableCell>{row.col3}</S.StyledTableCell>
              <S.StyledTableCell> <DownloadButton/> </S.StyledTableCell>
            </S.StyledTableRow>
          ))}
        </S.StyledTableBody>
      </S.StyledTable>
    </S.TableContainer>
    
  );
};

export default TableConsultation;
