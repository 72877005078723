import styled from 'styled-components';

export const PageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const PageContent = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 80px;
`;

export const MainContainer = styled.div`
  flex: 1;
  padding-bottom: 100px;
  position: relative;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 30px;
`;

export const FormRowOutros = styled.div`
  display: flex;
  gap: 2vw;
  margin-bottom: 0px;
  width: 73vw;
  max-width: 1400px;
`;

export const FormRowWithMargin = styled.div`
  display: flex;
  gap: 2vw;
  margin-top: 25px;
  padding-top: 0px;
  width: 73vw;
  max-width: 1400px;
`;

export const FormConteiner = styled.div`
  display: flex;
  gap: 2vw;
  width: 741px;
  
`;

export const DateFieldsContainer = styled.div`
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   width:540px;
  
`;


export const Arrow = styled.span`
  margin: 0 10px;
  font-size: 24px;
`;

export const DateInputWrapper = styled.div`
  display: flex;
  align-items: center; 
  //margin-right: 10px;
  margin-top: 0px;
`;

export const DateFieldsTitle = styled.h2`
  color: var(--Gray-900, var(--900, #383838));
  font-family: Roboto;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
`;

export const CabecalhoTabela = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  margin-top: 40px;
  color: #F9922A;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  background:#FFF1E8;
  border-radius: 8px;
  padding: 0 15px;
  height: 53px;
  width: auto;

`;