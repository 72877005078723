import * as S from './styles';


const InactiveStatus  = () => {
    return (
        <S.SuspendedStatus>
            Inativo
        </S.SuspendedStatus>
    );
};

export default InactiveStatus ;