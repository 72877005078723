export const status_options = [
  { value: "1", textLabel: "Ativo" },
  { value: "2", textLabel: "Advertência" },
  { value: "3", textLabel: "Bloqueado" },
  { value: "4", textLabel: "Suspenso" },,
  { value: "0", textLabel: "Cancelado" },
];

export const type_options = [
  { value: "0", textLabel: "Todos os benefícios" },
  { value: "1", textLabel: "Gestante" },
  { value: "2", textLabel: "Criança (síndrome congênita - 0 a 6 anos)" },
  { value: "3", textLabel: "Criança (0 a 6 anos)" },
  { value: "4", textLabel: "Gêmeos" },
];

export const sort_options = [
  { value: "0", textLabel: "Ordem alfabética A-Z" },
  { value: "1", textLabel: "Ordem alfabética Z-A" },
  { value: "2", textLabel: "Data de criação recente" },
  { value: "3", textLabel: "Data de criação antiga" },
];

export const textsCria = {
  pregnant: "Gestante",
  child: "Criança",
  childWithSindrome: "Criança com Síndrome",
  twins: "Gêmeos",
};

export const a_status_options = [
  { value: "1", textLabel: "Ativo" },
  { value: "0", textLabel: "Inativo" },
];

export const a_type_options = [
  //{ value: "0", textLabel: "Todos os agentes"},
  { value: "1", textLabel: "Gestão" },
  { value: "2", textLabel: "Coordenador Municipal" },
  { value: "3", textLabel: "Agente Municipal" },
  { value: "4", textLabel: "Coordenador Estadual" },
  // {value:"5", textLabel: "Cheat"},
  { value: "6", textLabel: "Convidado" },
];

export const states = [
  { value: "AC", textLabel: "AC" },
  { value: "AL", textLabel: "AL" },
  { value: "AP", textLabel: "AP" },
  { value: "AM", textLabel: "AM" },
  { value: "BA", textLabel: "BA" },
  { value: "CE", textLabel: "CE" },
  { value: "DF", textLabel: "DF" },
  { value: "ES", textLabel: "ES" },
  { value: "GO", textLabel: "GO" },
  { value: "MA", textLabel: "MA" },
  { value: "MT", textLabel: "MT" },
  { value: "MS", textLabel: "MS" },
  { value: "MG", textLabel: "MG" },
  { value: "PA", textLabel: "PA" },
  { value: "PB", textLabel: "PB" },
  { value: "PR", textLabel: "PR" },
  { value: "PE", textLabel: "PE" },
  { value: "PI", textLabel: "PI" },
  { value: "RR", textLabel: "RR" },
  { value: "RO", textLabel: "RO" },
  { value: "RJ", textLabel: "RJ" },
  { value: "RN", textLabel: "RN" },
  { value: "RS", textLabel: "RS" },
  { value: "SC", textLabel: "SC" },
  { value: "SP", textLabel: "SP" },
  { value: "SE", textLabel: "SE" },
  { value: "TO", textLabel: "TO" },
];

export const distributionPoints = [
    // {value: 'Selecione', textLabel: 'Selecione'},
    {value: 'Água Branca', textLabel: 'Água Branca'},
    {value: 'Anadia', textLabel: 'Anadia'},
    {value: 'Arapiraca', textLabel: 'Arapiraca'},
    {value: 'Atalaia', textLabel: 'Atalaia'},
    {value: 'Barra de Santo Antônio', textLabel: 'Barra de Santo Antônio'},
    {value: 'Barra de São Miguel', textLabel: 'Barra de São Miguel'},
    {value: 'Batalha', textLabel: 'Batalha'},
    {value: 'Belém', textLabel: 'Belém'},
    {value: 'Belo Monte', textLabel: 'Belo Monte'},
    {value: 'Boca da Mata', textLabel: 'Boca da Mata'},
    {value: 'Branquinha', textLabel: 'Branquinha'},
    {value: 'Cacimbinhas', textLabel: 'Cacimbinhas'},
    {value: 'Cajueiro', textLabel: 'Cajueiro'},
    {value: 'Campestre', textLabel: 'Campestre'},
    {value: 'Campo Alegre', textLabel: 'Campo Alegre'},
    {value: 'Campo Grande', textLabel: 'Campo Grande'},
    {value: 'Canapi', textLabel: 'Canapi'},
    {value: 'Capela', textLabel: 'Capela'},
    {value: 'Carneiros', textLabel: 'Carneiros'},
    {value: 'Chã Preta', textLabel: 'Chã Preta'},
    {value: 'Coité do Nóia', textLabel: 'Coité do Nóia'},
    {value: 'Colônia Leopoldina', textLabel: 'Colônia Leopoldina'},
    {value: 'Coqueiro Seco', textLabel: 'Coqueiro Seco'},
    {value: 'Coruripe', textLabel: 'Coruripe'},
    {value: 'Craíbas', textLabel: 'Craíbas'},
    {value: 'Delmiro Gouveia', textLabel: 'Delmiro Gouveia'},
    {value: 'Dois Riachos', textLabel: 'Dois Riachos'},
    {value: 'Estrela de Alagoas', textLabel: 'Estrela de Alagoas'},
    {value: 'Feira Grande', textLabel: 'Feira Grande'},
    {value: 'Feliz Deserto', textLabel: 'Feliz Deserto'},
    {value: 'Flexeiras', textLabel: 'Flexeiras'},
    {value: 'Girau do Ponciano', textLabel: 'Girau do Ponciano'},
    {value: 'Ibateguara', textLabel: 'Ibateguara'},
    {value: 'Igaci', textLabel: 'Igaci'},
    {value: 'Igreja Nova', textLabel: 'Igreja Nova'},
    {value: 'Inhapi', textLabel: 'Inhapi'},
    {value: 'Jacaré dos Homens', textLabel: 'Jacaré dos Homens'},
    {value: 'Jacuípe', textLabel: 'Jacuípe'},
    {value: 'Japaratinga', textLabel: 'Japaratinga'},
    {value: 'Jaramataia', textLabel: 'Jaramataia'},
    {value: 'Jequiá da Praia', textLabel: 'Jequiá da Praia'},
    {value: 'Joaquim Gomes', textLabel: 'Joaquim Gomes'},
    {value: 'Jundiá', textLabel: 'Jundiá'},
    {value: 'Junqueiro', textLabel: 'Junqueiro'},
    {value: 'Lagoa da Canoa', textLabel: 'Lagoa da Canoa'},
    {value: 'Limoeiro de Anadia', textLabel: 'Limoeiro de Anadia'},
    {value: 'Maceió', textLabel: 'Maceió'},
    {value: 'Major Isidoro', textLabel: 'Major Isidoro'},
    {value: 'Mar Vermelho', textLabel: 'Mar Vermelho'},
    {value: 'Maragogi', textLabel: 'Maragogi'},
    {value: 'Maravilha', textLabel: 'Maravilha'},
    {value: 'Marechal Deodoro', textLabel: 'Marechal Deodoro'},
    {value: 'Maribondo', textLabel: 'Maribondo'},
    {value: 'Mata Grande', textLabel: 'Mata Grande'},
    {value: 'Matriz de Camaragibe', textLabel: 'Matriz de Camaragibe'},
    {value: 'Messias', textLabel: 'Messias'},
    {value: 'Minador do Negrão', textLabel: 'Minador do Negrão'},
    {value: 'Monteirópolis', textLabel: 'Monteirópolis'},
    {value: 'Murici', textLabel: 'Murici'},
    {value: 'Novo Lino', textLabel: 'Novo Lino'},
    {value: 'Olho dÁgua das Flores', textLabel: 'Olho dÁgua das Flores'},
    {value: 'Olho dÁgua do Casado', textLabel: 'Olho dÁgua do Casado'},
    {value: 'Olho dÁgua Grande', textLabel: 'Olho dÁgua Grande'},
    {value: 'Olivença', textLabel: 'Olivença'},
    {value: 'Ouro Branco', textLabel: 'Ouro Branco'},
    {value: 'Palestina', textLabel: 'Palestina'},
    {value: 'Palmeira dos Índios', textLabel: 'Palmeira dos Índios'},
    {value: 'Pão de Açúcar', textLabel: 'Pão de Açúcar'},
    {value: 'Pariconha', textLabel: 'Pariconha'},
    {value: 'Paripueira', textLabel: 'Paripueira'},
    {value: 'Passo de Camaragibe', textLabel: 'Passo de Camaragibe'},
    {value: 'Paulo Jacinto', textLabel: 'Paulo Jacinto'},
    {value: 'Penedo', textLabel: 'Penedo'},
    {value: 'Piaçabuçu', textLabel: 'Piaçabuçu'},
    {value: 'Pilar', textLabel: 'Pilar'},
    {value: 'Pindoba', textLabel: 'Pindoba'},
    {value: 'Piranhas', textLabel: 'Piranhas'},
    {value: 'Poço das Trincheiras', textLabel: 'Poço das Trincheiras'},
    {value: 'Porto Calvo', textLabel: 'Porto Calvo'},
    {value: 'Porto de Pedras', textLabel: 'Porto de Pedras'},
    {value: 'Porto Real do Colégio', textLabel: 'Porto Real do Colégio'},
    {value: 'Quebrangulo', textLabel: 'Quebrangulo'},
    {value: 'Rio Largo', textLabel: 'Rio Largo'},
    {value: 'Roteiro', textLabel: 'Roteiro'},
    {value: 'Santa Luzia do Norte', textLabel: 'Santa Luzia do Norte'},
    {value: 'Santana do Ipanema', textLabel: 'Santana do Ipanema'},
    {value: 'Santana do Mundaú', textLabel: 'Santana do Mundaú'},
    {value: 'São Brás', textLabel: 'São Brás'},
    {value: 'São José da Laje', textLabel: 'São José da Laje'},
    {value: 'São José da Tapera', textLabel: 'São José da Tapera'},
    {value: 'São Luís do Quitunde', textLabel: 'São Luís do Quitunde'},
    {value: 'São Miguel dos Campos', textLabel: 'São Miguel dos Campos'},
    {value: 'São Miguel dos Milagres', textLabel: 'São Miguel dos Milagres'},
    {value: 'São Sebastião', textLabel: 'São Sebastião'},
    {value: 'Satuba', textLabel: 'Satuba'},
    {value: 'Senador Rui Palmeira', textLabel: 'Senador Rui Palmeira'},
    {value: 'Tanque dArca', textLabel: 'Tanque dArca'},
    {value: 'Taquarana', textLabel: 'Taquarana'},
    {value: 'Teotônio Vilela', textLabel: 'Teotônio Vilela'},
    {value: 'Traipu', textLabel: 'Traipu'},
    {value: 'União dos Palmares', textLabel: 'União dos Palmares'},
    {value: 'Viçosa', textLabel: 'Viçosa'},
];

export const irregularities = [
  { value: "1r", textLabel: "RESPONSAVEL FORA DO CADUNICO" },
  { value: "1c", textLabel: "CRIANCA FORA DO CADUNICO" },
  { value: "1g", textLabel: "GESTANTE MENOR FORA DO CADUNICO" },
  { value: "1f", textLabel: "CRIANÇA NO CADÚNICO FORA DO CRIA" },
  {
    value: "2c",
    textLabel: "RESPONSAVEL E CRIANÇA NÃO PERTENCENTES A MESMA FAMILIA",
  },
  {
    value: "2g",
    textLabel: "RESPONSAVEL E GESTANTE MENOR NÃO PERTENCENTES A MESMA FAMILIA",
  },
  { value: "3r", textLabel: "RESPONSÁVEL MENOR DE IDADE" },
  { value: "3g", textLabel: "GESTANTE MENOR ACIMA DA IDADE PERMITIDA" },
  { value: "3c", textLabel: "CRIANÇA COM MAIS DE 6 ANOS" },
  {
    value: "4s",
    textLabel: "GESTANTES COM MAIS DE 45 SEMANAS SEM CRIANÇA CADASTRADA",
  },
  { value: "4c", textLabel: "VERIFICAR MUDANÇA TIPO BENEFICIO" },
  { value: "5", textLabel: "FAMILIAS COM EXCEDENTES DE BENEFICIARIOS" },
  { value: "6r", textLabel: "RESPONSAVEIS COM MAIS DE UM CADASTRO NO CRIA" },
  { value: "6g", textLabel: "GESTANTE MENOR COM MAIS DE UM CADASTRO NO CRIA" },
  { value: "6c", textLabel: "CRIANÇA COM MAIS DE UM CADASTRO NO CRIA" },
  { value: "7", textLabel: "VERIFICA MUDANÇA CIDADE DO BENEFICIARIO" },
  { value: "8", textLabel: "VERIFICA IREGULARIDADES NAS CONDICONALIDADES" },
  { value: "9", textLabel: "BENEFICIÁRIOS FORA DO PERFIL DE RENDA" },
  // { value: "10", textLabel: "ABORTO" },
  // { value: "11", textLabel: "ÓBITO" },
];

export const condicionalTypes = [
  {
    value: "1",
    textLabel: "Consulta Pré-natal",
  },
  { value: "2", textLabel: "Cumprimento do calendário vacinal" },
  {
    value: "3",
    textLabel: "Avaliação Nutricional",
  },
  {
    value: "4",
    textLabel: "Realização de exames de urocultura e EAS (Sumário de urina)",
  },
  {
    value: "5",
    textLabel: "Acompanhamento familiar no CRAS ",
  },
  {
    value: "6",
    textLabel: "Cumprimento do calendário de consultas de puericultura",
  },
  {
    value: "7",
    textLabel: "Avaliação do estado nutricional",
  },
  { value: "8", textLabel: "Justificativa" },
  { value: "9", textLabel: "Acompanhamento do calendário vacinal" },
];

export const fieldLabelsConditionality = {
  benefit: "Benefício",
  check_current: "Verificação Atual",
  check_first: "Primeira Verificação",
  check_last: "Última consulta",
  check_number: "Número de Verificações",
  child_cpf: "CPF da Criança",
  child_name: "Nome da Criança",
  child_nis: "NIS da Criança",
  current_gestational_age: "Idade Gestacional Atual",
  current_gestational_month: "Mês Gestacional Atual",
  height: "Altura",
  imc: "IMC",
  justification: "Justificativa",
  mark_count: "Contagem de Marcas",
  month_justification_choice: "Escolha de Mês de Justificativa",
  nutritional_graph_check: "Verificação do Gráfico Nutricional",
  observation: "Observação",
  other_vaccine_dose: "Outra Dose de Vacina",
  other_vaccine_name: "Outro Nome de Vacina",
  pre_registration: "Pré-registro",
  showed_up: "Compareceu",
  social_assistance: "Assistência Social",
  type_justification: "Tipo de Justificativa",
  url: "URL",
  vaccination_date: "Data de Vacinação",
  vaccine_dose: "Dose da Vacina",
  vaccine_name: "Nome da Vacina",
  warning: "Aviso",
  weight: "Peso",
  created_date: "Data",
};

export const monitoringTypesPregnant = [
  {
    value: "5",
    textLabel: "Acompanhamento familiar no CRAS ",
  },
  {
    value: "1",
    textLabel: "Consulta Pré-natal",
  },
  {
    value: "3",
    textLabel: "Avaliação Nutricional",
  },
  { value: "8", textLabel: "Justificativa" },
];

export const monitoringTypesChild = [
  {
    value: "5",
    textLabel: "Acompanhamento familiar no CRAS ",
  },
  {
    value: "6",
    textLabel: "Informar consulta de Puericultura",
  },
  {
    value: "2",
    textLabel: "Acompanhamento Vacinal",
  },
  {
    value: "7",
    textLabel: "Avaliação Nutricional",
  },
  { value: "8", textLabel: "Justificativa" },
];

export const vaccines = [
  { value: "0", textLabel: "BCG - ID" },
  { value: "1", textLabel: "Vacina Hepatite B" },
  { value: "2", textLabel: "VIP (Vacina inativada poliomielite)" },
  { value: "3", textLabel: "VORH (Vacina Oral de RotavírusHumano)" },
  { value: "4", textLabel: "Vacina pneumocócica 10 (valente)" },
  { value: "5", textLabel: "Vacina meningocócica C (conjugada)" },
  { value: "6", textLabel: "Vacina febre amarela" },
  { value: "7", textLabel: "VOP (vacina oral poliomielite)" },
  { value: "8", textLabel: "VACN SRC (tríplice viral)" },
  { value: "9", textLabel: "Vacina hepatite A" },
  { value: "10", textLabel: "DTP (tríplice bacteriana)" },
  { value: "11", textLabel: "SCRV (tetra viral)" },
  { value: "12", textLabel: "Vacina varicela" },
  { value: "13", textLabel: "Vacina Covid-19" },
  { value: "14", textLabel: "HPV quadrivalente" },
  { value: "15", textLabel: "Vacina influenza" },
  { value: "16", textLabel: "Outra vacina" },
];

export const vaccines_doses = [
  { value: "0", textLabel: "1º dose" },
  { value: "1", textLabel: "2º dose" },
  { value: "2", textLabel: "3º dose" },
  { value: "3", textLabel: "4º dose" },
  { value: "4", textLabel: "Dose única" },
  { value: "5", textLabel: "Dose de reforço" },
  { value: "6", textLabel: "Outra" },
];

export const justification_types_pregnant = [
  {
    value: "Não Acompanhamento de Gestantes pela Saúde",
    textLabel: "Não Acompanhamento de Gestantes pela Saúde",
    justifications: [
      { value: "Falecimento", textLabel: "Falecimento" },
      {
        value: "Aborto (Exclusivo para Gestantes)",
        textLabel: "Aborto (Exclusivo para Gestantes)",
      },
      {
        value: "Nascido Morto (Natimorto)",
        textLabel: "Nascido Morto (Natimorto)",
      },
      { value: "Mudança de Estado", textLabel: "Mudança de Estado" },
      {
        value: "Mudança de Município Intraestadual",
        textLabel: "Mudança de Município Intraestadual",
      },
      {
        value:
          "Beneficiária(o) não Localizada(o) (Endereço Incorreto ou Inexistente)",
        textLabel:
          "Beneficiária(o) não Localizada(o) (Endereço Incorreto ou Inexistente)",
      },
    ],
  },
  {
    value: "Descumprimento da Realização de Exames",
    textLabel: "Descumprimento da Realização de Exames",
    justifications: [
      {
        value: "Condições de Saúde que Impedem a Ida à UBS ou Laboratório",
        textLabel: "Condições de Saúde que Impedem a Ida à UBS ou Laboratório",
      },
      {
        value:
          "Fatos que Impedem o Deslocamento/Acesso à UBS ou Laboratório (Enchente, Falta de Transporte, Violência no Território, etc)",
        textLabel:
          "Fatos que Impedem o Deslocamento/Acesso à UBS ou Laboratório (Enchente, Falta de Transporte, Violência no Território, etc)",
      },
      {
        value:
          "Indícios de Situação de Risco Social tal como Negligência, Abuso Sexual, Violência Intrafamiliar e Outras que Impedem a Ida à UBS ou Laboratório",
        textLabel:
          "Indícios de Situação de Risco Social tal como Negligência, Abuso Sexual, Violência Intrafamiliar e Outras que Impedem a Ida à UBS ou Laboratório",
      },
      {
        value:
          "Horario de Atendimento na UBS ou Laboratorio Inviavel para Gestante",
        textLabel:
          "Horario de Atendimento na UBS ou Laboratorio Inviavel para Gestante",
      },
      {
        value:
          "Houve Recusa por Parte da Equipe de Saúde em Realizar o Acompanhamento da Condicionalidade Dentro da Rotina de Atenção Básica",
        textLabel:
          "Houve Recusa por Parte da Equipe de Saúde em Realizar o Acompanhamento da Condicionalidade Dentro da Rotina de Atenção Básica",
      },
      {
        value: "Falta de Oferta dos Exames (EAS e Urocultura)",
        textLabel: "Falta de Oferta dos Exames (EAS e Urocultura)",
      },
      {
        value:
          "Falta de Insumos Necessários para Coleta de Urina (Pote Coletor)",
        textLabel:
          "Falta de Insumos Necessários para Coleta de Urina (Pote Coletor)",
      },
      {
        value: "Extravio ou Atraso na Entrega do Resultado dos Exames",
        textLabel: "Extravio ou Atraso na Entrega do Resultado dos Exames",
      },
      {
        value:
          "Gestante foi Informada Pessoalmente que Deveria Comparecer à UBS para Realizar o Acompanhamento da Condicionalidade, Mas Não Fez",
        textLabel:
          "Gestante foi Informada Pessoalmente que Deveria Comparecer à UBS para Realizar o Acompanhamento da Condicionalidade, Mas Não Fez",
      },
    ],
  },
  {
    value: "Descumprimento do Calendário Vacinal",
    textLabel: "Descumprimento do Calendário Vacinal",
    justifications: [
      {
        value: "Condições de Saúde que Impedem a Ida à UBS ou Laboratório",
        textLabel: "Condições de Saúde que Impedem a Ida à UBS ou Laboratório",
      },
      {
        value:
          "Fatos que Impedem o Deslocamento/Acesso à UBS ou Laboratório (Enchente, Falta de Transporte, Violência no Território, etc)",
        textLabel:
          "Fatos que Impedem o Deslocamento/Acesso à UBS ou Laboratório (Enchente, Falta de Transporte, Violência no Território, etc)",
      },
      {
        value:
          "Indícios de Situação de Risco Social tal como Negligência, Abuso Sexual, Violência Intrafamiliar e Outras que Impedem a Ida à UBS ou Laboratório",
        textLabel:
          "Indícios de Situação de Risco Social tal como Negligência, Abuso Sexual, Violência Intrafamiliar e Outras que Impedem a Ida à UBS ou Laboratório",
      },
      {
        value:
          "Horario de Atendimento na UBS ou Laboratorio Inviavel para Gestante",
        textLabel:
          "Horario de Atendimento na UBS ou Laboratorio Inviavel para Gestante",
      },
      {
        value:
          "Houve Recusa por Parte da Equipe de Saúde em Realizar o Acompanhamento da Condicionalidade Dentro da Rotina de Atenção Básica",
        textLabel:
          "Houve Recusa por Parte da Equipe de Saúde em Realizar o Acompanhamento da Condicionalidade Dentro da Rotina de Atenção Básica",
      },
      {
        value: "Falta de Oferta de Vacina",
        textLabel: "Falta de Oferta de Vacina",
      },
      {
        value:
          "Falta de Insumos Necessários para Realizar a Vacinação (Seringas e Agulhas)",
        textLabel:
          "Falta de Insumos Necessários para Realizar a Vacinação (Seringas e Agulhas)",
      },
      {
        value:
          "Gestante foi Informada Pessoalmente que Deveria Comparecer à UBS para Realizar o Acompanhamento da Condicionalidade, Mas Não Fez",
        textLabel:
          "Gestante foi Informada Pessoalmente que Deveria Comparecer à UBS para Realizar o Acompanhamento da Condicionalidade, Mas Não Fez",
      },
    ],
  },
  {
    value: "Descumprimento da Avaliação Nutricional",
    textLabel: "Descumprimento da Avaliação Nutricional",
    justifications: [
      {
        value: "Condições de Saúde que Impedem a Ida à UBS ou Laboratório",
        textLabel: "Condições de Saúde que Impedem a Ida à UBS ou Laboratório",
      },
      {
        value:
          "Fatos que Impedem o Deslocamento/Acesso à UBS ou Laboratório (Enchente, Falta de Transporte, Violência no Território, etc)",
        textLabel:
          "Fatos que Impedem o Deslocamento/Acesso à UBS ou Laboratório (Enchente, Falta de Transporte, Violência no Território, etc)",
      },
      {
        value:
          "Indícios de Situação de Risco Social tal como Negligência, Abuso Sexual, Violência Intrafamiliar e Outras que Impedem a Ida à UBS ou Laboratório",
        textLabel:
          "Indícios de Situação de Risco Social tal como Negligência, Abuso Sexual, Violência Intrafamiliar e Outras que Impedem a Ida à UBS ou Laboratório",
      },
      {
        value:
          "Horário de Atendimento na UBS ou Laboratório Inviavel para Gestante",
        textLabel:
          "Horário de Atendimento na UBS ou Laboratório Inviavel para Gestante",
      },
      {
        value:
          "Houve Recusa por Parte da Equipe de Saúde em Realizar o Acompanhamento da Condicionalidade Dentro da Rotina de Atençao Básica",
        textLabel:
          "Houve Recusa por Parte da Equipe de Saúde em Realizar o Acompanhamento da Condicionalidade Dentro da Rotina de Atençao Básica",
      },
      {
        value: "Falta de Equipamentos Antropométricos (Balança, Estadiômetro)",
        textLabel:
          "Falta de Equipamentos Antropométricos (Balança, Estadiômetro)",
      },
      {
        value:
          "Falta de Profissionais Capacitados para Realizar a Coleta de Dados Nutricionais",
        textLabel:
          "Falta de Profissionais Capacitados para Realizar a Coleta de Dados Nutricionais",
      },
      {
        value:
          "Gestante foi Informada Pessoalmente que Deveria Comparecer à UBS para Realizar o Acompanhamento da Condicionalidade, Mas Não Fez",
        textLabel:
          "Gestante foi Informada Pessoalmente que Deveria Comparecer à UBS para Realizar o Acompanhamento da Condicionalidade, Mas Não Fez",
      },
    ],
  },
  {
    value: "Descumprimento de Consultas Pré-Natal",
    textLabel: "Descumprimento de Consultas Pré-Natal",
    justifications: [
      {
        value: "Condições de Saúde que Impedem a Ida à UBS ou Laboratório",
        textLabel: "Condições de Saúde que Impedem a Ida à UBS ou Laboratório",
      },
      {
        value:
          "Fatos que Impedem o Deslocamento/Acesso à UBS ou Laboratório (Enchente, Falta de Transporte, Violência no Território, etc)",
        textLabel:
          "Fatos que Impedem o Deslocamento/Acesso à UBS ou Laboratório (Enchente, Falta de Transporte, Violência no Território, etc)",
      },
      {
        value:
          "Indícios de Situação de Risco Social tal como Negligência, Abuso Sexual, Violência Intrafamiliar e Outras que Impedem a Ida à UBS ou Laboratório",
        textLabel:
          "Indícios de Situação de Risco Social tal como Negligência, Abuso Sexual, Violência Intrafamiliar e Outras que Impedem a Ida à UBS ou Laboratório",
      },
      {
        value:
          "Horario de Atendimento na UBS ou Laboratório Inviavel para Gestante",
        textLabel:
          "Horario de Atendimento na UBS ou Laboratório Inviavel para Gestante",
      },
      {
        value:
          "Houve Recusa por Parte da Equipe de Saúde em Realizar o Acompanhamento da Condicionalidade Dentro da Rotina de Atenção Básica",
        textLabel:
          "Houve Recusa por Parte da Equipe de Saúde em Realizar o Acompanhamento da Condicionalidade Dentro da Rotina de Atenção Básica",
      },
      {
        value: "Falta de Oferta de Serviço Pré-Natal",
        textLabel: "Falta de Oferta de Serviço Pré-Natal",
      },
      {
        value:
          "Gestante foi Informada Pessoalmente que Deveria Comparecer à UBS para Realizar o Acompanhamento da Condicionalidade, Mas Não Fez",
        textLabel:
          "Gestante foi Informada Pessoalmente que Deveria Comparecer à UBS para Realizar o Acompanhamento da Condicionalidade, Mas Não Fez",
      },
    ],
  },
];

export const justification_types_child = [
  {
    value: "Não Acompanhamento da Criança pela Saúde",
    textLabel: "Não Acompanhamento da Criança pela Saúde",
    justifications: [
      {
        value: "Falecimento",
        textLabel: "Falecimento",
      },
      {
        value: "Mudança de Estado",
        textLabel: "Mudança de Estado",
      },
      {
        value: "Mudança de Município Intraestadual",
        textLabel: "Mudança de Município Intraestadual",
      },
      {
        value:
          "Beneficiária(o) não Localizada(o) (Endereço Incorreto ou Inexistente)",
        textLabel:
          "Beneficiária(o) não Localizada(o) (Endereço Incorreto ou Inexistente)",
      },
    ],
  },
  {
    value: "Descumprimento da Realização de Exames",
    textLabel: "Descumprimento da Realização de Exames",
    justifications: [
      {
        value: "Condições de Saúde que Impedem a Ida à UBS ou Laboratório",
        textLabel: "Condições de Saúde que Impedem a Ida à UBS ou Laboratório",
      },
      {
        value:
          "Fatos que Impedem o Deslocamento/Acesso à UBS ou Laboratório (Enchente, Falta de Transporte, Violência no Território, etc)",
        textLabel:
          "Fatos que Impedem o Deslocamento/Acesso à UBS ou Laboratório (Enchente, Falta de Transporte, Violência no Território, etc)",
      },
      {
        value:
          "Indícios de Situação de Risco Social tal como Negligência, Abuso Sexual, Violência Intrafamiliar e Outras que Impedem a Ida à UBS ou Laboratório",
        textLabel:
          "Indícios de Situação de Risco Social tal como Negligência, Abuso Sexual, Violência Intrafamiliar e Outras que Impedem a Ida à UBS ou Laboratório",
      },
      {
        value:
          "Horário de Atendimento na UBS ou Laboratório Inviável para Gestante",
        textLabel:
          "Horário de Atendimento na UBS ou Laboratório Inviável para Gestante",
      },
      {
        value:
          "Houve Recusa por Parte da Equipe de Saúde em Realizar o Acompanhamento da Condicionalidade Dentro da Rotina de Atenção Básica",
        textLabel:
          "Houve Recusa por Parte da Equipe de Saúde em Realizar o Acompanhamento da Condicionalidade Dentro da Rotina de Atenção Básica",
      },
      {
        value: "Falta de Oferta dos Exames (EAS e Urocultura)",
        textLabel: "Falta de Oferta dos Exames (EAS e Urocultura)",
      },
      {
        value:
          "Falta de Insumos Necessários para Coleta de Urina (Pote Coletor)",
        textLabel:
          "Falta de Insumos Necessários para Coleta de Urina (Pote Coletor)",
      },
      {
        value: "Extravio ou Atraso na Entrega do Resultado dos Exames",
        textLabel: "Extravio ou Atraso na Entrega do Resultado dos Exames",
      },
      {
        value:
          "Gestante foi Informada Pessoalmente que Deveria Comparecer à UBS para Realizar o Acompanhamento da Condicionalidade, Mas Não Fez",
        textLabel:
          "Gestante foi Informada Pessoalmente que Deveria Comparecer à UBS para Realizar o Acompanhamento da Condicionalidade, Mas Não Fez",
      },
    ],
  },
  {
    value: "Descumprimento do Calendário Vacinal",
    textLabel: "Descumprimento do Calendário Vacinal",
    justifications: [
      {
        value: "Condições de Saúde que Impedem a Ida à UBS ou Laboratório",
        textLabel: "Condições de Saúde que Impedem a Ida à UBS ou Laboratório",
      },
      {
        value:
          "Fatos que Impedem o Deslocamento/Acesso à UBS ou Laboratório (Enchente, Falta de Transporte, Violência no Território, etc)",
        textLabel:
          "Fatos que Impedem o Deslocamento/Acesso à UBS ou Laboratório (Enchente, Falta de Transporte, Violência no Território, etc)",
      },
      {
        value:
          "Indícios de Situação de Risco Social tal como Negligência, Abuso Sexual, Violência Intrafamiliar e Outras que Impedem a Ida à UBS ou Laboratório",
        textLabel:
          "Indícios de Situação de Risco Social tal como Negligência, Abuso Sexual, Violência Intrafamiliar e Outras que Impedem a Ida à UBS ou Laboratório",
      },
      {
        value:
          "Horário de Atendimento na UBS ou Laboratório Inviável para Gestante",
        textLabel:
          "Horário de Atendimento na UBS ou Laboratório Inviável para Gestante",
      },
      {
        value:
          "Houve Recusa por Parte da Equipe de Saúde em Realizar o Acompanhamento da Condicionalidade Dentro da Rotina de Atenção Básica",
        textLabel:
          "Houve Recusa por Parte da Equipe de Saúde em Realizar o Acompanhamento da Condicionalidade Dentro da Rotina de Atenção Básica",
      },
      {
        value: "Falta de Oferta de Vacina",
        textLabel: "Falta de Oferta de Vacina",
      },
      {
        value:
          "Falta de Insumos Necessários para Realizar a Vacinação (Seringas e Agulhas)",
        textLabel:
          "Falta de Insumos Necessários para Realizar a Vacinação (Seringas e Agulhas)",
      },
      {
        value:
          "Gestante foi Informada Pessoalmente que Deveria Comparecer à UBS para Realizar o Acompanhamento da Condicionalidade, Mas Não Fez",
        textLabel:
          "Gestante foi Informada Pessoalmente que Deveria Comparecer à UBS para Realizar o Acompanhamento da Condicionalidade, Mas Não Fez",
      },
    ],
  },
  {
    value: "Descumprimento da Avaliação Nutricional",
    textLabel: "Descumprimento da Avaliação Nutricional",
    justifications: [
      {
        value: "Condições de Saúde que Impedem a Ida à UBS ou Laboratório",
        textLabel: "Condições de Saúde que Impedem a Ida à UBS ou Laboratório",
      },
      {
        value:
          "Fatos que Impedem o Deslocamento/Acesso à UBS ou Laboratório (Enchente, Falta de Transporte, Violência no Território, etc)",
        textLabel:
          "Fatos que Impedem o Deslocamento/Acesso à UBS ou Laboratório (Enchente, Falta de Transporte, Violência no Território, etc)",
      },
      {
        value:
          "Indícios de Situação de Risco Social tal como Negligência, Abuso Sexual, Violência Intrafamiliar e Outras que Impedem a Ida à UBS ou Laboratório",
        textLabel:
          "Indícios de Situação de Risco Social tal como Negligência, Abuso Sexual, Violência Intrafamiliar e Outras que Impedem a Ida à UBS ou Laboratório",
      },
      {
        value:
          "Horário de Atendimento na UBS ou Laboratório Inviável para Gestante",
        textLabel:
          "Horário de Atendimento na UBS ou Laboratório Inviável para Gestante",
      },
      {
        value:
          "Houve Recusa por Parte da Equipe de Saúde em Realizar o Acompanhamento da Condicionalidade Dentro da Rotina de Atenção Básica",
        textLabel:
          "Houve Recusa por Parte da Equipe de Saúde em Realizar o Acompanhamento da Condicionalidade Dentro da Rotina de Atenção Básica",
      },
      {
        value: "Falta de Equipamentos Antropométricos (Balança, Estadiômetro)",
        textLabel:
          "Falta de Equipamentos Antropométricos (Balança, Estadiômetro)",
      },
      {
        value:
          "Falta de Profissionais Capacitados para Realizar a Coleta de Dados Nutricionais",
        textLabel:
          "Falta de Profissionais Capacitados para Realizar a Coleta de Dados Nutricionais",
      },
      {
        value:
          "Gestante foi Informada Pessoalmente que Deveria Comparecer à UBS para Realizar o Acompanhamento da Condicionalidade, Mas Não Fez",
        textLabel:
          "Gestante foi Informada Pessoalmente que Deveria Comparecer à UBS para Realizar o Acompanhamento da Condicionalidade, Mas Não Fez",
      },
    ],
  },
  {
    value: "Descumprimento de Consultas de Puericultura",
    textLabel: "Descumprimento de Consultas de Puericultura",
    justifications: [
      {
        value: "Condições de Saúde que Impedem a Ida à UBS ou Laboratório",
        textLabel: "Condições de Saúde que Impedem a Ida à UBS ou Laboratório",
      },
      {
        value:
          "Fatos que Impedem o Deslocamento/Acesso à UBS ou Laboratório (Enchente, Falta de Transporte, Violência no Território, etc)",
        textLabel:
          "Fatos que Impedem o Deslocamento/Acesso à UBS ou Laboratório (Enchente, Falta de Transporte, Violência no Território, etc)",
      },
      {
        value:
          "Indícios de Situação de Risco Social tal como Negligência, Abuso Sexual, Violência Intrafamiliar e Outras que Impedem a Ida à UBS ou Laboratório",
        textLabel:
          "Indícios de Situação de Risco Social tal como Negligência, Abuso Sexual, Violência Intrafamiliar e Outras que Impedem a Ida à UBS ou Laboratório",
      },
      {
        value:
          "Horário de Atendimento na UBS ou Laboratório Inviável para o Beneficiário",
        textLabel:
          "Horário de Atendimento na UBS ou Laboratório Inviável para o Beneficiário",
      },
      {
        value:
          "Houve Recusa por Parte da Equipe de Saúde em Realizar o Acompanhamento da Condicionalidade Dentro da Rotina de Atenção Básica",
        textLabel:
          "Houve Recusa por Parte da Equipe de Saúde em Realizar o Acompanhamento da Condicionalidade Dentro da Rotina de Atenção Básica",
      },
      {
        value: "Falta de Oferta de Serviço de Puericultura",
        textLabel: "Falta de Oferta de Serviço de Puericultura",
      },
      {
        value:
          "Gestante foi Informada Pessoalmente que Deveria Comparecer à UBS para Realizar o Acompanhamento da Condicionalidade, Mas Não Fez",
        textLabel:
          "Gestante foi Informada Pessoalmente que Deveria Comparecer à UBS para Realizar o Acompanhamento da Condicionalidade, Mas Não Fez",
      },
    ],
  },
];

export const serverErrorMsg = "Erro interno. Tente novamente em instantes.";
