import * as React from 'react';
import { useState } from 'react';
import * as S from './styles';
import { SaveButton } from 'components/Buttons/SaveButton';
import { OrangeBackButton } from 'components/Buttons/OrangeBackButton';
import FooterAlt from '../../components/FooterAlt';
import CompTextField from 'components/CompTextField';
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useApi } from 'hooks/useApis';
import SuccessAlert from 'components/Alerts/SuccessAlert';
import FillErrorAlert from 'components/Alerts/FillErrorAlert';

const RecoverPassword = () => {
    const api = useApi();
    const [successForm, setSuccessForm] = useState(false);
    const [errorForm, setErrorForm] = useState(false);

    const cpfSchema = z.object({
        cpf: z.string().min(11),
    });

    const methods = useForm({
        resolver: zodResolver(cpfSchema),
        mode: "all",
        criteriaMode: "all",
    });

    const { register, handleSubmit, formState: { errors } } = methods;

    async function onSubmit(data) {
        try {
            await api.RecoverPassword(data.cpf);
            setSuccessForm(true);
        } catch (err) {
            setErrorForm(true);
        }
    }

    function onError(err) {
        setErrorForm(true);
    }

    const handleCloseSuccess = () => {
        setSuccessForm(false);
    };

    const handleCloseError = () => {
        setErrorForm(false);
    };


    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
                <S.RecoverPasswordContainer>

                    <S.RecoverPasswordBox>
                        <S.HeaderText>
                            Recuperar senha
                        </S.HeaderText>

                        <S.RecomendacoesConteiner>
                            <p>Informe no campo abaixo seu CPF. Em instantes, você receberá um novo e-mail com as instruções de cadastro da nova senha. </p>
                        </S.RecomendacoesConteiner>
                        <S.InputBox>
                            <CompTextField
                                textLabel="CPF:"
                                mask="999.999.999-99"
                                style={{ flexGrow: '1' }}
                                name={'cpf'}
                                register={register}
                            />
                        </S.InputBox>
                        <S.ButtonsConteiner>
                            <SaveButton />
                            <OrangeBackButton />
                        </S.ButtonsConteiner>

                    </S.RecoverPasswordBox>
                    <FooterAlt />
                </S.RecoverPasswordContainer>
            </form>

            <SuccessAlert 
                open={successForm} 
                onClose={handleCloseSuccess} 
                alertText="Verifique seu e-mail para redefinir sua senha." 
            />

            <FillErrorAlert
                open={errorForm} 
                onClose={handleCloseError} 
                message="Verifique o CPF e tente novamente."
            />
        </FormProvider>

    );
};

export default RecoverPassword;
