import React, { useState } from "react";
import * as S from "./PregnantForms/styles";
import CompTextField from "components/CompTextField";
import CompDropdown from "components/CompDropdown";
import AgentType from 'utils/agentType';

const Forms = ({
  isView,
  beneficiaryData = null,
  register,
  unregister,
  isEdit,
  errors,
}) => {
  const estados = [
    { value: "AL", textLabel: "AL" },
    { value: "PE", textLabel: "PE" },
    { value: "SE", textLabel: "SE" },
  ];

  const options = [
    { value: "SELECIONE", textLabel: "SELECIONE" },
    { value: "Sim", textLabel: "Sim" },
    { value: "Não", textLabel: "Não" },
  ];
  const agent_type = AgentType()

  return (
    <S.Form>
      <S.FormRowEndereco>
      {/* Linha 1 */}
      <CompTextField
        isView={isView}
        apiText={beneficiaryData?.person?.family.street_type}
        textLabel="TIPO DE LOGRADOURO:"
        fromNis={true}
        // enabled={false}
        style={{ flexGrow: "1" }}
        name="street_type"
        register={register}
        unregister={unregister}
        error={errors.street_type}
        helperText={errors.street_type && errors.street_type.message}
      />
      <CompTextField
        isView={isView}
        apiText={beneficiaryData?.person?.family.street_name}
        textLabel="LOGRADOURO:"
        placeholder="Rua do Beneficiário"
        fromNis={true}
        // enabled={false}
        style={{ flexGrow: "1" }}
        name="street_name"
        register={register}
        unregister={unregister}
        error={errors.street_name}
        helperText={errors.street_name && errors.street_name.message}
      />
      <CompTextField
        isView={isView}
        apiText={beneficiaryData?.person?.family.street_number}
        textLabel="NÚMERO:"
        // enabled={false}
        placeholder="N°"
        fromNis={true}
        style={{ flexGrow: "1" }}
        name="street_number"
        register={register}
        unregister={unregister}
        error={errors.street_number}
        helperText={errors.street_number && errors.street_number.message}
      />
      <CompTextField
        isView={isView}
        fromNis={true}
        apiText={beneficiaryData?.person?.family.complement}
        textLabel="COMPLEMENTO:"
        style={{ flexGrow: "1" }}
        name="complement"
        register={register}
        unregister={unregister}
        error={errors.complement}
        helperText={errors.complement && errors.complement.message}
        // enabled={false}
      />
    </S.FormRowEndereco>

    <S.FormRowWithMargin>
      {/* Linha 2 */}
      <CompTextField
        isView={isView}
        fromNis={true}
        textLabel="BAIRRO:"
        apiText={beneficiaryData?.person?.family.name_location}
        // enabled={false}
        style={{ flexGrow: "2" }}
        name="name_location"
        register={register}
        unregister={unregister}
        error={errors.name_location}
        helperText={errors.name_location && errors.name_location.message}
      />
      <CompTextField
        isView={isView}
        fromNis={true}
        textLabel="MUNICÍPIO:"
        apiText={beneficiaryData?.person?.family.city}
        // enabled={false}
        style={{ flexGrow: "2" }}
        name="city"
        register={register}
        unregister={unregister}
        error={errors.city}
        helperText={errors.city && errors.city.message}
      />
      {/* <CompDropdown
        isView={isView}
        style={{ flexGrow: '1' }}
        defaultValue="AL"
        textLabel="UF:"
        required
        options={estados}
        name="uf"
        register={register}
        unregister={unregister}
        error={errors.uf}
        helperText={errors.uf && errors.uf.message}
      /> */}
      <CompTextField
        isView={isView}
        fromNis={true}
        apiText={beneficiaryData?.person?.rg_uf}
        style={{ flexGrow: "1" }}
        textLabel="UF:"
        name="rg_uf"
        register={register}
        unregister={unregister}
        error={errors.rg_uf}
        helperText={errors.uf && errors.rg_uf.message}
        // enabled={false}
      />
    </S.FormRowWithMargin>

    <S.FormRowWithMarginEndereco>
      {/* Linha 3 */}
      <CompTextField
        isView={isView}
        fromNis={true}
        textLabel="NOME DA UNIDADE DE SAÚDE DA FAMÍLIA (USF) / UNIDADE BÁSICA DE SAÚDE (UBS):"
        apiText={beneficiaryData?.ufs_ubs}
        required
        edit={isEdit}
        style={{ flexGrow: "6" }}
        name="ufs_ubs"
        register={register}
        unregister={unregister}
        error={errors.ufs_ubs}
        // enabled={!(!isView && ["2","3"].includes(agent_type))}
        helperText={errors.ufs_ubs && errors.ufs_ubs.message}
      />
      <CompTextField
        isView={isView}
        fromNis={true}
        apiValue={beneficiaryData?.updated ? "Sim" : "Não"}
        style={{ flexGrow: "1" }}
        textLabel="ATUALIZADO CADÚNICO?"
        name="updated"
        register={register}
        unregister={unregister}
        error={errors.updated}
        helperText={errors.updated && errors.updated.message}
        // enabled={false}
      />
    </S.FormRowWithMarginEndereco>
    </S.Form>
  );
};

export default Forms;
