import AgentType from "../agentType";
import AgentCity from "../agentCity";


const disabledFieldsForMunicipalWhenEdit = [
  'bf', 'bvg', 'bcn', 'pcf', 'bpc', 'paif', 'paefi', 'pl', 'mcmv'
];

// utils/dropdownLogic.js
export const evaluateDropdown = (name, options, isEdit) => {
  const agentType = AgentType();
  const agentCity = AgentCity();

  let filteredOptions = options;
  let enabled = true;

  //campo para "tipo de agente"
  if (name === "agent_type") {
    if (agentType === '2') {
      filteredOptions = filteredOptions.filter(type => ["2", "3"].includes(type.value));
    }
    if (agentType === "4") {
      filteredOptions = options.filter(
        (type) => !["1"].includes(type.value)
      );
    }
  }
  //campo para "cidade"
  if (name === "city") {
    if (agentType === "2" || agentType === "3") {
      enabled = false;
    }
  }

  //campo para "ponto de distribuição"
  if (name === "work_location") {
    if (agentType === "2" || agentType === "3") {
      enabled = false;
      filteredOptions = filteredOptions.filter(
        (type) => type.value === agentCity
      );
    }
  }


  if (isEdit && ["2", "3"].includes(agentType)) {
    if (disabledFieldsForMunicipalWhenEdit.includes(name)) {
        enabled = false;
    }
  }

  return {
    options: filteredOptions,
    enabled: enabled,
  };
};
