import React from "react";
import Checkbox from "@mui/material/Checkbox";
import * as S from "./styles";
import {
  DownloadButton,
  VisibilityButton,
  AddButton,
  HistoryButton,
} from "components/Buttons/ActionButton";
import { getBeneficiaryType, convertDateFromApi, scrollToTop } from "utils";
import { Link } from "react-router-dom";

import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";
import { Tooltip } from "@mui/material";
import AgentType from "utils/agentType";

const getNisAndBirthDate = (row) => {
  switch (row.b_type) {
    case "1": // Gestante
      return [{ nis: row.nis, birth: row.birth }];
    case "2": // Criança (Síndrome congênita)
      return [{ nis: row.child_nis, birth: row.child_birth }];
    case "3": // Criança (0 a 5 anos)
      return [{ nis: row.child_nis, birth: row.child_birth }];
    case "4": // Gêmeos
      return [
        { nis: row.child_nis, birth: row.child_birth },
        { nis: row.twin_child_nis, birth: row.twin_child_birth }
      ];
    default:
      return [{ nis: row.nis, birth: row.birth }];
  }
};

const TableBeneficiary = ({
  beneficiaryList,
  page = 1,
  totalPages,
  setPage,
}) => {
  const handleChange = (event, value) => {
    setPage(value);
    scrollToTop();
  };
  const count = totalPages;
  const agentType = AgentType();

  return (
    <S.TableContainer>
      <S.StyledTable>
        <S.StyledTableHead>
          <tr>
            <S.StyledTableHeaderCell></S.StyledTableHeaderCell>
            <S.StyledTableHeaderCell>Nome</S.StyledTableHeaderCell>
            <S.StyledTableHeaderCell>NIS</S.StyledTableHeaderCell>
            <S.StyledTableHeaderCell>Data de Nascimento</S.StyledTableHeaderCell>
            <S.StyledTableHeaderCell>Tipo de Benefício</S.StyledTableHeaderCell>
            <S.StyledTableHeaderCell>Ação</S.StyledTableHeaderCell>
          </tr>
        </S.StyledTableHead>
        <S.StyledTableBody>
          {beneficiaryList?.map((row) => {
            const beneficiaryType = getBeneficiaryType(row.b_type);
            const beneficiaryName =
              row.pregnant_name || row.child_name || row.twin_child_name || "";

            const nisAndBirthDates = getNisAndBirthDate(row);

            return (
              <S.StyledTableRow key={row.id}>
                <S.StyledTableCell></S.StyledTableCell>
                <S.StyledTableCell>
                  {row?.name || ""}
                  {beneficiaryType && beneficiaryName && (
                    <>
                    <>
                      <br />
                      <S.StyledTableBenef>{beneficiaryType === 'Gêmeos' ? 'Criança': beneficiaryType}: </S.StyledTableBenef>
                      {beneficiaryName}
                    </>
                    {beneficiaryType === 'Gêmeos' && (<>
                      <br />
                      <S.StyledTableBenef>Criança: </S.StyledTableBenef>
                      {row.twin_child_name}
                    </>)}
                    </>
                  )}
                </S.StyledTableCell>
                <S.StyledTableCell>
                  {nisAndBirthDates.map(({ nis }, index) => (
                    <div key={index}>{nis}</div>
                  ))}
                </S.StyledTableCell>
                <S.StyledTableCell>
                  {nisAndBirthDates.map(({ birth }, index) => (
                    <div key={index}>{convertDateFromApi(birth)?.toLocaleDateString("pt-BR")}</div>
                  ))}
                </S.StyledTableCell>
                <S.StyledTableCell>
                  {beneficiaryType}
                </S.StyledTableCell>
                <S.StyledTableCell>
                  <S.ButtonsContainer>
                    {!["6"].includes(agentType) && (
                      <Tooltip title="Editar beneficiário">
                        <Link to={`/beneficiarios/${row.id}/editar`}>
                          <DownloadButton />
                        </Link>
                      </Tooltip>
                    )}
                    <Tooltip title="Visualizar beneficiário">
                      <Link to={`/beneficiarios/${row.id}`}>
                        <VisibilityButton />
                      </Link>
                    </Tooltip>
                    {!["6"].includes(agentType) ? (
                      <Tooltip title="Adicionar acompanhamento do beneficiário">
                        <Link
                          to={
                            beneficiaryType === "Gestante"
                              ? `/acompanhamento/gestante/${row.id}`
                              : `/acompanhamento/crianca/${row.id}`
                          }
                        >
                          <AddButton />
                        </Link>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Visualizar histórico de acompanhamentos">
                        <Link to={`/acompanhamento/historico/${row.id}`}>
                          <HistoryButton />
                        </Link>
                      </Tooltip>
                    )}
                  </S.ButtonsContainer>
                </S.StyledTableCell>
              </S.StyledTableRow>
            );
          })}
        </S.StyledTableBody>
      </S.StyledTable>
      <S.TableFooter>
        <Typography>
          Página {page} de {count}
        </Typography>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handleChange}
          renderItem={(item) => (
            <PaginationItem
              slots={{ previous: WestIcon, next: EastIcon }}
              {...item}
            />
          )}
        />
      </S.TableFooter>
    </S.TableContainer>
  );
};

export default TableBeneficiary;
