import React from 'react';
import { useSelector } from 'react-redux';

const AgentCity = () => {
  const user = useSelector((state) => state.user?.user);

  return user ? user.work_location : '';
};

export default AgentCity;
