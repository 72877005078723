import * as React from 'react';
import Button from '@mui/material/Button';
import BlockIcon from '@mui/icons-material/Block';

export const DeleteAgentBtn = ({ onClick }) => {
  return (
    <Button
      onClick={onClick}
      variant="contained"
      startIcon={<BlockIcon />}
      sx={{
        background: "var(--Light-Vermelho---light, #EE3B5E)",
        width: "236px",
        height: "54px",
        padding: "15px 16px",
        borderRadius: "8px",
        fontFamily: 'Poppins, sans-serif',
        fontFize: '20px',
        fontWeight: 300,
        textTransform: 'none',

        '&:hover': {
          backgroundColor: "#EE3B5E",
        },
      }}
    >
      Desativar agente
    </Button>
  );
};


