import React, { useEffect, useMemo, useState } from "react";
import i, { Link } from "@react-pdf/renderer";
import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";

import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import { ptBR } from "@mui/x-date-pickers/locales";
dayjs.locale("pt-br");

Font.register({
  family: "Work Sans",
  src: "https://fonts.gstatic.com/s/worksans/v2/z9rX03Xuz9ZNHTMg1_ghGS3USBnSvpkopQaUR-2r7iU.ttf",
});

Font.register({
  family: "Inter",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/inter/v11/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfAZFhjQ.ttf",
    },
    {
      src: "https://fonts.gstatic.com/s/inter/v11/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYAZFhjQ.ttf",
      fontWeight: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#FFFFFF",
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  containerHeader: {
    backgroundColor: "rgb(248, 163, 0)",
    // display: "flex",
    // justifyContent: "space-between",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    marginBottom: 55,
  },
  wrapperHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "stretch",
    flexWrap: "nowrap",
    alignItems: "stretch",
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: 35,
    padding: 16,
    paddingBottom: 12,
  },
  imgObservatorio: {
    width: 139,
    height: 38,
    marginBottom: 6,
  },
  imgSectiSetur: {
    width: 295,
    height: 39,
    marginBottom: 6,
  },
  title: {
    fontSize: 14,
    marginBottom: 6,
    textAlign: "justify",
    lineHeight: 1.5,
    fontFamily: "Work Sans",
    color: "#667085",
  },
  text: {
    fontSize: 12,
    fontFamily: "Inter",
    color: "#667085",
    marginBottom: 16,
    // display: "inline",
  },
  titleHeader: {
    fontSize: 20,
    fontFamily: "Work Sans",
    color: "#1259a5",
    marginBottom: 12,
    paddingTop: 50,
    // fontWeight: 'bold',
    // display: "inline",
  },
  boldText: {
    fontFamily: "Helvetica-Bold",
  },
  img: {
    maxWidth: 300,
  },
  footer: {
    position: "absolute",
    fontSize: 12,
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
    fontFamily: "Inter",
  },
});

const stylesTable = StyleSheet.create({
  em: {
    fontStyle: "bolder",
    color: "black",
    backgroundColor: "#88C54B",
  },
  table: {
    width: "100%",
    borderWidth: 2,
    display: "flex",
    flexDirection: "column",
    marginVertical: 12,
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
  },
  cell: {
    borderWidth: 1,
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    textAlign: "center",
    flexWrap: "wrap",
  },
});

function capitalizeFirstLetter(val) {
  return String(val).charAt(0).toUpperCase() + String(val).slice(1);
}

export const RelatorioSituacional = ({ children, col, th }) => (
  <Document>
    <Page>
      <Text>
        Número do Cartão Cria em{" "}
        {capitalizeFirstLetter(dayjs(new Date()).format("MMMM"))}
      </Text>
      <View style={stylesTable.table}>
        {children.map((row, ind) => (
          <>
            <View
              key={ind}
              style={[
                stylesTable.tableRow,
                th && ind === 0 ? stylesTable.em : {},
              ]}
            >
              {ind !== children.length - 1
                ? row.map((cell, j) => (
                    <View
                      key={j}
                      style={[stylesTable.cell, { width: col[j], height: 40 }]}
                    >
                      {typeof cell === "string" || typeof cell === "number" ? (
                        <Text>{cell}</Text>
                      ) : (
                        cell
                      )}
                    </View>
                  ))
                : null}
            </View>
          </>
        ))}
     
      </View>
    </Page>
  </Document>
);

export const MyPDFContent = () => {
  return (
    <Document>
      <Page style={styles.body}>
        <View style={styles.containerHeader} fixed>
          <View style={styles.wrapperHeader}>
            {/* <Image source={LogoWithName.src} style={styles.imgObservatorio} />
            <Image source={SectiSeturLogo.src} style={styles.imgSectiSetur} /> */}
          </View>
        </View>

        <Text style={styles.titleHeader}>Dashboard</Text>
        <View>
          <Text style={styles.title}>Nº de hospedagens em Alagoas</Text>
        </View>

        <Text style={styles.footer} fixed>
          {`Realização: `}
          <Link
            style={{
              paddingLeft: 60,
              color: "grey",
            }}
            src={"https://sites.google.com/laccan.ufal.br/laccan"}
          >
            LaCCAN
          </Link>
        </Text>
      </Page>
    </Document>
  );
};
