import styled from 'styled-components';

export const WaitingStatus = styled.div`
   width: 170px;
    height: 42px;
    border-radius: 16px;
    border: 1px solid #0090CE;
    background: rgba(75, 191, 239, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;

    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
`