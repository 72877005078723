import * as S from './styles';


const WaitingStatus = () => {
    return (
        <S.WaitingStatus>
            Lista de espera
        </S.WaitingStatus>
    );
};

export default WaitingStatus;