import { DateRangePicker } from "@mui/x-date-pickers-pro";
import styled from "styled-components";

export const DashboardContainer = styled.div`
  margin-top: -24px;
`;

export const DashboardHeaderTexts = styled.div`
  position: relative;
  .title {
    color: #f9922a;
    margin-top: -20px;
    font-size: 28px;
    font-family: "Poppins";
    font-style: normal;
    line-height: 40px;
    font-weight: 500;
    margin-bottom: 20px;
    margin-top: 16px;
  }

  .description {
    font-size: 16px;
    font-family: "Poppins";
    font-style: normal;
    color: #494e53;
    margin-bottom: 48px;
    max-width: 1142px;
  }

  span {
    font-size: 12px;
    font-family: "Poppins";
    font-weight: lighter;
    color: #515151;
    position: absolute;
    top: 4px;
    right: 0;
  }

  @media (max-width: 768px) {
    .title {
      margin-top: 0px;
    }
    .description {
      margin-bottom: 32px;
    }
    span {
      position: relative;
      top: -14px;
    }
  }
`;

export const CustomizedDateRangePicker = styled(DateRangePicker)`
  color: red;
`;

export const TextFieldCityContainer = styled.div`
  width: 100%;
  label {
    margin-bottom: 3px;
  }
  .MuiFormControl-root {
    margin-top: 3px;
  }
`;

export const DateCalendarContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* place-content: center; */
  label {
    margin-bottom: 3px;
  }
  .MuiInputBase-root {
    width: 255px;
  }

  .MuiFormControl-root {
    margin-top: 3px;
  }
  .MuiDateRangeCalendar-root {
    border-radius: 17.961px;
    /* width: 100%; */
    max-width: min-content;
    border: 1.048px solid #d8dadf;
    font-family: Inter;
    & > div:first-child {
      display: none;
    }

    & > div:nth-child(2) {
      width: 100%;
    }
  }
  .MuiPickersCalendarHeader-root {
    /* padding: 0 3rem 0 4rem; */
    margin-bottom: 24px;
    @media (max-width: 768px) {
      padding: inherit;
      padding-left: 1.5rem;
    }
  }

  .MuiButtonBase-root.Mui-selected[aria-selected="true"] {
    background: #efad1b;
    color: #344054;
  }

  .MuiButtonBase-root {
    color: #344054;
  }

  .MuiPickersCalendarHeader-root,
  .MuiPickersFadeTransitionGroup-root,
  .MuiButtonBase-root svg {
    color: #1259a5;
    font-family: Inter !important;
  }

  .MuiPickersCalendarHeader-label {
    text-transform: capitalize;
    font-weight: 600;
    font-size: 21px;
  }
  .MuiPickersSlideTransition-root {
    /* min-height: 220px; */
  }

  .MuiDayCalendar-header,
  .MuiDayCalendar-weekContainer {
    width: 100%;
    /* place-content: space-evenly; */
  }
`;

export const MapSectionMapContainer = styled.div`
  padding: 20px 8px 20px 20px;
  display: flex;
  border-radius: 10px;
  /* background: #f7f7fb; */
  margin: auto;
  margin-top: 4px;
  justify-content: space-between;

  .leaflet-container {
    min-width: 47vw;
  }

  .map-tooltip {
    font-weight: bold;
    font-family: Work Sans;
  }

  @media (max-width: 768px) {
    gap: 48px;
    flex-direction: column;

    .leaflet-container {
      min-width: 100vw;
      margin-left: -21px;
    }
  }
`;

export const MapSubtitle = styled.div`
  display: flex;
  place-content: center;
  margin-top: 24px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 32px;
  }
  .subtitle-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    .color {
      width: 93px;
      height: 11px;
      border-radius: 12px;
    }
    span {
      font-weight: lighter;
      font-size: 12px;
    }
  }
`;

export const MapResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
`;

export const MapResultCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  min-width: 270px;

  padding: 24px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0px 4px 32px 0px rgba(107, 108, 126, 0.2);

  .MuiCircularProgress-circle {
    color: #f9922a;
  }

  .MuiCircularProgress-root {
    width: 68px !important;
    height: 68px !important;
  }

  .card-title {
    color: #6b6b6b;
    font-size: 16px;
    line-height: 24px;
    font-weight: lighter;
  }

  .card-number {
    color: #f9922a;
    font-size: 48px;
    font-weight: bold;
  }
`;

export const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
`;

export const StyledTable = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

export const StyledTableHead = styled.thead`
  margin-bottom: 16px;
  color: var(--Dark-Verde---dark, #70ac17);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 900;
  line-height: 24px;
`;

export const StyledTableBody = styled.tbody`
  tr:last-child td {
    border-bottom: 2px solid #70ac17;
  }
`;

export const StyledTableRow = styled.tr`
  background-color: #ffffff;
  margin-bottom: 16px;
  color: #222222;
  font-family: sans-serif;
  font-size: 14px;
`;

export const StyledTableCell = styled.td`
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
`;

export const StyledTableHeaderCell = styled.th`
  border-bottom: 2px solid #fff;
  text-align: left;
  padding: 8px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 8px; // Espaço entre os botões
`;

export const AllDataWrapper = styled.div`
  /* background-color: #fff1e8; */
  border-radius: 8px;
  /* padding: 12px; */
  padding-top: 0px;
  max-width: 1360px;
  margin: auto;
  margin-bottom: 64px;

  .firstRow {
    min-width: max(1012px, 100%);
    & .MuiFormControl-root .MuiInputBase-root {
      width: 100%;
    }
  }

  .MuiInputBase-root {
    height: 45px;
  }

  @media (max-width: 768px) {
    .firstRow {
      flex-direction: column;
      max-width: 100%;
      min-width: auto;
    }
    .flex-column-mobile {
      flex-direction: column;
      align-items: center;
    }

    .min-width {
      max-width: 100%;
      min-width: auto !important;
    }
  }

  label {
    text-transform: uppercase;
    font-weight: bold;
    color: #383838;
    font-size: 18px;
    line-height: 24px;
  }

  fieldset {
    border-color: #4bbfef;
    border-radius: 8px;
  }
`;

export const DemographicDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;

  min-width: max(1012px, 100%);

  @media (max-width: 768px) {
    min-width: 100%;
    margin-top: 24px;
  }
`;

export const DemographicDataTitle = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
  gap: 12px;

  h4 {
    color: #f26a1d;
    font-size: 28px;
    line-height: 40px;
    font-weight: 500;
  }

  hr {
    border: 1px solid #f26a1d;
    flex-grow: 1;
  }
`;

export const GraphicContainer = styled.div`
  padding: 24px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0px 4px 32px 0px rgba(107, 108, 126, 0.2);

  .MuiCircularProgress-circle {
    color: #f9922a;
  }

  .MuiCircularProgress-root {
    width: 68px !important;
    height: 68px !important;
  }

  .title {
    color: #222222;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 8px;
  }

  .description {
    color: #6b6b6b;
    font-size: 16px;
    font-weight: lighter;
  }

  &.tipo-beneficiario {
    width: 100%;
    @media (max-width: 768px) {
      width: -webkit-fill-available;
    }
  }

  &.max-width-25 {
    max-width: 25%;
    @media (max-width: 768px) {
      max-width: -webkit-fill-available;
    }
  }
  &.max-width-28 {
    max-width: 28%;
    @media (max-width: 768px) {
      max-width: -webkit-fill-available;
    }
  }

  &.max-width-40 {
    max-width: 28%;
    @media (max-width: 768px) {
      max-width: -webkit-fill-available;
    }
  }
`;

export const StepProgressBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 16px;
`;

export const CustomBarLine = styled.div`
  .content {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    gap: 14px;
    align-items: center;
  }
  .range {
    color: #222222;
    min-width: 100px;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: lighter;
    line-height: 18px;
  }

  .value {
    color: #9e9e9e;
    width: 60px;
    font-family: Roboto;
    font-size: 10px;
    font-style: normal;
    font-weight: lighter;
    line-height: 18px;
    display: flex;
    justify-content: end;
  }

  .bar {
    background-color: ${(props) => props.color};
    border-radius: 15px;
    margin-right: auto;
    width: ${(props) => {
      return `${props.percentage}%`;
    }};
    max-width: 90%;
    border-radius: 0px 4px 4px 0px;
    height: 26px;
  }
`;

export const StepProgressBarLine = styled.div`
  .texts {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }
  .type {
    color: #222222;

    /* subtitle 2 */
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: lighter;
    line-height: 18px;
  }

  .percentage {
    color: ${(props) => props.color};

    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: lighter;
    line-height: 18px;
  }

  .progress-bar {
    background-color: ${(props) => props.backgroundColor};
    border-radius: 15px;
  }

  .progress-bar-inner {
    background-color: ${(props) => props.color};
    height: 8px;
    border-radius: 6px;
    width: ${(props) => {
      return `${props.percentage}%`;
    }};
  }
`;

export const GraphicSubtitleContainer = styled.div`
  padding: 25px 0px;
  display: flex;
  gap: 24px;

  color: var(--primrias-preto, #131313);

  /* Body-2 */
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%; /* 19.6px */

  p {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  span.circle {
    width: 14px;
    height: 14px;
    border-radius: 100%;
  }
  span.green {
    background-color: #88c54b;
  }
  span.blue {
    background-color: #4bbfef;
  }
  span.red {
    background-color: #ee3b5e;
  }
`;

export const StatusCardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 18px;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 15px;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(4, 1fr); 
  }

  @media (max-width: 900px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr); 
  }
`;

export const StatusCard = styled.div`
  padding: 24px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0px 4px 32px 0px rgba(107, 108, 126, 0.2);
  height: 152px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
  box-sizing: border-box; 

  .status-title {
    color: #bfbfbf;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom:10px;
  }

  .status-number {
    color: rgba(0, 0, 0, 0.75);
    font-size: 32px;
    font-weight: 400;
    line-height: 40px;
    margin-top:15px;
  }

  .status-description {
    font-family: "Inter";
    color: rgba(0, 0, 0, 0.50);
    font-size: 12px;
    font-weight: 400;
    margin-bottom:10px;
  }
`;

export const PercentageChangeContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 6px;
`;

export const PercentageChange = styled.p`
  font-size: 12px;
  font-weight: 400;
  margin: 0;
`;

export const SubDescription = styled.p`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.35); 
  margin-left: 8px;
`;