import React from "react";
import Routes from "./routes/Routes";
import { SnackbarProvider } from "notistack";


function App() {
  return (
      <SnackbarProvider maxSnack={2} autoHideDuration={3000}>
        <Routes />
      </SnackbarProvider>
  );
}

export default App;
