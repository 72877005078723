import React, { useState } from 'react';
import * as S from './styles';
import CloseIcon from '@mui/icons-material/Close';
import { SubmitButton } from 'components/Buttons/SubmitButton';
import { CheckBoxOutlined } from '@mui/icons-material';

const DeleteModal = ({
  isOpen,
  onClose,
  onConfirm,
  cancelError,
  alertType,
  setAlertType,
  isLoading,
}) => {
  const [justification, setJustification] = useState('');

  if (!isOpen) {
    return null;
  }

  const handleJustificationChange = (e) => {
    setJustification(e.target.value);
  };

  const handleConfirm = () => {
    onConfirm(justification);
  };

  const ConfirmCancellationBeneficiary = ({ onClick, alertType, setAlertType, }) => {
    return (
      <SubmitButton
        isLoading={isLoading}
        onClick={onClick}
        alertType={alertType}
        setAlertType={setAlertType}
        buttonText="Confirmar cancelamento"
        successMsg="Beneficiário cancelado com sucesso."
        errorMsg="Erro ao cancelar o beneficiário."
        startIcon={<CheckBoxOutlined />}
        reload
        sx={{ width: "260px" }}
      />
    );
  };

  return (
    <S.ModalOverlay>
      <S.ModalContent>
        <S.CloseButton onClick={onClose}>
          <CloseIcon />
        </S.CloseButton>
        <S.ModalHeader>
          <S.ModalTitle>Cancelar Beneficiário</S.ModalTitle>
        </S.ModalHeader>
        <S.ModalBody>
          <S.JustificationText>JUSTIFICATIVA PARA O CANCELAMENTO DESTE BENEFICIÁRIO</S.JustificationText>
          <S.TextAreaWrapper>
            <S.CustomTextArea
              placeholder="Justifique aqui o motivo do cancelamento do beneficiário"
              maxLength={200}
              value={justification}
              onChange={handleJustificationChange}
            />
          </S.TextAreaWrapper>
          {cancelError && <S.ErrorMessage>{cancelError}</S.ErrorMessage>}
          <S.CharLimit>Máximo de 200 caracteres</S.CharLimit>
        </S.ModalBody>
        <S.ModalFooter>
          <ConfirmCancellationBeneficiary
            onClick={handleConfirm}
            alertType={alertType}
            setAlertType={setAlertType}
          />
        </S.ModalFooter>
      </S.ModalContent>
    </S.ModalOverlay>
  );
};

export default DeleteModal;
