import * as React from 'react';
import { useState, useEffect } from 'react';
import * as S from './styles';
import { OrangeBackButton } from 'components/Buttons/OrangeBackButton';
import FooterAlt from '../../components/FooterAlt';
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useAuth } from 'hooks/useAuth';
import { useApi } from 'hooks/useApis';

import CompPassword from 'components/CompPassword';
import { SubmitButton } from 'components/Buttons/SubmitButton';

const ChangePassword = (errors) => {
    const api = useApi();
    const { agent } = useAuth();
    const [submitStatus, setSubmitStatus] = useState('')
    const [password2Error, setPassword2Error] = useState('');

    const passwordSchema = z.object({
        old_password: z.string().min(9),
        password: z.string().min(9),
        password2: z.string().min(9),
    }).refine(({ password, password2 }) => password === password2, {
        message: "As senhas precisam ser iguais.",
        path: ["password2"]
    });

    const methods = useForm({
        resolver: zodResolver(passwordSchema),
        mode: "all",
        criteriaMode: "all",
    });

    const validatePasswords = (data) => {
        const { password, password2 } = data;

        if (password !== password2) {
            setPassword2Error("As senhas não coincidem");
        } else {
            setPassword2Error("");
        }
    };

    const handlePasswordChange = (event) => {
        validatePasswords(event);
    };

    const { register, watch } = methods;

    useEffect(() => {
        const subscription = watch((data) => {
            validatePasswords(data);
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    async function onSubmit(data) {
        try {
            await api.updatePassword(agent.id, data);
            setSubmitStatus('success')

            methods.setError("apiError", {
                type: "manual",
                message: ""
            });

        } catch (error) {
            setSubmitStatus('error')

            methods.setError("apiError", {
                type: "manual",
                message: "Erro ao alterar a senha:"
            });
        }
    }

    const ChangePasswordBtn = () => (
        <SubmitButton
            alertType={submitStatus}
            setAlertType={setSubmitStatus}
            buttonText="Salvar"
            successMsg="Sucesso ao alterar senha."
            routeToGoBack="/beneficiarios"
            isLoading={methods.formState.isSubmitting}
            disabled={methods.formState.isSubmitting || submitStatus === "success"}
        />
    );

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <S.AlterarSenhaContainer>
                    <S.AlterarSenhaBox>
                        <S.HeaderText>
                            Alterar Senha
                        </S.HeaderText>
                        <S.ContentContainer>

                            <S.AlterarSenhaForms>
                                <S.InputConteiner>
                                    <CompPassword
                                        textLabel="Senha atual:"
                                        required
                                        style={{ flexGrow: '1' }}
                                        name={'old_password'}
                                        register={register}
                                        error={errors.old_password}
                                        helperText={errors.old_password && errors.old_password.message}
                                    //unregister={unregister}
                                    //error={methods.formState.errors.old_password}
                                    />
                                </S.InputConteiner>
                                <S.InputConteiner>
                                    <CompPassword
                                        name={'password'}
                                        required
                                        textLabel="Nova senha:"
                                        style={{ flexGrow: '1' }}
                                        register={register}
                                        //unregister={unregister}
                                        //error={errors.nis}
                                        onChange={handlePasswordChange}
                                    />

                                </S.InputConteiner>
                                <S.InputConteiner>
                                    <CompPassword
                                        name={'password2'}
                                        required
                                        textLabel="Confirme a nova senha:"
                                        style={{ flexGrow: '1' }}
                                        register={register}
                                        //unregister={unregister}
                                        //error={errors.nis}
                                        error={password2Error}
                                        onChange={handlePasswordChange}
                                        helperText={password2Error}
                                    />
                                </S.InputConteiner>

                            </S.AlterarSenhaForms>

                            <S.RecomendacoesConteiner>
                                <h3>Recomendações para criação de senhas:</h3>
                                <p><span style={{ color: 'red' }}>*</span> A senha não pode ser muito semelhante às suas outras informações pessoais. </p>
                                <p><span style={{ color: 'red' }}>*</span> A senha deve conter pelo menos 9 caracteres. </p>
                                <p><span style={{ color: 'red' }}>*</span> A senha não pode ser uma senha comumente usada. </p>
                                <p><span style={{ color: 'red' }}>*</span> A senha não pode ser totalmente numérica.</p>
                                <S.Botoes>
                                    <ChangePasswordBtn />
                                    <OrangeBackButton />
                                </S.Botoes>
                            </S.RecomendacoesConteiner>
                        </S.ContentContainer>
                    </S.AlterarSenhaBox>
                    <FooterAlt />
                </S.AlterarSenhaContainer>
            </form>
        </FormProvider >
    );
}

export default ChangePassword;
