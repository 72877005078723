import styled from 'styled-components';

export const Emoji = styled.img`
    width: 72px;
    height: 72px;
    flex-shrink: 0;
    margin-bottom: 0px;
`;

export const CloseButton = styled.button`
    border: none;
    background: none;
    width: 18px;
    height: 18px;
    flex-shrink: 0;
    color: #434343;
    font-size: 18px;
    position: absolute;
    top: 12px; 
    right: 16px;
    cursor: pointer;
`

export const AlertContent = styled.p`
    color: var(--dark-preto, #222);
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px; 
    width: 479px;
    margin-top: 0px;
    margin-bottom: 0px;
`;
