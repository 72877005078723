import React, { useEffect, useState } from "react";
import { useApi } from "hooks/useApis";
import Title from "components/Title";
import * as S from "./styles";
import CardCollapse from "components/CardCollapse";
import { Grid, CircularProgress, Button } from "@mui/material";
import * as f from "./functions.js";
import * as palette from "styles/variables";
import ConditionalityInfo from "./conditionalityInfo.jsx";
import { Link, useParams, useNavigate } from "react-router-dom";
import { getBeneficiaryType } from "utils";
import AgentType from "utils/agentType";
function History() {
  const api = useApi();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const agentType = AgentType();
  const navigate = useNavigate();
  const [condicionalities, setCondicionalities] = useState([]);
  const [condicionalitiesByMonth, setCondicionalitiesByMonth] = useState({});
  const [selectedYear, setSelectedYear] = useState("");
  const [benefit, setBenefit] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.historyCondicionality(id);
        setCondicionalities(response.results);
      } catch (err) {
        setError(err);
        console.log(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (condicionalities.length > 0) {
      const organizedData = f.organizeByMonth(condicionalities);
      setCondicionalitiesByMonth(organizedData);
    }
  }, [condicionalities]);

  const getBeneficiaryData = async () => {
    try {
      const data = await api.getBeneficiaryById(id);
      setBenefit(data);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (id) {
      getBeneficiaryData();
    }
  }, []);
  const handleYearClick = (year) => {
    setSelectedYear(year);
  };

  const uniqueYears = [
    ...new Set(
      Object.keys(condicionalitiesByMonth).map((date) => date.split("/")[1])
    ),
  ];

  const filteredData = selectedYear
    ? f.filterByYear(selectedYear, condicionalitiesByMonth)
    : condicionalitiesByMonth;
  // console.log(benefit)
  return (
    <>
      <S.Box>
        <Title
          titleText="Histórico de acompanhamentos"
          subtitleTexts={[
            "Início",
            "Gerência de beneficiários",
            <Link
              to={
                getBeneficiaryType(benefit.b_type) === "Gestante"
                  ? `/acompanhamento/gestante/${id}`
                  : `/acompanhamento/crianca/${id}`
              }
              style={{
                textDecoration: "none",
                color: `${palette.darkLaranja}`,
              }}
            >
              {benefit?.name ? benefit.name : "--"}
            </Link>,
            "Histórico de acompanhamentos",
          ]}
        />
        {loading ? (
          <S.LoadingBox>
            <CircularProgress style={{ color: `${palette.lightAmarelo}` }} />
          </S.LoadingBox>
        ) : condicionalities.length > 0 ? (
          <Grid container spacing={2}>
            {uniqueYears.map((year) => (
              <Grid item key={year}>
                <S.ButtonFilter
                  variant="outlined"
                  key={year}
                  onClick={() => handleYearClick(year)}
                  active={selectedYear === year}
                >
                  {year}
                </S.ButtonFilter>
              </Grid>
            ))}
            {Object.keys(filteredData).map((monthYear) => (
              <Grid item sm={12} key={monthYear}>
                <CardCollapse
                  title={`Relatório de ${monthYear}`}
                  children={filteredData[monthYear].map((condi) => (
                    <ConditionalityInfo condicionality={condi} />
                  ))}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <p>Histórico vazio.</p>
        )}
        <Button
          onClick={() => navigate(-1)}
          sx={{
            position: "relative",
            marginTop: '4%',
            left: "250",
            backgroundColor: palette.darkLaranja,
            color: "white",
            fontFamily: "Roboto",
            textTransform: "capitalize",
            fontSize: "16px",
            "&:hover": {
              backgroundColor: palette.lightAmarelo,
            },
          }}
        >
          Voltar
        </Button>
      </S.Box>
    </>
  );
}

export default History;
